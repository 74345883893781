import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Popup, Loader } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

import { useAppThunkDispatch } from 'src/store';
import { fetchEIDRelation } from 'src/actions/eIdentificationRelationActions';
import type { State } from 'src/types/initialState';

interface EIDRelationVerifierProps {
  contentId: number;
  metaData: {
    eIdentifier?: string;
    [key: string]: any;
  } | null;
}

export default function EIDRelationVerifier({ contentId, metaData }: EIDRelationVerifierProps) {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const eIdentificationRelation = useSelector((state: State) => state.eIdentificationsRelation[contentId]);
  const [isVerified, setVerified] = useState(!!eIdentificationRelation);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const parsedMetaData = typeof metaData === 'string' ? JSON.parse(metaData) : metaData;
    if (!eIdentificationRelation && parsedMetaData?.eIdentifier) {
      setLoading(true);
      dispatch(
        fetchEIDRelation({
          contentId,
          eIdentificationRelationHash: parsedMetaData?.eIdentifier
        })
      )
        .then(({ payload }) => {
          if (payload?.status === 'success') {
            setVerified(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [eIdentificationRelation]);

  const getContent = () => {
    if (isLoading) {
      return <Loader active inline />;
    }

    if (isVerified) {
      return (
        <Popup
          position="bottom right"
          trigger={<FontAwesomeIcon icon={faUserShield} color="green" size="xl" style={{ alignSelf: 'center' }} />}
        >
          <Popup.Content>{t('widgets.eIdentification.labels.ticketGeneratedBy')}</Popup.Content>
        </Popup>
      );
    }

    return null;
  };

  return <div style={{ display: 'flex', marginRight: '5px' }}>{getContent()}</div>;
}
