import ChannelType from '../CommentIconContent/ChannelType';
import React from 'react';
import { CommentWidgetButtons } from './CommentWidgetButtons';
import ErrorBoundary from '../../ErrorBoundary';

interface WhatsAppCommentButtonsProps {
  channel: number;
  onReplyClick: () => void;
}

export const WhatsAppCommentButtons = (props: WhatsAppCommentButtonsProps) => {
  if (props.channel !== ChannelType.WhatsApp) {
    return <></>;
  }

  return (
    <div className="Comment-Content">
      <ErrorBoundary>
        <CommentWidgetButtons onReplyClick={props.onReplyClick} />
      </ErrorBoundary>
    </div>
  );
};
