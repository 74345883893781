import type { UserWithProfile } from '@eeedo/types';

export const userToNumericalId = (user: UserWithProfile | string | number): number => {
  if (typeof user === 'number') {
    return user;
  }

  if (typeof user !== 'string') {
    user = user.UID;
  }

  return parseInt(user.substring(3), 10);
};
