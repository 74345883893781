import EventEmitter from 'events';
import type { EnreachVoiceWebRTC } from 'src/types/PhoneConfiguration';

export interface EnreachVoiceEvent extends Event {
  detail: {
    callDirection: 'incoming' | 'outbound';
    callId: string;
    endTime?: string;
    interlocutorName?: string;
    interlocutorProfileImageUrl?: string;
    isConsulted: boolean;
    phoneNumber: string;
    queueId: string;
    queueName: string;
    serverSideCallId: string;
    startTime?: string;
    userData: any;
  };
}

export enum EnreachStatus {
  callIncoming = 'call-incoming',
  callAnswered = 'call-answered',
  callEnded = 'call-ended',
  callInProgress = 'call-in-progress'
}

class EnreachVoiceIntegration extends EventEmitter {
  private static callId: string | null = null;

  constructor() {
    super();
    this.init();
  }

  private init() {
    const enreachVoiceFrame = document.querySelector<HTMLIFrameElement>('#EnreachVoice');

    if (enreachVoiceFrame) {
      enreachVoiceFrame.onload = this.onLoad.bind(this);
    }
  }

  private onLoad = (event: Event) => {
    this.emit('onload', event);
    const webrtc = EnreachVoiceIntegration.getWebRTC();

    if (webrtc) {
      webrtc.addEventListener('logged-in', this.loggedInHandler);
      webrtc.addEventListener('call-answered', this.callAnsweredHandler);
      webrtc.addEventListener('call-incoming', this.callIncomingHandler);
      webrtc.addEventListener('call-ended', this.callEndedHandler);
      webrtc.addEventListener('call-in-progress', this.callInProgressHandler);
    }
  };

  public static getWebRTC = (): EnreachVoiceWebRTC | null => {
    const iframe = document.querySelector('#EnreachVoice') as HTMLIFrameElement;
    return iframe?.contentDocument?.querySelector('webrtc-client-ui') || null;
  };

  destroy() {
    const webrtc = EnreachVoiceIntegration.getWebRTC();
    this.emit('logged-in', false);

    if (webrtc) {
      webrtc.removeEventListener('logged-in', this.loggedInHandler);
      webrtc.removeEventListener('call-answered', this.callAnsweredHandler);
      webrtc.removeEventListener('call-incoming', this.callIncomingHandler);
      webrtc.removeEventListener('call-ended', this.callEndedHandler);
      webrtc.removeEventListener('call-in-progress', this.callInProgressHandler);
    }
  }

  private loggedInHandler = () => {
    this.emit('logged-in', true);
  };

  private callAnsweredHandler = (event: Event) => {
    this.emit('call-answered', event);
    this.emit('status', EnreachStatus.callAnswered);
  };

  private callIncomingHandler = (event: Event) => {
    EnreachVoiceIntegration.callId = (event as EnreachVoiceEvent).detail.callId;

    this.emit('call-incoming', event);
    this.emit('status', EnreachStatus.callIncoming);
  };

  private callEndedHandler = (event: Event) => {
    this.emit('call-ended', event);
    this.emit('status', EnreachStatus.callEnded);
  };

  private callInProgressHandler = (event: Event) => {
    this.emit('call-in-progress', event);
    this.emit('status', EnreachStatus.callInProgress);
  };

  static answerEnreachVoice() {
    const webrtc = EnreachVoiceIntegration.getWebRTC();
    const { callId } = EnreachVoiceIntegration;

    if (webrtc && callId) {
      webrtc.answerCall(callId);
    }
  }

  static rejectEnreachVoice() {
    const webrtc = EnreachVoiceIntegration.getWebRTC();
    const { callId } = EnreachVoiceIntegration;

    if (webrtc && callId) {
      webrtc.rejectCall(callId);
    }
  }

  static callEnreachVoice(phoneNumber: string) {
    const webrtc = EnreachVoiceIntegration.getWebRTC();

    if (webrtc) {
      webrtc.call(phoneNumber);
    }
  }
}

export default EnreachVoiceIntegration;
