import React from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { Comment } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import type { ConnectedProps } from 'react-redux';
import type { CSSProperties } from 'react';

import type { ChatTypeStatusTypes } from 'src/types/ChatTypeStatus';
import type { State } from 'src/types/initialState';
import type ChatTypeStatus from 'src/types/ChatTypeStatus';

const RETURN_TO_IDLE_TIMEOUT = 10000;

interface ChatTypingStatusProps extends ConnectedProps<typeof connector> {
  ticketId: string;
}

interface ChatTypingStatusState {
  status: ChatTypeStatusTypes;
  timeoutId: NodeJS.Timeout | null;
}

class ChatTypingStatus extends React.Component<ChatTypingStatusProps, ChatTypingStatusState> {
  constructor(props: ChatTypingStatusProps) {
    super(props);

    this.state = {
      status: 'idle',
      timeoutId: null
    };
  }

  private reset() {
    if (this.state.timeoutId !== null) {
      clearTimeout(this.state.timeoutId);
    }
    this.setState({
      status: 'idle',
      timeoutId: null
    });
  }

  private setIdleTimout() {
    const timeoutId = setTimeout(() => {
      this.setState({ status: 'idle' });
    }, RETURN_TO_IDLE_TIMEOUT);
    this.setState({
      timeoutId
    });
  }

  componentDidMount() {
    const chatTypeStatus: ChatTypeStatus | undefined = this.props.statuses[this.props.ticketId];

    if (!chatTypeStatus) {
      // We didn't so we don't do anything
      return;
    }
    this.setState({ status: chatTypeStatus.status });
  }

  componentWillReceiveProps(nextProps: ChatTypingStatusProps) {
    // If the ticket changes, reset timer
    if (nextProps.ticketId !== this.props.ticketId) {
      this.reset();
      return;
    }

    // Do we have any statuses that affect this ticket
    const chatTypeStatus: ChatTypeStatus | undefined = nextProps.statuses[nextProps.ticketId];

    if (!chatTypeStatus) {
      // We didn't so we don't do anything
      return;
    }
    this.setState({ status: chatTypeStatus.status });

    // If we have previously defined a timeout, clear it
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }

    // If new status is "typing" set a new timeout
    if (chatTypeStatus.status === 'typing') {
      this.setIdleTimout();
    }
  }

  render() {
    const visibility: CSSProperties['visibility'] = this.state.status === 'typing' ? 'visible' : 'hidden';

    return (
      <Translation ns="translations">
        {(translation) => (
          <Comment style={{ visibility }}>
            <Avatar name={`C H A T`} size="35" style={{ float: 'left' }} round={false} />
            <Comment.Author as="a" className="s chatTypeStatusCommentTitle">
              {translation('TAB_NAME_CUSTOMER')}
            </Comment.Author>
            <Comment.Content className="CommentIcon">
              <Comment.Text>
                <div className="Comment-Content">
                  <div className="typing">
                    <div className="typing__dot" />
                    <div className="typing__dot" />
                    <div className="typing__dot" />
                  </div>
                </div>
              </Comment.Text>
            </Comment.Content>
          </Comment>
        )}
      </Translation>
    );
  }
}

const connector = connect((state: State) => ({
  statuses: state.chatTypeStatuses
}));

export default connector(ChatTypingStatus);
