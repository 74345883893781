import { createReducer } from '@reduxjs/toolkit';

import { fetchEIDRelationSuccess } from 'src/actions/eIdentificationRelationActions';

type EIdentificationsState = {
  eIdentificationsRelation: { [key: number]: string };
};

const initialState: EIdentificationsState = {
  eIdentificationsRelation: {}
};

const eIdentificationRelationReducer = createReducer(initialState.eIdentificationsRelation, (builder) => {
  builder.addCase(fetchEIDRelationSuccess, (state, action) => {
    const { contentId, eIdentificationRelationHash } = action.payload;
    state[contentId] = eIdentificationRelationHash;
  });
});

export default eIdentificationRelationReducer;
