import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Message, Button } from 'semantic-ui-react';

const MissingADAccount = () => {
  const { t } = useTranslation();

  return (
    <div className="loginWrapper">
      <div className="loginForm" style={{ maxWidth: '425px' }}>
        <Message warning>
          <Message.Header>{t('missingAd.message_title')}</Message.Header>
          <p>{t('missingAd.message_description')}</p>
        </Message>

        <Link to="/login" style={{ width: '100%' }}>
          <Button style={{ width: '100%' }}>{t('missingAd.login_page_button')}</Button>
        </Link>
      </div>
    </div>
  );
};

export default MissingADAccount;
