import { connect } from 'react-redux';

import type { SenderEmail } from '@eeedo/types';
import type { Dispatch } from 'redux';

import CommentEditorWidget from '../Components/CommentEditorWidget/CommentEditorWidget';
import ChannelType from '../Components/CommentIconContent/ChannelType';
import { appendToDraft, switchDraftTabIndex } from 'src/actions/draftActions';
import { Channels } from 'src/types/Channel';

import type { State } from 'src/types/initialState';

interface OwnProps {
  comment: string;
  taskId: string;
  title: string;
  metaData: any;
  senderEmails: SenderEmail[];
  created: number;
  isHTML: boolean;
  attachmentIds: string[];
  buttonsType?: 'basic' | 'primary';
}

const mapStateToProps = (_: State, ownProps: OwnProps) => ({ ...ownProps });

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    replyToEmail: (payload: any, taskID: string) => {
      dispatch(appendToDraft(payload, taskID, Channels.email));
      dispatch(switchDraftTabIndex(taskID, ChannelType.Email));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentEditorWidget);
