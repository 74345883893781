import type { ComponentProps, FC } from 'react';
import React from 'react';
import type { SemanticICONS } from 'semantic-ui-react';
import { Popup } from 'semantic-ui-react';
import { Button, Icon } from 'semantic-ui-react';

interface ReplyControlButtonProps extends ComponentProps<typeof Button> {
  iconName?: SemanticICONS;
  shrink?: boolean;
}

const ShrinkableButton: FC<ReplyControlButtonProps> = ({ children, iconName, shrink, ...buttonProps }) => {
  const button = (
    <Button icon {...buttonProps}>
      {!!iconName && <Icon name={iconName} />}
      {!shrink && children}
    </Button>
  );

  return shrink ? <Popup trigger={button} content={children} /> : button;
};

export default React.memo(ShrinkableButton);
