import { createSlice } from '@reduxjs/toolkit';

import type { TicketType } from '@eeedo/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export type TicketTypesState = TicketType[];

const initialState: TicketTypesState = [];

const ticketTypesSlice = createSlice({
  name: 'ticketTypes',
  initialState,
  reducers: {
    fetchTicketTypesSuccess: (_state, action: PayloadAction<TicketType[]>) =>
      action.payload.map((ticketType) => ({
        ...ticketType,
        id: typeof ticketType.id === 'string' ? parseInt(ticketType.id) : ticketType.id
      })),
    updateTicketTypeSuccess: (state, action: PayloadAction<TicketType>) => {
      const filteredTicketTypes = state.filter((ticketType) => ticketType.id !== action.payload.id);

      return [
        ...filteredTicketTypes,
        {
          ...action.payload,
          id: typeof action.payload.id === 'string' ? parseInt(action.payload.id) : action.payload.id
        }
      ];
    },
    removeTicketTypeSuccess: (state, action: PayloadAction<number>) => {
      return state.filter((ticketType) => ticketType.id !== action.payload);
    }
  }
});

export const { fetchTicketTypesSuccess, updateTicketTypeSuccess, removeTicketTypeSuccess } = ticketTypesSlice.actions;

export default ticketTypesSlice.reducer;
