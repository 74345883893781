import React from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
  tooltip: string;
  onClick: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  disabled: boolean;
}

const CallButton = ({ id, tooltip, disabled, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Popup
      content={tooltip}
      trigger={
        <Button
          positive
          icon="phone"
          id={id}
          labelPosition="left"
          content={t('CALL')}
          onClick={onClick}
          disabled={disabled}
        />
      }
    />
  );
};

export default CallButton;
