import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';

import type { PhoneConfiguration } from '@eeedo/types';

import ConfigurationApi from '../api/ConfigurationApi';
import EnvSettings from '../api/EnvSettings';
import FeatureFlags from '../api/FeatureFlags';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_PHONE_CONFIGURATIONS_SUCCESS } from './index';
import { initialRequestFailure, initialRequestSuccess } from './initActions';

export const fetchPhoneConfigurationsSuccess = createAction<{ configurations: PhoneConfiguration[] }>(
  FETCH_PHONE_CONFIGURATIONS_SUCCESS
);

export const fetchEnvSettings = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchEnvSettings',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_ENV_SETTINGS' }));
    try {
      const envSettings = await ConfigurationApi.getEnvSettings();
      EnvSettings.setSettings(envSettings);
      dispatch(initialRequestSuccess('envSettings'));
    } catch (error) {
      console.error('Failed to load env settings', error);
      dispatch(initialRequestFailure('envSettings', error));
    }
  }
);

export const fetchFeatureFlags = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchFeatureFlags',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_FEATURE_FLAGS' }));
    try {
      const featureFlags = await ConfigurationApi.getFeatureFlags();
      FeatureFlags.setFlags(featureFlags);
      dispatch(initialRequestSuccess('featureFlags'));
    } catch (error) {
      console.error('Failed to load featureFlags', error);
      dispatch(initialRequestFailure('featureFlags', error));
    }
  }
);

export const fetchPhoneConfigurations = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchPhoneConfigurations',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_ELISA_CONFIGURATIONS' }));
    try {
      const configurations = await ConfigurationApi.getPhoneConfigurations();
      dispatch(fetchPhoneConfigurationsSuccess({ configurations }));
      dispatch(initialRequestSuccess('PhoneConfigurations'));
    } catch (error) {
      iziToast.error({
        title: t('ERROR_HAPPENED'),
        message: t('management.phone_config.toasts.fetch_failed'),
        position: 'bottomRight',
        timeout: 2500,
        icon: 'delete'
      });
      dispatch(initialRequestFailure('PhoneConfigurations', error));
    }
  }
);

export const createPhoneConfigurations = createAsyncThunk<
  void,
  { phoneConfiguration: Omit<PhoneConfiguration, 'id'> },
  { rejectValue: Error }
>('createPhoneConfigurations', async ({ phoneConfiguration }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'CREATE_PHONE_CONFIGURATION' }));
  await ConfigurationApi.createPhoneConfigurations(phoneConfiguration);
});

export const updatePhoneConfigurations = createAsyncThunk<
  void,
  { id: number; phoneConfiguration: Omit<PhoneConfiguration, 'id'> },
  { rejectValue: Error }
>('updatePhoneConfigurations', async ({ id, phoneConfiguration }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'UPDATE_PHONE_CONFIGURATION' }));
  await ConfigurationApi.updatePhoneConfigurations(id, phoneConfiguration);
});

export const deletePhoneConfigurations = createAsyncThunk<void, { id: number }, { rejectValue: Error }>(
  'deletePhoneConfigurations',
  async ({ id }, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'DELETE_PHONE_CONFIGURATION' }));
    await ConfigurationApi.deletePhoneConfigurations(id);
  }
);
