import React from 'react';
import { useTranslation } from 'react-i18next';

import CommentIconHeader from './CommentIconHeader';

interface EntitiesDataContentProps {
  index: number;
  ticketCustomerFields: any;
  data: any;
}

function EntitiesDataContent(props: EntitiesDataContentProps) {
  const { t } = useTranslation();

  return (
    <CommentIconHeader index={props.index} title={t('COMMENT_METADATA_ENTITIES')}>
      {props.ticketCustomerFields &&
        props.ticketCustomerFields.customerInfo.map((cField: any) => {
          if (cField.object && props.data[cField.object]) {
            return <p>{cField.name + ': ' + (props.data[cField.object][cField.value] || '')}</p>;
          } else {
            return <p>{cField.name + ': ' + (props.data[cField.value] || '')}</p>;
          }
        })}
    </CommentIconHeader>
  );
}

export default EntitiesDataContent;
