import { history } from '../reducers/browserHistory';

import type { ContentTypePrefixes } from '../types/ContentTypes';

export const HistoryPrefixValues = ['case', 'infopage'];
export type HistoryPrefixes = (typeof HistoryPrefixValues)[number];

export const ContentTypePrefixesToHistoryPrefix: { [key in ContentTypePrefixes]: HistoryPrefixes } = {
  TSK: 'case',
  INF: 'infopage'
};

export const contentIdToHistoryPrefix = (id: string): HistoryPrefixes => {
  return ContentTypePrefixesToHistoryPrefix[id.substring(0, 3)] ?? 'case';
};

export const buildContentPathname = (id: string) => `/${contentIdToHistoryPrefix(id)}/${id}`;

export const navigateToContent = (id: string): void => {
  history.push({ pathname: buildContentPathname(id), search: window.location.search });
};
