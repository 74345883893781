import type { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '80vw',
  maxHeight: '80vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollbarWidth: 'none'
};
