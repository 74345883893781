import iziToast from 'izitoast';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Button, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { ButtonProps } from 'semantic-ui-react';

import * as styles from './RescheduleCallback.style';
import RescheduledCallbacksApi from 'src/api/RescheduledCallbacksApi';
import DateTimePicker from 'src/Components/DateTimePicker';
import {
  addRescheduledCallback,
  setRescheduleModalOpen,
  setSelectedRescheduledDate,
  setSelectedRescheduledOption
} from 'src/reducers/rescheduledCallbackWidget';
import { selectActiveTicketType, selectRescheduleWidgetOptions } from 'src/selectors/rootStateSelectors';
import { userToNumericalId } from 'src/Utilities/users';

import type { State } from 'src/types/initialState';
import type { RescheduledCallback } from 'src/types/RescheduledCallback';

interface RescheduleCallbackModalProps extends ConnectedProps<typeof connector> {
  contentId: number;
}

const RescheduleCallbackModal: FC<RescheduleCallbackModalProps> = ({
  contentId,
  open,
  callbacks,
  selectedDate,
  rescheduledBy,
  widgetOptions,
  ticketTypeName
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(true);

  const options = useMemo(
    () =>
      widgetOptions ?? [
        { text: `1 ${t('widgets.reschedule_call.hour')}`, value: 1 },
        { text: `6 ${t('widgets.reschedule_call.hours')}`, value: 6 },
        { text: t('widgets.reschedule_call.tomorrow'), value: 24 },
        { text: `2 ${t('widgets.reschedule_call.days')}`, value: 48 },
        { text: `7 ${t('widgets.reschedule_call.days')}`, value: 168 }
      ],
    [ticketTypeName]
  );

  const callback = callbacks[callbacks.length - 1];

  const openModal = useCallback(() => {
    dispatch(setRescheduleModalOpen(true));
  }, []);

  const closeModal = useCallback(() => {
    dispatch(setSelectedRescheduledDate(undefined));
    dispatch(setSelectedRescheduledOption(undefined));
    dispatch(setRescheduleModalOpen(false));
  }, []);

  const onDateChange = useCallback((timestamp: number | null) => {
    if (timestamp === null) {
      return;
    }

    dispatch(setSelectedRescheduledDate(timestamp));
    setIsSaved(false);
  }, []);

  const onSave = useCallback(() => {
    if (selectedDate) {
      RescheduledCallbacksApi.createRescheduledCallback({
        contentId,
        rescheduledBy,
        timestamp: selectedDate
      })
        .then((newCallback?: RescheduledCallback) => {
          if (newCallback?.id) {
            dispatch(addRescheduledCallback(newCallback));
          }
          setIsSaved(true);
          closeModal();
          iziToast.success({ message: t('widgets.reschedule_call.rescheduled_successfully') });
        })
        .catch((error) => {
          console.error(error);
          iziToast.error({ message: t('widgets.reschedule_call.failed_to_reschedule') });
        });
    }
  }, [contentId, rescheduledBy, selectedDate]);

  const onOptionClick = useCallback<NonNullable<ButtonProps['onClick']>>((_e, data) => {
    const value = data.value as number;

    const timestamp = moment().add(value, 'hours').unix();
    dispatch(setSelectedRescheduledDate(timestamp));
    setIsSaved(false);
  }, []);

  return (
    <Modal
      open={open}
      trigger={<Button>{t('widgets.reschedule_call.choose_new_duedate')}</Button>}
      onOpen={openModal}
      onClose={closeModal}
      closeIcon
    >
      <ModalHeader>{t('widgets.reschedule_call.scheduleMessage')}</ModalHeader>
      <ModalContent>
        <div style={styles.modalContentWrapper}>
          <div style={styles.optionsWrapper}>
            <h3>{t('widgets.reschedule_call.move_duedate_by')}:</h3>

            {options.map((option, index) => (
              <Button
                key={`reschedule-callback-button-${index}`}
                style={styles.optionButton}
                value={option.value}
                onClick={onOptionClick}
              >
                {option.text}
              </Button>
            ))}
          </div>
          <div style={styles.datepickerWrapper}>
            <h3>{t('widgets.reschedule_call.choose_specific_time')}:</h3>

            <DateTimePicker
              value={selectedDate ?? callback?.timestamp}
              onChange={onDateChange}
              minDate={moment()}
              inline
            />
          </div>
        </div>
      </ModalContent>
      <ModalActions>
        <Button primary disabled={isSaved || !selectedDate} onClick={onSave}>
          {t('GENERAL_SAVE')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

const connector = connect((state: State) => ({
  open: state.rescheduleCallbackWidget.open,
  widgetOptions: selectRescheduleWidgetOptions(state),
  ticketTypeName: selectActiveTicketType(state)?.name,
  rescheduledBy: userToNumericalId(state.userData.UID),
  selectedDate: state.rescheduleCallbackWidget.selectedDate,
  callbacks: state.rescheduleCallbackWidget.rescheduledCallbacks
}));

export default connector(RescheduleCallbackModal);
