import React from 'react';
import { Route, Switch } from 'react-router-dom';

import type { PersonalData } from '@eeedo/types';

import InfoPagesSidebar from '../Sidebar/InfoPagesSidebar';
import TicketsSidebar from '../Sidebar/TicketsSidebar';
import FeatureFlags from 'src/api/FeatureFlags';
import ActivateFeatureModal from 'src/Components/generic/ActivateFeatureModal';
import EntityViewer from 'src/Components/Management/Entities/EntityViewer';
import PhoneIntegrations from 'src/Components/PhoneIntegrations';
import TicketListTab from 'src/Components/ticketList/TicketListTab';
import CaseContainer from 'src/containers/CaseContainer';
import InfopageCaseContainer from 'src/containers/InfopageCaseContainer';
import SettingsContainer from 'src/containers/SettingsContainer';
import TicketListTabBarContainer from 'src/containers/TicketListTabBarContainer';
import TicketTabBarContainer from 'src/containers/TicketTabBarContainer';
import urlActionContainer from 'src/containers/urlActionContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import ReportingPage from 'src/ReportingPage';

type MainViewProps = {
  mobileMode: boolean;
  hideTicketList: boolean;
  mobileSideMenuClass: string;
  mobileContentClass: string;
  userPersonalData: PersonalData;
};

const MainView: React.FC<MainViewProps> = ({
  mobileMode,
  hideTicketList,
  mobileSideMenuClass,
  mobileContentClass,
  userPersonalData
}) => (
  <>
    {mobileMode ? (
      <div className={`${mobileSideMenuClass} mobileSideMenu`} style={{ display: hideTicketList ? 'none' : 'block' }}>
        <ErrorBoundary>
          <TicketListTabBarContainer />
        </ErrorBoundary>
        <div className="flexSideView">
          <ErrorBoundary>
            <TicketListTab contentType="tickets" />
          </ErrorBoundary>

          {userPersonalData.permissions.includes('allowPhoneIntegrations') && (
            <ErrorBoundary>
              <PhoneIntegrations />
            </ErrorBoundary>
          )}
        </div>
      </div>
    ) : (
      <MainNonMobileView hideTicketList={hideTicketList} userPersonalData={userPersonalData} />
    )}

    <div className={mobileMode ? mobileContentClass : 'detailView'}>
      <MainRouteSwitch mobileMode={mobileMode} />
    </div>
  </>
);

const MainNonMobileView: React.FC<{ hideTicketList: boolean; userPersonalData: PersonalData }> = ({
  hideTicketList,
  userPersonalData
}) => (
  <ErrorBoundary>
    <Switch>
      <Route>
        <TicketsSidebar userPersonalData={userPersonalData} onSettingsPage={hideTicketList} />

        <Switch>
          <Route
            path={['/infopage', '/infopage/:id']}
            render={(routeProps) => (
              <InfoPagesSidebar
                {...routeProps}
                userPersonalData={userPersonalData}
                key={(routeProps.match.params as { id: string }).id}
              />
            )}
          />
        </Switch>
      </Route>
    </Switch>
  </ErrorBoundary>
);

const MainRouteSwitch: React.FC<{ mobileMode: boolean }> = ({ mobileMode }) => (
  <>
    <ErrorBoundary>
      <Switch>
        {!mobileMode && FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
          <Route
            path={['/infopage', '/infopage/:id']}
            render={(routeProps) => (
              <TicketTabBarContainer
                {...routeProps}
                contentType="infopages"
                key={(routeProps.match.params as { id: string }).id}
              />
            )}
          />
        )}
        <Route path={'/entities'} />
        <Route>
          <TicketTabBarContainer contentType="tickets" />
        </Route>
      </Switch>

      <Switch>
        {FeatureFlags.isFlagOn('ENABLE_ENTITY_VIEWER') && <Route path="/entities/" component={EntityViewer} />}
        <Route
          path="/case/:id"
          exact={true}
          render={(routeProps) => <CaseContainer {...routeProps} key={routeProps.match.params.id} />}
        />

        {!mobileMode && FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
          <Route
            path="/infopage/:id"
            render={(routeProps) => <InfopageCaseContainer {...routeProps} key={routeProps.match.params.id} />}
          />
        )}
        {!FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && <Route path="/feature" component={ActivateFeatureModal} />}

        <Route path="/actions/:type" component={urlActionContainer} />
        <Route path="/reporting" component={ReportingPage} exact={true} />
        <Route
          path={['/settings/:pageName', '/settings']}
          render={(...props) => <SettingsContainer noMenuContainer={mobileMode} {...(props as any)} />}
        />
      </Switch>
    </ErrorBoundary>
  </>
);

export default MainView;
