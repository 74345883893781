import { SET_SETTINGS_TAB } from '../actions/index';

const initialState = {
  activeTabIndex: 0
};

export type SettingsState = typeof initialState

const settingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SETTINGS_TAB: {
      const nState = { ...state };
      nState.activeTabIndex = action.payload.index;
      return nState;
    }
    default:
      return state;
  }
};

export default settingsReducer;
