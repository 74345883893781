export type ContentTypes = 'task' | 'message' | 'infopage' | 'poll' | 'reportitem';

export const ContentTypePrefixValues = ['TSK', 'INF'] as const;
export type ContentTypePrefixes = typeof ContentTypePrefixValues[number];

export const ContentTypePrefixesToTypes: { [key in ContentTypePrefixes]: ContentTypes } = {
  TSK: 'task',
  INF: 'infopage'
};

export const typeToPrefix = (type: ContentTypes | undefined) => {
  switch (type) {
    case 'task':
    case null:
    default:
      return 'TSK';
    case 'infopage':
      return 'INF';
  }
};

export const ticketIdToType = (id: string): ContentTypes => {
  for (const entry of Object.entries(ContentTypePrefixesToTypes)) {
    const prefix = entry[0];
    const type = entry[1];

    if (id.startsWith(prefix)) {
      return type;
    }
  }

  return 'task';
};
