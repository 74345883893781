import { SEARCH_ENTITY_BY_DETAILS_SUCCESS } from '../actions/index';

const initialState = {
  entitySearchResults: []
};

const entityReducer = (state = initialState.entitySearchResults, action: any) => {
  switch (action.type) {
    case SEARCH_ENTITY_BY_DETAILS_SUCCESS: {
      return action.entities;
    }

    default: {
      return state;
    }
  }
};

export default entityReducer;
