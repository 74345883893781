import { createAction } from '@reduxjs/toolkit';

import {
  CLEAR_INFOPAGELIST_FILTER,
  FETCH_INFOPAGES_ERROR,
  FETCH_INFOPAGES_SUCCESS,
  REMOVE_INFOPAGE_FROM_INFOPAGELIST,
  SET_INFOPAGELIST_ACCORDION,
  SET_INFOPAGELIST_FILTER,
  UPDATE_INFOPAGE_IN_INFOPAGELIST
} from '.';

import type { IFilterItem } from '../types/Filter';
import type { TicketListTicket } from '../types/Ticket';
import type { InfoPageListItem } from '../Utilities/infoPageList';

export const setInfopagelistFilter = createAction<IFilterItem>(SET_INFOPAGELIST_FILTER);

export const fetchInfoPagesSuccess = createAction<{ tickets: InfoPageListItem[]; id: string }>(FETCH_INFOPAGES_SUCCESS);

export const fetchInfoPagesError = createAction<string>(FETCH_INFOPAGES_ERROR);

export const updateInfoPageInList = createAction(
  UPDATE_INFOPAGE_IN_INFOPAGELIST,
  (data: TicketListTicket, tabId: string) => ({
    payload: { data, tabId }
  })
);

export const setInfoPageListAccordion = createAction<{ id: string | null; accordionIndex: number | null }>(
  SET_INFOPAGELIST_ACCORDION
);

export const clearInfopagelistFilter = createAction<string>(CLEAR_INFOPAGELIST_FILTER);

export const removeInfopageFromInfopagelist = createAction(
  REMOVE_INFOPAGE_FROM_INFOPAGELIST,
  (infopageId: string, tabId: string) => ({
    payload: { infopageId, tabId }
  })
);
