import type { ActionCreator, AnyAction } from 'redux';

import { TOGGLE_MOBILE_MODE } from './index';

export interface MobileAction extends AnyAction {
  type: string;
  payload: boolean;
}

export const toggleMobileMode: ActionCreator<MobileAction> = (payload: boolean) => {
  return { type: TOGGLE_MOBILE_MODE, payload: payload };
};
