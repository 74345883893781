import { createReducer } from '@reduxjs/toolkit';

import {
  addNotification,
  removeAllNotifications,
  removeNotification,
  showDisconnectedNotification
} from '../actions/notificationsActions';

export interface INotificationStore {
  delays: string[];
  soundTimeouts: string[];
  popupTimeouts: string[];
  runOnce: string[];
  showDisconnected: boolean;
}

const initialState: INotificationStore = {
  delays: [],
  soundTimeouts: [],
  popupTimeouts: [],
  runOnce: [],
  showDisconnected: false
};

const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addNotification, (draft, action) => {
      const { ticketId, type } = action.payload;
      if (!draft[type].includes(ticketId)) {
        draft[type].push(ticketId);
      }
    })
    .addCase(removeNotification, (draft, action) => {
      const { ticketId, type } = action.payload;
      const ticketIndex = draft[type].findIndex((id: string) => id === ticketId);
      if (ticketIndex > -1) {
        draft[type].splice(ticketIndex, 1);
      }
    })
    .addCase(removeAllNotifications, () => initialState)
    .addCase(showDisconnectedNotification, (draft, action) => {
      draft.showDisconnected = action.payload;
    });
});

export default notificationsReducer;
