import { createReducer } from '@reduxjs/toolkit';

import { setEnreachVoiceStatus, setEnreachVoiceLoggedIn } from 'src/actions/integrationsActionsRTK';
import type { EnreachStatus } from 'src/Utilities/intergrations/callEnreachVoice';

export type IntegrationsReducerState = {
  enreachVoiceLoggedIn: boolean;
  enreachVoiceStatus: EnreachStatus | null;
};

const initialState: IntegrationsReducerState = {
  enreachVoiceLoggedIn: false,
  enreachVoiceStatus: null
};

const integrationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setEnreachVoiceLoggedIn, (draft, action) => {
      draft.enreachVoiceLoggedIn = action.payload.loggedIn;
    })
    .addCase(setEnreachVoiceStatus, (draft, action) => {
      draft.enreachVoiceStatus = action.payload.status;
    });
});

export default integrationsReducer;
