import type { ChatStatus } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class ChatsApi {
  static getChats = async () => {
    return apiClient.get<ChatStatus[]>('/chatStatuses').then((response) => response.data);
  };

  static patchChat = async (id: number, payload: Partial<ChatStatus>) => {
    return apiClient.patch<number>(`/chatStatus/${id}`, payload).then((response) => response.data);
  };

  static toggleAllChats = async (status: 'enable' | 'disable') => {
    return apiClient.patch('/chatStatus', { status }).then((response) => response.data);
  };

  static createChat = async (payload: Partial<ChatStatus>) => {
    return apiClient
      .post('/chatStatuses', payload)
      .then((response) => response.data)
      .catch((error) => {
        console.error('ERROR createChat: ', error);
        throw error;
      });
  };
}
