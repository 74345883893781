import iziToast from 'izitoast';
import { get } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Divider, Icon } from 'semantic-ui-react';

import type { Field } from '@eeedo/types';
import type { CSSProperties, DragEvent, FC } from 'react';

import EntityDeletionModal from '../EntityDeletionModal';
import EntityDetails from './EntityDetails';
import EntityTicketCreationModal from './EntityTicketCreationModal';

import type { Entity } from 'src/types/Ticket';

interface CustomerResultProps {
  fields: Field[];
  values: Entity;
  readonly: boolean;
  mongodb?: boolean;
  isAttached?: boolean;
  onTicketCreateSuccess(): void;
  onDrop?(entitiesIds: string[]): void;
  onClick?(): void;
  deleteEntity?(entity?: Entity): Promise<void>;
}

const attachedStyles: CSSProperties = {
  boxShadow: '0 0 0 1px #d4d4d5, 0 5px 0 0 #21ba45, 0 1px 3px 0 #d4d4d5'
};

const EntityResult: FC<CustomerResultProps> = ({
  fields,
  values,
  isAttached,
  readonly,
  mongodb,
  onDrop,
  onClick,
  deleteEntity,
  onTicketCreateSuccess
}) => {
  const { t } = useTranslation();

  const onClickAlreadyAttachedEntity = useCallback(() => {
    iziToast.info({
      icon: 'info',
      message: t('TICKET_CUSTOMER_ALREADY_ATTACHED_TOAST'),
      timeout: 5000
    });
  }, []);

  const entityName = useMemo(() => {
    const firstNameField = fields.find((field) => field.displayField === 'name1');
    const lastNameField = fields.find((field) => field.displayField === 'name2');
    const additionalNameField = fields.find((field) => field.displayField === 'name3');

    const firstName = firstNameField ? get(values, firstNameField.value, '') : '';
    const lastName = lastNameField ? get(values, lastNameField.value, '') : '';
    const additionalName = additionalNameField ? get(values, additionalNameField.value, '') : '';

    return [firstName, lastName, additionalName].filter(Boolean).join(' ');
  }, [fields, values]);

  return (
    <Card
      data-test="entityResult"
      className="entity-result-card"
      link={!isAttached}
      color={isAttached ? 'green' : undefined}
      style={{
        ...(isAttached ? attachedStyles : {}),
        width: '100%',
        padding: '0.5em',
        cursor: isAttached ? 'default' : readonly ? 'pointer' : 'grab'
      }}
      fluid
      draggable={!readonly}
      onMouseDown={isAttached ? onClickAlreadyAttachedEntity : onClick}
      onDragStart={(event: DragEvent) => {
        event.dataTransfer.setData('text/plain', values._id);
      }}
      onDragOver={(e: DragEvent) => {
        e.preventDefault();
      }}
      onDrop={(event: DragEvent) => {
        onDrop?.([values._id, event.dataTransfer.getData('text/plain')]);
      }}
    >
      <Card.Content>
        <Card.Header>
          <div className="customerCard">
            <div className="customerName">{entityName}</div>
            <div className={mongodb ? 'eeedoCustomer' : 'integrationCustomer'} />
          </div>
        </Card.Header>
        <Card.Meta style={{ fontSize: '16px' }}>
          <EntityDetails fields={fields} values={values} />
        </Card.Meta>
      </Card.Content>
      {isAttached && (
        <Card.Content extra>
          <Icon name="check" color="green" /> {t('TICKET_CUSTOMER_ALREADY_ATTACHED_CARD')}
        </Card.Content>
      )}
      <Divider style={{ margin: '0 5px' }} />
      <Card.Content style={{ border: 'none' }}>
        <EntityTicketCreationModal entity={values} onTicketCreateSuccess={onTicketCreateSuccess} />
        {typeof deleteEntity === 'function' && !readonly && (
          <div style={{ float: 'right' }}>
            <EntityDeletionModal deleteEntity={deleteEntity} mini />
          </div>
        )}
      </Card.Content>
    </Card>
  );
};

export default React.memo(EntityResult);
