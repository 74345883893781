import { trimAndSplitRecipients } from '../Utilities/recipients';
import { transformSecureEmailField } from '../Utilities/secureEmail';
import ReplyEmail from './ReplyEmail';
import ApiConfig from 'src/api/ApiConfig';
import { serializeContent } from 'src/Utilities/parseUtils';

import type { ReplyEmailProps } from './ReplyEmail';
import type { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';

class ReplySecureEmail extends ReplyEmail {
  constructor(props: ReplyEmailProps) {
    super(props);
  }

  protected getAddressValue(type: 'to' | 'cc' | 'bcc', addresses: string, opts: any): string[] | undefined {
    const value = addresses.length > 0 ? trimAndSplitRecipients(addresses)?.filter((address) => address) : undefined;
    return transformSecureEmailField(type, value, opts) as string[] | undefined;
  }

  /**
   *  submitEmail function : encrypt email in replySecureEmail Component
   *  in the mongo db, cv-dev->TicketTypes (id:1 support) additionnal data : secureMailTitlePrefix : "*salaa*" was added
   *  so if the ticketType.secureMailTitlePrefix exists, we add its contents into the subject
   */
  protected submitEmail = async (closeWithStatus?: TicketStatusAfterCommentSubmit) => {
    try {
      const { secureMailAddressMailPostfix, secureMailTitlePrefix, secureMailTitlePostfix } = this.props.ticketType;
      const { from, to, cc, bcc, subject, content, selectedAttachmentIds } = this.state;
      const secureTransformOptions = {
        apiurl: ApiConfig.getConfig().API_URL,
        secureMailAddressMailPostfix,
        secureMailTitlePrefix,
        secureMailTitlePostfix
      };

      const addresses = {
        to: this.getAddressValue('to', to, secureTransformOptions),
        cc: this.getAddressValue('cc', cc, secureTransformOptions),
        bcc: this.getAddressValue('bcc', bcc, secureTransformOptions)
      };

      const title = (
        transformSecureEmailField(
          'title',
          subject?.length > 900 ? subject.slice(0, 900) : subject,
          secureTransformOptions
        ) as string
      ).trim();

      const contentWithCids = serializeContent(content, this.props.attachments);
      const metaData = {
        cc: addresses.cc,
        bcc: addresses.bcc,
        title,
        from,
        to: addresses.to,
        attachments: this.getSelectedAttachments(selectedAttachmentIds)
      };
      const bodyOfRequest = {
        content: subject?.length > 900 ? subject.slice(900, -1) + ' \n\n ' + contentWithCids : contentWithCids,
        sendAsMail: true,
        subject: title,
        channel: this.channel,
        direction: 'out',
        metaData,
        html: contentWithCids,
        ...metaData
      };

      const response = await this.props.onSubmit(bodyOfRequest);
      if (response) {
        if (typeof closeWithStatus !== 'undefined') {
          this.props.changeTicketStatus(this.props.taskId, this.props.userData.UID, closeWithStatus);
        }
        this.clearFields();
      }
    } catch (error) {
      console.error('Failed to save ticket', error);
    } finally {
      this.updateState({
        closeWithStatus: undefined
      });
    }
  };
}

export default ReplySecureEmail;
