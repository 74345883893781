import React, { createContext, useContext, useState } from 'react';

import type { FunctionComponent, ReactNode } from 'react';

import type { ModalSize } from './types';

interface ModalContextType {
  modalContent: ReactNode;
  modalSize: ModalSize | undefined;

  openModal: (content: ReactNode, size?: ModalSize) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export const ModalProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalSize, setModalSize] = useState<ModalSize | undefined>(undefined);

  const openModal = (content: ReactNode, size: ModalSize = 'small') => {
    setModalContent(content);
    setModalSize(size);
  };

  const closeModal = () => {
    setModalContent(null);
    setModalSize(undefined);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, modalContent, modalSize }}>{children}</ModalContext.Provider>
  );
};
