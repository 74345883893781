import { t } from 'i18next';
import iziToast from 'izitoast';
import md5 from 'js-md5';

import type { AxiosError } from 'axios';

export const getTicketHash = (id: string, created: number) => md5(id + created.toString()).substring(0, 2);

export const handleAssignOperationRejection = (err: unknown) => {
  if (
    (err as AxiosError)?.isAxiosError &&
    ((err as AxiosError)?.response?.data as Record<string, any>)?.msg === 'failed to lock the workstatus operation'
  ) {
    iziToast.error({
      title: t('ERROR_SOMEONE_ELSE_STARTED_WORKING_ON'),
      position: 'center',
      timeout: 5000
    });
    return;
  }

  throw err;
};
