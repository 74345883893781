import { createAction } from '@reduxjs/toolkit';

import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
  SHOW_DISCONNECTED_NOTIFICATION
} from './index';
import type { NotificationStoreType } from 'src/types/Notifications';

const preparePayload = (ticketId: string, type: NotificationStoreType) => ({ payload: { ticketId, type } });

export const addNotification = createAction(ADD_NOTIFICATION, preparePayload);

export const removeNotification = createAction(REMOVE_NOTIFICATION, preparePayload);

export const removeAllNotifications = createAction(REMOVE_ALL_NOTIFICATIONS);

export const showDisconnectedNotification = createAction<boolean>(SHOW_DISCONNECTED_NOTIFICATION);
