import { cloneDeep } from 'lodash-es';

import { ACTIVATE_TAB, SWIPE_LEFT, SWIPE_RIGHT, TOGGLE_MOBILE_MODE } from '../actions/index';
import { closeTab } from 'src/actions/tabActionsRTK';

const initialState = {
  leftTabOpen: true,
  rightTabOpen: false,
  mobileMode: false
};

export type MobileReducerType = typeof initialState;

const mobileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SWIPE_LEFT: {
      const newState = cloneDeep(state);
      newState.leftTabOpen = false;
      return newState;
    }
    case SWIPE_RIGHT:
    case closeTab.type: {
      const newState = cloneDeep(state);
      newState.leftTabOpen = true;
      return newState;
    }
    case ACTIVATE_TAB: {
      const newState = cloneDeep(state);
      newState.leftTabOpen = false;
      return newState;
    }
    case TOGGLE_MOBILE_MODE: {
      const newState = cloneDeep(state);
      newState.mobileMode = action.payload;
      return newState;
    }
    default:
      return state;
  }
};

export default mobileReducer;
