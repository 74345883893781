import queryString from 'query-string';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';

import type { InputOnChangeData } from 'semantic-ui-react';

import ApiConfig from './api/ApiConfig';
import AccordionHeader from './Components/Case/AccordionHeader';
import { getURLParam } from 'src/Utilities/helper';

export type LoginFunction = (user: string, pasword: string) => Promise<void>;

export interface LoginProps {
  logUserIn: LoginFunction;
  isUserAuthenticated: boolean;
  hasEnteredIncorrectLoginCredentials: boolean;
  initialAddress: string;
  activationSuccess: boolean;
}

export interface LoginState {
  user: string;
  password: string;
  isLoading: boolean;
}

export default class LoginComponent extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      user: '',
      password: '',
      isLoading: false
    };
  }

  private buttonHandler = () => {
    if (!this.state.isLoading) {
      this.setState(
        {
          isLoading: true
        },
        async () => {
          await this.props.logUserIn(this.state.user, this.state.password);
          this.setState({ isLoading: false });
        }
      );
    }
  };

  private handleRedirect = () => {
    const redirectUrl = getURLParam<string | undefined>(window.location.search, 'redirectUrl');
    const searchParam = redirectUrl ? `?${queryString.stringify({ redirectUrl })}` : '';

    return (window.location = (ApiConfig.getConfig().API_URL + `/login/${searchParam}`) as unknown as Location);
  };

  private handleChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({ ...this.state, [data.name]: data.value });
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <div className="loginWrapper">
            {this.props.isUserAuthenticated ? (
              <Redirect to={localStorage.getItem('initialAddress') || '/'} />
            ) : (
              <div className="loginForm">
                <div className="logo" />
                <Form style={{ width: '260px', paddingTop: '10px', margin: '10px 0' }}>
                  {ApiConfig.getConfig().LIKEIT_AUTH === true && (
                    <div>
                      <Form.Button
                        className="extLoginButton"
                        labelPosition="right"
                        icon={true}
                        type="button"
                        fluid={true}
                        onClick={this.handleRedirect}
                      >
                        {'Kirjaudu Likeit-tunnuksilla'}
                        <Icon name="sign in" />
                      </Form.Button>
                      <div className="eeedoAccordionLogin">
                        <AccordionHeader
                          as="h4"
                          active={false}
                          title={t('LOGIN_WITH_EEEDO_CREDENTIALS')}
                          icon="lock"
                          id={'placeholder'}
                          render={() => (
                            <div>
                              <Form.Input
                                error={this.props.hasEnteredIncorrectLoginCredentials}
                                disabled={this.state.isLoading}
                                autoComplete="username"
                                name="user"
                                icon="user"
                                placeholder={t('PLACEHOLDER_USERNAME')}
                                onChange={this.handleChange}
                              />
                              <Form.Input
                                error={this.props.hasEnteredIncorrectLoginCredentials}
                                autoComplete="current-password"
                                disabled={this.state.isLoading}
                                name="password"
                                type="password"
                                icon="lock"
                                placeholder={t('PLACEHOLDER_PASSWORD')}
                                onChange={this.handleChange}
                              />

                              <div style={{ margin: '10px' }}>
                                <Link to="/forgotPassword"> {t('LOGIN_FORGOTPASSWORD')} </Link>{' '}
                              </div>

                              <Form.Button
                                loading={this.state.isLoading}
                                labelPosition="right"
                                icon={true}
                                fluid={true}
                                onClick={this.buttonHandler}
                              >
                                {t('LOGIN_LOG_IN')}
                                <Icon name="sign in" />
                              </Form.Button>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}
                  {ApiConfig.getConfig().LIKEIT_AUTH === false && ApiConfig.getConfig().AD_ENABLED !== true && (
                    <div>
                      <Form.Input
                        error={this.props.hasEnteredIncorrectLoginCredentials}
                        disabled={this.state.isLoading}
                        autoComplete="username"
                        name="user"
                        icon="user"
                        placeholder={t('PLACEHOLDER_USERNAME')}
                        onChange={this.handleChange}
                      />
                      <Form.Input
                        error={this.props.hasEnteredIncorrectLoginCredentials}
                        autoComplete="current-password"
                        disabled={this.state.isLoading}
                        name="password"
                        type="password"
                        icon="lock"
                        placeholder={t('PLACEHOLDER_PASSWORD')}
                        onChange={this.handleChange}
                      />

                      <div style={{ margin: '10px' }}>
                        <Link to="/forgotPassword"> {t('LOGIN_FORGOTPASSWORD')} </Link>{' '}
                      </div>

                      <Form.Button
                        loading={this.state.isLoading}
                        labelPosition="right"
                        icon={true}
                        type="submit"
                        fluid={true}
                        onClick={this.buttonHandler}
                      >
                        {t('LOGIN_LOG_IN')}
                        <Icon name="sign in" />
                      </Form.Button>
                    </div>
                  )}
                  {ApiConfig.getConfig().AD_ENABLED === true && (
                    <>
                      <Form.Button
                        className="extLoginButton marginTop10px"
                        type="button"
                        labelPosition="right"
                        icon={true}
                        fluid={true}
                        onClick={this.handleRedirect}
                      >
                        <span style={{ paddingRight: '10px' }}>{t('LOGIN_WITH_MICROSOFT_CREDENTIALS')}</span>
                        <Icon name="sign in" />
                      </Form.Button>
                      {
                        <div className="eeedoAccordionLogin marginTop25px">
                          <AccordionHeader
                            as="h4"
                            icon="lock"
                            active={false}
                            title={t('LOGIN_WITH_EEEDO_CREDENTIALS')}
                            render={() => (
                              <div>
                                <Form.Input
                                  error={this.props.hasEnteredIncorrectLoginCredentials}
                                  disabled={this.state.isLoading}
                                  autoComplete="username"
                                  name="user"
                                  icon="user"
                                  placeholder={t('PLACEHOLDER_USERNAME')}
                                  onChange={this.handleChange}
                                />
                                <Form.Input
                                  error={this.props.hasEnteredIncorrectLoginCredentials}
                                  autoComplete="current-password"
                                  disabled={this.state.isLoading}
                                  name="password"
                                  type="password"
                                  icon="lock"
                                  placeholder={t('PLACEHOLDER_PASSWORD')}
                                  onChange={this.handleChange}
                                />

                                <div style={{ margin: '10px' }}>
                                  <Link to="/forgotPassword"> {t('LOGIN_FORGOTPASSWORD')} </Link>{' '}
                                </div>

                                <Form.Button
                                  id="loginButton"
                                  type="submit"
                                  loading={this.state.isLoading}
                                  labelPosition="right"
                                  icon={true}
                                  fluid={true}
                                  onClick={this.buttonHandler}
                                >
                                  {t('LOGIN_LOG_IN')}
                                  <Icon name="sign in" />
                                </Form.Button>
                              </div>
                            )}
                          />
                        </div>
                      }
                    </>
                  )}
                </Form>
              </div>
            )}
          </div>
        )}
      </Translation>
    );
  }
}
