import type { CSSProperties } from 'react';

export const defaultMargin: CSSProperties = { marginBottom: '0.5rem' };

export const filterByMe: CSSProperties = { marginLeft: 'auto' };

export const radioMargins: CSSProperties = { marginLeft: 'auto', marginTop: '.75em' };

export const filterByWithinToday: CSSProperties = { marginLeft: 'auto', marginTop: '.75em', marginBottom: '.75em' };

export const mainControlsWrapper: CSSProperties = { display: 'flex', justifyContent: 'space-between' };

export const clearFilter: CSSProperties = { marginTop: '5px', marginBottom: '5px', whiteSpace: 'nowrap' };

export const filterComponent = (isFullWidth: boolean): CSSProperties => ({
  width: isFullWidth ? '100%' : 'auto'
});
