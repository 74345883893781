import React from 'react';
import { Form, Select } from 'semantic-ui-react';

interface IProps {
  value: string | undefined;
  fieldName: string;
  errorMessage: string;
  items: { text: string; value: string }[];
  handleState: (property: string, value: string) => void;

  [x: string]: any;
}

const SalesforceMemoSelect = React.memo(({ value, fieldName, items, errorMessage, handleState, ...rest }: IProps) => {
  return (
    <Form.Field
      error={
        errorMessage && {
          content: errorMessage,
          pointing: 'above'
        }
      }
      control={Select}
      options={items}
      value={value}
      {...rest}
      onChange={(event: React.SyntheticEvent, data: { [key: string]: any }) => handleState(fieldName, data.value)}
    />
  );
});

export default SalesforceMemoSelect;
