import React, { createContext, createRef, useState } from 'react';

import type { FC } from 'react';
import type { DropzoneRef } from 'react-dropzone';

interface DrozoneRefCtx {
  dropzoneRef: React.RefObject<DropzoneRef> | undefined;
  setDropzoneRef: React.Dispatch<React.SetStateAction<React.RefObject<DropzoneRef> | undefined>>;
}
const DropzoneRefContext = createContext<DrozoneRefCtx>({
  dropzoneRef: createRef<DropzoneRef>(),
  setDropzoneRef: () => {
    // no op function is gonna be replaced with state setter
  }
});

const DropzoneRefProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [dropzoneRef, setDropzoneRef] = useState<React.RefObject<DropzoneRef>>();

  return <DropzoneRefContext.Provider value={{ dropzoneRef, setDropzoneRef }}>{children}</DropzoneRefContext.Provider>;
};

export { DropzoneRefContext, DropzoneRefProvider };
