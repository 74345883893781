import { DefaultTicketsOrdering, FilterPresetView } from '@eeedo/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Icon, Menu, Tab } from 'semantic-ui-react';

import type { FC } from 'react';
import type { WithTranslation } from 'react-i18next';
import type { ConnectedProps } from 'react-redux';
import type { TabProps } from 'semantic-ui-react';

import OpenById from './OpenById';
import Search from './Search';
import TicketLists from './TicketLists';
import { fetchFilterPresets, resetFilterPresets, setActiveFilterPreset } from 'src/actions/filterPresetsActions';
import { setInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketListFilter from 'src/containers/TicketListFilterContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { useAppThunkDispatch } from 'src/store';

import type { IFilterItem } from 'src/types/Filter';
import type { State } from 'src/types/initialState';
import type { MenuTab } from 'src/types/MenuTab';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { TicketListTab as TicketTab } from 'src/types/TicketList';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;

interface OwnProps {
  contentType: ContentTypesFields;
  noDefaultDates?: boolean;
}

interface Props extends TicketListTabReduxProps, OwnProps {}

const TicketListTab: FC<Props> = (props) => {
  const { t } = useTranslation();

  // Check undefined values in filters objects
  const isFilterOn =
    !Object.keys(props.filters).every((k) => !props.filters[k]) ||
    (props.sorting && props.sorting !== defaultSorting) ||
    (props.direction && props.direction !== defaultDirection);

  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    const { pathname, contentType } = props;
    // this component never seems to unmount so need to follow some state change
    if (pathname === '/' || ['/case', '/infopage'].some((path) => pathname.startsWith(path))) {
      dispatch(
        fetchFilterPresets({
          view: contentType === 'infopages' ? FilterPresetView.infopage : FilterPresetView.main
        })
      );
    }
  }, [props.pathname, props.contentType]);

  useEffect(() => {
    return () => {
      props.resetFilterPresets();
    };
  }, []);

  useEffect(() => {
    if (isFilterOn) {
      return;
    }

    const defaultFilter = props.filterPresets.find((p) => p.id === props.defaultFilterPreset);
    if (defaultFilter) {
      if (!props.activeTabId) {
        return;
      }

      dispatch(setActiveFilterPreset({ filterPresetId: defaultFilter.id }));

      Object.keys(defaultFilter?.filters).forEach((filterKey) => {
        props.setFilter({
          id: props.activeTabId!,
          value: defaultFilter?.filters[filterKey],
          parameter: filterKey as any
        });
      });

      props.setSorting?.(defaultFilter.sorting as any, defaultFilter.direction);
    }
  }, [props.filterPresets, props.defaultFilterPreset]);

  const [activeIndex, setActiveIndex] = useState(2);

  const panes = useMemo(() => {
    return [
      {
        menuItem: FeatureFlags.isFlagOn('ENABLE_TICKET_LIST_FILTERING') ? (
          <Menu.Item
            key="filterComponentTab"
            id="filterComponentTab"
            className="filterComponentTab"
            style={{ marginLeft: '10px !important' }}
          >
            <Icon name="filter" style={{ marginRight: '5px' }} color={isFilterOn ? 'red' : undefined} />
            <span
              style={{
                textDecoration: isFilterOn ? 'underline' : 'none',
                color: isFilterOn ? 'red' : 'inherit'
              }}
            >
              {t('SEARCH_FIlTER_OPTIONS')}
            </span>
          </Menu.Item>
        ) : undefined,
        render: () =>
          FeatureFlags.isFlagOn('ENABLE_TICKET_LIST_FILTERING') ? (
            <ErrorBoundary>
              <TicketListFilter contentType={props.contentType} />
            </ErrorBoundary>
          ) : undefined
      },
      {
        menuItem: (
          <Menu.Item key="searchComponentTab" id="searchComponentTab" className="filterComponentTab">
            <Icon name="search" />
            {t('SEARCH_MORE_SEARCH_OPTIONS')}
          </Menu.Item>
        ),
        render: () => (
          <ErrorBoundary>
            <Search
              noDefaultDates={props.noDefaultDates}
              contentType={props.contentType}
              closePane={() => setActiveIndex(2)}
            />
          </ErrorBoundary>
        )
      },
      {
        menuItem: (
          <Menu.Item key="searchByIdTab" className="filterComponentTabFloatRight NoActiveTabEffects">
            <OpenById contentType={props.contentType} />
          </Menu.Item>
        )
      }
    ];
  }, [props.noDefaultDates, props.contentType, t, isFilterOn]);

  const changeActiveIndex = useCallback(
    (_event: any, tabData: TabProps) => {
      const index = tabData.activeIndex;

      if (activeIndex !== index) {
        setActiveIndex(Number(index));
      } else {
        setActiveIndex(2);
      }
    },
    [activeIndex]
  );

  return (
    <React.Fragment>
      <Tab className="searchTabMenu" panes={panes} activeIndex={activeIndex} onTabChange={changeActiveIndex} />
      <ErrorBoundary>
        <TicketLists contentType={props.contentType} />
      </ErrorBoundary>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const tabs: MenuTab[] =
    ownProps.contentType === 'tickets' ? Object.values(state.ticketListTabs) : [...state.infoPageListTabs.values()];
  const activeTab = tabs.find((tab) => tab.activeTab);

  return {
    activeTabId: activeTab?.id,
    filters: activeTab?.filters ?? {},
    sorting: (activeTab as TicketTab)?.sorting,
    direction: (activeTab as TicketTab)?.direction,
    filterPresets: state.filterPresets.filterPresets,
    defaultFilterPreset: state.userData.userPreferences.defaultFilterPreset,
    pathname: state.router.location.pathname
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  setFilter: (item: IFilterItem) => {
    dispatch((ownProps.contentType === 'infopages' ? setInfopagelistFilter : setTicketlistFilter)(item));
  },
  setSorting:
    ownProps.contentType === 'tickets'
      ? (...args: Parameters<typeof setTicketlistSorting>) => {
          dispatch(setTicketlistSorting(...args));
        }
      : undefined,
  resetFilterPresets: () => {
    dispatch(resetFilterPresets());
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface TicketListTabReduxProps extends ConnectedProps<typeof connector>, WithTranslation {}

export default connector(withTranslation('translations')(TicketListTab));
