import * as React from 'react';
import { Message, Icon, Button } from 'semantic-ui-react';
import { Translation } from 'react-i18next';

import './Components/Case/Info/Info.css';

interface ErrorMessageProps {
  onClick: React.MouseEventHandler;
  negative?: boolean;
  warning?: boolean;
}

const ErrorMessage: React.VFC<ErrorMessageProps> = ({ onClick, negative = false, warning = false }) => (
  <Translation ns="translations">
    {(t) => (
      <Message icon={true} negative={negative} warning={warning}>
        <Icon name="warning sign" />
        <Message.Content>
          <Message.Header>{t('ERROR_HAPPENED')}</Message.Header>
          {t('ERROR_APOLOGIES')}
          <br />
          {t('ERROR_RELOAD')}
          <br />
          <Button labelPosition="left" icon size="mini" onClick={onClick}>
            <Icon name="refresh" />
            {t('ERROR_TRY_AGAIN')}
          </Button>
        </Message.Content>
      </Message>
    )}
  </Translation>
);

export default ErrorMessage;
