import { phone } from 'phone';
import EnvSettings from 'src/api/EnvSettings';
import FeatureFlags from 'src/api/FeatureFlags';

export enum COUNTRY_ISO3 {
  FIN = 'FIN',
  BEL = 'BEL'
}

const isShortPhoneNumber = (num: string) => {
  const regExp = /^099[0-9]+$/;
  return regExp.test(num) && num.length < 7;
};

/**
 * Normalize phone number to E.164 form, eg: +358407530715
 */
export function normalizePhoneNumber(num: string, defaultCountryCode = COUNTRY_ISO3.FIN): string {
  if (FeatureFlags.isFlagOn('ENABLE_FILTER_SHORT_PHONE_NUMBERS') && isShortPhoneNumber(num)) {
    return 'anonymous';
  }

  const countryCode = EnvSettings.getSettings().LOCALIZATION.countryIso3 || defaultCountryCode;
  const country = typeof num === 'string' && num.startsWith('+') ? '' : countryCode;

  const result = phone(num, { country });

  return result.isValid ? result.phoneNumber : num;
}
