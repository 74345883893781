import type { Suggestion } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class SuggestionsApi {
  static fetchAutoSuggestions = async () => {
    return apiClient.get<Suggestion[]>('/autoSuggestions').then((response) => response.data);
  };

  static fetchAutoSuggestion = async (autoSuggestionId: number) => {
    return apiClient.get<Suggestion>(`/autoSuggestion/${autoSuggestionId}`).then((response) => response.data);
  };

  static patchAutoSuggestion = async (data: { id: number; payload: Partial<Omit<Suggestion, 'id'>> }) => {
    return apiClient.patch<Suggestion>('/autosuggestion/' + data.id, data.payload).then((response) => response.data);
  };

  static createAutoSuggestion = async (data: Partial<Omit<Suggestion, 'id'>>) => {
    return apiClient.post<Suggestion>('/autosuggestions', data).then((response) => response.data);
  };

  static deleteAutoSuggestion = async (autoSuggestionId: number) => {
    return apiClient
      .delete<{ success: boolean }>('/autosuggestion/' + autoSuggestionId)
      .then((response) => response.data);
  };
}
