import path from 'path';

export const IMG_URL_PREFIX = 'https://s3-eu-west-1.amazonaws.com/cdn.eee.do/';
export const IMG_SIZE_SMALL = '35x35';
export const IMG_SIZE_LARGE = '140x140';

export function getUserImage(usr: string, large?: boolean) {
  if (usr === '') {
    return '';
  }
  const prefix = usr.slice(0, usr.lastIndexOf('/') + 1);
  const postfix = usr.slice(usr.lastIndexOf('/'));

  const size = large ? IMG_SIZE_LARGE : IMG_SIZE_SMALL;
  return `${IMG_URL_PREFIX}${prefix}${size}${postfix}`;
}

const imageExtensions = new Set<string>([
  'jpg',
  'jpeg',
  'jpe',
  'jif',
  'jfif',
  'jfi',
  'png',
  'gif',
  'webp',
  'tiff',
  'tif',
  'psd',
  'raw',
  'arw',
  'cr2',
  'nrw',
  'k25',
  'bmp',
  'dib',
  'heif',
  'heic',
  'ind',
  'indd',
  'indt',
  'jp2',
  'j2k',
  'jpf',
  'jpx',
  'jpm',
  'mj2',
  'svg',
  'svgz',
  'ai',
  'eps'
]);

export function hasImageExtension(file: string | null): boolean {
  if (file == null) {
    return false;
  }

  let extension = path.extname(file);
  if (!extension) {
    return false;
  }

  // remove the leading dot
  extension = extension.slice(1);
  return imageExtensions.has(extension.toLowerCase());
}
