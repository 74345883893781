import { FieldTypes } from '@eeedo/types';

import type { Field } from '@eeedo/types';

export const getInfoInputType = (field: Field) => (typeof field.value === 'number' ? 'number' : 'text');

export const processSubEntities = (fields: Field[], values: any): Field[] =>
  fields.reduce((acc, field) => {
    if (field?.customType !== 'subEntity') {
      return [...acc, field];
    }

    const targetSubEntity = values?.subEntities?.[values[field.params?.getByValue ?? ''] + field.params?.entityType];
    if (!targetSubEntity) {
      return [
        ...acc,
        {
          name: field.name,
          value: field.name!,
          customType: FieldTypes.loadingChevron,
          params: field.params
        }
      ];
    }

    if (targetSubEntity.statusCode !== 200) {
      return [
        ...acc,
        {
          name: field.name,
          value: field.value,
          customType: FieldTypes.errorMessage
        }
      ];
    }

    return [
      ...acc,
      ...((field.value as unknown as any[]).map((subField) => ({
        name: subField.name,
        value: subField.value,
        customType: FieldTypes.subEntityField,
        params: Object.assign(subField.params || {}, field.params)
      })) as Field[])
    ];
  }, [] as Field[]);
