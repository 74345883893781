import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import type { VFC } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';

import TicketPreviewPopup from '../TicketPreviewPopup';
import * as styles from './TicketListItem.style';
import FeatureFlags from 'src/api/FeatureFlags';
import UserLabel from 'src/Components/User/UserLabel';
import ErrorBoundary from 'src/ErrorBoundary';
import useForceUpdate from 'src/hooks/useForeceUpdate';
import { getPhoneIcon } from 'src/Utilities/getPhoneIcon';

import type { TicketItemProps } from './TicketListItem';

type TicketListTitleRowProps = Pick<
  TicketItemProps,
  | 'id'
  | 'type'
  | 'title'
  | 'channel'
  | 'created'
  | 'touched'
  | 'dueDate'
  | 'priority'
  | 'taskType'
  | 'delegates'
  | 'ticketTypes'
  | 'originalDirection'
>;

const TicketListTitleRow: VFC<TicketListTitleRowProps> = ({
  id,
  type,
  title,
  channel,
  created,
  touched,
  dueDate,
  priority,
  taskType,
  delegates,
  ticketTypes,
  originalDirection
}) => {
  const forceUpdate = useForceUpdate();
  const iconColor = channel?.color ?? '';
  const ticketListPreviewEnabled = FeatureFlags.isFlagOn('ENABLE_TICKETLIST_PREVIEW');
  const iconName = channel?.id === 2 ? getPhoneIcon(originalDirection) : channel?.icon ?? 'question';

  const priorityColor = priority ? priority.color : '';
  const priorityIcon = priority ? priority.icon : null;

  const correctTicketType = ticketTypes.find((x) => x.name === taskType);

  // Filtering out eeedo admin
  const actualDelegates = delegates?.filter?.((del) => del !== 'USR1') ?? [];
  const actualDelegatedRender = (delegates: string[]) => {
    if (delegates.length > 1) {
      return (
        <div className="delegateIconContainer">
          <Popup
            on={'hover'}
            hoverable={true}
            position="bottom right"
            trigger={
              <span className="ticketListDelegateIcon">
                <Icon name="user" />
                <span className="badge">{delegates.length}</span>
              </span>
            }
            content={
              <ErrorBoundary>
                {delegates.map((usr, index) => (
                  <UserLabel key={`user-label-${usr}-${index}`} UID={usr} size="24" />
                ))}
              </ErrorBoundary>
            }
          />
        </div>
      );
    } else {
      const user = delegates[0];
      return (
        <span className="ticketListDelegateIcon">
          <UserLabel UID={user} size="26" showTitle={false} />
        </span>
      );
    }
  };

  return (
    <div className="ticketListTitleRow">
      {type !== 'infopage' && (
        <Icon style={{ color: iconColor }} name={iconName as SemanticICONS} className="ticketListTitleRow_icon" />
      )}
      {!!priorityIcon && <Icon style={{ color: priorityColor }} name={priorityIcon as SemanticICONS} />}

      {ticketListPreviewEnabled && (
        <TicketPreviewPopup
          fields={correctTicketType?.fieldSets}
          id={id}
          title={title}
          popupTitle={title}
          type={correctTicketType?.name}
          created={created}
          touched={touched}
          dueDate={Number(dueDate)}
          position={'right center'}
          onLoaded={forceUpdate}
          ellipsis
        />
      )}
      {!ticketListPreviewEnabled && <div style={styles.title}>{title}</div>}

      {!!actualDelegates?.length && actualDelegatedRender(actualDelegates)}
    </div>
  );
};

export default React.memo(TicketListTitleRow);
