import * as React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import AccordionHeader from '../../AccordionHeader';
import Info from '../../Info/Info';

interface NonCollectionFieldSetProps {
  taskCase: any;
  widgetOpen: boolean;
  entitySearchButton: string;
  onEntitySearch: () => void;
  fieldMap: Map<string, any>;
  onSave: (editedField: any, editedValue: any) => void;
  userPermissions: string[];
  onSearch: (value: any, type: string) => void;
  className: string;
  index: number;
  fieldSetName: string;
}

interface CollectionFieldSetProps {
  taskCase: any;
  taskId: string;
  widgetOpen: boolean;
  i: number;
  fieldMap: Map<string, any>;
  className: string;
  index: number;
  fieldSetName: string;
}

export function CollectionFieldSets(props: CollectionFieldSetProps) {
  const { taskCase, widgetOpen, i, fieldMap, className, index, fieldSetName } = props;
  return (
    <div className={className} key={index}>
      <AccordionHeader key={index * i - i} as="h4" active={widgetOpen} title={fieldSetName} icon="info circle">
        {taskCase[fieldSetName].data.map((dataSet: any) => {
          return (
            <div className={className} key={index}>
              <AccordionHeader
                key={index}
                as="h4"
                active={true}
                title={dataSet[taskCase[fieldSetName].subHeaderValue]}
                icon="info circle"
              >
                <Info key={i + index} fields={fieldMap.get(fieldSetName) || []} values={dataSet || {}} />
              </AccordionHeader>
            </div>
          );
        })}
      </AccordionHeader>
    </div>
  );
}

export function NonCollectionFieldSets(props: NonCollectionFieldSetProps) {
  const {
    className,
    entitySearchButton,
    fieldMap,
    fieldSetName,
    index,
    onEntitySearch,
    onSave,
    onSearch,
    taskCase,
    userPermissions,
    widgetOpen
  } = props;

  return (
    <div className={className} key={index}>
      <AccordionHeader key={index} as="h4" active={widgetOpen} title={fieldSetName} icon="info circle">
        {entitySearchButton && (
          <Button icon={true} color="blue" labelPosition="left" onClick={onEntitySearch}>
            <Icon name="search" />
            {entitySearchButton}
          </Button>
        )}

        <Info
          onSave={onSave}
          generalDisable={!userPermissions.includes('modifyCaseDetails')}
          fields={fieldMap.get(fieldSetName) || []}
          values={taskCase || {}}
          fireSearch={onSearch}
        />
      </AccordionHeader>
    </div>
  );
}
