import type { Priority } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class PriorityApi {
  static addPriority = async (data: Omit<Priority, 'id'>) => {
    return apiClient.put('/priority', data).then((response) => response.data);
  };

  static patchPriority = async ({ id, data }: { id: number; data: Priority }) => {
    return apiClient.patch(`/priority/${id}`, data).then((response) => response.data);
  };

  static getPriorities = async (): Promise<Priority[]> => {
    return apiClient.get('/priority').then((response) => response.data);
  };
}
