import type { Channel, TicketDirection } from '@eeedo/types';
import type { SemanticICONS } from 'semantic-ui-react';

import { getPhoneIcon } from './getPhoneIcon';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';

export function iconName(channel: Channel | undefined, direction: TicketDirection): SemanticICONS {
  if (!channel) {
    return 'question';
  }

  if (channel.id === ChannelType.Phone) {
    return getPhoneIcon(direction) ?? 'phone';
  }

  return (channel.icon as SemanticICONS) || 'question';
}
