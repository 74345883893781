import { createAction } from '@reduxjs/toolkit';

import { SET_COMMENT_FILTER, CLEAR_COMMENT_FILTER } from './index';
import type { CommentFilters } from 'src/types/Ticket';

export const setCommentFilter = createAction<{
  caseId: string;
  property: keyof CommentFilters;
  value: any;
}>(SET_COMMENT_FILTER);

export const clearCommentFilter = createAction<string>(CLEAR_COMMENT_FILTER);
