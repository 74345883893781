import { generatePath } from 'react-router-dom';
import { apiClient } from 'src/Utilities/httpClients';
import type { Template, TemplateFormValues } from '../types/Template';

import ApiConfig from './ApiConfig';

const API_URL_TEMPLATES = '/templates/:id?';

export default class TemplatesApi {
  static getTemplates = (): Promise<Template[]> => {
    return apiClient
      .get(ApiConfig.getConfig().API_URL + generatePath(API_URL_TEMPLATES))
      .then((response) => response.data);
  };

  static createTemplate = (template: TemplateFormValues): Promise<Template> => {
    return apiClient
      .post(generatePath(API_URL_TEMPLATES), {
        ...template,
        template: JSON.stringify(template.template)
      })
      .then((response) => response.data);
  };

  static updateTemplate = (template: TemplateFormValues): Promise<Template> => {
    return apiClient
      .put(generatePath(API_URL_TEMPLATES, { id: template.id }), {
        ...template,
        template: JSON.stringify(template.template)
      })
      .then((response) => response.data);
  };

  static deleteTemplate = (id: string): Promise<Template> => {
    return apiClient.delete(generatePath(API_URL_TEMPLATES, { id })).then((response) => response.data);
  };
}
