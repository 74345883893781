import UserTimeLogsApi from './UserTimeLogsApi';
import { consoleLogUserTimeLogs, getCurrentSeconds } from './utils';

import type { UserTimeLogsRow } from './types';

const AUTOSAVE_INTERVAL = 1000 * 60 * 5; // 5 minutes
const MAX_TRACKABLE_DURATION = 1000 * 60 * 15; // 15 minutes

class UserTimeLogs {
  private startedAt: number | null = null;
  private activeTicketId: string | null = null;
  public unSentLogs: UserTimeLogsRow[] = [];
  private autoSaveInterval: NodeJS.Timeout | null = null;

  init() {
    this.initializeAutoSave();
  }

  public getActiveTicketId() {
    return this.activeTicketId;
  }

  public setActiveTicketId(ticketId: string): void {
    if (ticketId !== this.activeTicketId) {
      this.finishCurrentLogSession();
      this.activeTicketId = ticketId;
      this.startedAt = getCurrentSeconds();
    }
  }

  public finishCurrentLogSession(): void {
    if (this.activeTicketId && this.startedAt) {
      const duration = Math.min(getCurrentSeconds() - this.startedAt, MAX_TRACKABLE_DURATION);
      this.unSentLogs.push({ id: this.activeTicketId, duration });
      this.sendLogs();
    }
    // Reset session
    this.startedAt = null;
    this.activeTicketId = null;
  }

  public async sendLogs(): Promise<void> {
    if (this.unSentLogs.length > 0) {
      consoleLogUserTimeLogs('[userTimeLogs]: Sending unsent logs.', this.unSentLogs);
      try {
        await UserTimeLogsApi.sendUserTimeLogs(this.unSentLogs);
        this.unSentLogs = [];
      } catch (error) {
        consoleLogUserTimeLogs('[userTimeLogs]: Error sending logs', error);
      }
    }
  }

  private initializeAutoSave(): void {
    this.autoSaveInterval = setInterval(() => {
      this.autoSaveLogs();
    }, AUTOSAVE_INTERVAL);
  }

  private autoSaveLogs(): void {
    if (this.activeTicketId && this.startedAt) {
      // Add current session to unsent logs but do not reset session
      const duration = Math.min(getCurrentSeconds() - this.startedAt, MAX_TRACKABLE_DURATION);
      this.unSentLogs.push({ id: this.activeTicketId, duration });
      // Attempt to send all logs
      this.sendLogs();
      // Reset the timer without changing the activeTicketId
      this.startedAt = getCurrentSeconds();
    }
  }

  public dispose(): void {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
    // Ensure current session is logged before disposing
    this.finishCurrentLogSession();
  }
}

export default new UserTimeLogs();
