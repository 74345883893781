import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';

import type { ConnectedProps } from 'react-redux';

import openConfirmationToast from './ConfirmationPopup';
import TicketsApi from 'src/api/TicketsApi';
import { useModal } from 'src/Components/Modal/ModalContext';
import { Roles } from 'src/types/User';

import type { State } from 'src/types/initialState';

type MergeTicketsProps = MergeTickersReduxProps;

const MergeTickets: React.VFC<MergeTicketsProps> = ({ openTicketsIds, activeTicketTab, user }) => {
  const [selected, setSelected] = useState<string[]>([]);
  const { closeModal } = useModal();

  const mergeTickets = () => {
    if (activeTicketTab && selected?.length) {
      TicketsApi.mergeTicketsInto(activeTicketTab, selected).then(() => {
        setSelected([]);
        closeModal();
      });
    }
  };
  const isAllowed = user?.role.id && !Roles.isAnyOf(user.role.id, Roles.ReadOnly, Roles.ReadOnlySingleTicker);

  return (
    <Translation>
      {(t) => (
        <>
          <ModalHeader>{t('top_bar.advanced_actions.merge_tickets.title')}</ModalHeader>

          <ModalContent>
            <Select
              styles={{ container: (styles) => ({ ...styles, width: '100%' }) }}
              onChange={(value) => {
                const option = value ?? [];
                setSelected(option.map((v) => v.value));
              }}
              options={openTicketsIds.filter((id) => id !== activeTicketTab).map((id) => ({ label: id, value: id }))}
              isMulti
              closeMenuOnSelect={false}
              isDisabled={!isAllowed}
              value={selected.map((id) => ({ label: id, value: id }))}
            />
          </ModalContent>

          <ModalActions>
            <Button content={t('GENERAL_CANCEL')} labelPosition="right" icon="remove" onClick={() => closeModal()} />

            <Button
              content={t('top_bar.advanced_actions.merge_tickets.button')}
              labelPosition="right"
              icon="checkmark"
              positive
              disabled={!isAllowed || !selected?.length}
              onClick={() => {
                openConfirmationToast({
                  t,
                  onConfirm: mergeTickets,
                  mergeTickets: selected.join(', '),
                  mainTicket: activeTicketTab as string
                });
              }}
            />
          </ModalActions>
        </>
      )}
    </Translation>
  );
};

const mapToStateProps = (state: State) => ({
  openTicketsIds: state.ticketTabs.map((t) => t.id),
  activeTicketTab: state.activeTicketTab,
  user: state.usersList.usersList.find((u) => state.userData.UID === u.UID)
});

const connector = connect(mapToStateProps);

type MergeTickersReduxProps = ConnectedProps<typeof connector>;

export default connector(MergeTickets);
