import { createSlice } from '@reduxjs/toolkit';

import type { UserWithProfile } from '@eeedo/types';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { SignInType } from '../types/User';

export interface UsersListReducerType {
  usersList: UserWithProfile[];
  copiedUser: UserWithProfile | null;
  signInType: SignInType;
}

const initialState: UsersListReducerType = {
  usersList: [],
  copiedUser: null,
  signInType: 'link'
};

const usersListSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    fetchUsersSuccess(state, action: PayloadAction<{ users: UserWithProfile[] }>) {
      state.usersList = action.payload.users;
    },
    fetchUserSuccess(state, action: PayloadAction<{ user: UserWithProfile }>) {
      const userIndex = state.usersList.findIndex((user) => user.UID === action.payload.user.UID);
      if (userIndex !== -1) {
        state.usersList[userIndex] = action.payload.user;
      } else {
        state.usersList.push(action.payload.user);
      }
    },
    copyUserData(state, action: PayloadAction<{ user: UserWithProfile | null }>) {
      state.copiedUser = action.payload.user;
    },
    setSignInType(state, action: PayloadAction<{ signInType: SignInType }>) {
      state.signInType = action.payload.signInType;
    }
  }
});

export const { fetchUsersSuccess, fetchUserSuccess, copyUserData, setSignInType } = usersListSlice.actions;
export default usersListSlice.reducer;
