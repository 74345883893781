export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SortBy {
  dueDate = 'dueDate',
  created = 'created',
  touched = 'touched'
}

export const DefaultTicketsOrdering = [Direction.ASC, SortBy.dueDate] as const;
export const DefaultCommentsSorting = Direction.DESC;
