import type { CSSProperties } from 'react';

export const wrapper: CSSProperties = { position: 'relative' };

export const description: CSSProperties = {
  position: 'absolute',
  top: '20px',
  minHeight: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 10px',
  backgroundColor: 'rgba(0, 0, 0, .7)',
  color: 'white'
};

export const content: CSSProperties = { padding: '50px 80px' };
