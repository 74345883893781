import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updateSingleTicketCaseDetail } from 'src/actions/ticketsActions';
import type { Ticket } from 'src/types/Ticket';
import AccordionHeader from '../AccordionHeader';
import Info from '../Info/Info';

interface HandleStatusProps {
  task: Ticket;
  widgetOpen: boolean;
  ind: number;
  handleInfo: any[];
}

const HandleStatus = ({ task, widgetOpen, ind, handleInfo }: HandleStatusProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <AccordionHeader
      as="h4"
      active={widgetOpen}
      title={t('CASE_TITLE_HANDLESTATUS')}
      icon="edit"
      key={`case-title-handlestatus-${ind}`}
    >
      <Info
        onSave={(editedField: any, editedValue: any) => {
          dispatch(updateSingleTicketCaseDetail(task.id, editedField, editedValue, 'CaseDetails'));
        }}
        fields={handleInfo}
        values={task.case}
      />
    </AccordionHeader>
  );
};

export default React.memo(HandleStatus);
