import type { Channel } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class ChannelApi {
  static getChannelTypes = async (): Promise<Channel[]> => {
    return apiClient.get('/channelTypes').then((response) => response.data);
  };

  static patchChannel = async (payload: { id: number; data: Channel }): Promise<Channel> => {
    return apiClient.patch(`/channel/${payload.id}`, payload.data).then((response) => response.data);
  };
}
