import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import type { FloatingWindowState } from 'src/types/FloatingWindow';

const initialState: FloatingWindowState[] = [];

const floatingWindowsSlice = createSlice({
  name: 'floatingWindows',
  initialState,
  reducers: {
    addFloatingWindow: (draft, action: PayloadAction<FloatingWindowState>) => {
      const currentWindow = draft.find((w) => w.id === action.payload.id);
      if (!currentWindow) {
        draft.push(action.payload);
      }
    },
    removeFloatingWindow: (draft, action: PayloadAction<string>) => {
      return draft.filter((w) => w.id !== action.payload);
    },
    updateFloatingWindow: (draft, action: PayloadAction<Partial<FloatingWindowState> & { id: string }>) => {
      const currentWindowIndex = draft.findIndex((w) => w.id === action.payload.id);
      if (currentWindowIndex > -1) {
        draft[currentWindowIndex] = { ...draft[currentWindowIndex], ...action.payload };
      }
    },
    setWindowTop: (draft, action: PayloadAction<string>) => {
      const currentWindow = draft.find((w) => w.id === action.payload);
      if (!currentWindow) {
        return draft;
      }

      return [...draft.filter((w) => w.id !== action.payload), currentWindow];
    }
  }
});

export const { addFloatingWindow, updateFloatingWindow, removeFloatingWindow, setWindowTop } =
  floatingWindowsSlice.actions;

export default floatingWindowsSlice.reducer;
