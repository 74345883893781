import { t } from 'i18next';
import React from 'react';
import { Icon, Label, List } from 'semantic-ui-react';

import type { SemanticICONS, SemanticSIZES } from 'semantic-ui-react';

import { getPrettyDate } from '../../Utilities/dates';
import { timePrefix, timestampPrefixToDate } from '../Case/DateSelector';

interface SearchConditionListProps {
  size: SemanticSIZES;
  items: Array<ListItem>;
  onDelete: Function;
}

export interface ListItem {
  param: string;
  name: string;
  text?: string;
  icon?: SemanticICONS;
  value: any;
}

class SearchConditionList extends React.Component<SearchConditionListProps, {}> {
  private deleteItem = (item: ListItem) => {
    this.props.onDelete(item);
  };

  private displaySearchValue(value: string) {
    if (value.startsWith(timePrefix) && timestampPrefixToDate(value)) {
      return getPrettyDate(timestampPrefixToDate(value), { sinceCurrentTime: false, format: 'lll' });
    }

    return value;
  }

  render() {
    const { size, items } = this.props;
    if (items.length === 0) {
      return null;
    }

    return (
      <List>
        {items.map((item, index) => {
          const value =
            item.text !== undefined ? item.text : item.value !== undefined ? item.value.toString() : undefined;
          return (
            <List.Item key={index}>
              <Label color="green" size={size} style={{ width: '100%' }}>
                {item.icon && <Icon name={item.icon} />}
                {item.name === t('SEARCH_DELEGATED_TO_ME') || item.name === t('SEARCH_STATUS_ONLY_READY')
                  ? `${item.name}`
                  : `${item.name}: ${this.displaySearchValue(value)}`}
                <Icon name="delete" style={{ float: 'right' }} onClick={() => this.deleteItem(item)} />
              </Label>
            </List.Item>
          );
        })}
      </List>
    );
  }
}

export default SearchConditionList;
