import type { Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { roomSubscriberClosed } from 'src/actions/middlewareActions';
import { removeTicketFromDetailed } from 'src/actions/ticketsActionsRTK';
import SocketInstance from 'src/realTimeNotifications';

import type { State } from 'src/types/initialState';

export const leaveRoomMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, State>) => (next) => (action) => {
  const returnValue = next(action);

  try {
    if (action.type === roomSubscriberClosed.type) {
      const { id } = action.payload as ReturnType<typeof roomSubscriberClosed>['payload'];
      const { windows, ticketTabs } = api.getState();
      const ticketStillOpen = ticketTabs.some((t) => t.id === id) || windows.some((w) => w.id === id);

      if (!ticketStillOpen) {
        SocketInstance.leaveRoom(id);
        api.dispatch(removeTicketFromDetailed(id));
      }
    }
  } catch (error) {
    console.error(error);
  }

  return returnValue;
};
