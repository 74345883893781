import { VersionControlClient } from './VersionControl';

// once per minute
const UPDATE_DB_INTERVAL = 10 * 1000;

export const contentsVC = new VersionControlClient('contents');
export const commentsVC = new VersionControlClient('comments');

const caches: VersionControlClient[] = [contentsVC, commentsVC];

function refreshCaches() {
  for (const c of caches) {
    c.updateDB();
  }
}

export const cacheUpdateTimer = setInterval ? setInterval(refreshCaches, UPDATE_DB_INTERVAL) : null;
