import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dimmer, Icon, Loader, Message } from 'semantic-ui-react';

import type { EntityResponse } from 'src/types/Ticket';

interface EntityStatusProps {
  entity: EntityResponse;
  children: any;
  getEntityById(_id: string, _type: string): void;
}

const EntityStatus = ({ entity, children, getEntityById }: EntityStatusProps) => {
  const { t } = useTranslation();

  if (entity.hasAccess === false) {
    return (
      <Message info icon data-test="EntityStatusNoAccessMessage">
        <Icon name="key" />
        <Message.Header>
          {t('ENTITY_USER_HAS_NO_ACCESS_TITLE')}
          <p>{t('ENTITY_USER_HAS_NO_ACCESS_DESCRIPTION')}</p>
        </Message.Header>
      </Message>
    );
  }

  if (entity.errorMessage) {
    return (
      <>
        <Message error icon data-test="EntityStatusErrorMessage">
          <Icon name="warning sign" />
          <Message.Header>
            {`${t('ENTITY_ENCOUNTERED_ERROR_TITLE')} (${entity.statusCode})`}
            <p>{t('ENTITY_ENCOUNTERED_ERROR_DESCRIPTION')}</p>
          </Message.Header>
        </Message>
        <Button
          onClick={() => {
            getEntityById(entity._id, entity._type);
          }}
          primary={true}
          labelPosition="left"
          icon={true}
        >
          <Icon name="redo" />
          {t('INIT_LOAD_BUTTON_RETRY')}
        </Button>
      </>
    );
  }

  if (entity.isLoading) {
    return (
      <>
        <Dimmer inverted active={entity.isLoading} data-test="EntityStatusLoader">
          <Loader indeterminate content={t('ENTITY_LOADING_DATA')} />
        </Dimmer>

        {!!entity.data && children}
      </>
    );
  }

  return children;
};

export default EntityStatus;
