import iziToast from 'izitoast';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppThunkDispatch } from 'src/store';
import { Icon, Button } from 'semantic-ui-react';

import { generateTags } from 'src/actions/openAIActions';
import GeneratedTagButton from './GeneratedTagButton';
import { convertCaseNaming, getLastIncommingComment } from 'src/Utilities/helper';
import type { Ticket } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';

interface GenerateTagsProps {
  task: Ticket;
}

export default ({ task }: GenerateTagsProps) => {
  const { t } = useTranslation();
  const dispatchThunk = useAppThunkDispatch();
  const stateTags = useSelector((state: State) => state.tags);
  const suggestedTags = useSelector(
    (state: State) => state.openAI.generatedTags[parseInt(task.id.substring(3), 10)] || []
  );
  const [isGenerating, setGenerating] = useState(false);

  const handleGenerateTags = async () => {
    setGenerating(true);
    const commentPayload = getLastIncommingComment(task);
    if (commentPayload.content) {
      await dispatchThunk(
        generateTags({
          contentId: convertCaseNaming(task.id, 'number', 'task') as number,
          content: commentPayload.content,
          isHtml: commentPayload.isHtml,
          commentId: commentPayload.commentId
        })
      );
    } else {
      iziToast.info({
        message: t('widgets.chatGPT.toasts.no_incoming')
      });
    }
    setGenerating(false);
  };

  const label = (text: string) => (
    <label style={{ display: 'block', fontWeight: 700, margin: '10px 0 5px 0', fontSize: '1rem' }}>{text}</label>
  );

  const renderTags = () => {
    const newTags = suggestedTags.filter((suggestedTag) => !stateTags.find((tag) => tag.name === suggestedTag));
    const existedTags = stateTags.filter((tag) => suggestedTags.includes(tag.name));

    return (
      <>
        <div>
          {label(`${t('widgets.chatGPT.labels.create_add_tag')}:`)}
          {newTags.map((tag) => (
            <GeneratedTagButton contentId={task.id} name={tag} isNew={true} />
          ))}
        </div>

        <div>
          {label(`${t('widgets.chatGPT.labels.add_tag')}:`)}
          {existedTags.map((tag) => (
            <GeneratedTagButton
              contentId={task.id}
              isAdded={!!task.tags.find((tagName) => tagName === tag.id)}
              name={tag.name}
              id={tag.id}
              isNew={false}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <div>
      <Button
        disabled={isGenerating}
        onClick={() => handleGenerateTags()}
        primary
        icon
        labelPosition="left"
        style={{ display: 'block' }}
      >
        {isGenerating ? <Icon name="circle notch" loading /> : <Icon name="tags" />}
        {t('widgets.chatGPT.buttons.generate_tags')}
      </Button>

      {suggestedTags.length ? renderTags() : null}
    </div>
  );
};
