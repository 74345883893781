import React, { useState } from 'react';

import type { FC } from 'react';

import type { IItem, IItemsCollection, IMultiDraggableDropdownContext } from './types';

interface IProps {
  itemsCollection: IItemsCollection;
  setItemsCollection: (value: IItemsCollection) => void;
  children?: React.ReactNode;
}

const MultiDraggableDropdownProvider: FC<IProps> = ({ itemsCollection, setItemsCollection, children }) => {
  const [draggingItem, setDraggingItem] = useState<IItem | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [sourceArea, setSourceArea] = useState<string | null>(null);
  const [targetArea, setTargetArea] = useState<string | null>(null);

  const contextValue = {
    draggingItem,
    isDragging,
    itemsCollection,
    sourceArea,
    targetArea,
    setDraggingItem,
    setIsDragging,
    setItemsCollection,
    setSourceArea,
    setTargetArea
  };

  return (
    <MultiDraggableDropdownContext.Provider value={contextValue}>{children}</MultiDraggableDropdownContext.Provider>
  );
};

export const MultiDraggableDropdownContext = React.createContext<IMultiDraggableDropdownContext | null>(null);

export default MultiDraggableDropdownProvider;
