import React from 'react';
import { useTranslation } from 'react-i18next';

import type ChannelType from './ChannelType';

export interface CustomerPortalContentProps {
  channel: ChannelType;
  metaData: Record<string, any>;
}

function CustomerPortalContent(props: CustomerPortalContentProps) {
  const { t } = useTranslation();

  return (
    <div>
      <b>{t('COMMENT_ICON_CONTENT_CP_CHANNEL')}</b>
      <hr />
      {!!props.metaData.title && (
        <div>
          <b>{t('COMMENT_METADATA_TITLE')}</b>
          <p style={{marginTop: '8px'}}>{props.metaData.title}</p>
        </div>
      )}
    </div>
  );
}

export default CustomerPortalContent;
