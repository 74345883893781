const MAX_RETRY_TIMES = 3;
const sleep = (seconds: number = 1) => new Promise((res) => setTimeout(() => res(undefined), seconds * 1000));

export const getRetryableFunction =
  (fn: (...args: any[]) => Promise<any> | any, times = 3) =>
  async (...args: any[]) => {
    const intervals = [3, 5, 10];
    const retryTimes = Math.min(times, MAX_RETRY_TIMES);
    let retriedTimes = 0;

    const execRetryableFunction = async (): Promise<any> => {
      try {
        return await fn(...args);
      } catch (err) {
        if (retriedTimes === retryTimes) {
          console.warn('retry failed after', retriedTimes, 'times', 'with error', (err as Error)?.message);
          throw err;
        } else {
          await sleep(intervals[retriedTimes]);
          console.warn('retry attempt #', retriedTimes + 1);
          retriedTimes += 1;
          return await execRetryableFunction();
        }
      }
    };

    return await execRetryableFunction();
  };
