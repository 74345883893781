import { connect } from 'react-redux';

import ProfileDropdown from '../ProfileDropdown';
import { logout } from 'src/actions/authActions';

import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';

const mapStateToProps = (state: State) => {
  return {
    userData: state.userData,
    usersList: state.usersList.usersList
  };
};

const mapDispatchToProps = (dispatch: ThunkAppDispatch) => ({
  onLogout: () => {
    dispatch(logout({ type: 'manual' }));
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfileDropdown);
