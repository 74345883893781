export const startWorkingOn = (UID: string, ticketId: string): [number, number] => [
  parseInt(ticketId.substring(3), 10),
  parseInt(UID.substring(3), 10)
];

export const stopWorkingOn = (UID: string, ticketId: string): [number, number] => [
  parseInt(ticketId.substring(3), 10),
  parseInt(UID.substring(3), 10)
];

export const replaceWorkingOn = (ticketId: string, previousUID: string, _nextUID: string): [number, number, number] => [
  parseInt(ticketId.substring(3), 10),
  parseInt(previousUID.substring(3), 10),
  parseInt(_nextUID.substring(3), 10)
];
