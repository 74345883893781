import React from 'react';
import { useTranslation } from 'react-i18next';

import AccordionHeader from 'src/Components/Case/AccordionHeader';
import GenerateTags from './GenerateTags';
import GenerateTranscriptionsSummary from './GenerateTranscriptionsSummary';
import RelevantInformation from './RelevantInformation';
import type { Ticket } from 'src/types/Ticket';

interface ChatGPTWidgetProps {
  task: Ticket;
}

export default ({ task }: ChatGPTWidgetProps) => {
  const { t } = useTranslation();

  return (
    <AccordionHeader
      key="eIdentification-widget"
      as="h4"
      active={true}
      title={t('widgets.chatGPT.title')}
      icon="microsoft"
    >
      <RelevantInformation task={task} />
      <GenerateTags task={task} />
      <GenerateTranscriptionsSummary id={parseInt(task.id.substring(3), 10)} />
    </AccordionHeader>
  );
};
