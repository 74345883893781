import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import type { State } from 'src/types/initialState';

interface CloseAndReturnAsDoingContainerProps {
  closeAndReturnAsDoing: () => Promise<void>;
}

const mapStateToProps = (state: State) => ({
  workStatus: state.workStatus
});

const CloseAndReturnAsDoingContainer = ({ closeAndReturnAsDoing }: CloseAndReturnAsDoingContainerProps) => (
  <Translation ns="translations">
    {(tr) => (
      <Button
        style={{ width: '100%' }}
        className={'close-as-wont-fix'}
        color="yellow"
        labelPosition="left"
        icon={true}
        onClick={() => closeAndReturnAsDoing()}
      >
        <Icon name="hourglass half" />
        {tr('CASE_STATUS_CLOSE_AND_CHANGE_TO_DOING')}
      </Button>
    )}
  </Translation>
);

export default connect(mapStateToProps)(CloseAndReturnAsDoingContainer);
