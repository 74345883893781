import { Howl } from 'howler';

import FeatureFlags from '../api/FeatureFlags';

import notificationSound from './sounds/notification.mp3';

class SoundQueue {
  private queueCounter = 0;
  private runningState = false;
  private sound: Howl;

  constructor() {
    this.sound = new Howl({
      src: [notificationSound],
      onend: () => {
        this.queueCounter--;

        if (this.queueCounter === 0) {
          this.runningState = false;
          return;
        } else {
          this.fireSound();
        }
      }
    });
  }

  public pushSound = () => {
    if (FeatureFlags.isFlagOn('ENABLE_SOUND_NOTIFICATION')) {
      this.queueCounter++;

      if (!this.runningState) {
        this.fireSound();
      }
    }
  };

  private fireSound = () => {
    this.runningState = true;
    this.sound.play();
  };
}

export const soundQueue = new SoundQueue();
