import type { Tag } from '@eeedo/types';

import type { FormattedSearch } from './Search';

export enum FieldActionType {
  addEntity = 'add_entity'
}

export interface DropdownOption {
  text: string;
  value: string;
}

export type OnInfoSave = (
  param: keyof FormattedSearch['basic'],
  value: string | boolean | Tag[] | null | undefined,
  object: any,
  partial: boolean,
  name: string,
  text: string,
  datagroup?: string,
  entityType?: string,
  id?: any,
  entityTypes?: string[]
) => void;

export type EntitySearchFields = Partial<{ [key in Parameters<OnInfoSave>[0]]: Parameters<OnInfoSave>[1] }>;
