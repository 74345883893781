import { createReducer } from '@reduxjs/toolkit';

import type { Webhook } from '@eeedo/types';

import { fetchWebhooksSuccess } from 'src/actions/webhooksActions';

type WebhooksState = {
  webhooks: Webhook[];
};

const initialState: WebhooksState = {
  webhooks: []
};

const webhooksReducer = createReducer(initialState.webhooks, (builder) => {
  builder.addCase(fetchWebhooksSuccess, (state, action) => {
    return action.payload.webhooks;
  });
});

export default webhooksReducer;
