import { Direction, FilterPresetType, FilterPresetView, SortBy } from '@eeedo/types';
import iziToast from 'izitoast';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Divider, Dropdown, Form, Icon, Input, Label, Modal, Radio } from 'semantic-ui-react';
import * as yup from 'yup';

import type { FilterPresetPayload } from '@eeedo/types';
import type { FC } from 'react';

import FilterPresetsFiltersEditor from './FilterPresetsFiltersEditor';
import { createFilterPreset, updateFilterPreset } from 'src/actions/filterPresetsActions';
import { useAppThunkDispatch } from 'src/store';

import type { State } from 'src/types/initialState';

type AlwaysRequiredProps = 'filters' | 'sorting' | 'direction';

interface FilterPresetsModalProps {
  isOpen: boolean;
  modalPayload: Partial<Omit<FilterPresetPayload, AlwaysRequiredProps>> &
    Pick<FilterPresetPayload, AlwaysRequiredProps> & { isDefault?: boolean };
  currentView?: FilterPresetView;

  editId: number | null;

  closeModal: () => void;
  setSelectedPreset?: (id: number) => void;
}

const defaultModalPayload: FilterPresetPayload & { isDefault?: boolean } = {
  sorting: SortBy.dueDate,
  direction: Direction.ASC,
  filters: {},
  name: '',
  type: FilterPresetType.personal,
  isFavourite: false,
  isActive: true,
  ticketTypes: [],
  view: FilterPresetView.main,
  isDefault: undefined
};

const validationSchema = yup.object().shape({
  name: yup.string().min(1).required(),
  type: yup.string().oneOf(Object.values(FilterPresetType)).required(),
  isActive: yup.boolean().required(),
  isDefault: yup.boolean().optional(),
  isFavourite: yup.boolean().required(),
  ticketTypes: yup.array(yup.number().integer().required()).required(),
  filters: yup.object().required(),
  direction: yup.string().oneOf(['ASC', 'DESC']).required(),
  sorting: yup.string().min(1).required()
});

const FilterPresetsWidgetModal: FC<FilterPresetsModalProps> = ({
  isOpen,
  editId,
  closeModal,
  currentView,
  setSelectedPreset,
  ...props
}) => {
  const { t } = useTranslation();

  const [modalPayload, setModalPayload] = useState<FilterPresetPayload & { isDefault?: boolean }>(defaultModalPayload);
  const [validationErrors, setValidationErrors] = useState<Partial<Record<keyof FilterPresetPayload, string>>>({});
  const [isLoading, setLoading] = useState(false);

  const ticketTypes = useSelector((state: State) => state.ticketTypes);
  const isCurrentUserAdmin = useSelector((state: State) =>
    ['ROL1', 'ROL10'].includes(state.usersList.usersList.find((u) => u.UID === state.userData.UID)?.role.id ?? '')
  );

  useEffect(() => {
    const newPayload: FilterPresetPayload & { isDefault?: boolean } = {
      ...defaultModalPayload,
      view: currentView ?? FilterPresetView.main,
      ...props.modalPayload
    };
    setModalPayload(newPayload);
  }, [props.modalPayload, currentView]);

  useEffect(() => {
    setLoading(false);
  }, [isOpen]);

  const dispatchThunk = useAppThunkDispatch();

  const onSave = useCallback(async () => {
    setValidationErrors({});

    const payload = {
      ...modalPayload,
      // only include 'isDefault' in payload if the value is significant (setting it as 'true', or changing it to 'false')
      // backend resets user's default filter if it's changing to 'false'
      ...(!props.modalPayload.isDefault && !modalPayload.isDefault ? { isDefault: undefined } : {})
    };

    try {
      await validationSchema.validate(payload, { abortEarly: false });

      if (editId) {
        await dispatchThunk(updateFilterPreset({ filterPresetId: editId, payload }));
      } else {
        const result = await dispatchThunk(createFilterPreset({ payload }));
        if ('filterPresetId' in result.payload! && payload.isActive && setSelectedPreset) {
          setSelectedPreset(result.payload.filterPresetId as number);
        }
      }
      closeModal();
    } catch (err: any) {
      if (!(err instanceof yup.ValidationError)) {
        console.error(`Failed to create filterPreset: ${err?.message}`);
        iziToast.error({
          title: t('ERROR_SOMETHING_WENT_WRONG')
        });
        return;
      }

      setValidationErrors(
        err.inner.reduce((acc: typeof validationErrors, error: yup.ValidationError) => {
          const errors = { ...acc };

          if (error.path) {
            errors[error.path] = error.message;
          }

          return errors;
        }, {})
      );
    } finally {
      setLoading(false);
    }
  }, [setLoading, setValidationErrors, closeModal, modalPayload, setSelectedPreset]);

  const getErrorPrompt = (errorKey: string) => (
    <Label pointing="below" prompt>
      {t(`management.filter_presets.modal.errors.${errorKey}`)}
    </Label>
  );

  return (
    <Modal
      open={isOpen}
      className="filterPreset__modal"
      onClose={() => {
        setValidationErrors({});
        closeModal();
      }}
      closeOnDocumentClick
      closeOnDimmerClick
      centered
      closeOnEscape
    >
      <Modal.Header>
        {t(editId ? 'management.filter_presets.modal.edit_title' : 'management.filter_presets.modal.create_title')}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field
            error={
              validationErrors.name && {
                content: validationErrors.name,
                pointing: 'above'
              }
            }
          >
            <label htmlFor="modal-form-name">{t('management.filter_presets.modal.name_label')}</label>
            {validationErrors.name && getErrorPrompt('name')}
            <Input
              id="modal-form-name"
              onChange={(e) => setModalPayload({ ...modalPayload, name: e.target.value })}
              value={modalPayload.name}
              placeholder={t('management.filter_presets.modal.name_placeholder')}
              autoFocus
            />
          </Form.Field>

          <Form.Field>
            <label>{t('INIT_LOAD_TITLE_ticketTypes')}</label>
            <Dropdown
              onChange={(event, data) => {
                setModalPayload({
                  ...modalPayload,
                  ticketTypes: data.value as number[]
                });
              }}
              options={ticketTypes.map((t) => ({ text: t.name, value: t.id }))}
              text={t('management.filter_presets.modal.ticket_types_text')}
              selectOnBlur={false}
              value={modalPayload.ticketTypes}
              search
              scrolling
              selection
              multiple
            />
          </Form.Field>
          <Form.Group widths="equal">
            {isCurrentUserAdmin ? (
              <Form.Field>
                <label htmlFor="modal-form-type">{t('GENERAL_TYPE')}</label>
                <Dropdown
                  id="modal-form-type"
                  value={modalPayload.type}
                  options={Object.values(FilterPresetType).map((type: string) => ({
                    value: type,
                    text: t(`management.filter_presets.modal.type_labels.${type}`)
                  }))}
                  onChange={(e, data) => {
                    setModalPayload({
                      ...modalPayload,
                      type: String(data.value) as FilterPresetType
                    });
                  }}
                  // there are always defaults, no one should be seeing this
                  placeholder={t('GENERAL_TYPE')}
                  selection
                />
              </Form.Field>
            ) : null}
            {currentView ? null : (
              <Form.Field>
                <label>{t('management.filter_presets.modal.view_label')}</label>
                <Dropdown
                  onChange={(event, data) => {
                    setModalPayload({
                      ...modalPayload,
                      view: data.value as FilterPresetView
                    });
                  }}
                  options={Object.values(FilterPresetView).map((v) => ({
                    text: t(`management.filter_presets.modal.view_labels.${v}`),
                    value: v
                  }))}
                  value={modalPayload.view}
                  selection
                />
              </Form.Field>
            )}
          </Form.Group>
          <Form.Field>
            <label>{t('management.filter_presets.modal.settings_label')}</label>
            <FilterPresetsFiltersEditor
              contentType={modalPayload.view === FilterPresetView.main ? 'tickets' : 'infopages'}
              filters={modalPayload.filters}
              sorting={modalPayload.sorting}
              direction={modalPayload.direction}
              setFilter={({ parameter, value }) =>
                setModalPayload({ ...modalPayload, filters: { ...modalPayload.filters, [parameter]: value } })
              }
              setSorting={(sorting: SortBy, direction: Direction) =>
                setModalPayload({ ...modalPayload, sorting, direction })
              }
            />
          </Form.Field>
          <Divider fitted />
          <Form.Group inline>
            <Form.Field style={{ marginTop: '25px' }} width={3}>
              <Radio
                label={t('management.filter_presets.modal.favourite_label')}
                checked={modalPayload.isFavourite}
                onChange={() => setModalPayload({ ...modalPayload, isFavourite: !modalPayload.isFavourite })}
                toggle
              />
            </Form.Field>
            <Form.Field style={{ marginTop: '25px' }} width={4}>
              <Radio
                label={t('management.filter_presets.modal.default_label')}
                checked={modalPayload.isDefault}
                onChange={() => setModalPayload({ ...modalPayload, isDefault: !modalPayload.isDefault })}
                toggle
              />
            </Form.Field>
            <Form.Field style={{ marginTop: '25px' }} width={3}>
              <Radio
                label={t('management.filter_presets.modal.active_label')}
                checked={modalPayload.isActive}
                onChange={() => setModalPayload({ ...modalPayload, isActive: !modalPayload.isActive })}
                toggle
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button positive labelPosition="right" onClick={onSave} disabled={isLoading} icon>
          {isLoading ? <Icon name="circle notch" loading /> : <Icon name="check" />}
          {t('GENERAL_SAVE')}
        </Button>
        <Button
          labelPosition="right"
          onClick={() => {
            setValidationErrors({});
            closeModal();
          }}
          negative
          icon
        >
          <Icon name="times" />
          {t('GENERAL_CANCEL')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default FilterPresetsWidgetModal;
