import { get } from 'lodash-es';

const formatTitleWithValues = (title: string | undefined, values: any) => {
  const regex = /\[(.*?)]/;

  if (!title) {
    return '';
  }

  return (title || '')
    .split('|')
    .map((param) => {
      const paramRegexTest = param.match(regex);

      if (paramRegexTest && paramRegexTest[1].length > 0) {
        return paramRegexTest[1];
      }

      return get(values, param.toString(), '');
    })
    .join('');
};

export default formatTitleWithValues;
