export enum ChannelType {
  Email = 1,
  Phone = 2,
  Webform = 3,
  Other = 4,
  Chat = 5,
  Internal = 6,
  Sms = 7,
  SecureEmail = 12,
  Facebook = 20,
  Giosg = 21,
  CustomerPortal = 22,
  WhatsApp = 23,
  Salesforce = 24,
  PriorityMail = 25,
  Tampuuri = 26,
  Eezy = 28,
  ViestiTytti = 30,
  Instagram = 31,
  D365 = 32
}

export const getChannelTypeKey = (value: number): string | undefined => {
  const keys = Object.keys(ChannelType).filter((k) => typeof ChannelType[k as any] === 'number') as string[];
  for (const key of keys) {
    if (ChannelType[key as keyof typeof ChannelType] === value) {
      return key;
    }
  }
  return undefined;
};

export enum Channels {
  intra = 'intra',
  email = 'email',
  chat = 'chat',
  sms = 'sms',
  secureMail = 'secureMail',
  facebook = 'facebook',
  giosg = 'giosg',
  tampuuri = 'tampuuri',
  customerPortal = 'customerPortal',
  whatsapp = 'whatsapp',
  salesforce = 'salesforce',
  d365 = 'd365',
  phone = 'phone',
  eezy = 'eezy',
  viestitytti = 'viestitytti',
  instagram = 'instagram'
}

export const exampleChannels = [
  {
    id: 1,
    channel: 'Sähköposti',
    icon: 'mail',
    color: 'green',
    active: 1
  },
  {
    id: 2,
    channel: 'Puhelin',
    icon: 'phone',
    color: 'yellow',
    active: 1
  },
  {
    id: 3,
    channel: 'Verkkolomake',
    icon: 'wpforms',
    color: 'gray',
    active: 1
  }
];
