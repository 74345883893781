import { withTranslation } from 'react-i18next';

import { ReplySalesforce } from './ReplySalesforce';
import { Channels } from 'src/types/Channel';

class ReplyD365 extends ReplySalesforce {
  getDraftChannel(): Channels {
    return Channels.d365;
  }
}

export default withTranslation()(ReplyD365);
