import { t as tr } from 'i18next';
import iziToast from 'izitoast';
import React from 'react';
import { Translation } from 'react-i18next';
import { Button, Card, Divider, Form, Header, Icon, Input, Message, Modal } from 'semantic-ui-react';

import type { AxiosError, AxiosResponse } from 'axios';
import type { InputOnChangeData } from 'semantic-ui-react';

import { getPrettyDate } from '../../Utilities/dates';
import { highlightContentByInput } from '../../Utilities/highlighter';
import { sanitizeHTML } from '../../Utilities/sanitize';
import { absolutifyUrls } from '../../Utilities/url';

import type { ExtraOptions } from '../../Utilities/sanitize';

import './KnowledgeBank.css';

import { apiClient } from 'src/Utilities/httpClients';

interface KnowledgeBankProps {
  id: string;
  title?: string;
  content: string;
  updateState: Function;
  extraArguments?: {
    portalId?: number;
    rangesize?: number;
  };
}

interface EGainResult {
  name: string;
  description: string;
  contentText: string;
  content: string;
  createdDate: string;
  lastModifiedDate: string;
  link: {
    rel: 'self';
    href: string;
  };
  alternateId: string;
  id: number;
}

interface KnowledgeBankState {
  isModalOpen: boolean;
  hasPerformedSearch: boolean;
  results: EGainResult[];
  error: null | AxiosError;
  searchValue: string;
  selectedSearchValue: string;
  isLoading: boolean;
  collapsed: string[];
  fullData: any | null;
}

export class KnowledgeBank extends React.Component<KnowledgeBankProps, KnowledgeBankState> {
  constructor(props: KnowledgeBankProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      hasPerformedSearch: false,
      collapsed: [],
      results: [],
      fullData: null,
      error: null,
      searchValue: props.title || '',
      selectedSearchValue: props.title || '',
      isLoading: false
    };
  }

  componentWillReceiveProps(nextProps: KnowledgeBankProps) {
    if (this.props.id !== nextProps.id) {
      this.setState({
        searchValue: nextProps.title || '',
        selectedSearchValue: nextProps.title || '',
        isLoading: false,
        collapsed: [],
        results: [],
        error: null,
        fullData: null
      });
    }
  }

  onCollapse = (item: string) => {
    this.setState((previousState: KnowledgeBankState) => {
      const indexOfItem = this.state.collapsed.indexOf(item);
      if (indexOfItem === -1) {
        return {
          collapsed: [...previousState.collapsed, item]
        };
      } else {
        return {
          collapsed: [
            ...previousState.collapsed.slice(0, indexOfItem),
            ...previousState.collapsed.slice(indexOfItem + 1)
          ]
        };
      }
    });
  };

  performSearch = async () => {
    this.setState(
      {
        hasPerformedSearch: true,
        selectedSearchValue: this.state.searchValue,
        isLoading: true
      },
      () => {
        apiClient
          .get(`/knowledge/search`, {
            params: {
              q: this.state.searchValue,
              rangestart: 0,
              rangesize: this.props.extraArguments?.rangesize || 5,
              portalId: this.props.extraArguments?.portalId
            }
          })
          .then((response: AxiosResponse) => {
            this.setState({
              results: response.data.article,
              collapsed: [],
              fullData: response.data,
              isLoading: false,
              error: null
            });
          })
          .catch((error: AxiosError) => {
            this.setState({
              error: error,
              fullData: null,
              results: [],
              collapsed: [],
              isLoading: false
            });
          });
      }
    );
  };

  handleInput = (event: any, data: InputOnChangeData) => {
    this.setState({ searchValue: data.value });
  };

  handleLink = (result: EGainResult) => {
    if (this.state.fullData !== null) {
      const link = result.link.href;
      const base = `${link.substring(
        0,
        link.indexOf('/system/')
      )}/system/templates/selfservice/desktop/help/customer/locale/fi-fi/portal/`;
      const url = `${base}${this.state.fullData.callInfo.portalId}/content/${result.alternateId}`;
      window.open(url);
    }
  };

  getContent = (result: EGainResult) => {
    const sanitizationSettings: ExtraOptions = {
      addAllowedTags: ['img', 'a'],
      addAllowedSchemes: ['http', 'https'],
      addAllowedAttributes: {
        a: ['href', 'name', 'target'],
        img: ['src']
      },
      disallowedTagsMode: 'discard'
    };
    // Sanitize content
    let content = result.content;

    content = sanitizeHTML(content, sanitizationSettings);
    // Highlight search values
    content = highlightContentByInput({
      content: content,
      input: this.state.selectedSearchValue
    });

    // To allow all pictures to work, change relative urls to absolute urls
    content = absolutifyUrls({
      content: content,
      domain: result.link.href.substring(0, result.link.href.indexOf('/system/'))
    });

    return content;
  };

  renderArticle = (result: EGainResult) => {
    return (
      <Translation ns="translations">
        {(t: Function) => (
          <Card fluid>
            <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
              <Icon
                className="hoverClick"
                name={this.state.collapsed.indexOf(result.alternateId) !== -1 ? 'chevron down' : 'chevron up'}
                onClick={() => {
                  this.onCollapse(result.alternateId);
                }}
              />
            </div>
            <Card.Content>
              <Card.Header>{result.name}</Card.Header>
              <Card.Meta>{result.description}</Card.Meta>
              <Card.Meta>
                <Icon name="calendar plus" />
                {getPrettyDate(result.createdDate)}
              </Card.Meta>
              <Card.Meta>
                <Icon name="pencil square" />
                {getPrettyDate(result.lastModifiedDate)}
              </Card.Meta>
              {this.state.collapsed.indexOf(result.alternateId) === -1 && (
                <Card.Description>
                  <div
                    className="knowledgeBank-article-content"
                    dangerouslySetInnerHTML={{ __html: this.getContent(result) }}
                  ></div>
                </Card.Description>
              )}
            </Card.Content>
            {this.state.collapsed.indexOf(result.alternateId) === -1 && (
              <Card.Content extra>
                <Button
                  primary
                  floated="right"
                  icon
                  labelPosition="right"
                  onClick={() => {
                    this.onSelect(result.content);
                  }}
                >
                  <Icon name="copy" />
                  {t('knowledge.actions.use')}
                </Button>
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => {
                    this.handleLink(result);
                  }}
                >
                  <Icon name="external" />
                  {t('knowledge.actions.open')}
                </Button>
              </Card.Content>
            )}
          </Card>
        )}
      </Translation>
    );
  };

  render() {
    return (
      <Translation ns="translations">
        {(t: Function) => (
          <Modal
            closeOnDocumentClick
            open={this.state.isModalOpen}
            centered={false}
            closeOnDimmerClick
            onClose={() => {
              this.setState({
                isModalOpen: false
              });
            }}
            trigger={
              <Button
                labelPosition="left"
                icon
                basic
                onClick={() => {
                  this.setState(
                    (previousState: KnowledgeBankState) => ({
                      isModalOpen: !previousState.isModalOpen
                    }),
                    () => {
                      document.getElementById('knowledgeBank-search-input')?.focus();
                    }
                  );
                }}
              >
                <Icon name="search" />
                {t('knowledge.title')}
              </Button>
            }
          >
            <Modal.Header>{t('knowledge.title')}</Modal.Header>
            <Modal.Content>
              <Form>
                <Input
                  type="text"
                  id="knowledgeBank-search-input"
                  placeholder={t('knowledge.placeholder')}
                  fluid
                  onChange={this.handleInput}
                  value={this.state.searchValue}
                  action
                >
                  <input />
                  <Button
                    primary
                    icon
                    disabled={this.state.searchValue.length === 0}
                    labelPosition="right"
                    type="submit"
                    onClick={this.performSearch}
                  >
                    <Icon name="search" />
                    {t('knowledge.search')}
                  </Button>
                </Input>
              </Form>
              {this.state.hasPerformedSearch && !this.state.isLoading && (
                <Header as="h4">
                  <Icon name="search" />
                  <Header.Content>
                    {t('SEARCH_RESULTS_LIST')} ({this.state.results.length})
                  </Header.Content>
                </Header>
              )}
              {this.state.isLoading && (
                <Header as="h4">
                  <Icon name="circle notch" loading />
                  <Header.Content>{t('LOADING')}...</Header.Content>
                </Header>
              )}
              <Divider />
              {!this.state.isLoading &&
                this.state.error === null &&
                this.state.hasPerformedSearch &&
                this.state.results.length === 0 && (
                  <Message
                    icon="search"
                    info
                    header={t('knowledge.info.title')}
                    content={t('knowledge.info.message')}
                  />
                )}
              {this.state.error !== null && (
                <Message
                  icon="warning sign"
                  error
                  header={`${t('knowledge.error.title')} (${this.state.error.response?.status})`}
                  content={`${t('knowledge.error.message')}. (${this.state.error.response?.statusText})`}
                />
              )}
              {!this.state.isLoading && this.state.error === null && (
                <Card.Group>{this.state.results.map(this.renderArticle)}</Card.Group>
              )}
            </Modal.Content>
          </Modal>
        )}
      </Translation>
    );
  }

  userHasSelected = (solution: string) => {
    this.props.updateState(solution);
    this.setState({
      isModalOpen: false
    });
  };

  onSelect = (solution: string) => {
    if (this.props.content.length > 0) {
      iziToast.question({
        timeout: 0,
        close: false,
        overlay: true,
        id: 'question',
        zindex: 999999,
        message: tr('TEMPLATE_TEXT_AREA_ALREADY_HAS_TEXT'),
        position: 'center',
        buttons: [
          [
            `<button><b>${tr('GENERAL_REPLACE')}</b></button>`,
            (instance: any, toast: any) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
              this.userHasSelected(solution);
            },
            true
          ],
          [
            `<button>${tr('GENERAL_CONCAT_END')}</button>`,
            (instance: any, toast: any) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
              const concatted = `${this.props.content}<br/><p>${solution}</p>`;
              this.userHasSelected(concatted);
            },
            false
          ],
          [
            `<button>${tr('GENERAL_CONCAT_START')}</button>`,
            (instance: any, toast: any) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
              const concatted = `<p>${solution}</p><br/>${this.props.content}`;
              this.userHasSelected(concatted);
            },
            false
          ],
          [
            `<button>${tr('GENERAL_CANCEL')}</button>`,
            (instance: any, toast: any) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
            },
            false
          ]
        ]
      });
    } else {
      this.userHasSelected(solution);
    }
  };
}
