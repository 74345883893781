import type { CacheKey } from './types';

type CacheMeta = {
  valid: boolean;
  // updated: number;
};

export type CallbackType<T> = (key: CacheKey) => Promise<T | null>;
export type NotifyCallback = (key: CacheKey) => void;

export class Cache<T> {
  protected cache = new Map<CacheKey, { meta: CacheMeta; data: T }>();
  protected notifyChange: NotifyCallback | null = null;

  constructor(protected defaultValue: T, protected callback?: CallbackType<T>) {}

  setNoticyCallback(callback: NotifyCallback | null) {
    this.notifyChange = callback;
  }

  setData(key: CacheKey, data: T) {
    const meta: CacheMeta = {
      valid: true
      // updated: const now = new Date().getTime();
    };
    this.cache.set(key, { meta: meta, data: data });
    this.notify(key);
  }

  getData(key: CacheKey): T {
    const res = this.cache.get(key);
    if (!res) return this.defaultValue;
    return res.data;
  }

  async invalidate(key: CacheKey) {
    const data = this.cache.get(key);
    if (data) {
      data.meta.valid = false;
      this.cache.set(key, data);
    }
    await this.refresh(key);
  }

  delete(key: CacheKey) {
    this.cache.delete(key);
  }

  notify(key: CacheKey) {
    if (this.notifyChange) this.notifyChange(key);
  }

  protected async refresh(key: CacheKey) {
    if (this.callback) {
      const data = await this.callback(key);
      if (data) {
        this.setData(key, data);
        this.notify(key);
      } else {
        this.delete(key);
        this.notify(key);
      }
    }
  }

  clear() {
    this.cache = new Map<CacheKey, { meta: CacheMeta; data: T }>();
  }
}
