import React from 'react';
import { Icon } from 'semantic-ui-react';
import type { VFC } from 'react';

import FeatureFlags from 'src/api/FeatureFlags';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import type { TicketItemProps } from './TicketListItem';

type TicketListDateRowProps = Pick<TicketItemProps, 'created' | 'touched' | 'dueDate'>;

const formatDate = (date: number) => getPrettyDate(date, { format: DATE_TIME_FORMAT });

const TicketListDateRow: VFC<TicketListDateRowProps> = ({ created, touched, dueDate }) => (
  <div className="ticketListDateRow">
    {FeatureFlags.isFlagOn('ENABLE_TICKETLIST_CREATED_TIMESTAMP') && (
      <>
        <Icon className="ticketListDateRow_icon" name="plus" />
        {formatDate(created)}
      </>
    )}
    {FeatureFlags.isFlagOn('ENABLE_TICKETLIST_TOUCHED_TIMESTAMP') && (
      <>
        <Icon className="ticketListDateRow_icon" name="pencil" />
        {formatDate(touched)}
      </>
    )}
    {FeatureFlags.isFlagOn('ENABLE_TICKETLIST_DUEDATE_TIMESTAMP') && dueDate && (
      <>
        <Icon className="ticketListDateRow_icon" name="time" />
        {formatDate(dueDate)}
      </>
    )}
    {!FeatureFlags.isFlagOn('ENABLE_TICKETLIST_CREATED_TIMESTAMP') &&
      !FeatureFlags.isFlagOn('ENABLE_TICKETLIST_TOUCHED_TIMESTAMP') &&
      !FeatureFlags.isFlagOn('ENABLE_TICKETLIST_DUEDATE_TIMESTAMP') && (
        <>
          <Icon className="ticketListDateRow_icon" name="plus" />
          {formatDate(created)}
        </>
      )}
  </div>
);

export default React.memo(TicketListDateRow);
