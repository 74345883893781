import React from 'react';
import { useTranslation } from 'react-i18next';

import type ChannelType from './ChannelType';

// FIXME: should extend generic interface together with all other prosp
export interface PhoneContentProps {
  channel: ChannelType;
  metaData: Record<string, any>;
  showSender?: boolean;
}

function PhoneContent(props: PhoneContentProps) {
  const { t } = useTranslation();

  return (
    <div>
      <b>{t('PHONE')}</b>
      <hr />
      {!!props.showSender && !!props.metaData.phone && (
        <div>
          <b>{t('COMMENT_USER_POPUP_CALL_FROM')}</b>
          <p style={{marginTop: '8px'}}>{props.metaData.phone}</p>
        </div>
      )}
      {Array.isArray(props.metaData.to) && 
        <div style={{marginTop: '8px'}}>
        <b>{t('COMMENT_METADATA_TARGETED_TO')}</b>
        {props.metaData.to.length > 1 ? (
          <ol style={{margin:0}}>
            {props.metaData.to.map((to: string, index: number) => (
              <li key={`phone-content-to-${index}`}>{to}</li>
            ))}
          </ol>) : <p style={{margin:0}}>{props.metaData.to[0]}</p>}
      </div>}
      {!!props.metaData.acdName && <div style={{ marginTop: '8px' }}><b>{t('COMMENT_METADATA_CALL_ACD_NAME')}</b><p style={{marginTop: '8px'}}>{props.metaData.acdName}</p></div>}
      {!!props.metaData.type && <div style={{ marginTop: '8px' }}><b>{t('COMMENT_METADATA_CALL_TYPE')}</b><p style={{marginTop: '8px'}}>{props.metaData.type}</p></div>}
    </div>
  );
}

export default PhoneContent;
