import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';

import type { CSSProperties, VFC } from 'react';
import type { ConnectedProps } from 'react-redux';

import * as styles from './RescheduleCallback.style';
import RescheduleCallbackModal from './RescheduleCallbackModal';
import RescheduledCallbacksApi from 'src/api/RescheduledCallbacksApi';
import { getCallbackTime } from 'src/Components/Utilities/reschedule-callbakc-widget';
import { clearRescheduledWidgetState, setRescheduledCallbacks } from 'src/reducers/rescheduledCallbackWidget';

import type { State } from 'src/types/initialState';

const style: CSSProperties = { padding: '10px' };

interface ScheduleMessageProps extends ConnectedProps<typeof connector> {
  contentId: number;
}

const RescheduleCallback: VFC<ScheduleMessageProps> = ({ contentId, duedate, numberOfReschedules }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearRescheduledWidgetState());

    RescheduledCallbacksApi.getRescheduledCallbacks(contentId).then((data) => {
      if (data?.length) {
        dispatch(setRescheduledCallbacks(data));
      }
    });
  }, [contentId]);

  return (
    <Form style={style}>
      <div style={styles.modalTrigger}>
        <RescheduleCallbackModal contentId={contentId} />
      </div>

      <div style={styles.rescheduledCallsInfo}>
        <div>
          <strong>{t('widgets.reschedule_call.current_duedate')}:</strong> {getCallbackTime(duedate)}
        </div>
        <div>
          <strong>{t('widgets.reschedule_call.number_of_reschedules')}:</strong> {numberOfReschedules}
        </div>
      </div>
    </Form>
  );
};

const connector = connect((state: State) => ({
  numberOfReschedules: state.rescheduleCallbackWidget.rescheduledCallbacks.length,
  duedate: state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)?.dueDate
}));

export default connector(RescheduleCallback);
