import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Attachment } from 'src/types/Ticket';

const initialState: AttachmentsState = { previewAttachments: false, attachments: [] };

export interface AttachmentsState {
  attachments: Attachment[];
  previewAttachments: boolean;
  attachmentId?: string;
}

const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    openAttachmentsPreview: (
      state,
      action: PayloadAction<{ firstAttachmentId: string; attachments: Attachment[] }>
    ) => {
      state.previewAttachments = true;
      state.attachmentId = action.payload.firstAttachmentId;
      state.attachments = action.payload.attachments;
    },
    closeAttachmentsPreview: () => {
      return initialState;
    }
  }
});

export const { openAttachmentsPreview, closeAttachmentsPreview } = attachmentsSlice.actions;

export default attachmentsSlice.reducer;
