import React from 'react';
import { Header, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import DateSelector from '../Case/DateSelector';
import ErrorBoundary from '../../ErrorBoundary';
import { camelToSnakeCase } from 'src/Utilities/casing';
import type { SearchCriterion } from 'src/types/Search';

export const DatesValues = [
  'createdAfter',
  'createdBefore',
  'touchedAfter',
  'touchedBefore',
  'duedateAfter',
  'duedateBefore'
] as const;
export type Dates = typeof DatesValues[number];

export interface SearchDateRangesProps {
  noDefaultDates?: boolean;
  onChange: (param: Dates, value: string | undefined) => void;
  values: SearchCriterion[];
}

const valuesToDates = (values: SearchCriterion[]) => {
  return values.reduce<{ [key in Dates]?: string }>(
    (prev, curr) => ({
      ...prev,
      ...(DatesValues.includes(curr.param as Dates) && { [curr.param]: curr.value })
    }),
    {}
  );
};

export const SearchDateRanges = (props: SearchDateRangesProps) => {
  const dates = valuesToDates(props.values);
  const { t } = useTranslation();

  return (
    <ErrorBoundary>
      <Table striped={false}>
        <Table.Body>
          {DatesValues.map((date) => (
            <Table.Row>
              <Table.Cell width={6}>
                <div style={{ display: 'flex' }}>
                  <Header
                    as="h5"
                    style={{
                      margin: '0px',
                      marginTop: 'auto',
                      marginBottom: 'auto'
                    }}
                  >
                    {t(`search.date.${camelToSnakeCase(date)}`)}
                  </Header>
                  <div style={{ marginTop: '-8px', marginBottom: '-9px' }} />
                </div>
              </Table.Cell>

              <DateSelector
                value={dates[date]}
                onChange={(timestamp) => {
                  props.onChange(date, timestamp);
                }}
              />
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </ErrorBoundary>
  );
};
