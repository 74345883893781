import { apiClient } from 'src/Utilities/httpClients';
import type { LinkList } from 'src/types/Widgets';

export default class LinkListsApi {
  static getLinkLists = (): Promise<LinkList[]> => {
    return apiClient.get('/linklists').then((response) => {
      return response.data;
    });
  };
}
