import type { NotificationRule } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class NotificationApi {
  static createNotification = async (notificationPayload: Partial<NotificationRule>): Promise<NotificationRule> => {
    return apiClient
      .post('/user/notifications', {
        ...notificationPayload,
        conditions: JSON.stringify(notificationPayload.conditions),
        soundConfig: JSON.stringify(notificationPayload.soundConfig),
        popupConfig: JSON.stringify(notificationPayload.popupConfig)
      })
      .then((response) => {
        return response.data;
      });
  };

  static deleteNotification = async (notificationId: number): Promise<{ msg: string }> => {
    return apiClient.delete(`/user/notifications/${notificationId}`).then((response) => {
      return response.data;
    });
  };

  static updateNotification = async (
    notificationId: number,
    notificationPayload: Partial<NotificationRule>
  ): Promise<NotificationRule> => {
    return apiClient
      .patch(`/user/notifications/${notificationId}`, {
        ...notificationPayload,
        conditions: JSON.stringify(notificationPayload.conditions),
        soundConfig: JSON.stringify(notificationPayload.soundConfig),
        popupConfig: JSON.stringify(notificationPayload.popupConfig)
      })
      .then((response) => {
        return response.data;
      });
  };
}
