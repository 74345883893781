import type { UserWithProfile } from '@eeedo/types';

import { userToNumericalId } from 'src/Utilities/users';

export const getUserById = (users: UserWithProfile[], UID?: number | string): UserWithProfile | undefined => {
  if (!UID) {
    return;
  }

  return users.find((usr) => userToNumericalId(usr.UID) === userToNumericalId(UID));
};

export const getProfileName = (user?: UserWithProfile) => {
  const { profile } = user || {};
  return `${profile?.firstName || ''} ${profile?.lastName || ''}`;
};
