import { TextArea } from 'semantic-ui-react';

const insertAtPosition = (value: string, toInsert: string, position: number): string => {
  return value.slice(0, position) + toInsert + value.slice(position);
};

export const insertAtCursor = (
  el: HTMLInputElement | HTMLTextAreaElement | TextArea | null | undefined,
  text: string
): string => {
  if (!el) {
    return '';
  }

  const element = el instanceof TextArea ? (el['ref']['current'] as HTMLTextAreaElement) : el;

  const position = element.selectionStart;
  if (position == null) {
    return element.value;
  }

  return insertAtPosition(element.value, text, position);
};
