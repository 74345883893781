import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Popup } from 'semantic-ui-react';
import type { VFC } from 'react';

import { setShowEvents } from 'src/reducers/commentsMenuReducer';
import type { State } from 'src/types/initialState';

const EventsToggle: VFC = () => {
  const { t } = useTranslation();
  const showEvents = useSelector<State, boolean>((state) => !!state.commentsMenu.showEvents);
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(setShowEvents(!showEvents));
  };

  return (
    <Popup
      trigger={
        <Button icon size="tiny" onClick={onClick}>
          <Icon name="calendar" color={showEvents ? 'green' : 'grey'} />
        </Button>
      }
    >
      <Popup.Content>{showEvents ? t('comments.popups.hide_events') : t('comments.popups.show_events')}</Popup.Content>
    </Popup>
  );
};

export default EventsToggle;
