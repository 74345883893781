import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type Status from 'src/types/ChatTypeStatus';
import type { ChatStatusData } from 'src/types/ChatTypeStatus';

export type ChatTypeStatusState = { [k: Status['ticketId']]: Status };

const initialState: ChatTypeStatusState = {};

const statusReducerSlice = createSlice({
  name: 'statusSlice',
  initialState,
  reducers: {
    updateChatVisitorTypeStatusSuccess: (state, action: PayloadAction<ChatStatusData>) => {
      const { composing_status: status, ticketId, chatType: type } = action.payload;

      state[ticketId] = state[ticketId] ? { ...state[ticketId], status } : { status, ticketId, type };
    }
  }
});

export const { updateChatVisitorTypeStatusSuccess } = statusReducerSlice.actions;

export default statusReducerSlice.reducer;
