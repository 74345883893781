import iziToast from 'izitoast';
import { t } from 'i18next';
import type { FileRejection } from 'react-dropzone';

import { MAX_ATTACHMENT_SIZE } from '../../config';

export function onDropRejected(files: FileRejection[]) {
  files.forEach(() => {
    iziToast.error({
      title: t('ERROR'),
      message: `${t('UNABLE_TO_SEND_ATT_FILE_TOO_BIG')} ${MAX_ATTACHMENT_SIZE / 1024 / 1024} MB`
    });
  });
}
