// Imported for isAxiosError
import axios from 'axios';
import { t } from 'i18next';

import ApiConfig from 'src/api/ApiConfig';
import { apiClient } from 'src/Utilities/httpClients';

import type { UploadedFileData } from 'src/types/Campaign';
import type { Survey, SurveyAnswer, SurveyQuestionAnswerQuestion, Template } from 'src/types/Survey';

const surveyUrl = 'cases';

const getSurveyAnswers = async (ticketId: string): Promise<SurveyAnswer[]> => {
  const response = await apiClient.get(`/${surveyUrl}/${ticketId}/surveys`);
  const { data } = response;

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined || response.status === 404;
      successful ? resolve(data) : reject({ message: t('CASE_SURVEY_NOT_FOUND') });
    }, 0);
  });
};

const getManualSurveys = async (ticketId: string) => {
  const { data: surveys } = await apiClient.get<Partial<Survey>[]>(`/surveys/manual/${ticketId}`);
  return surveys;
};

const getSurveyLink = async (taskId: string, surveyId: number) => {
  const { data } = await apiClient.post(`/surveys/link/${taskId}/${surveyId}`);
  return data?.link;
};

const getSurveyComponentsHtml = async () => {
  try {
    const response = await apiClient.get(`/surveys/components`);

    return response.data;
  } catch (err) {
    console.error('error fetching survey html', err);
    throw err;
  }
};

// Get Survey Templates List
// NOTE: This doesn't include Survey details! Use getSurveyTemplate for that
const getSurveyList = async () => {
  const response = await apiClient.get(`/cases/surveys`);

  const { data } = response;
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined;
      successful ? resolve(data) : reject({ message: t('CASE_SURVEY_NOT_FOUND') });
    }, 0);
  });
};

const getSurvey = async (id: number): Promise<Survey> => {
  const response = await apiClient.get(`/cases/surveys/${id}`);
  const { data } = response;

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined;
      successful ? resolve(data) : reject({ message: t('CASE_SURVEY_NOT_FOUND') });
    }, 0);
  });
};

const getSurveyQuestions = async (): Promise<SurveyQuestionAnswerQuestion[]> => {
  const response = await apiClient.get(`${surveyUrl}Questions`);
  const { data } = response;

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined;
      const questions: SurveyQuestionAnswerQuestion[] = data;
      successful ? resolve(questions) : reject({ message: t('CASE_SURVEY_QUESTIONS_NOT_FOUND') });
    }, 0);
  });
};

const updateSurvey = async (survey: Survey) => {
  let returnData: any;
  let message: string;
  let statusCode: number;

  try {
    const response = await apiClient.put(`/surveys/${survey.id}`, survey);
    const { data } = response;

    returnData = data;
    statusCode = response.status;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      statusCode = parseInt(error.code!);
      message = error.message;
    }
  }

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = statusCode === 200;
      successful ? resolve(returnData.msg) : reject({ message });
    }, 0);
  });
};

const createSurvey = async (survey: Survey) => {
  const response = await apiClient.post(`/surveys/`, survey);
  const { data } = response;

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined;
      successful ? resolve(data.msg) : reject({ message: t('CASE_SURVEY_NOT_FOUND') });
    }, 0);
  });
};

const getTemplateList = async () => {
  const response = await apiClient.get(`/surveys/templates/`);
  return response.data;
};

const updateTemplate = async (templateData: any) => {
  let returnData: any;
  let message: string;
  let statusCode: number;

  try {
    const response = await apiClient.patch(`/surveys/templates/${templateData.id}`, templateData);
    const { data } = response;

    returnData = data;
    statusCode = response.status;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      statusCode = parseInt(error.code!);
      message = error.message;
    }
  }

  return new Promise((resolve, reject) => {
    const successful = statusCode === 200;
    successful ? resolve(returnData) : reject({ message });
  });
};

const createTemplate = (template: Template) => {
  return apiClient.post(`/surveys/templates/`, template);
};

const deleteTemplate = async (templateId: number) => {
  let returnData: any;
  let message: string;
  let statusCode: number;

  try {
    const response = await apiClient.delete(`/surveys/templates/${templateId}`);
    const { data } = response;

    returnData = data;
    statusCode = response.status;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      statusCode = parseInt(error.code!);
      message = error.message;
    }
  }

  return new Promise((resolve, reject) => {
    const successful = statusCode === 200;
    successful ? resolve(returnData) : reject({ message });
  });
};

const uploadCustomLogo = async (file: FormData): Promise<UploadedFileData> => {
  const response = await apiClient.post(ApiConfig.getConfig().API_URL + '/upload/survey', file, {
    params: { access: 'public' }
  });
  return response.data;
};

export {
  createSurvey,
  createTemplate,
  deleteTemplate,
  getManualSurveys,
  getSurvey,
  getSurveyAnswers,
  getSurveyComponentsHtml,
  getSurveyLink,
  getSurveyList,
  getSurveyQuestions,
  getTemplateList,
  updateSurvey,
  updateTemplate,
  uploadCustomLogo
};
