import classNames from 'classnames';
import React from 'react';
import { Header, Input, Table } from 'semantic-ui-react';

import type { VFC } from 'react';

import type { InfoFieldProps } from './InfoFieldProps';

interface ComplexListProps extends Pick<InfoFieldProps, 'field'> {
  fieldValue: { name: string; value: string }[];
  disabled?: boolean;
  mongoSaveDisabled?: boolean;
  integrationSaveDisabled?: boolean;
}

const ComplexList: VFC<ComplexListProps> = ({
  field,
  fieldValue,
  disabled,
  mongoSaveDisabled,
  integrationSaveDisabled
}) => {
  const className = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });

  if (!Array.isArray(fieldValue)) {
    return null;
  }

  return (
    <Table.Cell negative={false} colspan={10} style={{ padding: 0 }}>
      <Table>
        {fieldValue.map((fieldSubVal, index) => {
          const cellTitle = field.childPrefix ? field.childPrefix + fieldSubVal.name : fieldSubVal.name;

          return (
            <Table.Row key={`info-complex-list-row-${index}`}>
              <Table.Cell width={6}>
                <Header as="h5">{cellTitle}</Header>
              </Table.Cell>
              <Table.Cell width={10} textAlign="right">
                {field.params?.input ? (
                  <Input
                    disabled={disabled}
                    className={className}
                    size="mini"
                    value={fieldSubVal.value || ''}
                    fluid={true}
                  />
                ) : (
                  fieldSubVal.value
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
    </Table.Cell>
  );
};

export default ComplexList;
