import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Message, Modal } from 'semantic-ui-react';
import type { VFC } from 'react';

const ActivateFeatureModal: VFC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const onClose = () => {
    history.goBack();
  };

  return (
    <Modal open={true} closeIcon onClose={onClose}>
      <Modal.Content>
        <Message
          info
          icon="chat"
          header={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {t('settings.knowledgebase.feature_disabled')}
            </div>
          }
        />
      </Modal.Content>
    </Modal>
  );
};

export default React.memo(ActivateFeatureModal);
