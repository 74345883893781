import type { Category } from '@eeedo/types';
import type { AxiosResponse } from 'axios';

import { apiClient } from 'src/Utilities/httpClients';

export default class CategoriesApi {
  static getCategories = async (): Promise<Category[]> => {
    return apiClient
      .get('/categories')
      .then((response: AxiosResponse<Category[]>) => response.data)
      .catch((error) => {
        console.error('Could not get categories');
        throw error;
      });
  };

  static createCategory = async (categoryData: Partial<Category>): Promise<Category> => {
    return apiClient
      .post('/categories/', categoryData)
      .then((response: AxiosResponse<Category>) => response.data)
      .catch((error) => {
        console.error('Could not create a category');
        throw error;
      });
  };

  static updateCategory = async (categoryId: number, categortyData: Partial<Category>): Promise<Category> => {
    return apiClient
      .patch(`/categories/${categoryId}`, categortyData)
      .then((response: AxiosResponse<Category>) => response.data)
      .catch((error) => {
        console.error('Could not update the category');
        throw error;
      });
  };

  static deleteCategory = async (categoryId: number) => {
    return apiClient
      .delete(`/categories/${categoryId}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Could not delete the category');
        throw error;
      });
  };

  static attachTagToCategory = async (categoryId: number, tagId: number): Promise<Category> => {
    return apiClient
      .post(`/categories/${categoryId}/tags/${tagId}`)
      .then((response: AxiosResponse<Category>) => response.data)
      .catch((error) => {
        console.error('Could not update the category');
        throw error;
      });
  };

  static detachTagFromCategory = async (categoryId: number, tagId: number): Promise<Category> => {
    return apiClient
      .delete(`/categories/${categoryId}/tags/${tagId}`)
      .then((response: AxiosResponse<Category>) => response.data)
      .catch((error) => {
        console.error('Could not update the category');
        throw error;
      });
  };
}
