// NOTE
// Cache api is not saving final version to db, this is
// done in backend. Frontend api is only for draft related usage.
import { Cache } from './cache';
import { SimpleCache } from './simpleCache';
import { VersionApi } from './api';
import { parseContent, serializeContent } from '../../Utilities/parseUtils';
import type { Attachment } from '../../types/Ticket';
import type { CacheKey, Counters, DraftLogEntry, DraftType, VersionLogEntry } from './types';

type CounterNotifyCallback = (taskId: number) => void;

// cache settings in miliseconds
// how long loaded from api data is valid in cache
// 5 min
const DATA_LIFETIME = 5 * 60 * 1000;
// how long information about not existing entry in cache is valid
// 10 min
const NX_LIFETIME = 10 * 60 * 1000;
// how long data should be keept in cache before save to db
// 3 min
const WRITE_LIFETIME = 3 * 60 * 1000;
// how long keep data in cache
// all saved entries will be erased after this time
// 15 min
const CACHE_LIFETIME = 15 * 60 * 1000;

type CacheEntry = {
  exists: boolean;
  content: string;
  time: number;
  saved: boolean;
};

export class VersionControlClient {
  private api: VersionApi;
  private cache = new Map<CacheKey, CacheEntry>();
  private counters: Cache<Counters>;
  private draftCache: SimpleCache<DraftLogEntry[]>;

  constructor(tableName: string) {
    this.api = new VersionApi(tableName);
    this.draftCache = new SimpleCache<DraftLogEntry[]>(120);
    this.counters = new Cache<Counters>(
      {
        drafts: 0,
        versions: 0
      },
      this.api.loadDraftInfo
    );
  }

  getCounters = (taskId: number, channelId?: number): Counters => {
    const key = this.getCacheKey(taskId, channelId);
    return this.counters.getData(key);
  };

  private getCacheKey = (taskId: number, channelId?: number): CacheKey => {
    return [taskId, channelId].filter(Boolean).join('_');
  };

  private parseCacheKey = (key: CacheKey): { taskId: number; channelId?: number } => {
    const [taskId, channelId] = key.split('_').map(Number);
    return { taskId, channelId };
  };

  /**
   * null - entry not exist in cache (need revalidate)
   * "nx" - entry is not existing in database
   * { id: id, content:string } - cache entry
   */
  private cacheGet = (key: CacheKey): DraftType | null | 'nx' => {
    const { taskId, channelId } = this.parseCacheKey(key);
    const entry = this.cache.get(key);
    if (!entry) return null;
    const now = new Date().getTime();
    const lifetime = now - entry.time;
    if (!entry.exists) {
      if (lifetime > NX_LIFETIME) {
        // cache entry too old, delete
        this.cache.delete(key);
        return null;
      }
      return 'nx';
    }
    if (lifetime > NX_LIFETIME) {
      if (lifetime > DATA_LIFETIME) {
        // cache entry too old, delete if saved and return null
        // if not saved, then use this old version to not loose
        // current editing
        if (entry.saved) {
          this.cache.delete(key);
          return null;
        }
      }
    }
    return {
      taskId,
      channelId,
      content: entry.content
    };
  };

  /**
   * Set value in cache
   */
  private cacheSet = (key: CacheKey, content: string, saved: boolean) => {
    const existingEntry = this.cache.get(key);
    let time = new Date().getTime();
    if (!saved && existingEntry && existingEntry.exists && !existingEntry.saved) {
      // saving entry shouldn't reset time for unsaved entries
      time = existingEntry.time;
    }
    this.cache.set(key, {
      exists: true,
      time: time,
      saved: saved,
      content: content
    });
  };

  private cacheList = (): CacheKey[] => {
    return Array.from(this.cache.keys());
  };

  /**
   * List of entries that should be saved to db
   */
  private cacheDirtyList = (noTimeCheck = false): CacheKey[] => {
    const now = new Date().getTime();
    const keys = this.cacheList();
    return keys.filter((key) => {
      const entry = this.cache.get(key);
      if (!entry) return false;
      if (!entry.exists) return false;
      if (entry.saved) return false;
      return noTimeCheck || now - entry.time > WRITE_LIFETIME;
    });
  };

  /**
   * Remove from cache all old entries which are saved to db.
   */
  private cacheCleanup = () => {
    const now = new Date().getTime();
    const delList = this.cacheList().filter((key) => {
      const entry = this.cache.get(key);
      return entry && entry.saved && now - entry.time > CACHE_LIFETIME;
    });
    for (const id of delList) {
      this.cache.delete(id);
    }
  };

  /**
   * Set in cache information that entry not exist in db
   */
  private cacheSetNX = (key: CacheKey) => {
    this.cache.set(key, {
      exists: false,
      content: '',
      time: new Date().getTime(),
      saved: true
    });
  };

  // send new draft version
  // database update is deferred!
  sendDraft({
    id,
    channelId,
    content,
    attachments,
    forceSave
  }: {
    id: number;
    channelId?: number;
    content: string;
    attachments: Attachment[];
    forceSave?: boolean;
  }) {
    if (!content) return;

    if (attachments.length) {
      content = serializeContent(content, attachments);
    }

    const key = this.getCacheKey(id, channelId);
    const lastVersion = this.cacheGet(key);
    if (lastVersion !== 'nx' && lastVersion !== null && lastVersion.content === content && !forceSave) return;
    this.cacheSet(key, content, false);
    // force save
    if (forceSave) {
      this._writeDraftEntryToDB(key, content);
    }
  }

  // load draft for specified entry id
  async loadDraft({
    taskId,
    channelId,
    attachments
  }: {
    taskId: number;
    channelId?: number;
    attachments: Attachment[];
  }): Promise<DraftType | null> {
    // get from cache...
    const cacheKey = this.getCacheKey(taskId, channelId);
    const cachedDraft = this.cacheGet(cacheKey);
    if (cachedDraft === 'nx') return null;
    if (cachedDraft !== null) return cachedDraft;
    // ...or from api
    const draft = await this.api.loadDraft(taskId, channelId);
    this.counters.setData(cacheKey, {
      drafts: draft.drafts,
      versions: draft.versions
    });
    if (draft.content === null) {
      this.cacheSetNX(cacheKey);
      return null;
    } else {
      const content = parseContent(draft.content, attachments, true);
      this.cacheSet(cacheKey, content, true);
      return {
        taskId,
        channelId,
        content
      };
    }
  }

  /**
   * Remove entry from cache.
   * Should be triggered after saving content as final.
   */
  clearEntry = (taskId: number, channelId?: number) => {
    const key = this.getCacheKey(taskId, channelId);
    this.cache.delete(key);
    this.counters.invalidate(key);
  };

  /**
   * Physical save content to db
   */
  private _writeDraftEntryToDB = async (key: CacheKey, content: string) => {
    const { taskId, channelId } = this.parseCacheKey(key);
    await this.api.saveDraft(taskId, content, channelId);
    this.cacheSet(key, content, true);
    this.draftCache.delete(key);
    await this.counters.invalidate(key);
  };

  /**
   * Save all new/updated entries to db and update timestamps.
   * This method store only entries which are older than WRITE_LIFETIME time
   * flush - set to true will save all entries
   */
  updateDB = async (noTimeCheck = false): Promise<void> => {
    const keyList = this.cacheDirtyList(noTimeCheck);
    for (const key of keyList) {
      const entry = this.cache.get(key);
      if (entry) {
        this._writeDraftEntryToDB(key, entry.content);
      }
    }
    this.cacheCleanup();
  };

  // save all unsaved data from cache do db
  // without checking time of life.
  // This method should be triggered before closing the page.
  // flush = (): Promise<void> => {
  //   return this.updateDB(true);
  // };

  setCounterNotify = (callback: CounterNotifyCallback | null) => {
    this.counters.setNoticyCallback(callback ? (key) => callback(this.parseCacheKey(key).taskId) : null);
  };

  // ----

  loadDraftHistory = async (taskId: number, channelId?: number): Promise<DraftLogEntry[]> => {
    const key = this.getCacheKey(taskId, channelId);
    const data = this.draftCache.getData(key);
    if (data) return data;
    const loadedData = await this.api.loadAllDrafts(taskId, channelId);
    this.draftCache.setData(key, loadedData);
    return loadedData;
  };

  loadVersionHistory = async (id: number): Promise<VersionLogEntry[]> => {
    const data = await this.api.loadAllVersions(id);
    if (data === null) return [];
    return data.log;
  };

  loadDraftById = async (taskId: number, draftId: number): Promise<DraftLogEntry | null> => {
    const key = this.getCacheKey(taskId);
    this.draftCache.delete(key);
    return this.api.loadDraftById(taskId, draftId);
  };

  loadVersionById = (id: number, versionId: number): Promise<VersionLogEntry | null> => {
    return this.api.loadVersionById(id, versionId);
  };
}
