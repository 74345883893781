import classNames from 'classnames';
import React, { Component } from 'react';

import type { PersonalData } from '@eeedo/types';
import type { History } from 'history';
import type { GestureState, vector2 } from 'react-with-gesture';

import ModalLoaderComponent from '../Loader/ModalLoader';
import MainView from './MainView';
import ApiConfig from 'src/api/ApiConfig';
import FeatureFlags from 'src/api/FeatureFlags';
import UserTimeLogs from 'src/api/UserTimeLog/UserTimeLogs';
import Layout from 'src/Components/Layout/Layout';
import ErrorBoundary from 'src/ErrorBoundary';
import RedirectRole from 'src/RedirectRole';

import '../../../../../node_modules/semantic-ui-css/semantic.min.css';
import './Main.css';

const ExportDataContainer = React.lazy(() => import('src/containers/ExportDataContainer'));

interface MainProps {
  history: History;
  location: Location;
  mobileMode: boolean;
  leftTabOpen: boolean;
  userPersonalData: PersonalData;

  onUp(delta: vector2): void;
}

interface MainState {
  visible: boolean;
  showExportDialog: boolean;
  initialPosition: vector2;
  endPosition: vector2;
}

const hideTicketListPaths = ['/settings', '/entities', '/infopage'];

class Main extends Component<MainProps, MainState> {
  constructor(props: MainProps) {
    super(props);

    this.state = {
      visible: true,
      showExportDialog: false,
      initialPosition: [0, 0],
      endPosition: [0, 0]
    };
  }

  private leavePromptEnabled(): boolean {
    return (
      FeatureFlags.isFlagOn('ENABLE_USER_TIME_LOGGING') &&
      process.env.NODE_ENV !== 'development' &&
      !localStorage.getItem('__disableLeavePrompt')
    );
  }

  /**
   * Prompt message on leaging or refreshing page
   * sending time active log to database
   * @param e
   */
  private handleLeavePage(e: BeforeUnloadEvent) {
    UserTimeLogs.finishCurrentLogSession();
    // https://stackoverflow.com/questions/40570164/how-to-customize-the-message-changes-you-made-may-not-be-saved-for-window-onb
    const confirmationMessage = 'Are you sure ?';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  private onToggleExportData = () => {
    this.setState(() => ({
      showExportDialog: !this.state.showExportDialog
    }));
  };

  private onUp = (state: GestureState) => {
    this.props.onUp(state.delta);
    this.setState({ endPosition: state.delta });
  }; // event => eventHandler, respond to touchstart/mousedown events within React's render cycle

  private onDown = (state: GestureState) => {
    this.setState({ initialPosition: state.initial });
  }; // event => eventHandler, respond to mouseup/touchend events within React's render cycle

  componentDidMount() {
    UserTimeLogs.init();
    // Alert on closing or refreshing page
    if (this.leavePromptEnabled()) {
      window.addEventListener('beforeunload', this.handleLeavePage);
    }
  }

  componentWillUnmount() {
    // Alert on closing or refreshing page
    if (this.leavePromptEnabled()) {
      window.removeEventListener('beforeunload', this.handleLeavePage);
    }
  }

  render() {
    const { location, leftTabOpen, mobileMode, userPersonalData } = this.props;
    const hideTicketList = hideTicketListPaths.some((path) => location.pathname.includes(path));
    const mobileSideMenuClass = classNames({ hiddenContainer: !leftTabOpen || hideTicketList });
    const mobileContentClass = classNames({
      detailViewMobile: true,
      hiddenContainer: leftTabOpen && !hideTicketList
    });

    return (
      <ErrorBoundary>
        {this.state.showExportDialog && (
          <React.Suspense fallback={<ModalLoaderComponent />}>
            <ExportDataContainer handleClose={this.onToggleExportData} isOpen={this.state.showExportDialog} />
          </React.Suspense>
        )}
        {ApiConfig.getConfig().REDIRECT_ROLE6 !== undefined && <RedirectRole />}

        <Layout
          mobileMode={mobileMode}
          onClickExportData={this.onToggleExportData}
          onDown={this.onDown}
          onUp={this.onUp}
        >
          <MainView
            mobileMode={mobileMode}
            hideTicketList={hideTicketList}
            mobileSideMenuClass={mobileSideMenuClass}
            mobileContentClass={mobileContentClass}
            userPersonalData={userPersonalData}
          />
        </Layout>
      </ErrorBoundary>
    );
  }
}

export default Main;
