import { TicketDirectionValues } from '@eeedo/types';
import { t } from 'i18next';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import type { TabFilter, TicketDirection } from '@eeedo/types';
import type { CSSProperties } from 'react';

interface OriginalDirectionDropdownProps {
  filters: TabFilter;
  changeFilter: (value: string, type: keyof TabFilter) => void;
  style?: CSSProperties;
}

const defaultStyle: CSSProperties = { width: '100%' };

function getDirectionDisplayed(direction: TicketDirection | undefined) {
  return direction ? t(`FILTER_BY_ORIGINAL_DIRECTION_${direction.toUpperCase()}`) : '';
}

function OriginalDirectionDropdown({ style, filters, changeFilter }: OriginalDirectionDropdownProps) {
  const options = TicketDirectionValues.map((dir) => ({ text: getDirectionDisplayed(dir), value: dir }));
  const directionValue = filters.originalDirection;

  return (
    <>
      <Dropdown
        id={'GENERAL_SEARCH_ORIGINAL_DIRECTION'}
        options={options}
        size={'small'}
        selectOnBlur={false}
        value={directionValue ?? ''}
        text={getDirectionDisplayed(directionValue)}
        selection={true}
        clearable={true}
        onChange={(e, data) => {
          changeFilter(data.value as string, 'originalDirection');
        }}
        style={{ ...defaultStyle, ...style }}
        placeholder={t('FILTER_BY_ORIGINAL_DIRECTION')}
      />
    </>
  );
}

export default OriginalDirectionDropdown;
