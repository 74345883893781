import React from 'react';
import { useTranslation } from 'react-i18next';

import type ChannelType from './ChannelType';

export interface SmsContentProps {
  channel: ChannelType;
  metaData: Record<string, any>;
  showSender?: boolean;
}

function SmsContent(props: SmsContentProps) {
  const { t } = useTranslation();

  return (
    <div>
      <b>SMS</b>
      <hr />
      {props.showSender && props.metaData.sender && (
        <div>
          <b>{t('COMMENT_METADATA_SENDER')}</b>
          {<p style={{ margin: 0 }}>{props.metaData.sender}</p>}
        </div>
      )}
      {Array.isArray(props.metaData.to) && (
        <div style={{ marginTop: '10px' }}>
          <b>{t('COMMENT_METADATA_TARGETED_TO')}</b>
          {props.metaData.to.length > 1 ? (
            <ol style={{ margin: 0 }}>
              {props.metaData.to.map((to: string, index: number) => (
                <li key={`sms-content-to-${index}`}>{to}</li>
              ))}
            </ol>
          ) : (
            <p style={{ margin: 0 }}>{props.metaData.to[0]}</p>
          )}
        </div>
      )}
    </div>
  );
}

export default SmsContent;
