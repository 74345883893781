import React from 'react';
import { useTranslation } from 'react-i18next';

import CommentIconHeader from './CommentIconHeader';

interface AttachmentsContentProps {
  index: number;
  data: any;
}

function AttachmentsContent(props: AttachmentsContentProps) {
  const { t } = useTranslation();

  return (
    <CommentIconHeader index={props.index} title={t('COMMENT_METADATA_ATTACHMENTS')}>
      <ol>
        {props.data.map((attachment: any) => {
          return (
            <li>
              {attachment.originalname && (
                <p>{t('COMMENT_METADATA_ATTACHMENT_ORIGINALNAME') + ': ' + attachment.originalname}</p>
              )}
              {attachment.mimetype && <p>{t('COMMENT_METADATA_ATTACHMENT_MIMETYPE') + ': ' + attachment.mimetype}</p>}
              {attachment.filename && <p>{t('COMMENT_METADATA_ATTACHMENT_FILENAME') + ': ' + attachment.filename}</p>}
              <br />
            </li>
          );
        })}
      </ol>
    </CommentIconHeader>
  );
}

export default AttachmentsContent;
