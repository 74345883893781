import type { LinkedTickets } from 'src/types/LinkedTickets';
import { FETCH_LINKED_TICKETS_SUCCESS, FETCH_LINKED_TICKETS_ERROR, RESET_LINKED_TICKETS } from '../actions';

const initialState = {
  data: {
    tickets: {},
    activeTicketId: undefined
  }
};

export interface LinkedTicketState {
  data: LinkedTickets;
}

const linkedTicketReducer = (state = initialState.data, action: any) => {
  switch (action.type) {
    case RESET_LINKED_TICKETS: {
      return {
        tickets: {},
        activeTicketId: undefined
      };
    }
    case FETCH_LINKED_TICKETS_SUCCESS: {
      const returnState = {} as any;
      returnState.tickets = action.payload.tickets;
      returnState.activeTicketId = action.payload.id;
      return returnState;
    }
    case FETCH_LINKED_TICKETS_ERROR: {
      return state;
    }
    default:
      return state;
  }
};

export default linkedTicketReducer;
