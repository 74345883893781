import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

import type { Tag as TagData } from '@eeedo/types';
import type { SyntheticEvent, VFC } from 'react';

interface TagProps {
  tag: TagData;
  onClose?: React.EventHandler<SyntheticEvent>;
}

const Tag: VFC<TagProps> = ({ tag, onClose, ...props }) => (
  <Label
    className="ticketTagLabel"
    tag={true}
    style={{ backgroundColor: tag.styles?.bgColor, color: tag.styles?.fontColor }}
    {...props}
  >
    {tag.name}
    {onClose && <Icon name="delete" onClick={onClose} />}
  </Label>
);

export default React.memo(Tag);
