import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import type { TranscriptionMessage } from 'src/Components/Case/Widget/transcriptionsWidgetTypes';

export interface TranscriptionReducerType {
  transcriptionsByContentId: {
    [key: number]: TranscriptionMessage[];
  };
}

const initialState: TranscriptionReducerType = {
  transcriptionsByContentId: {}
};

const transcriptionMessagesSlice = createSlice({
  name: 'transcriptionMessages',
  initialState,
  reducers: {
    transcriptionsUpdated(state, action: PayloadAction<{ contentId: string; message: TranscriptionMessage }>) {
      const transcriptionsByContentId = state.transcriptionsByContentId[action.payload.contentId];
      if (!transcriptionsByContentId) {
        state.transcriptionsByContentId[action.payload.contentId] = [];
      }
      state.transcriptionsByContentId[action.payload.contentId].push(action.payload.message);
    }
  }
});

export const transcriptionsUpdated = transcriptionMessagesSlice.actions.transcriptionsUpdated;

export default transcriptionMessagesSlice.reducer;
