import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';

import type { Suggestion } from '@eeedo/types';
import type { AxiosError } from 'axios';

import {
  CREATE_AUTO_SUGGESTION_SUCCESS,
  DELETE_AUTO_SUGGESTION_SUCCESS,
  FETCH_AUTO_SUGGESTIONS_SUCCESS,
  UPDATE_AUTO_SUGGESTION_SUCCESS
} from './index';
import { initialRequestFailure, initialRequestSuccess } from './initActions';
import SuggestionsApi from 'src/api/SuggestionsApi';

export const fetchAutoSuggestionsSuccess = createAction<{ suggestions: Suggestion[] }>(FETCH_AUTO_SUGGESTIONS_SUCCESS);
export const updateAutoSuggestionSuccess = createAction<{ suggestion: Suggestion }>(UPDATE_AUTO_SUGGESTION_SUCCESS);
export const createAutoSuggestionSuccess = createAction<{ suggestion: Suggestion }>(CREATE_AUTO_SUGGESTION_SUCCESS);
export const deleteAutoSuggestionSuccess = createAction<{ id: number }>(DELETE_AUTO_SUGGESTION_SUCCESS);

export const fetchAutoSuggestions = createAsyncThunk<{ status: string }, undefined, { rejectValue: Error }>(
  'fetchAutoSuggestions',
  async (argument, { dispatch }) => {
    try {
      const autosuggestions = await SuggestionsApi.fetchAutoSuggestions();
      dispatch(fetchAutoSuggestionsSuccess({ suggestions: autosuggestions }));
      dispatch(initialRequestSuccess('autoSuggestions'));
      return { status: 'success' };
    } catch (error) {
      dispatch(initialRequestFailure('autoSuggestions', error));

      if ((error as AxiosError)?.isAxiosError) {
        console.error('Failed to fetch autosuggestions: ', (error as AxiosError).response?.data);
      } else {
        console.error('Failed to fetch autosuggestions: ', (error as Error)?.message);
      }
      return { status: 'failed' };
    }
  }
);

export const patchAutoSuggestion = createAsyncThunk<
  Suggestion,
  { id: number; payload: Partial<Omit<Suggestion, 'id'>> },
  { rejectValue: Error }
>('patchAutoSuggestion', async (data, { dispatch }) => {
  try {
    const autosuggestion = await SuggestionsApi.patchAutoSuggestion(data);
    dispatch(updateAutoSuggestionSuccess({ suggestion: autosuggestion }));
    return autosuggestion;
  } catch (error) {
    if ((error as AxiosError)?.isAxiosError) {
      console.error('Failed to patch autosuggestion: ', (error as AxiosError).response?.data);
    } else {
      console.error('Failed to patch autosuggestion: ', (error as Error)?.message);
    }
    throw error;
  }
});

export const createAutoSuggestion = createAsyncThunk<
  Suggestion,
  Partial<Omit<Suggestion, 'id'>>,
  { rejectValue: Error }
>('createAutoSuggestion', async (data, { dispatch }) => {
  try {
    const autosuggestion = await SuggestionsApi.createAutoSuggestion(data);
    dispatch(createAutoSuggestionSuccess({ suggestion: autosuggestion }));
    return autosuggestion;
  } catch (error) {
    if ((error as AxiosError)?.isAxiosError) {
      console.error('Failed to create an autosuggestion: ', (error as AxiosError).response?.data);
    } else {
      console.error('Failed to create an autosuggestion: ', (error as Error)?.message);
    }
    throw error;
  }
});

export const deleteAutoSuggestion = createAsyncThunk<void, number, { rejectValue: Error }>(
  'deleteAutoSuggestion',
  async (id, { dispatch }) => {
    try {
      await SuggestionsApi.deleteAutoSuggestion(id);
      dispatch(deleteAutoSuggestionSuccess({ id }));
      iziToast.success({
        message: t('management.autosuggestion_management.AUTOSUGGESTION_DELETED_SUCCESFULLY'),
        timeout: 3000
      });
    } catch (error) {
      if ((error as AxiosError)?.isAxiosError) {
        console.error('Failed to delete autosuggestion: ', (error as AxiosError).response?.data);
      } else {
        console.error('Failed to delete autosuggestion: ', (error as Error)?.message);
      }
      throw error;
    }
  }
);
