import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface NotificationProps {
  type: any;
  taskId: string;
  className: string;
  //   icon: string;
  text: string;
}

const Notification = ({ text }: NotificationProps) => {
  const { t } = useTranslation();

  return (
    <Message icon>
      <Icon name="sticky note" />
      <Message.Content>
        <Message.Header>{t('CASE_SURVEY_NOTIFICATION_TITLE')}</Message.Header>
        {text}
      </Message.Content>
    </Message>
  );
};

export default Notification;
