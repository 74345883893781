import React, { useMemo } from 'react';
import { Table } from 'semantic-ui-react';

import type { Field } from '@eeedo/types';
import type { ComponentProps, VFC } from 'react';

import InfoField from 'src/Components/Case/Info/InfoField/InfoField';

type InfoFieldParams = ComponentProps<typeof InfoField>;

interface SalesforceObjectFormFieldProps {
  field: Field;
  fields: Field[];

  values: InfoFieldParams['values'];
  setValue: InfoFieldParams['setValue'];
}

const SalesforceObjectFormField: VFC<SalesforceObjectFormFieldProps> = ({
  field: originalField,
  fields,
  values,
  setValue
}) => {
  const field = useMemo(
    () => ({ ...originalField, params: { ...originalField.params, readOnly: false } }),
    [originalField]
  );

  if (field.params?.formNoRender) {
    return null;
  }

  return (
    <Table.Row>
      <InfoField field={field} fields={fields} values={values} setValue={setValue} />
    </Table.Row>
  );
};

export default React.memo(SalesforceObjectFormField);
