import type { Option } from 'src/Components/MultiSelectInput/MultiSelectInput';

export enum TicketStatusAfterCommentSubmit {
  doing = 'doing',
  done = 'done',
  todo = 'todo'
}

export type SalesforceStandardObjectType =
  | 'Account'
  | 'Contact'
  | 'Opportunity'
  | 'Lead'
  | 'Case'
  | 'Task'
  | 'Event'
  | 'Campaign'
  | 'User'
  | 'Product'
  | 'PriceBook'
  | 'Quote'
  | 'Order'
  | 'Asset'
  | 'Contract'
  | 'Dashboard'
  | 'Document'
  | 'Note'
  | 'Group'
  | 'Report'
  | 'RecordType'
  | 'UserRole'
  | 'Profile'
  | 'PermissionSet'
  | 'Queue';
export type SalesforceObjectType = SalesforceStandardObjectType | 'Email' | 'Call';

export interface SalesforceFormPayload {
  type?: SalesforceObjectType;
  Subject?: string;
  Emails?: Option[];
  Phones?: Option[];
  Description?: string;
  Status?: string;
  Name?: string;
  AccountId?: string;
  WhoId?: string;
  OwnerId?: string;
  Company?: string;
  StageName?: string;
  CloseDate?: number;
  ActivityDate?: number;
  Amount?: number;
}

export interface IGeneralSalesforceData {
  Id: string;
  Name: string;
  [key: string]: string;
}

export interface SalesforceResponseStatus {
  success: boolean;
}

export interface SalesforceObjectDescription {
  actionOverrides: SalesforceActionOverride[];
  activateable: boolean;
  associateEntityType: null;
  associateParentEntity: null;
  childRelationships: SalesforceChildRelationship[];
  compactLayoutable: boolean;
  createable: boolean;
  custom: boolean;
  customSetting: boolean;
  deepCloneable: boolean;
  defaultImplementation: null;
  deletable: boolean;
  deprecatedAndHidden: boolean;
  extendedBy: null;
  extendsInterfaces: null;
  feedEnabled: boolean;
  fields: SalesforceField[];
  hasSubtypes: boolean;
  implementedBy: null;
  implementsInterfaces: null;
  isInterface: boolean;
  isSubtype: boolean;
  keyPrefix: string;
  label: string;
  labelPlural: string;
  layoutable: boolean;
  listviewable: null;
  lookupLayoutable: null;
  mergeable: boolean;
  mruEnabled: boolean;
  name: string;
  namedLayoutInfos: any[];
  networkScopeFieldName: null;
  queryable: boolean;
  recordTypeInfos: SalesforceRecordTypeInfo[];
  replicateable: boolean;
  retrieveable: boolean;
  searchLayoutable: boolean;
  searchable: boolean;
  sobjectDescribeOption: string;
  supportedScopes: SalesforceSupportedScope[];
  triggerable: boolean;
  undeletable: boolean;
  updateable: boolean;
  urls: SalesforceUrls;
}

export interface SalesforceActionOverride {
  formFactor: string;
  isAvailableInTouch: boolean;
  name: string;
  pageId: string;
  url: null;
}

export interface SalesforceChildRelationship {
  cascadeDelete: boolean;
  childSObject: string;
  deprecatedAndHidden: boolean;
  field: string;
  junctionIdListNames: any[];
  junctionReferenceTo: any[];
  relationshipName: null | string;
  restrictedDelete: boolean;
}

export interface SalesforceField {
  aggregatable: boolean;
  aiPredictionField: boolean;
  autoNumber: boolean;
  byteLength: number;
  calculated: boolean;
  calculatedFormula: null | string;
  cascadeDelete: boolean;
  caseSensitive: boolean;
  compoundFieldName: null;
  controllerName: null | string;
  createable: boolean;
  custom: boolean;
  defaultValue: boolean | null | string;
  defaultValueFormula: null;
  defaultedOnCreate: boolean;
  dependentPicklist: boolean;
  deprecatedAndHidden: boolean;
  digits: number;
  displayLocationInDecimal: boolean;
  encrypted: boolean;
  externalId: boolean;
  extraTypeInfo: null | string;
  filterable: boolean;
  filteredLookupInfo: SalesforceFilteredLookupInfo | null;
  formulaTreatNullNumberAsZero: boolean;
  groupable: boolean;
  highScaleNumber: boolean;
  htmlFormatted: boolean;
  idLookup: boolean;
  inlineHelpText: null | string;
  label: string;
  length: number;
  mask: null;
  maskType: null;
  name: string;
  nameField: boolean;
  namePointing: boolean;
  nillable: boolean;
  permissionable: boolean;
  picklistValues: SalesforcePicklistValue[];
  polymorphicForeignKey: boolean;
  precision: number;
  queryByDistance: boolean;
  referenceTargetField: null;
  referenceTo: string[];
  relationshipName: null | string;
  relationshipOrder: null;
  restrictedDelete: boolean;
  restrictedPicklist: boolean;
  scale: number;
  searchPrefilterable: boolean;
  soapType: SalesforceSoapType;
  sortable: boolean;
  type: string;
  unique: boolean;
  updateable: boolean;
  writeRequiresMasterRead: boolean;
}

export interface SalesforceFilteredLookupInfo {
  controllingFields: any[];
  dependent: boolean;
  optionalFilter: boolean;
}

export interface SalesforcePicklistValue {
  active: boolean;
  defaultValue: boolean;
  label: string;
  validFor: null | string;
  value: string;
}

export enum SalesforceSoapType {
  TnsID = 'tns:ID',
  XSDBoolean = 'xsd:boolean',
  XSDDateTime = 'xsd:dateTime',
  XSDDouble = 'xsd:double',
  XSDString = 'xsd:string'
}

export interface SalesforceRecordTypeInfo {
  active: boolean;
  available: boolean;
  defaultRecordTypeMapping: boolean;
  developerName: string;
  master: boolean;
  name: string;
  recordTypeId: string;
  urls: SalesforceRecordTypeInfoUrls;
}

export interface SalesforceRecordTypeInfoUrls {
  layout: string;
}

export interface SalesforceSupportedScope {
  label: string;
  name: string;
}

export interface SalesforceUrls {
  rowTemplate: string;
  approvalLayouts: string;
  uiDetailTemplate: string;
  quickActions: string;
  layouts: string;
  compactLayouts: string;
  uiEditTemplate: string;
  caseArticleSuggestions: string;
  caseRowArticleSuggestions: string;
  listviews: string;
  describe: string;
  uiNewRecord: string;
  sobject: string;
}
