import { createAction } from '@reduxjs/toolkit';

import type { Priority } from '@eeedo/types';

import {
  ADD_TICKET_TO_TICKETLIST,
  FETCH_TICKET_PRIORITIES_SUCCESS,
  FETCH_TICKET_SUCCESS,
  FETCH_TICKETS_ERROR,
  FETCH_TICKETS_SUCCESS,
  PARSE_TICKET_COMMENTS_SUCCESS,
  REMOVE_TICKET_FROM_DETAILED,
  REMOVE_TICKET_FROM_TICKETLIST,
  UPDATE_TICKET_IN_TICKETLIST
} from '.';

import type { TicketComments, TicketListTicket } from 'src/types/Ticket';

export const fetchTicketsSuccess = createAction<{ id: string; tickets: TicketListTicket[] }>(FETCH_TICKETS_SUCCESS);

export const fetchTicketsError = createAction<string>(FETCH_TICKETS_ERROR);

export const addTicketToTicketlist = createAction<{ ticket: TicketListTicket; id: string }>(ADD_TICKET_TO_TICKETLIST);

export const removeTicketFromTicketlist = createAction<{ ticketId: string; id: string }>(REMOVE_TICKET_FROM_TICKETLIST);

export const updateTicketInTicketlist = createAction<{ ticket: TicketListTicket; id: string }>(
  UPDATE_TICKET_IN_TICKETLIST
);

export const fetchTicketSuccess = createAction<{
  ticket: TicketListTicket;
}>(FETCH_TICKET_SUCCESS);

export const parseTicketCommentsSuccess = createAction<TicketComments>(PARSE_TICKET_COMMENTS_SUCCESS);

export const loadTicketPrioritiesSuccess = createAction<Priority[]>(FETCH_TICKET_PRIORITIES_SUCCESS);

export const removeTicketFromDetailed = createAction(REMOVE_TICKET_FROM_DETAILED, (id: string) => ({
  payload: { id }
}));
