import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import type { FC } from 'react';

import ErrorMessage from '../../../../ErrorMessage';
import * as styles from './ItemWrapper.style';

interface ItemWrapperProps {
  loaded: boolean;
  reload: () => void;
  hasError: boolean;
  children?: React.ReactNode;
}

const ItemWrapper: FC<ItemWrapperProps> = ({ hasError, loaded, reload, children }) => (
  <div style={styles.wrapper}>
    {!loaded && !hasError && (
      <Dimmer active>
        <Loader />
      </Dimmer>
    )}
    {children}
    {hasError && <ErrorMessage warning onClick={reload} />}
  </div>
);

export default ItemWrapper;
