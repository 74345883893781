import { createReducer } from '@reduxjs/toolkit';

import { setCommentFilter, clearCommentFilter } from 'src/actions/commentFiltersActions';
import type { CommentFilters } from 'src/types/Ticket';

type CommentFiltersState = {
  commentFilters: { [key: string]: CommentFilters };
};

const initialState: CommentFiltersState = {
  commentFilters: {}
};

const commentFiltersReducer = createReducer(initialState.commentFilters, (builder) => {
  builder
    .addCase(setCommentFilter, (state, action) => {
      const { caseId, property, value } = action.payload;
      const filtersReference = state[caseId];
      if (filtersReference) {
        filtersReference[property] = value;

        // Clear filters for the ticket in case all values for filter options are falsy
        if (
          !Object.keys(filtersReference).some((filterKey) => {
            if (Array.isArray(filtersReference[filterKey])) {
              return !!filtersReference[filterKey].length;
            }
            return !!filtersReference[filterKey];
          })
        ) {
          delete state[caseId];
        }
      } else {
        state[caseId] = { [property]: value };
      }
    })
    .addCase(clearCommentFilter, (state, action) => {
      delete state[action.payload];
    });
});

export default commentFiltersReducer;
