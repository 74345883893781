import type { Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { ACTIVATE_TAB, CLOSE_TAB, UPDATE_WORK_STATUS } from 'src/actions';
import FeatureFlags from 'src/api/FeatureFlags';
import UserTimeLogs from 'src/api/UserTimeLog/UserTimeLogs';
import { ticketIdToType } from 'src/types/ContentTypes';
import { convertCaseNaming } from 'src/Utilities/helper';

import type { UpdateWorkStatusData } from 'src/actions/workStatusActions';
import type { State } from 'src/types/initialState';

export const userTimeLogsMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, State>) => (next) => async (action) => {
  if (!FeatureFlags.isFlagOn('ENABLE_USER_TIME_LOGGING')) {
    return next(action);
  }

  try {
    const store = api.getState();
    const currentUserUID = store.userData.UID ? parseInt(store.userData.UID.substring(3)) : null;

    switch (action.type) {
      case CLOSE_TAB: {
        const ticketId: string = action.payload;
        if (ticketId && ticketIdToType(ticketId) === 'infopage') {
          break;
        }

        const isWorkingOnTicket = store.workStatus.status.find(
          (workStatus) =>
            parseInt(workStatus.ticketId) === convertCaseNaming(ticketId, 'number') &&
            parseInt(workStatus.UID) === currentUserUID
        );

        // Closing a tab with ticket which was counting for userTimeLogs
        if (isWorkingOnTicket && UserTimeLogs.getActiveTicketId() === ticketId) {
          UserTimeLogs.finishCurrentLogSession();
        }
        break;
      }

      case ACTIVATE_TAB: {
        const ticketId: string = action.payload;
        if (ticketId && ticketIdToType(ticketId) === 'infopage') {
          break;
        }

        const isWorkingOnTicket = store.workStatus.status.find(
          (workStatus) =>
            parseInt(workStatus.ticketId) === convertCaseNaming(ticketId, 'number') &&
            parseInt(workStatus.UID) === currentUserUID
        );

        // Opening a ticket on which we're working on
        if (isWorkingOnTicket && UserTimeLogs.getActiveTicketId() !== ticketId) {
          UserTimeLogs.setActiveTicketId(ticketId);
        }
        break;
      }

      case UPDATE_WORK_STATUS: {
        const payload: UpdateWorkStatusData = action.payload;
        const activeTicketTab = store.ticketTabs.find((tab) => tab.activeTab);

        const incomingWorkStatusForActiveTicketLog = payload.status.find(
          (workStatus) =>
            workStatus.ticketId === UserTimeLogs.getActiveTicketId()?.substring(3) &&
            ticketIdToType(workStatus.ticketId) === 'task'
        );
        // Current user no longer working on a ticket which was counting for userTimeLogs
        if (
          !incomingWorkStatusForActiveTicketLog ||
          parseInt(incomingWorkStatusForActiveTicketLog.UID) !== currentUserUID
        ) {
          UserTimeLogs.finishCurrentLogSession();
        }

        const incomingWorkStatusForCurrentTab = payload.status.find(
          (workStatus) =>
            workStatus.ticketId === activeTicketTab?.id.substring(3) && ticketIdToType(workStatus.ticketId) === 'task'
        );
        // No one is working on ticket on the current tab
        if (!incomingWorkStatusForCurrentTab) break;
        // Someone else started working on a ticket on the current tab which was counting for userTimeLogs
        if (
          incomingWorkStatusForCurrentTab.ticketId === UserTimeLogs.getActiveTicketId() &&
          incomingWorkStatusForCurrentTab.UID !== store.userData.UID
        ) {
          UserTimeLogs.finishCurrentLogSession();
        }
        if (parseInt(incomingWorkStatusForCurrentTab.UID) === currentUserUID) {
          UserTimeLogs.setActiveTicketId(activeTicketTab!.id);
        }
        break;
      }

      default: {
        break;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return next(action);
};
