import { ACTIVATE_TICKET, DEACTIVATE_TICKET, SWIPE_RIGHT } from '../actions/index';

const initialState = {
  activeTicketTab: null
};

const ticketReducer = (state: any = initialState.activeTicketTab, action: any) => {
  switch (action.type) {
    case ACTIVATE_TICKET: {
      return action.payload;
    }

    case DEACTIVATE_TICKET:
    case SWIPE_RIGHT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

export default ticketReducer;
