interface HighlighterParams {
  input: string;
  content: string;
  highlightElement?: string;
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(string: string, find: string, replace: string) {
  return string.replace(new RegExp(find, 'gi'), replace);
}

export const highlightContentByInput = (params: HighlighterParams) => {
  if (params.highlightElement && params.highlightElement.indexOf('$1') === -1) {
    throw new Error('Highlighter element did not contain $1 to indicate where to put highlighted text');
  }

  const replacingElement = params.highlightElement || '<span class=highlight>$1</span>';

  return replaceAll(params.content, `(?<!<[^>]*)(${escapeRegExp(params.input)})`, replacingElement);
};
