import type { VFC } from 'react';
import React from 'react';
import type { SemanticICONS } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import type { StatusTypes } from 'src/types/Ticket';

interface TicketStatusDropdownProps {
  disabled: boolean;
  value: string;
  loading: boolean;
  statusOptions: {
    text: string;
    icon: SemanticICONS;
    value: string;
  }[];
  handleStatusChange: (value: string) => void;
}

const TicketStatusDropdown: VFC<TicketStatusDropdownProps> = ({
  disabled,
  value,
  loading,
  statusOptions,
  handleStatusChange
}) => {
  return (
    <Dropdown
      disabled={disabled}
      style={{ minWidth: '100%', width: '100%' }}
      selectOnBlur={false}
      value={value}
      loading={loading}
      id="ticketStatusDropdown"
      className="ticketStatusDropdown"
      onChange={(event, data) => {
        handleStatusChange(data.value!.toString() as StatusTypes);
      }}
      options={statusOptions}
      selection
      search
    />
  );
};

export default TicketStatusDropdown;
