import React, { useState } from 'react';
import iziToast from 'izitoast';
import { Button, Icon } from 'semantic-ui-react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import type { VFC } from 'react';

import TicketNumberInput from '../generic/TicketNumberInput';
import { activateTab } from 'src/actions/tabActionsRTK';
import { activateTicket } from 'src/actions/ticketsActions';
import type { ContentTypesFields } from 'src/types/Ticket';

interface IProps {
  contentType: ContentTypesFields;
}

const OpenById: VFC<IProps> = ({ contentType }) => {
  const [id, setId] = useState<number | null>(null);
  const dispatch = useDispatch();

  const onClick = async () => {
    if (id === null) {
      return;
    }

    try {
      const contentId = `${contentType === 'infopages' ? 'INF' : 'TSK'}${id}`;
      dispatch(activateTicket(contentId));
      dispatch(activateTab(contentId));
      setId(null);
    } catch (error) {
      iziToast.error({ message: t('QUICKSEARCH_COULD_NOT_FIND_TASK') + ': ' + id });
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  const label = (
    <Button icon onClick={onClick} primary style={{ borderRadius: '0px' }}>
      <Icon name="angle right" />
    </Button>
  );

  return (
    <TicketNumberInput
      label={label}
      onChange={setId}
      placeholder={t('OPEN_A_TICKET')}
      value={id}
      onKeyDown={onKeyDown}
    />
  );
};

export default OpenById;
