import { Wrapper } from '@googlemaps/react-wrapper';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Search } from 'semantic-ui-react';

import type { VFC } from 'react';
import type { SearchProps, SearchResultData } from 'semantic-ui-react';

import EnvSettings from 'src/api/EnvSettings';

import type { InfoFieldProps } from 'src/Components/Case/Info/InfoField/InfoFieldProps';

const DEFAULT_ZOOM = 11;
const DEFAULT_WIDTH = '100%';
const DEFAULT_HEIGHT = 100;

interface GoogleMapsFieldProps extends Pick<InfoFieldProps, 'field' | 'setValue' | 'onSaveData'> {
  disabled?: boolean;
  fieldValue: string;
  integrationSaveDisabled?: boolean;
  mongoSaveDisabled?: boolean;
}

const GoogleMapsField: VFC<GoogleMapsFieldProps> = ({
  disabled,
  field,
  fieldValue,
  integrationSaveDisabled,
  mongoSaveDisabled,
  onSaveData
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { width, height, googleMaps } = field.params || {};
  const [location, setLocation] = useState<google.maps.LatLngLiteral | null>(null);
  const [selectedValue, setSelectedValue] = useState(fieldValue || '');
  const [value, setValue] = useState(fieldValue || '');

  const { GOOGLE_API_KEY } = EnvSettings.getSettings();

  if (!GOOGLE_API_KEY) {
    console.warn('Google Maps API key is not set');
  }

  useEffect(() => {
    if (!ref.current || !location || typeof google === 'undefined') {
      return;
    }

    const map = new google.maps.Map(ref.current, {
      center: location,
      zoom: DEFAULT_ZOOM,
      mapTypeControl: false,
      streetViewControl: false,
      controlSize: 25
    });

    new google.maps.Marker({
      position: location,
      map
    });
  }, [location]);

  useEffect(() => {
    if (!value) setSelectedValue('');
  }, [value]);

  useEffect(() => {
    if (typeof google === 'undefined') {
      return;
    }

    if (!selectedValue) {
      setLocation(null);
      return;
    }

    geocodeByAddress(selectedValue)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setLocation(latLng))
      .catch(() => setLocation(null));
  }, [selectedValue]);

  const handleResultSelect = (_event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) => {
    const address = data.result.description;
    setValue(address);
    setSelectedValue(address);

    if (!integrationSaveDisabled && !mongoSaveDisabled) {
      onSaveData?.(field, address);
    }
  };

  return (
    <Wrapper apiKey={GOOGLE_API_KEY} libraries={['places']}>
      <PlacesAutocomplete value={value} onChange={setValue}>
        {({ getInputProps, suggestions, loading }) => (
          <Search
            {...getInputProps()}
            className="googleMapsSearch"
            disabled={disabled}
            loading={loading}
            results={suggestions}
            size="mini"
            onResultSelect={handleResultSelect}
            onSearchChange={(event: React.MouseEvent<HTMLElement>, data: SearchProps) => {
              getInputProps().onChange({ target: { value: data.value || '' } });
            }}
          />
        )}
      </PlacesAutocomplete>

      {location && googleMaps && (
        <div
          ref={ref}
          style={{
            width: width || DEFAULT_WIDTH,
            height: height || DEFAULT_HEIGHT,
            marginTop: 20
          }}
        />
      )}
    </Wrapper>
  );
};

export default GoogleMapsField;
