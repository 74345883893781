import { t } from 'i18next';
import iziToast from 'izitoast';

import type { ActionCreator, ThunkAction } from '@reduxjs/toolkit';

import { ADD_COMMENT_SUCCESS } from '.';
import TicketsApi from '../api/TicketsApi';
import { startAjaxCall } from './ajaxStatusActions';
import { ChannelType } from 'src/types/Channel';

import type { State } from 'src/types/initialState';
import type { Comment } from 'src/types/Ticket';

export const addComments = (ticketId: string, comment: Comment) => {
  return { type: ADD_COMMENT_SUCCESS, payload: { ticketId, comment } };
};

export const addComment: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  data: { [key: string]: any }
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'ADD_COMMENT', id: ticketId }));
    return TicketsApi.addComment(ticketId, data)
      .then((response) => {
        if (typeof response.id !== 'undefined') {
          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: `${t('TICKET_COMMENT_SAVE_SUCCESS')}.`,
            timeout: 2500
          });
          if (data.channel === ChannelType.Email) {
            iziToast.success({
              title: t('OK'),
              icon: 'icon check',
              message: `${t('COMMENT_SENT_TO_EMAIL')}.`,
              timeout: 2500
            });
          }
          dispatch(addComments(ticketId, response));
          return response;
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: `${t('TICKET_COMMENT_SAVE_FAILURE')}.` + response,
            timeout: 7500
          });
          throw new Error('Failed to save comment due to id found in response');
        }
      })
      .catch((error) => {
        if (
          typeof error.response?.data?.message === 'string' &&
          error.response?.data?.message.substring(0, 1) === '[' &&
          t(error.response?.data?.message.substring(1, error.response?.data?.message.length - 2)) !==
            error.response?.data?.message
        ) {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: `${t(error.response.data?.message.substring(1, error.response.data?.message.length - 2))}.`,
            timeout: 7500
          });
        } else if (error?.response?.data?.email_error_message) {
          iziToast.error({
            icon: 'icon delete',
            title: t('FAILED_TO_SEND_EMAIL_MESSAGE', { errorMessage: t(error.response.data.email_error_message) }),
            position: 'center',
            overlay: true,
            timeout: false
          });
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: `${t('TICKET_COMMENT_SAVE_FAILURE')}.`,
            timeout: 7500
          });
        }
        throw error;
      });
  };
};
