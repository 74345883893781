import { Channels } from 'src/types/Channel';
import type { Template } from 'src/types/Template';

export const nonHTMLChannels: Channels[] = [
  Channels.chat,
  Channels.facebook,
  Channels.giosg,
  Channels.instagram,
  Channels.sms,
  Channels.tampuuri,
  Channels.viestitytti,
  Channels.whatsapp
];

export const mapTemplate = (template: Template): Template => {
  if (typeof template.template === 'string') {
    try {
      return { ...template, template: JSON.parse(template.template) };
    } catch (err) {
      console.error(err);
    }
  }

  return template;
};
