import React, { useEffect, useRef } from 'react';

import type { VFC } from 'react';

import styles from './CommentTextEncapsulated.css?inline';

type CommentTextEncapsulatedProps = {
  comment?: string | null;
  isCommentExpanded?: boolean;
  setCommentTextHeight?: (height: number) => void;
};

export const EXPAND_COMMENT_HEIGHT = 150;
export const EXPAND_COMMENT_LENGTH = 500;

const styleElement = `<style>${styles}</style>`;

const CommentTextEncapsulated: VFC<CommentTextEncapsulatedProps> = ({
  comment,
  isCommentExpanded,
  setCommentTextHeight
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const commentText = isCommentExpanded ? comment : (comment ?? '').slice(0, EXPAND_COMMENT_LENGTH);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (divRef.current && comment) {
      let { shadowRoot } = divRef.current;
      if (!shadowRoot) {
        shadowRoot = divRef.current.attachShadow({ mode: 'open' });
      }

      timeoutId = setTimeout(() => {
        if (shadowRoot) {
          shadowRoot.innerHTML = `${styleElement}<div class="commentTextEncapsulatedContainer">${commentText}</div>`;
        }

        if (typeof setCommentTextHeight === 'function' && divRef.current) {
          setCommentTextHeight(divRef.current.clientHeight);
        }
      }, 0);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [commentText]);

  return <div ref={divRef}></div>;
};

export default CommentTextEncapsulated;
