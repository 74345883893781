import type { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  width: '75%',
  position: 'absolute',
  top: '10%',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: '#14375a'
};

export const logo: CSSProperties = { width: '360px', height: '80px' };

export const header: CSSProperties = { color: 'white' };

export const subheader: CSSProperties = { color: 'white' };

export const list: CSSProperties = { backgroundColor: 'white', padding: '20px' };

export const error: CSSProperties = {
  padding: '20px',
  backgroundColor: 'white',
  width: '100%',
  color: 'red'
};

export const btnContainer: CSSProperties = {
  padding: '20px',
  backgroundColor: 'white',
  width: '100%'
};
