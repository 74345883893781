import type { AnyAction, ActionCreator } from 'redux';

import { START_AJAX_CALL } from './index';
import type { AjaxCallStatus } from 'src/reducers/ajaxStatusReducer';

interface AjaxAction extends AnyAction {
  type: string;
}

export const startAjaxCall: ActionCreator<AjaxAction> = (ajaxCall: AjaxCallStatus) => {
  return { type: START_AJAX_CALL, callType: ajaxCall };
};
