import { isEmpty, isEqual } from 'lodash-es';
import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Form, Label } from 'semantic-ui-react';

import type { PersonalData, ResponseTemplate, Suggestion, TicketType } from '@eeedo/types';
import type { ConnectedProps } from 'react-redux';

import ChannelType, { channelNameToType } from '../CommentIconContent/ChannelType';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyVCDrafts, { parseJsonContent } from './components/ReplyVCDrafts';
import { KnowledgeBank } from './KnowledgeBank';
import CallButton from './Phone/CallButtons';
import ReplyTemplates from './ReplyTemplates';
import { addComment } from 'src/actions/commentsActions';
import { addCallRequest } from 'src/actions/phoneActions';
import FeatureFlags from 'src/api/FeatureFlags';
import { commentsVC } from 'src/api/VersionControl';
import { defaultCreatableSelectStyles } from 'src/Components/Utilities';
import { trimAndSplitRecipients } from 'src/Components/Utilities/recipients';
import { Channels } from 'src/types/Channel';
import { insertAtCursor } from 'src/Utilities/insertAtCursor';
import { callElisaOC } from 'src/Utilities/intergrations/callElisaOC';
import { callElisaRing } from 'src/Utilities/intergrations/callElisaRing';
import EnreachVoiceIntegration from 'src/Utilities/intergrations/callEnreachVoice';
import { callMitel } from 'src/Utilities/intergrations/callMitel';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import { replaceNordicCharacters, serializeContent } from 'src/Utilities/parseUtils';
import { hasPhoneIntegrations } from 'src/Utilities/phoneIntegrations';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';

import type { Option } from '../MultiSelectInput/MultiSelectInput';
import type { ViewMode } from '../Versions/VersionViewer';
import type { ReplyChannelTabBar } from './ReplyChannelTabBar';
import type { ReplyChannelTabBarProps } from 'src/containers/DraftsContainer';
import type { Entity, Ticket } from 'src/types/Ticket';

const ReplyEditor = React.lazy(() => import('src/Components/Case/ReplyEditor'));

interface ReplyPhoneProps extends ConnectedProps<typeof connector> {
  taskId: string;
  phoneNumbers: string;
  userData: PersonalData;
  ticketType: TicketType;
  ticketTypes: TicketType[];
  drafts?: Partial<ReplyPhoneState>;
  task: Ticket;
  entities: Entity[];
  templates: ResponseTemplate[];
  suggestions: Suggestion[];
  smallButtons?: boolean;

  updateState: ReplyChannelTabBar['updateStateToRedux'];
  onSubmit: ReplyChannelTabBarProps['onSubmit'];
  addRingCallRequest?: (ticketId: string, phoneNumber: string) => void;
  addOCCallRequest?: (ticketId: string, phoneNumber: string, taskType: string, ticketTypes: TicketType[]) => void;
  addMitelCallRequest?: (ticketId: string, phoneNumber: string) => void;
}

interface ReplyPhoneState {
  content: string;
  recipient: string;
  isLoading: boolean;
  selectedReplyTemplate: string | undefined;
}

const AUTOSAVE_TIMEOUT = 1000 * 60 * 15;

class ReplyPhone extends React.Component<ReplyPhoneProps, ReplyPhoneState> {
  private editor: HTMLTextAreaElement | null;

  private autoSaveIntervalId?: NodeJS.Timeout;

  constructor(props: ReplyPhoneProps) {
    super(props);

    this.state = this.getInitialState(this.props.drafts, this.props.phoneNumbers);
  }

  componentDidMount() {
    this.autoSaveIntervalId = setInterval(() => this.saveVCDraft(true), AUTOSAVE_TIMEOUT);
  }

  componentWillUnmount() {
    this.saveVCDraft(true);

    clearInterval(this.autoSaveIntervalId);
  }

  private saveDraft = (state: ReplyPhoneState) => {
    this.props.updateState(this.props.taskId, Channels.phone, {
      recipient: state.recipient,
      content: state.content,
      selectedReplyTemplate: state.selectedReplyTemplate
    });
  };

  private getInitialState = (drafts: Partial<ReplyPhoneState> | undefined, phoneNumbers: string): ReplyPhoneState => ({
    content: drafts?.content || '',
    recipient: drafts?.recipient || phoneNumbers,
    isLoading: false,
    selectedReplyTemplate: drafts?.selectedReplyTemplate || undefined
  });

  componentWillReceiveProps(nextProps: ReplyPhoneProps) {
    if (!isEqual(nextProps.drafts, this.props.drafts) || this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps.drafts, ''));
    }

    if (!isEqual(this.props.phoneNumbers, nextProps.phoneNumbers)) {
      this.setState(this.getInitialState(nextProps.drafts, nextProps.phoneNumbers));
    }
  }

  private updateState = (update: Partial<ReplyPhoneState>) => {
    this.setState(
      (previousState: ReplyPhoneState) => {
        return {
          ...previousState,
          ...update
        };
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  public submitComment = () => {
    if (this.state.isLoading || !this.state.content.length) {
      return;
    }

    this.setState({ isLoading: true }, () => {
      const bodyOfRequest = {
        content: serializeContent(this.state.content, this.props.task.attachments),
        channel: ChannelType.Phone,
        // Set to null due to business reporting reasons
        direction: null,
        metaData: {
          to: this.trimAndSplit(this.state.recipient)
        }
      };
      const request = this.props.onSubmit(bodyOfRequest, false);

      request
        .then((response: any) => {
          if (response !== false) {
            this.clearFields();
          }
          this.setState({ isLoading: false });
        })
        .catch((error: Error) => {
          console.error('Failed to add comment', error);
          this.setState({ isLoading: false });
        });
    });
  };

  private saveVCDraft = (isAutoSave = false) => {
    const channel = channelNameToType(Channels.phone);
    const id = taskIdToNumericalId(this.props.taskId);
    const { content } = this.state as any;

    if (!content && isAutoSave) {
      return;
    }

    commentsVC.sendDraft({
      id,
      channelId: channel,
      content: JSON.stringify({
        ...this.state,
        content: serializeContent(content, [])
      }),
      attachments: [],
      forceSave: true
    });
  };

  private loadVCDraft = async (_mode: ViewMode, draftId: number) => {
    const taskId = taskIdToNumericalId(this.props.taskId);
    const draft = await commentsVC.loadDraftById(taskId, draftId);

    if (draft && draft.content) {
      try {
        const state = parseJsonContent(draft.content);
        this.props.updateState(this.props.taskId, Channels.phone, {
          content: state.content,
          recipient: state.recipient
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  private trimAndSplit = (recipients: string) =>
    recipients
      .split(',')
      .map((recipient: string) => recipient.trim())
      .filter((recipient: string) => recipient.length > 1);

  private clearFields = () => {
    const { phoneNumbers } = this.props;
    this.setState({ content: '', recipient: phoneNumbers, selectedReplyTemplate: undefined }, () => {
      this.props.updateState(this.props.taskId, Channels.phone, {
        content: this.state.content,
        recipient: this.state.recipient
      });
    });
  };

  private getValue = (state: ReplyPhoneState) => {
    const separatedValues = trimAndSplitRecipients(state.recipient);

    return separatedValues?.map((val) => {
      if (val !== '') {
        return {
          label: '',
          value: val
        };
      } else {
        return;
      }
    });
  };

  private formatSuggestionOptionLabel = ({ label, value }: { label: string; value: string }) => (
    <span>
      {label ? <Label> {label}</Label> : ''} {value ? value : ''}
    </span>
  );

  private handleNumberChange = (selected: any) => {
    const phoneNumber = selected?.value ?? '';
    this.setState({ recipient: normalizePhoneNumber(phoneNumber) }, () => {
      this.saveDraft(this.state);
    });
  };

  private formatCreateLabel = (text: string) => {
    return <span>{text}</span>;
  };

  private onNewOption = (value: string) => {
    this.setState({ recipient: value?.trim?.() ?? '' }, () => {
      this.saveDraft(this.state);
    });
  };

  private mapSuggestions = (suggestions: Suggestion[]) => {
    return suggestions
      .filter((suggestion: Suggestion) => {
        if (!isEmpty(suggestion.ticketTypes)) {
          return suggestion.type === 'phone' && suggestion.ticketTypes.includes(this.props.ticketType.id);
        } else {
          return suggestion.type === 'phone';
        }
      })
      .map((suggestion: Suggestion) => ({
        label: suggestion.name,
        value: suggestion.value
      }));
  };

  private saveComment = (fromClickToCall = false) => {
    const { taskId: ticketId, addComment, addCallRequest } = this.props;
    const { recipient: phoneNumber } = this.state;

    const commentBody = {
      channel: ChannelType.Phone,
      content: `[user_called_to: ${phoneNumber}]`,
      direction: 'out',
      metaData: {
        to: [phoneNumber]
      }
    };
    addComment(ticketId, commentBody);

    const callRequestProps: Parameters<typeof addCallRequest>[0] = {
      phoneNumber,
      ticketId: parseInt(ticketId.substring(3), 10)
    };
    if (fromClickToCall) {
      callRequestProps.fromClickToCall = fromClickToCall;
    }
    addCallRequest(callRequestProps);
  };

  private callOC = () => {
    const { ticketType: taskType, ticketTypes } = this.props;
    const { recipient: phoneNumber } = this.state;

    this.saveComment(true);

    const ticketType = ticketTypes.find((tType: TicketType) => {
      return tType.name === taskType.name;
    });
    const serviceName = ticketType?.OCoutboundServiceName?.value;

    callElisaOC(phoneNumber, serviceName);
  };

  private callRing = () => {
    this.saveComment();

    callElisaRing(this.state.recipient);
  };

  private callMitel = () => {
    this.saveComment();

    callMitel(this.state.recipient);
  };

  private callEnreachVoice = () => {
    this.saveComment();

    EnreachVoiceIntegration.callEnreachVoice(this.state.recipient);
  };

  private callGenericPhoneLink = () => {
    this.saveComment();

    document.location.href = `tel:${this.state.recipient}`;
  };

  render() {
    const { hasElisaOCIntegration, hasElisaRingIntegration, hasMitelIntegration, hasEnreachVoiceIntegration } =
      hasPhoneIntegrations();

    return (
      <Translation ns="translations">
        {(t) => (
          <Form reply={true} style={{ marginTop: '20px' }}>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t('PHONENUMBER')}</label>
                <CreatableSelect
                  value={this.getValue(this.state) as Option[]}
                  hideSelectedOptions
                  isClearable
                  onChange={this.handleNumberChange}
                  onCreateOption={this.onNewOption}
                  formatOptionLabel={this.formatSuggestionOptionLabel}
                  options={this.mapSuggestions(this.props.suggestions)}
                  formatCreateLabel={() => this.formatCreateLabel(t('SELECT_ADD_NEW_RECIPIENT'))}
                  placeholder={t('ADD_COMMENT_PHONE_RECEPIENTS_PLACEHOLDER')}
                  noOptionsMessage={() => t('SELECT_NO_OPTIONS')}
                  classNamePrefix="addressSelect"
                  styles={{ ...defaultCreatableSelectStyles, menu: (css) => ({ ...css, zIndex: 10 }) }}
                  filterOption={createFilter({ ignoreAccents: false })}
                />
              </Form.Field>

              <Form.Field style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                {hasElisaOCIntegration && (
                  <CallButton
                    id="ocCallBtn"
                    tooltip={t('REPLY_PHONE_ELISA_OC_CALL')}
                    onClick={this.callOC}
                    disabled={!this.state.recipient}
                  />
                )}
                {hasElisaRingIntegration && (
                  <CallButton
                    id="ringCallBtn"
                    tooltip={t('REPLY_PHONE_ELISA_RING_CALL')}
                    onClick={this.callRing}
                    disabled={!this.state.recipient}
                  />
                )}
                {hasMitelIntegration && (
                  <CallButton
                    id="mitelCallBtn"
                    tooltip={t('REPLY_PHONE_MITEL_CALL')}
                    onClick={this.callMitel}
                    disabled={!this.state.recipient}
                  />
                )}
                {hasEnreachVoiceIntegration && (
                  <CallButton
                    id="enreachVoiceCallBtn"
                    tooltip={t('REPLY_PHONE_VOICE_CALL')}
                    onClick={this.callEnreachVoice}
                    disabled={!this.state.recipient}
                  />
                )}

                {FeatureFlags.isFlagOn('ENABLE_GENERIC_PHONE_LINK_CLICK_TO_CALL') && (
                  <CallButton
                    id="enreachVoiceCallBtn"
                    tooltip={t('REPLY_PHONE_GENERIC_LINK')}
                    onClick={this.callGenericPhoneLink}
                    disabled={!this.state.recipient}
                  />
                )}
              </Form.Field>
            </Form.Group>

            {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
              <Form.Field>
                <KnowledgeBank
                  title={this.state.content}
                  id={this.props.taskId}
                  extraArguments={this.props.ticketType.knowledgeBank}
                  content={this.state.content}
                  updateState={(value: string) => {
                    this.updateState({ content: value });
                  }}
                />
              </Form.Field>
            )}

            <Form.Field>
              <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
              <ReplyTemplates
                userData={this.props.userData}
                ticketType={this.props.ticketType}
                templates={this.props.templates}
                task={this.props.task}
                channel={ChannelType.Phone}
                content={this.state.content}
                entities={this.props.entities}
                selectedOption={this.state.selectedReplyTemplate}
                setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
                setContent={(value) => {
                  if (value.content && !isEmpty(value.content)) {
                    value.content = replaceNordicCharacters(value.content);
                  }
                  this.updateState({ ...value });
                }}
                insertAtCursor={(value, content) => {
                  value.content = insertAtCursor(this.editor, content);
                  this.updateState({ ...value });
                }}
              />
            </Form.Field>
            <ReplyVCDrafts
              taskId={this.props.taskId}
              channelId={channelNameToType(Channels.phone)}
              onClickLoad={this.loadVCDraft}
            />
            <Form.Field id="commentContentField">
              <label>
                {t('ADD_COMMENT_CONTENT')} ({this.state.content.length} {t('CHARACTERS')})
              </label>
              <ReplyEditor
                style={{
                  borderRadius: '5px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  wordBreak: 'break-word'
                }}
                value={this.state.content}
                ticket={this.props.task}
                onChange={(value) => this.updateState({ content: value })}
                editorLanguage={this.props.userData.language}
                onKeyDown={(event) => {
                  if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
                    this.submitComment();
                  }
                }}
              />
            </Form.Field>

            <ReplyControlButtons
              internal
              small={this.props.smallButtons}
              disabled={this.state.isLoading || !this.state.content.length}
              onClear={this.clearFields}
              onSaveDraft={() => this.saveVCDraft(false)}
              onSubmit={this.submitComment}
            />
          </Form>
        )}
      </Translation>
    );
  }
}

const connector = connect(null, { addComment, addCallRequest });

export default connector(ReplyPhone);
