import { createReducer } from '@reduxjs/toolkit';

import type { PhoneConfiguration } from '@eeedo/types';

import { fetchPhoneConfigurationsSuccess } from 'src/actions/configActions';

export type PhoneConfigurationsState = {
  phoneConfigurations: PhoneConfiguration[];
};

const initialState: PhoneConfigurationsState = {
  phoneConfigurations: []
};

const phoneConfigurationReducer = createReducer(initialState.phoneConfigurations, (builder) => {
  builder.addCase(fetchPhoneConfigurationsSuccess, (state, action) => {
    return action.payload.configurations;
  });
});

export default phoneConfigurationReducer;
