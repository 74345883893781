import React from 'react';
import { useTranslation } from 'react-i18next';

import AccordionHeader from '../../AccordionHeader';
import TicketRelations from '../../Widget/TicketRelationsWidget/TicketRelations';
import type { Ticket } from 'src/types/Ticket';
import type { RelationOptions } from 'src/types/LinkedTickets';

export interface InfoPageRelationsProps {
  widgetOpen: boolean;
  task: Ticket;
}

export default function (props: InfoPageRelationsProps) {
  const { t } = useTranslation();

  const relationOptions: RelationOptions = [
    {
      text: t('INFO_PAGE_RELATIONS_CHOICE_PARENT_TO_CHILD'),
      value: 'parentToChild'
    },
    {
      text: t('INFO_PAGE_RELATIONS_CHOICE_CHILD_TO_PARENT'),
      value: 'childToParent'
    }
  ];

  return (
    <div className={'InfoPageRelationsContainer'}>
      <AccordionHeader
        compact={true}
        as="h4"
        active={props.widgetOpen}
        title={t('CASE_TITLE_INFO_PAGE_RELATIONS')}
        icon="info circle"
        key={1}
      >
        <TicketRelations
          type={'infopage'}
          task={props.task}
          relationOptions={relationOptions}
          createText={t('CASE_CREATE_RELATED_INFO_PAGE')}
          attachPlaceholderText={t('CASE_ADD_INFO_PAGE_BY_ID')}
          parentsText={t('CASE_INFO_PAGE_RELATIONS_PARENTS')}
          childrenText={t('CASE_INFO_PAGE_RELATIONS_CHILDREN')}
          sameOriginText={t('CASE_TICKETRELATIONS_SAME_ORIGIN')}
        />
      </AccordionHeader>
    </div>
  );
}
