import { createReducer } from '@reduxjs/toolkit';

import { generateTagsSuccess } from 'src/actions/openAIActions';

export type OpenAIState = {
  openAI: {
    generatedTags: { [key: string]: string[] };
  };
};

const initialState: OpenAIState = {
  openAI: { generatedTags: {} }
};

const openAIReducer = createReducer(initialState.openAI, (builder) => {
  builder.addCase(generateTagsSuccess, (state, action) => {
    const { contentId, generatedTags } = action.payload;
    state.generatedTags[contentId] = generatedTags;
  });
});

export default openAIReducer;
