import { connect } from 'react-redux';

import { fetchTickets } from '../actions/ticketsActions';
import TabBar from '../TabBar';
import { activateContentListTab } from 'src/actions/CaseListActions';
import { closeContentListTab } from 'src/actions/ticketListTabActionsRTK';
import { StaticTabs } from 'src/types/TicketList';

import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';
import type { TabBarMethod } from 'src/types/TabBar';

const mapStateToProps = (state: State) => {
  return {
    mainTab: state.ticketListTabs[StaticTabs.MAIN_VIEW],
    tabs: Object.values(state.ticketListTabs).filter((t) => t.id !== StaticTabs.MAIN_VIEW),
    personalData: state.userData,
    ticketTypes: state.ticketTypes
  };
};

const mapDispatchToProps = (dispatch: ThunkAppDispatch) => {
  return {
    onTabClose: (id: string, activeId?: string) => {
      if (activeId) {
        dispatch(activateContentListTab(activeId, 'tickets'));
      }
      dispatch(closeContentListTab(`${id}`, 'tickets'));
    },
    method: 'contentLists' as TabBarMethod,
    onActivateTab: (id: string) => {
      dispatch(activateContentListTab(id, 'tickets'));
    },
    refreshMainView: () => {
      dispatch(fetchTickets({}, StaticTabs.MAIN_VIEW, false));
    }
  };
};

const TicketListTabBarContainer = connect(mapStateToProps, mapDispatchToProps)(TabBar);

export default TicketListTabBarContainer;
