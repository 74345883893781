import { t } from 'i18next';
import moment from 'moment';
import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Comment, Icon, Popup } from 'semantic-ui-react';

import type { Channel, Priority, TicketType, UserWithProfile } from '@eeedo/types';
import type { TFunction } from 'i18next';
import type { ConnectedProps } from 'react-redux';
import type { SemanticICONS } from 'semantic-ui-react';

import ChannelType from '../CommentIconContent/ChannelType';
import CommentIconContent from '../CommentIconContent/CommentIconContent';
import { actionTypes } from '../Management/Rules/RuleConfigurationData';
import ErrorBoundary from 'src/ErrorBoundary';
import { CommentMetadataType } from 'src/types/Ticket';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { getPrefixByType } from 'src/Utilities/helper';
import { userToNumericalId } from 'src/Utilities/users';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { State } from 'src/types/initialState';
import type { Attachment, Comment as TicketComment } from 'src/types/Ticket';

type ConnectorWithTicketComment = ConnectedProps<typeof connector> & Partial<TicketComment>;

interface CommentProps extends ConnectorWithTicketComment {
  usersData: UserWithProfile[];
  attachments: Attachment[];
  user: UserWithProfile;
  channels: Channel[];
  ticketTypes: TicketType[];
  contentType?: ContentTypes;
  priorities: Priority[];
}

class AutomaticComment extends React.Component<CommentProps> {
  private channelTypeName: string;
  constructor(props: CommentProps) {
    super(props);
    this.state = {
      isCommentExpanded: false
    };
    switch (this.props.channel) {
      case ChannelType.Email:
        this.channelTypeName = 'email';
        break;
      case ChannelType.Sms:
        this.channelTypeName = 'sms';
        break;
      case ChannelType.Internal:
        this.channelTypeName = 'internal';
        break;
      case ChannelType.Chat:
        this.channelTypeName = 'chat';
        break;
      default:
        this.channelTypeName = 'other';
        break;
    }
  }

  private translateContent = (content: string, translate: TFunction): string | undefined => {
    if (!content && this.props.metaData) {
      const { type, previousValue, value, mainContentId, mergedContents } = this.props.metaData;
      switch (type) {
        case CommentMetadataType.ticketType: {
          const previousTicketType = this.props.ticketTypes.find((ticketType) => ticketType.id == previousValue) || {
            name: `(${translate('INACCESSIBLE')})`
          };
          const currentTicketType = this.props.ticketTypes.find((ticketType) => ticketType.id == value) || {
            name: `(${translate('INACCESSIBLE')})`
          };
          return `${translate('CHANGED')} ${type}: ${previousTicketType.name} → ${currentTicketType.name}`;
        }
        case CommentMetadataType.ticketPriority: {
          const priority = this.props.priorities.find((priority) => priority.value === value);
          return `${translate('AUTOMATIC_COMMENT_PRIORITY_SET_TO')}: ${priority ? translate(priority.text) : value}`;
        }
        case CommentMetadataType.merge: {
          const prefix = getPrefixByType(this.props.contentType);
          return mergedContents?.length
            ? `${translate('CONTENTS_MERGED')} ${mergedContents.map((c) => `${prefix}${c}`).join(', ')}`
            : `${translate('CONTENT_IS_MERGED_INTO')} ${prefix}${mainContentId}`;
        }
        case CommentMetadataType.unansweredPhoneCall: {
          const { phone, duration, acdName } = this.props.metaData;
          return `${translate('AUTOMATIC_COMMENT_UNANSWERED_CALL_FROM', { phoneNumber: phone, duration, acdName })}`;
        }
        case CommentMetadataType.openExternalLink: {
          return `${translate('AUTOMATIC_COMMENT_OPEN_EXTERNAL_LINK', { link: value })}`;
        }
        default:
          return;
      }
    }

    const keys: { [key: string]: (c: string) => string | undefined } = {
      '[call_response_time:': (c) => this.translateComment(c, 'CALL_RESPONSE_TIME', translate),
      '[status_start_working_on:': (c) => this.translateComment(c, 'START', translate),
      '[status_stop_working_on:': (c) => this.translateComment(c, 'STOP', translate),
      '[delegated_task_to]': (c) => this.translateComment(c, 'DELEGATED_TASK_TO', translate) + c,
      '[delegation_removed_from]': (c) => this.translateComment(c, 'DELEGATION_REMOVED_FROM', translate) + c,
      '[new_task_status_is]': (c) => this.translateComment(c, 'NEW_TASK_STATUS_IS', translate),
      '[marked_as_quarantined:': (c) => this.translateQuarantine(c, true),
      '[marked_as_unquarantined:': (c) => this.translateQuarantine(c, false),
      '[added_attachment]': (c) => this.translateComment(c, 'ADDED_ATTACHMENT', translate) + c,
      '[comment_attached_previously_served_customer:': (c) =>
        this.translateComment(c, 'ATTACHED_PREVIOUSLY_SERVED_CUSTOMER', translate),
      '[status_replace_working_on:': (c) => this.translateComment(c, 'REPLACE', translate),
      '[customer added to the task]': (c) => this.translateComment(c, 'ATTACHED_CUSTOMER', translate) + c,
      '[customer removed from the task]': (c) => this.translateComment(c, 'DETACHED_CUSTOMER', translate) + c,
      '[customer_portal_customer_read_the_message]': (c) =>
        this.translateComment(c, 'TICKET_READ_BY_CUSTOMER', translate) + c,
      '[edited_content]': (c) => this.translateComment(c, 'EDITED_CONTENT', translate),
      '[applied_rule]': (c) => this.translateComment(c, 'APPLIED_RULE', translate),
      '[applied_rule always_run]': (c) => this.translateComment(c, 'APPLIED_ALWAYSRUN_RULE', translate),
      '[entity_verify:': (c) => this.translateComment(c, 'VERIFY', translate),
      '[entity_verify_open:': (c) => this.translateComment(c, 'VERIFY_OPEN', translate),
      '[VERIFIED_FIELDS:': (c) => this.translateComment(c, 'VERIFIED_FIELDS', translate),
      '[original_contact_changed:': (c) => this.translateComment(c, 'ORIGINAL_CONTACT_CHANGED', translate),
      '[tag_added:': (c) => this.translateComment(c, 'TAG_ADDED', translate),
      '[tag_removed:': (c) => this.translateComment(c, 'TAG_REMOVED', translate),
      '[BOT_RESPONSE_PATCH_ERROR:': (c) => this.translateComment(c, 'BOT_RESPONSE_PATCH_ERROR', translate),
      '[BOT_RESPONSE_COMMENT_ERROR]': (c) => this.translateComment(c, 'BOT_RESPONSE_COMMENT_ERROR', translate),
      '[BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR]': (c) =>
        this.translateComment(c, 'BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR', translate),
      '[eidentification_verified:': (c) => this.translateComment(c, 'EIDENTIFICATION_VERIFIED', translate),
      '[unanswered_call_from:': (c) => this.translateComment(c, 'UNANSWERED_CALL_FROM', translate),
      '[WEBHOOK_SUCCESS]': (c) => this.translateComment(c, 'WEBHOOK_SUCCESS', translate),
      '[WEBHOOK_FAIL]': (c) => this.translateComment(c, 'WEBHOOK_FAIL', translate),
      '[WEBHOOK_FAIL_NEXT_RETRY]': (c) => this.translateComment(c, 'WEBHOOK_FAIL_NEXT_RETRY', translate),
      '[WEBHOOK_FAIL_LAST_RETRY]': (c) => this.translateComment(c, 'WEBHOOK_FAIL_LAST_RETRY', translate),
      '[RULES_ACTION_APPLIED]': (c) => this.translateComment(c, 'RULES_ACTION_APPLIED', translate)
    };

    for (const entry of Object.entries(keys)) {
      const key = entry[0];
      const func = entry[1];

      if (content.startsWith(key)) {
        const slice = content.substring(key.length);
        return func(slice);
      }
    }

    return content;
  };

  private translateQuarantine = (content: string, quarantine: boolean): string => {
    const attachmentId = content.substring(0, content.length - 1);
    const attachment = this.props.attachments.find((attachment: Attachment) => {
      return attachment.id.substring(3) === attachmentId;
    });

    if (quarantine) {
      return `${t('AUTOMATIC_COMMENT_SET_QUARANTINE')} (${attachment?.fileName})`;
    }

    return `${t('AUTOMATIC_COMMENT_RELEASE_QUARANTINE')} (${attachment?.fileName})`;
  };

  private translateComment = (
    userString: string,
    action:
      | 'START'
      | 'STOP'
      | 'ADDED_ATTACHMENT'
      | 'REPLACE'
      | 'DELEGATED_TASK_TO'
      | 'DELEGATION_REMOVED_FROM'
      | 'NEW_TASK_STATUS_IS'
      | 'DETACHED_CUSTOMER'
      | 'USER_CALLED_TO_NUMBER'
      | 'ATTACHED_CUSTOMER'
      | 'ATTACHED_PREVIOUSLY_SERVED_CUSTOMER'
      | 'TICKET_READ_BY_CUSTOMER'
      | 'EDITED_CONTENT'
      | 'APPLIED_RULE'
      | 'APPLIED_ALWAYSRUN_RULE'
      | 'VERIFY'
      | 'VERIFY_OPEN'
      | 'VERIFIED_FIELDS'
      | 'ORIGINAL_CONTACT_CHANGED'
      | 'TAG_ADDED'
      | 'TAG_REMOVED'
      | 'BOT_RESPONSE_COMMENT_ERROR'
      | 'BOT_RESPONSE_PATCH_ERROR'
      | 'BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR'
      | 'EIDENTIFICATION_VERIFIED'
      | 'UNANSWERED_CALL_FROM'
      | 'WEBHOOK_SUCCESS'
      | 'WEBHOOK_FAIL'
      | 'CALL_RESPONSE_TIME'
      | 'WEBHOOK_FAIL_NEXT_RETRY'
      | 'WEBHOOK_FAIL_LAST_RETRY'
      | 'RULES_ACTION_APPLIED',
    translate: TFunction
  ): string | undefined => {
    userString = userString.substring(0, userString.length - 1);
    if (typeof userString === 'undefined' || userString === 'undefined') {
      return translate('UNKNOWN_USER_PERFORMED_ACTION');
    }
    const users = userString.split(',');
    switch (action) {
      case 'START':
      case 'STOP': {
        const userData = this.props.usersData.find((user) => user.UID.substring(3) === users[0]);
        if (!userData) {
          return translate('ERROR_DATA_WAS_NOT_FOUND');
        }
        const userName = `${userData.profile.firstName || ''} ${userData.profile.lastName}`;
        return translate('AUTOMATIC_COMMENT_WORKSTATUS_' + action, { userName });
      }
      case 'REPLACE': {
        const previousUserData = this.props.usersData.find((user) => user.UID.substring(3) === users[0]);
        const nextUserData = this.props.usersData.find((user) => user.UID.substring(3) === users[1]);

        if (!previousUserData || !nextUserData) {
          return translate('ERROR_DATA_WAS_NOT_FOUND');
        }

        const previousUserName = `${previousUserData.profile.firstName || ''} ${previousUserData.profile.lastName}`;
        const nextUserName = `${nextUserData.profile.firstName || ''} ${nextUserData.profile.lastName}`;

        return translate('AUTOMATIC_COMMENT_WORKSTATUS_REPLACE', {
          previousUserName,
          nextUserName
        });
      }
      case 'CALL_RESPONSE_TIME': {
        const momentDuration = moment.duration({ milliseconds: Number(userString) });
        return translate('AUTOMATIC_COMMENT_CALL_RESPONSE_TIME', {
          time: momentDuration.isValid() ? momentDuration.humanize({ ss: 0 }) : userString
        });
      }
      case 'DETACHED_CUSTOMER':
        return translate('AUTOMATIC_COMMENT_DETACHED_CUSTOMER');
      case 'NEW_TASK_STATUS_IS': {
        const sentence = translate('AUTOMATIC_COMMENT_NEW_TASK_STATUS_IS');
        const translatedStatus = translate('CASE_STATUS_' + userString.trim().substring(1).toUpperCase());
        return `${sentence} ${translatedStatus}`;
      }
      case 'USER_CALLED_TO_NUMBER':
        return translate('USER_CALLED_TO_NUMBER');
      case 'ADDED_ATTACHMENT':
        return translate('AUTOMATIC_COMMENT_ADDED_ATTACHMENT');
      case 'DELEGATED_TASK_TO':
        return translate('AUTOMATIC_COMMENT_DELEGATED_TASK_TO');
      case 'DELEGATION_REMOVED_FROM':
        return translate('AUTOMATIC_COMMENT_DELEGATION_REMOVED_FROM');
      case 'ATTACHED_CUSTOMER':
        return translate('AUTOMATIC_COMMENT_ATTACHED_CUSTOMER');
      case 'ATTACHED_PREVIOUSLY_SERVED_CUSTOMER':
        return translate('AUTOMATIC_COMMENT_ATTACHED_PREVIOUSLY_SERVED_CUSTOMER');
      case 'TICKET_READ_BY_CUSTOMER':
        return translate('CUSTOMER_PORTAL_TICKET_READ_BY_CUSTOMER');
      case 'EDITED_CONTENT':
        return translate('AUTOMATIC_COMMENT_EDITED_CONTENT');
      case 'BOT_RESPONSE_COMMENT_ERROR':
        return translate('BOT_RESPONSE_COMMENT_ERROR');
      case 'BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR':
        return translate('BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR');
      case 'BOT_RESPONSE_PATCH_ERROR':
        return `${translate('BOT_RESPONSE_PATCH_ERROR')}: ${userString}`;
      case 'APPLIED_RULE':
        return `${translate('AUTOMATIC_COMMENT_APPLIED_RULE')}: ${userString}`;
      case 'APPLIED_ALWAYSRUN_RULE':
        return `${translate('AUTOMATIC_COMMENT_APPLIED_ALWAYSRUN_RULE')}: ${userString}`;
      case 'VERIFY': {
        const matches = userString.match(/(\d+)] (.*)/);
        const id = Number(matches?.[1]);
        const fields = matches?.[2];
        const user = this.props.usersData.find((user) => userToNumericalId(user.UID) === id);
        return `${translate('AUTOMATIC_COMMENT_VERIFY', {
          userName: (user?.profile.firstName || '') + (user?.profile.lastName || ''),
          fields: fields
        })}`;
      }
      case 'VERIFY_OPEN': {
        const matches = userString.match(/(\d+)]/);
        const id = Number(matches?.[1]);
        const user = this.props.usersData.find((user) => userToNumericalId(user.UID) === id);
        return `${translate('AUTOMATIC_COMMENT_VERIFY_OPEN', {
          userName: (user?.profile.firstName || '') + (user?.profile.lastName || '')
        })}`;
      }
      case 'VERIFIED_FIELDS': {
        const fields = userString;
        return `${translate('AUTOMATIC_COMMENT_VERIFIED_FIELDS', { fields: fields.split(',').join(', ') })}`;
      }
      case 'ORIGINAL_CONTACT_CHANGED': {
        return `${translate('AUTOMATIC_COMMENT_ORIGINAL_CONTACT_CHANGED')}: ${userString}`;
      }
      case 'TAG_ADDED': {
        const [tagID, UID] = userString.split(',');
        const user = this.props.usersData.find((user) => user.UID.substring(3) === UID);
        const tag = this.props.tags.find((tag) => tag.id === tagID);

        return translate('AUTOMATIC_COMMENT_TAG_ADDED', {
          tagName: tag?.name,
          firstName: user?.profile.firstName,
          lastName: user?.profile.lastName
        });
      }
      case 'TAG_REMOVED': {
        const [tagID, UID] = userString.split(',');
        const user = this.props.usersData.find((user) => user.UID.substring(3) === UID);
        const tag = this.props.tags.find((tag) => tag.id === tagID);

        return translate('AUTOMATIC_COMMENT_TAG_REMOVED', {
          tagName: tag?.name,
          firstName: user?.profile.firstName,
          lastName: user?.profile.lastName
        });
      }
      case 'EIDENTIFICATION_VERIFIED': {
        return translate('AUTOMATIC_COMMENT_EIDENTIFICATION_VERIFIED', { hash: userString });
      }
      case 'UNANSWERED_CALL_FROM': {
        const [phoneNumber, duration = '', acdName = ''] = userString.split(',');
        return translate('AUTOMATIC_COMMENT_UNANSWERED_CALL_FROM', { phoneNumber, duration, acdName });
      }
      case 'WEBHOOK_SUCCESS': {
        const { name, rpaRuleId, responseStatus } = this.props.metaData as Record<string, unknown>;
        return translate('AUTOMATIC_COMMENT_WEBHOOK_SUCCESS', { name, rpaRuleId, responseStatus });
      }
      case 'WEBHOOK_FAIL': {
        const { name, rpaRuleId, responseStatus } = this.props.metaData as Record<string, unknown>;
        return translate('AUTOMATIC_COMMENT_WEBHOOK_FAIL', { name, rpaRuleId, responseStatus });
      }
      case 'WEBHOOK_FAIL_NEXT_RETRY': {
        const { name, rpaRuleId, nextInvocationCount, responseStatus } = this.props.metaData as Record<string, unknown>;
        return translate('AUTOMATIC_COMMENT_WEBHOOK_FAIL_NEXT_RETRY', {
          name,
          rpaRuleId,
          nextInvocationCount,
          responseStatus
        });
      }
      case 'WEBHOOK_FAIL_LAST_RETRY': {
        const { name, rpaRuleId, invocationCount, responseStatus } = this.props.metaData as Record<string, unknown>;
        return translate('AUTOMATIC_COMMENT_WEBHOOK_FAIL_LAST_RETRY', {
          name,
          rpaRuleId,
          invocationCount,
          responseStatus
        });
      }
      case 'RULES_ACTION_APPLIED': {
        const { ruleId, ruleName, actionType: actionTypeValue } = this.props.metaData as Record<string, unknown>;
        const actionType = translate(actionTypes.find((element) => element.value === actionTypeValue)!.text);
        return translate('AUTOMATIC_COMMENT_RULES_ACTION_APPLIED', {
          ruleId,
          ruleName,
          actionType
        });
      }
      default:
        return;
    }
  };

  private getChannelData = (channelId: number): Channel | undefined => {
    const ch = this.props.channels.find((c: Channel) => c.id === channelId);
    return ch !== undefined ? ch : undefined;
  };

  private getIcon = (icon: SemanticICONS | 'email' | 'note'): SemanticICONS => {
    switch (icon) {
      case 'email':
        return 'mail';
      case 'note':
        return 'sticky note';
      default:
        return icon;
    }
  };

  private getUserDisplayName = (user: UserWithProfile | null) => {
    const firstName = user?.profile?.firstName ?? '';
    const lastName = user?.profile?.lastName ?? '';
    return `${firstName} ${lastName}`;
  };

  render() {
    const channelData = this.getChannelData(this.props.channel!);
    const { user } = this.props;
    return (
      <Translation ns="translations">
        {(translate) => (
          <Comment>
            <Comment.Avatar />
            <Comment.Content>
              <Comment.Author as="a" style={{ color: 'slategray' }}>
                <Icon name="info circle" />
                {this.getUserDisplayName(user)}
              </Comment.Author>
              <Comment.Metadata>
                <div>{getPrettyDate(this.props.created, { format: DATE_TIME_FORMAT })}</div>
                <div className={this.channelTypeName || 'other CommentIcon'}>
                  <Popup
                    className="commentContentPopup"
                    position="top center"
                    trigger={
                      <i>
                        <Icon
                          style={{
                            color: (channelData && channelData.color) || ''
                          }}
                          name={(channelData && this.getIcon(channelData.icon as SemanticICONS)) || 'question'}
                        />
                      </i>
                    }
                    content={
                      <ErrorBoundary>
                        <CommentIconContent
                          channels={this.props.channels}
                          ticketTypes={this.props.ticketTypes}
                          channel={this.props.channel!}
                          title={this.props.title!}
                          metaData={this.props.metaData!}
                        />
                      </ErrorBoundary>
                    }
                    on={'hover'}
                    hoverable={true}
                  />
                </div>
              </Comment.Metadata>
              <Comment.Text>
                <em>{this.translateContent(this.props.content!, translate)}</em>
              </Comment.Text>
            </Comment.Content>
          </Comment>
        )}
      </Translation>
    );
  }
}

const connector = connect((state: State) => ({
  tags: state.tags,
  priorities: state.priorities
}));

export default connector(AutomaticComment);
