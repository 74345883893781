import { Channels, ChannelType } from 'src/types/Channel';

import type { Draft } from 'src/reducers/draftsReducer';
import type { TicketsState } from 'src/reducers/ticketReducer';

export default ChannelType;

const nameToTypeMap: { [channel in Channels]: ChannelType } = {
  [Channels.intra]: ChannelType.Internal,
  [Channels.email]: ChannelType.Email,
  [Channels.chat]: ChannelType.Chat,
  [Channels.sms]: ChannelType.Sms,
  [Channels.secureMail]: ChannelType.SecureEmail,
  [Channels.facebook]: ChannelType.Facebook,
  [Channels.giosg]: ChannelType.Giosg,
  [Channels.tampuuri]: ChannelType.Tampuuri,
  [Channels.customerPortal]: ChannelType.CustomerPortal,
  [Channels.whatsapp]: ChannelType.WhatsApp,
  [Channels.salesforce]: ChannelType.Salesforce,
  [Channels.d365]: ChannelType.D365,
  [Channels.phone]: ChannelType.Phone,
  [Channels.eezy]: ChannelType.Eezy,
  [Channels.viestitytti]: ChannelType.ViestiTytti,
  [Channels.instagram]: ChannelType.Instagram
};

type TypeToNameMap = { [type in Partial<ChannelType>]: Channels };
const typeToNameMap: TypeToNameMap = Object.keys(nameToTypeMap).reduce((obj, key) => {
  obj[nameToTypeMap[key]] = key;
  return obj;
}, {}) as TypeToNameMap;

// webform default active to email
typeToNameMap[ChannelType.Webform] = Channels.email;

// 101 default active to email
typeToNameMap[101] = Channels.email;

export const channelTypeToName = (type: ChannelType): Channels => {
  return typeToNameMap[type] ?? 'intra';
};

export const channelNameToType = (channel: Channels): ChannelType => {
  const type = nameToTypeMap[channel];
  if (!type) {
    throw new Error(`No channel type found with name ${channel}`);
  }

  return type;
};

export const getSelectedChannelTab = (activeTicketTab: null | string, detailedTickets: TicketsState, draft?: Draft) => {
  if (!activeTicketTab) {
    return null;
  }

  const detailedTicket = detailedTickets.find((t) => t.id === activeTicketTab);
  const channelType = draft?.tabIndex ?? detailedTicket?.channel;
  const selectedChannelTab = channelType ? channelTypeToName(channelType) : null;
  return selectedChannelTab === Channels.secureMail ? Channels.email : selectedChannelTab;
};
