import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { addTab, closeTab } from 'src/actions/tabActionsRTK';
import type { ContentTypesFields } from 'src/types/Ticket';

export enum typeKeyMap {
  tickets = 'ticketIds',
  infopages = 'infoPagesIds'
}

export interface TabsPersistReducerType {
  ticketIds: string[],
  infoPagesIds: string[],
}

const initialState: TabsPersistReducerType = {
  ticketIds: [],
  infoPagesIds: [],
};

const tabsPersistSlice = createSlice({
  name: 'ticketTabsPersist',
  initialState,
  reducers: {
    setTabsPersist(state, action: PayloadAction<{ ids: string[], type: ContentTypesFields }>) {
      const typeKey = typeKeyMap[action.payload.type];
      state[typeKey] = action.payload.ids;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTab, (state, action) => {
        const typeKey = typeKeyMap[action.payload.type];

        return {
          ...state,
          [typeKey]: [
            ...state[typeKey].filter((id) => id !== action.payload.id),
            action.payload.id
          ]
        };
      })
      .addCase(closeTab, (state, action) => {
        return {
          ticketIds: state.ticketIds.filter((id) => id !== action.payload),
          infoPagesIds: state.infoPagesIds.filter((id) => id !== action.payload),
        };
      })
  }
});

export const { setTabsPersist } = tabsPersistSlice.actions;
export default tabsPersistSlice.reducer;
