import type { CacheKey } from './types';

type CacheMeta = {
  updated: number;
};

export class SimpleCache<T> {
  protected cache = new Map<CacheKey, { meta: CacheMeta; data: T }>();
  protected lifetime: number;

  constructor(lifetimeSeconds: number) {
    this.lifetime = lifetimeSeconds * 1000;
  }

  setData(key: CacheKey, data: T) {
    const meta: CacheMeta = {
      updated: new Date().getTime()
    };
    this.cache.set(key, { meta: meta, data: data });
  }

  getData(key: CacheKey): T | undefined {
    const res = this.cache.get(key);
    if (res) {
      const now = new Date().getTime();
      const old = now - res.meta.updated;
      if (old <= this.lifetime) {
        return res.data;
      } else {
        this.delete(key);
      }
    }
    return undefined;
  }

  delete(key: CacheKey) {
    this.cache.delete(key);
  }
  clear() {
    this.cache = new Map<CacheKey, { meta: CacheMeta; data: T }>();
  }
}
