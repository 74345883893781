import type { TicketType } from '@eeedo/types';

import type { Entity } from 'src/types/Ticket';

const isEntityReadOnlyByTicketType = (entity: Entity, ticketType?: TicketType) => {
  if (!ticketType) {
    return false;
  }

  const { entityRouting } = ticketType;
  return entityRouting?.some(({ entityType, readonly }) => entityType === entity._type && readonly);
};

export default isEntityReadOnlyByTicketType;
