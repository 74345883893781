import { cloneDeep } from 'lodash-es';

import type { Tag } from '@eeedo/types';

import { CREATE_TAG_SUCCESS, DELETE_TAG_SUCCESS, FETCH_TAGS_SUCCESS, PATCH_TAGS_SUCCESS } from '../actions';

interface TagsState {
  tags: Tag[];
}

const initialState: TagsState = {
  tags: []
};

const tagsReducer = (state = initialState.tags, action: any) => {
  switch (action.type) {
    case FETCH_TAGS_SUCCESS: {
      return cloneDeep(action.tags);
    }

    case PATCH_TAGS_SUCCESS: {
      const { tag } = action;

      const exists = state.some((item) => item.id === tag.id);
      return exists ? state.map((item) => (item.id === tag.id ? { ...item, ...tag } : item)) : [...state, tag];
    }

    case CREATE_TAG_SUCCESS: {
      if (state.some((tag) => tag.id === action.tag.id)) {
        return state;
      } else {
        return [...state, action.tag];
      }
    }

    case DELETE_TAG_SUCCESS: {
      return state.filter((tag) => tag.id !== action.id);
    }

    default:
      return state;
  }
};

export default tagsReducer;
