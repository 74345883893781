import { t as tr } from 'i18next';
import iziToast from 'izitoast';
import React from 'react';
import { Form } from 'semantic-ui-react';

import type { StrictDropdownProps } from 'semantic-ui-react';

import type { TitleTemplate } from 'src/types/TitleTemplate';

interface TitleTemplatesProps {
  className?: string;
  title: string;
  ticketType: string;
  templates: TitleTemplate[];

  updateTitle: (value: string) => void;
}

const onChange = (
  update: StrictDropdownProps['value'],
  updateStateFunction: (value: string) => void,
  content: string
) => {
  if (update && content && content.length > 0 && content !== '') {
    iziToast.question({
      timeout: 0,
      close: false,
      overlay: true,
      id: 'question',
      zindex: 999,
      message: tr('TEMPLATE_TEXT_AREA_ALREADY_HAS_TEXT'),
      position: 'center',
      buttons: [
        [
          `<button><b>${tr('GENERAL_REPLACE')}</b></button>`,
          (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
            updateStateFunction(String(update));
          },
          true
        ],
        [
          `<button>${tr('GENERAL_CONCAT_END')}</button>`,
          (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
            const concatted = content + update;
            updateStateFunction(concatted);
          },
          false
        ],
        [
          `<button>${tr('GENERAL_CONCAT_START')}</button>`,
          (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
            const concatted = update + content;
            updateStateFunction(concatted);
          },
          false
        ],
        [
          `<button>${tr('GENERAL_CANCEL')}</button>`,
          (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
          },
          false
        ]
      ]
    });
  } else {
    updateStateFunction(String(update));
  }
};

const TitleTemplates = (props: TitleTemplatesProps) => {
  const titleTemplates = props.templates
    .filter((template) => template.ticketTypes?.find((ticketType) => ticketType === props.ticketType))
    .map((template) => ({ key: template.id, text: template.content, value: template.content }));

  return (
    <Form.Dropdown
      className={'titleEditorDropdown'}
      onChange={(event, selection) => {
        onChange(selection.value, props.updateTitle, props.title);
      }}
      options={titleTemplates}
      trigger={<div />}
      style={{ margin: '5px 10px' }}
    />
  );
};

export default TitleTemplates;
