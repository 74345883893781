import axios from 'axios';
import axiosRetry from 'axios-retry';

export const NOT_ACCEPTABLE = 406;

// Client for querying Desk API
const apiClient = axios.create({
  withCredentials: true
});

const refreshClient = axios.create({
  withCredentials: true
});

// Client for everything else
const publicHttpClient = axios.create();

axiosRetry(apiClient, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay
});

axiosRetry(publicHttpClient, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay
});

axiosRetry(refreshClient, {
  retries: 5,
  shouldResetTimeout: true,
  retryCondition: function (error) {
    /**
     * Retry under any circumstances except error 406.
     * Modern browsers often do not distinguish between CORS errors and network errors.
     * Similarly, axios-retry is unable to differentiate these errors.
     */

    return error.status !== NOT_ACCEPTABLE;
  },
  retryDelay: axiosRetry.exponentialDelay
});

export { apiClient, publicHttpClient, refreshClient };
