import type { AxiosResponse } from 'axios';

import { parseArticleTags } from 'src/Utilities/tags';
import type { ListArticle } from '../types/Articles';
import type { KnowledgeBaseSettingsData } from 'src/types/KnowledgeBaseSettings';
import { apiClient } from 'src/Utilities/httpClients';

const extractData = <T>(response: AxiosResponse<T>) => response.data;

export default class KBApi {
  static SEARCH_ARTICLES_LIMIT = 20;
  static SUGGESTED_ARTICLES_LIMIT = 5;

  static getSettings = (): Promise<KnowledgeBaseSettingsData> =>
    apiClient
      .get(`/knowledge/settings`)
      .then(extractData)
      .catch((error) => {
        console.error('Could not get kb settings');
        throw error;
      });

  static getArticleVisibility = (): Promise<any[]> =>
    apiClient
      .get(`/knowledge/visibility`)
      .then(extractData)
      .catch((error) => {
        console.error('Could not getArticleVisibility');
        throw error;
      });

  static removeTicketTypeArticleTypeRelation = (recordId: number): Promise<any> =>
    apiClient
      .delete(`/knowledge/visibility/${recordId}`)
      .then(extractData)
      .catch((error) => {
        console.error('Could not removeTicketTypeArticleTypeRelation');
        throw error;
      });

  static removeAllTicketTypeArticleTypeRelations = (ticketTypeId: number): Promise<any> =>
    apiClient
      .delete(`/knowledge/visibility/${ticketTypeId}/all`)
      .then(extractData)
      .catch((error) => {
        console.error('Could not removeAllTicketTypeArticleTypeRelations');
        throw error;
      });

  static addTicketTypeArticleTypeRelation = (ticketTypeId: number, articleTypeId: number): Promise<any> =>
    apiClient
      .post(`/knowledge/visibility/`, {
        ticketTypeId,
        articleTypeId
      })
      .then(extractData)
      .catch((error) => {
        console.error('Could not addTicketTypeArticleTypeRelation');
        throw error;
      });

  static updateSettings = (data: {
    oldSettings: KnowledgeBaseSettingsData;
    newSettings: KnowledgeBaseSettingsData;
  }): Promise<KnowledgeBaseSettingsData> =>
    apiClient
      .put<KnowledgeBaseSettingsData>(`/knowledge/settings`, data)
      .then(extractData)
      .catch((error) => {
        console.error('Could not update kb settings');
        throw error;
      });

  static getRelatedArticles = ({
    ticketId,
    term,
    signal,
    page
  }: {
    ticketId: string;
    term?: string;
    signal?: AbortSignal;
    page?: number;
  }) =>
    apiClient
      .post<{ hasMore: boolean; articles: ListArticle[] }>(`/knowledge/${ticketId.substring(3)}/suggested`, { term, page, limit: KBApi.SUGGESTED_ARTICLES_LIMIT }, { signal })
      .then(extractData)
      .then((data) => ({ ...data, articles: data.articles.map(parseArticleTags) }));

  static searchArticles = (ticketId: string, term: string, page: number, signal?: AbortSignal) =>
    apiClient
      .post<{ hasMore: boolean; articles: ListArticle[] }>(`/knowledge/${ticketId.substring(3)}/search`, { term, page, limit: KBApi.SEARCH_ARTICLES_LIMIT }, { signal })
      .then(extractData)
      .then((data) => ({ ...data, articles: data.articles.map(parseArticleTags) }));
}
