const tokenRefreshMinimumTimeoutMs = 10000;

export const tokenRefreshTimeout = (accessTokenExpiration: string) => {
  return Math.max(
    (parseInt(accessTokenExpiration) - Math.floor(Date.now() / 1000) - 30) * 1000,
    tokenRefreshMinimumTimeoutMs
  );
};

export const accessTokenRefresher = (refreshCallback: () => void) =>
  new Promise((resolve, reject) => {
    const accessTokenExpiration = localStorage.getItem('accessTokenExpiration');

    if (accessTokenExpiration) {
      clearTimeout(window.refreshTimeout);
      window.refreshTimeout = setTimeout(() => {
        if (localStorage.getItem('loggedIn')) {
          refreshCallback();
        }
      }, tokenRefreshTimeout(accessTokenExpiration));
      return resolve(true);
    }

    return reject();
  });
