import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

import type { TabFilter } from '@eeedo/types';

import { setTicketlistFilter } from './actions/ticketListTabActionsRTK';
import InitialLoad from './Components/InitialLoad/InitialLoad';
import { store } from './store';
import { getURLFilterParams } from './Utilities/helper';

export const PrivateRoute = ({ component: Component, authenticated, ...rest }: any) => {
  const redirectAdress = '/login';
  if (!authenticated && rest.location.pathname !== redirectAdress && rest.location.pathname !== '/forgotPassword') {
    localStorage.setItem('initialAddress', `${rest.location.pathname}${window.location.search}`);
  }

  const initialAddress = localStorage.getItem('initialAddress');
  if (authenticated && initialAddress) {
    const searchQuery = initialAddress.split('?')[1];
    if (searchQuery) {
      // Setting up filter params to the redux store
      const urlSearchParams = getURLFilterParams(searchQuery);
      (Object.keys(urlSearchParams) as (keyof TabFilter)[]).forEach((parameter) =>
        store.dispatch(
          setTicketlistFilter({
            id: 'MAIN_VIEW',
            parameter,
            value: urlSearchParams[parameter]
          })
        )
      );
    }

    localStorage.removeItem('initialAddress');
    return <Redirect to={initialAddress} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return authenticated ? (
          <InitialLoad>
            <Component {...props} />
          </InitialLoad>
        ) : (
          <Redirect to={redirectAdress} />
        );
      }}
    />
  );
};
