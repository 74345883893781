import CreatableSelect from 'react-select/creatable';
import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import type { SortStart } from 'react-sortable-hoc';
import type { MouseEventHandler, VFC } from 'react';
import type { Props, MultiValueProps, ValueType } from 'react-select';

import { MultiDraggableDropdownContext } from './MultiDraggableDropdownProvider';
import { defaultCreatableSelectStyles } from '../Utilities';
import './MultiDraggableDropdown.css';
import type { IItem } from './types';

interface IProps extends Props<IItem, boolean> {
  name: string;
}

const SortableSelect = SortableContainer<Props<IItem, boolean>>(CreatableSelect);

const SortableMultiValue = SortableElement((props: MultiValueProps<IItem>) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return <components.MultiValue {...props} innerProps={{ ...props.innerProps, onMouseDown }} />;
});

const SortableMultiValueLabel = SortableHandle(components.MultiValueLabel);

const MultiDraggableDropdown: VFC<IProps> = ({ onCreateOption, name, options, ...props }) => {
  const context = useContext(MultiDraggableDropdownContext);
  const [inputValue, setInputValue] = useState('');

  if (!context) {
    console.error('MultiDraggableDropdown: context not found');
    return null;
  }

  const {
    draggingItem,
    isDragging,
    itemsCollection,
    sourceArea,
    targetArea,
    setIsDragging,
    setDraggingItem,
    setItemsCollection,
    setSourceArea,
    setTargetArea
  } = context;

  const handleAreaMouseEnter = () => {
    if (isDragging) {
      setTargetArea(name);
    } else {
      setSourceArea(name);
      setTargetArea(name);
    }
  };

  const handleDragStart = (sortStart: SortStart) => {
    setIsDragging(true);
    setDraggingItem(itemsCollection[name][sortStart.index]);
  };

  const handleDragEnd = () => {
    if (draggingItem && sourceArea && targetArea && sourceArea !== targetArea) {
      setItemsCollection({
        ...itemsCollection,
        [sourceArea]: (itemsCollection[sourceArea] || []).filter(({ value }) => value !== draggingItem.value),
        [targetArea]: [...(itemsCollection[targetArea] || []), draggingItem]
      });
    }

    setIsDragging(false);
    setSourceArea(targetArea);
  };

  const handleChange = (value: ValueType<IItem, boolean>) => {
    setItemsCollection({
      ...itemsCollection,
      [name]: (value || []) as IItem[]
    });
    setInputValue('');
  };

  const className = classNames(
    'multiDraggableDropdown',
    isDragging && 'dropzone',
    sourceArea !== targetArea && targetArea === name && 'active'
  );

  return (
    <div className={className} onMouseEnter={handleAreaMouseEnter}>
      <SortableSelect
        useDragHandle
        // react-sortable-hoc props:
        axis="xy"
        distance={4}
        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        // react-select props:
        isMulti
        options={options}
        value={itemsCollection[name]}
        inputValue={inputValue}
        onInputChange={(value, { action }) => {
          if (action === 'input-change') setInputValue(value);
        }}
        onChange={handleChange}
        closeMenuOnSelect={false}
        components={{
          MultiValue: SortableMultiValue as any,
          MultiValueLabel: SortableMultiValueLabel
        }}
        // for draggable
        isDragging={isDragging}
        onCreateOption={onCreateOption}
        onMouseEnter={handleAreaMouseEnter}
        onSortStart={handleDragStart}
        onSortEnd={handleDragEnd}
        styles={defaultCreatableSelectStyles}
        {...props}
      />
    </div>
  );
};

export default MultiDraggableDropdown;
