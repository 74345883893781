import { trimAndSplitRecipients } from '../Components/Utilities/recipients';

const INCOMPATIBLE_COLOR = '#FAE5E3';

const isIncompatible = (value: string) =>
  !value.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

const mapEmailValueToOptions = (emailValue: string) => {
  const separatedValues = trimAndSplitRecipients(emailValue);

  return (separatedValues || []).filter(Boolean).map((val) => ({
    label: '',
    value: val,
    color: isIncompatible(val) ? INCOMPATIBLE_COLOR : ''
  }));
};

export default mapEmailValueToOptions;
