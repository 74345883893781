import { FETCH_ROLES_SUCCESS } from '../actions/index';

const initialState = {
  roles: []
};

const rolesReducer = (state = initialState.roles, action: any) => {
  switch (action.type) {
    case FETCH_ROLES_SUCCESS: {
      return action.roles;
    }
    default:
      return state;
  }
};

export default rolesReducer;
