import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { VFC } from 'react';

import SocketInstance from 'src/realTimeNotifications';
import { addTab } from 'src/actions/tabActionsRTK';
import { fetchTicket } from 'src/actions/ticketsActions';
import { setTabsPersist, typeKeyMap } from 'src/reducers/tabsPersistReducer';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';

const selectPreviousTabsIds = (contentType: ContentTypesFields) => (state: State) => (
  state.tabsPersist[typeKeyMap[contentType]]
);
const selectCurrentTicketTabsIds = (contentType: ContentTypesFields) => (state: State) => (
  state.ticketTabs
    .filter(({ type }) => type === contentType)
    .map(({ id }) => id)
);

interface Props {
  contentType: ContentTypesFields;
}

const TabBarRestore: VFC<Props> = ({ contentType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentTicketsIds = useSelector(selectCurrentTicketTabsIds(contentType));
  const previousTicketsIds = useSelector(selectPreviousTabsIds(contentType));
  const ticketIdsToRestore = previousTicketsIds.filter((id) => !currentTicketsIds.includes(id));

  const onClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(setTabsPersist({
      ids: currentTicketsIds,
      type: contentType,
    }));
  }

  const onClickRestore = () => {
    ticketIdsToRestore.forEach((id) => openTicket(id));
  }

  const openTicket = (id: string) => {
    SocketInstance.joinRoom(id);
    dispatch(addTab({ id, type: contentType, activeTab: false }));
    dispatch(fetchTicket({
      id,
      shouldActivateTicket: false,
      ...(contentType === 'infopages' && { type: 'infopage' })
    }));
  }

  if (!ticketIdsToRestore.length) {
    return null;
  }

  return (
    <Menu.Item className="pulse-active" onClick={onClickRestore}>
      {t('RESTORE_PREVIOUS_TABS')}
      <Icon className="TabBar-Delete-Icon" name="delete" onClick={onClickClose} />
    </Menu.Item>
  )
};

export default TabBarRestore;
