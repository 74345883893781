import queryString from 'query-string';

export interface CommonQuery {
  entityType?: string;
  taskType?: string;
  mongodb?: boolean;
  ticketType?: string;
}

export const buildUrlWithQuery = <Q extends CommonQuery>(url: string, query?: Q) =>
  `${url}${
    query && Object.keys(query).length > 0
      ? `?${queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
          arrayFormat: 'bracket',
          encode: true
        })}`
      : ''
  }`;
