import React from 'react';

import type { FC } from 'react';

import * as styles from './ItemDescription.style';

const ItemDescription: FC<{ description?: string; children?: React.ReactNode }> = ({ description, children }) => (
  <div style={styles.wrapper}>
    {description && <div style={styles.description}>{description}</div>}
    <div style={styles.content}>{children}</div>
  </div>
);

export default React.memo(ItemDescription);
