import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { DropdownItemProps } from 'semantic-ui-react';

import type { RescheduledCallback } from 'src/types/RescheduledCallback';

export interface RescheduleCallbackState {
  open: boolean;
  selectedDate?: number;
  selectedOption?: DropdownItemProps;
  rescheduledCallbacks: RescheduledCallback[];
}

const initialState: RescheduleCallbackState = {
  open: false,
  rescheduledCallbacks: []
};

const rescheduleCallbackSlice = createSlice({
  name: 'rescheduleCallback',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<RescheduleCallbackState['selectedDate']>) => {
      state.selectedDate = action.payload;
    },
    setSelectedOption: (state, action: PayloadAction<RescheduleCallbackState['selectedOption']>) => {
      state.selectedOption = action.payload;
    },
    setRescheduledCallbacks: (state, action: PayloadAction<RescheduledCallback[]>) => {
      state.rescheduledCallbacks = action.payload;
    },
    addRescheduledCallback: (state, action: PayloadAction<RescheduledCallback>) => {
      state.rescheduledCallbacks.push(action.payload);
    },
    clearRescheduledWidgetState: () => ({ ...initialState })
  }
});

export const {
  setOpen: setRescheduleModalOpen,
  setSelectedDate: setSelectedRescheduledDate,
  setSelectedOption: setSelectedRescheduledOption,
  setRescheduledCallbacks,
  addRescheduledCallback,
  clearRescheduledWidgetState
} = rescheduleCallbackSlice.actions;

export default rescheduleCallbackSlice.reducer;
