import React, { useState } from 'react';
import { Icon, Label, Button } from 'semantic-ui-react';

import { useAppThunkDispatch } from 'src/store';
import { addTagToContent } from 'src/actions/ticketsActions';

interface GeneratedTagButtonProps {
  contentId: string;
  name: string;
  id?: string;
  isNew?: boolean;
  isAdded?: boolean;
}

export default ({ contentId, name, id, isNew = false, isAdded = false }: GeneratedTagButtonProps) => {
  const dispatch = useAppThunkDispatch();
  const [isLoading, setLoading] = useState(false);

  const addTag = async () => {
    setLoading(true);
    const tagId = isNew ? name : id;
    await dispatch(addTagToContent(contentId, tagId, isNew));
    setLoading(false);
  };

  return (
    <Button as="div" disabled={isLoading || isAdded} labelPosition="right" style={{ margin: '0 5px 5px 0' }}>
      <Button basic icon onClick={() => addTag()}>
        {isLoading ? (
          <Icon name="circle notch" />
        ) : isAdded ? (
          <Icon name="check" color="green" />
        ) : (
          <Icon name="plus" />
        )}
      </Button>

      <Label as="a">{name}</Label>
    </Button>
  );
};
