import { useHotkeys } from 'react-hotkeys-hook';
import type { VFC } from 'react';

interface Props {
  onCloseAsDone: () => void;
}

const ShortcutsStatusButtons: VFC<Props> = ({ onCloseAsDone }) => {
  useHotkeys('alt+c', onCloseAsDone, undefined, [onCloseAsDone]);

  return null;
};

export default ShortcutsStatusButtons;
