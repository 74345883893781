import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Dropdown, Grid } from 'semantic-ui-react';

import type { Dispatch } from 'react';

import EnvSettings from 'src/api/EnvSettings';

import type { ArchivalAction, ArchivalState } from './types';

interface ArchivalSettingsProps {
  localState: ArchivalState;

  dispatchState: Dispatch<ArchivalAction>;
}

const ArchivalSettings = ({
  localState: { selectedQueue, mainFileType, includeComments },
  dispatchState
}: ArchivalSettingsProps) => {
  const { t } = useTranslation();
  const {
    data: { documentTypes, queues }
  } = EnvSettings.getSettings().ESSI_ARCHIVAL_OPTIONS!;

  return (
    <div>
      <h3>{t('top_bar.advanced_actions.archive.labels.settings')}</h3>

      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <div className="archival__settingsField">
              <Checkbox
                toggle
                onChange={(_, { checked }) => {
                  dispatchState({ type: 'SET_INCLUDE_COMMENTS', payload: checked as boolean });

                  dispatchState({ type: 'SET_MAIN_FILE_TYPE', payload: checked ? 12 : undefined });
                }}
                label={t('top_bar.advanced_actions.archive.labels.archive_comments')}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={6}>
            <div className="archival__settingsField">
              <Checkbox
                toggle
                onChange={(_, { checked }) => dispatchState({ type: 'SET_ARCHIVE_JOB', payload: checked as boolean })}
                label={t('top_bar.advanced_actions.archive.labels.archive_job')}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div className="archival__settingsField">
        <label>{t('top_bar.advanced_actions.archive.labels.queue')}</label>
        <Dropdown
          fluid
          search
          selection
          options={queues.map((queue) => ({ text: queue.name, value: queue.id }))}
          value={selectedQueue}
          onChange={(_, data) => dispatchState({ type: 'SET_QUEUE', payload: data.value as number })}
        />
      </div>

      {includeComments && (
        <div className="archival__settingsField">
          <label>{t('top_bar.advanced_actions.archive.labels.main_file_type')}</label>
          <Dropdown
            fluid
            search
            selection
            options={documentTypes.map((type) => ({ text: type.name, value: type.id }))}
            value={mainFileType}
            onChange={(_, data) => dispatchState({ type: 'SET_MAIN_FILE_TYPE', payload: data.value as number })}
          />
        </div>
      )}
    </div>
  );
};

export default ArchivalSettings;
