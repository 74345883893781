import { createReducer } from '@reduxjs/toolkit';

import {
  fetchEntityTagsSuccess,
  updateEntityTagsSuccess,
  deleteEntityTagsSuccess
} from 'src/actions/entityTagsActions';

type EntityTagsState = {
  entityTags: { [key: string]: number[] };
};

const initialState: EntityTagsState = {
  entityTags: {}
};

const entityTagsReducer = createReducer(initialState.entityTags, (builder) => {
  builder.addCase(fetchEntityTagsSuccess, (state, action) => {
    const { entityId, tagIds } = action.payload;
    state[entityId] = tagIds;
  });

  builder.addCase(updateEntityTagsSuccess, (state, action) => {
    const { entityId, tagIds } = action.payload;
    state[entityId] = [...state[entityId], ...tagIds];
  });

  builder.addCase(deleteEntityTagsSuccess, (state, action) => {
    const { entityId, tagIds } = action.payload;
    state[entityId] = state[entityId].filter((tagId) => !tagIds.includes(tagId));
  });
});

export default entityTagsReducer;
