import * as React from 'react';
import { Message } from 'semantic-ui-react';

const CriticalError = () => (
  <Message
    style={{ margin: '10px' }}
    error={true}
    icon="bug"
    header="There was an error loading the page"
    list={['Refresh the page and try again.', 'If the issue persists, contact the administrator.']}
  />
);

export default CriticalError;
