import React, { useEffect, useRef, useState } from 'react';
import ItemWrapper from './ItemWrapper/ItemWrapper';

const ImageViewer = ({ src }: { src: string }) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    const image = imageRef.current;
    if (image) {
      image.onload = () => {
        setHasError(false);
        setLoaded(true);
      };
      image.onerror = () => {
        setHasError(true);
        setLoaded(true);
      };
      image.src = `${src}?thumb=1`;
    }
  }, [src, loaded]);

  return (
    <ItemWrapper
      loaded={loaded}
      hasError={hasError}
      reload={() => {
        setHasError(false);
        setLoaded(false);
      }}
    >
      <img ref={imageRef} style={{ display: !loaded || hasError ? 'none' : 'initial' }} />
    </ItemWrapper>
  );
};

export default React.memo(ImageViewer);
