import { apiClient } from 'src/Utilities/httpClients';

export default class EezyApi {
  static sendReply = async (parameters: {
    replyToId: number;
    subject: string;
    content: string;
    selectedAttachmentURIs: string[];
    isSetReplyAsDone: boolean;
    ticketId: string;
  }) => apiClient.post(`/eezy/reply`, parameters).then((response) => response.data);
}
