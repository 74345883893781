import { Task } from './Task';
import { t } from 'i18next';

export function defaultInfoPage(defaultTaskType: string): InfoPage {
  const newPage = new InfoPage();
  newPage.title = t('MANUAL_INPUT');
  newPage.taskType = defaultTaskType;
  newPage.channel = 6;
  newPage.status = 'draft';
  newPage.type = 'infopage';

  return newPage;
}

export default class InfoPage extends Task {}
