import React from 'react';

import Select from './Types/Select';
import Smiley from './Types/Smiley';

import type {
  SurveyQuestionAnswerAnswer,
  SurveyQuestionAnswerQuestion,
  SurveyQuestionAnswerQuestionConfiguration,
  SurveyQuestionAnswerSkipped
} from 'src/types/Survey';

import './Survey.css';

import { useTranslation } from 'react-i18next';

interface SurveyAnswerProps {
  answer: SurveyQuestionAnswerAnswer | SurveyQuestionAnswerSkipped;
  question: SurveyQuestionAnswerQuestion;
}

const SurveyAnswer = ({ question, answer }: SurveyAnswerProps) => {
  const { t } = useTranslation();

  return (
    <div className="survey-widget-reply">
      <h4 className="survey-widget-question">
        {question.title}
        {question.optional && (
          <span className="survey-widget-optional"> {`(${t('SETTINGS_SURVEY_QUESTION_OPTIONAL')})`}</span>
        )}
      </h4>

      {answer.skipped && <div className="survey-widget-skipped">{t('SETTINGS_SURVEY_QUESTION_SKIPPED')}</div>}

      {!answer.skipped && (
        <>
          {renderAnswerType(question.configuration?.type, question.configuration, answer.answer)}
          <div className={`${answer.informalAnswer ? 'survey-informal' : 'hidden'}`}>{answer.informalAnswer}</div>
        </>
      )}
    </div>
  );
};

const renderAnswerType = (
  type: string | undefined,
  data: SurveyQuestionAnswerQuestionConfiguration,
  value: number | undefined
): any => {
  if (type === 'smileys' && value !== undefined) {
    return <Smiley configuration={data} value={value} />;
  } else if ((type === 'select' || type === 'requestContact') && data !== undefined && value !== undefined) {
    return <Select type={type} data={data} value={value} />;
  }
};

export default SurveyAnswer;
