import { createSlice } from '@reduxjs/toolkit';

import type { ResponseTemplate } from '@eeedo/types';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { Template } from 'src/types/Template';
import type { TitleTemplate } from 'src/types/TitleTemplate';

const initialState = {
  titleTemplates: [] as TitleTemplate[],
  responseTemplates: [] as ResponseTemplate[],
  templates: [] as Template[]
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    loadResponseTemplatesSuccess: (state, action: PayloadAction<ResponseTemplate[]>) => {
      state.responseTemplates = action.payload;
    },
    createTemplateSuccess: (state, action: PayloadAction<ResponseTemplate>) => {
      if (!state.responseTemplates.some((template) => template._id === action.payload._id)) {
        state.responseTemplates = [...state.responseTemplates, action.payload];
      }
    },
    patchTemplateSuccess: (state, action: PayloadAction<ResponseTemplate>) => {
      state.responseTemplates = [
        ...state.responseTemplates.filter((template) => template._id !== action.payload._id),
        action.payload
      ];
    },
    deleteTemplateSuccess: (state, action: PayloadAction<{ id: string }>) => {
      state.responseTemplates = [...state.responseTemplates.filter((template) => template._id !== action.payload.id)];
    },
    loadTitleTemplatesSuccess: (state, action: PayloadAction<TitleTemplate[]>) => {
      state.titleTemplates = action.payload;
    },
    loadTemplatesSuccess: (state, action: PayloadAction<Template[]>) => {
      state.templates = action.payload;
    }
  }
});

export const {
  loadResponseTemplatesSuccess,
  createTemplateSuccess,
  patchTemplateSuccess,
  deleteTemplateSuccess,
  loadTemplatesSuccess,
  loadTitleTemplatesSuccess
} = templatesSlice.actions;

export default templatesSlice.reducer;
