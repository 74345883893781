import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { EnhancedStore } from '@reduxjs/toolkit';
import type { PersistConfig } from 'redux-persist';

import { leaveRoomMiddleware } from './middlewares/leaveRoomMiddleware';
import { socketInitMiddleware } from './middlewares/socketInitMiddleware';
import { urlSyncMiddleware } from './middlewares/urlSyncMiddleware';
import { userTimeLogsMiddleware } from './middlewares/userTimeLogsMiddleware';
import reducers from './reducers';
import { history } from './reducers/browserHistory';
import { commentsMenuInitialState } from './reducers/commentsMenuReducer';

import type { State } from './types/initialState';
import type { ThunkAppDispatch } from './types/store';

const migrations = {
  3: (state: State): State => ({ ...state, commentsMenu: commentsMenuInitialState })
};

const persistConfig: PersistConfig<State> = {
  key: 'root',
  version: 3,
  storage,
  whitelist: ['tabsPersist', 'commentsMenu', 'windows'],
  migrate: createMigrate(migrations as any, { debug: false })
};

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }),
  routerMiddleware(history),
  urlSyncMiddleware,
  socketInitMiddleware,
  leaveRoomMiddleware,
  userTimeLogsMiddleware
];

const persistedReducer = persistReducer(persistConfig, reducers as any);

export const store: EnhancedStore<State> = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware
});

export type StoreType = typeof store.getState;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

export const persistor = persistStore(store);
