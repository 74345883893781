const isTicketListDisable = (): boolean => {
  const currentUrl: string = window.location.href;

  if (currentUrl.includes('/actions/') && !currentUrl.includes('showTicketList')) {
    return true;
  }
  return false;
};

export default isTicketListDisable;
