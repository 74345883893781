import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, TextArea } from 'semantic-ui-react';

import type { ISalesforceSettingsData } from '@eeedo/types';
import type { VFC } from 'react';

import SalesforceMemoSelect from './SalesforceMemoSelect';
import SalesforceApi from 'src/api/SalesforceApi';
import { replaceObjectParameters } from 'src/Components/Utilities';

import type { ReplySalesforceState } from '../ReplySalesforce';
import type {
  IGeneralSalesforceData,
  SalesforceFormPayload,
  SalesforceObjectDescription,
  SalesforceObjectType
} from 'src/types/Salesforce';

interface SalesforceCustomObjectProps {
  validationErrors: { [key: string]: any };
  salesforceSettings: ISalesforceSettingsData;
  payload: SalesforceFormPayload;
  accounts: IGeneralSalesforceData[];
  contacts: IGeneralSalesforceData[];

  handleSetState: (fields: Partial<ReplySalesforceState['payload']>) => void;
  handleSelectChange: (property: string, value: string) => void;
  setSearchableSelectFields: (type: SalesforceObjectType, stringToSearch: string) => void;
}

const SalesforceCaseForm: VFC<SalesforceCustomObjectProps> = ({
  payload,
  salesforceSettings,
  validationErrors,
  accounts = [],
  contacts = [],

  handleSetState,
  handleSelectChange,
  setSearchableSelectFields
}) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState<SalesforceObjectDescription>();

  useEffect(() => {
    if (payload.type) {
      SalesforceApi.describeObject(payload.type!).then((data) => {
        setDescription(data);
      });
    } else {
      setDescription(undefined);
    }
  }, [payload.type]);

  return (
    <>
      {!salesforceSettings.hiddenFields.includes('task.Subject') && (
        <Form.Field
          required
          error={
            validationErrors.Subject && {
              content: validationErrors.Subject,
              pointing: 'above'
            }
          }
          control={Input}
          value={payload.Subject}
          label={t('GENERAL_SUBJECT')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleSetState({
              Subject: event.target.value
            });
          }}
        />
      )}

      {!salesforceSettings.hiddenFields.includes('task.Description') && (
        <Form.Field
          error={
            validationErrors.Description && {
              content: validationErrors.Description,
              pointing: 'above'
            }
          }
          control={TextArea}
          value={payload.Description}
          label={t('GENERAL_DESCRIPTION')}
          onChange={(_event: React.SyntheticEvent, data: { [key: string]: any }) =>
            handleSetState({
              Description: data.value
            })
          }
        />
      )}

      <SalesforceMemoSelect
        value={payload.Status}
        fieldName="Status"
        items={
          description?.fields
            .find((field) => field.name === 'Status')
            ?.picklistValues?.map(({ label, value }) => ({ text: label, value })) ?? []
        }
        errorMessage={validationErrors.Status}
        handleState={handleSelectChange}
        label={t('GENERAL_STATUS')}
      />

      <SalesforceMemoSelect
        value={payload.WhoId}
        fieldName="WhoId"
        items={contacts
          .sort((a, b) => (a.Name > b.Name ? 1 : -1))
          .map((singleItem) => ({
            text: salesforceSettings.contactFieldView
              ? replaceObjectParameters(salesforceSettings.contactFieldView, singleItem)
              : singleItem.Name,
            value: singleItem.Id
          }))}
        errorMessage={validationErrors.OwnerId}
        handleState={handleSelectChange}
        onSearchChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
          setSearchableSelectFields('Contact', data.searchQuery)
        }
        label={t('GENERAL_CONTACT')}
        search
        clearable
      />
      <SalesforceMemoSelect
        value={payload.OwnerId || salesforceSettings.leadDefaultOwnerId}
        fieldName="OwnerId"
        items={[...accounts]
          .sort((a, b) => (a.Name > b.Name ? 1 : -1))
          .map((singleItem) => ({
            text: salesforceSettings.accountFieldView
              ? replaceObjectParameters(salesforceSettings.accountFieldView, singleItem)
              : singleItem.Name,
            value: singleItem.Id
          }))}
        errorMessage={validationErrors.OwnerId}
        handleState={handleSelectChange}
        onSearchChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
          setSearchableSelectFields('Account', data.searchQuery)
        }
        label={t('GENERAL_OWNER')}
        search
        clearable
      />
    </>
  );
};

export default React.memo(SalesforceCaseForm);
