import React, { useEffect, useState } from 'react';

import { VALID_HTTP_CODES } from '../../Utilities/lightbox';

import type { IFrameViewContentType } from '../../types/IFrameView';

interface BlobFetcherProps {
  url: string;
  type: IFrameViewContentType;
  children({
    blobUrl,
    error,
    reload
  }: {
    blobUrl?: string;
    loaded: boolean;
    error?: Error;
    reload: () => void;
  }): JSX.Element;
}

const BlobFetcher = ({ url, type, children }: BlobFetcherProps) => {
  const [loaded, setLoaded] = useState(false);
  const [blobUrl, setBlobUrl] = useState<string | undefined>();
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        const response = await fetch(url, { credentials: 'include', signal: controller.signal });
        if (!VALID_HTTP_CODES.includes(response.status)) {
          throw new Error(response.statusText);
        }
        const buffer = await response.arrayBuffer();
        const bUrl = URL.createObjectURL(new Blob([buffer], { type }));

        setBlobUrl(bUrl);
      } catch (err) {
        if ((err as any).name !== 'AbortError') {
          setError(err as any);
        }
      }

      setLoaded(true);
    })();

    return () => {
      controller.abort();
    };
  }, [url, loaded]);

  const reload = () => {
    setError(undefined);
    setLoaded(false);
  };

  return <>{children({ blobUrl, loaded, error, reload })}</>;
};

export default BlobFetcher;
