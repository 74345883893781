import { DefaultTicketsOrdering } from '@eeedo/types';
import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';

import type { TabFilter } from '@eeedo/types';

import {
  ACTIVATE_INFOPAGELIST_TAB,
  ACTIVATE_TICKETLIST_TAB,
  ADD_INFOPAGELIST_TAB,
  ADD_TICKETLIST_TAB,
  SET_INFOPAGELIST_SEARCH,
  SET_TICKETLIST_SEARCH
} from 'src/actions';

import type { InfoPageListTab } from 'src/types/InfoPageList';
import type { SearchCriterion } from 'src/types/Search';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { TicketListTab } from 'src/types/TicketList';

const prepareListPayload = (id: string, title: string): { payload: TicketListTab | InfoPageListTab } => {
  const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;
  return {
    payload: {
      id,
      title,
      activeTab: true,
      hasError: false,
      tickets: [],
      filters: {} as TabFilter,
      accordionIndex: 0,
      searchCriteria: [],
      sorting: defaultSorting,
      direction: defaultDirection
    }
  };
};

export const addTicketlistTab = createAction(ADD_TICKETLIST_TAB, prepareListPayload);

export const addInfopagelistTab = createAction(ADD_INFOPAGELIST_TAB, prepareListPayload);

export function addContentListTab(id: string, title: string, type: ContentTypesFields) {
  id = id ? id : moment().unix().toString();

  return (
    {
      tickets: addTicketlistTab(id, title),
      infopages: addInfopagelistTab(id, title)
    }[type] ?? addTicketlistTab(id, title)
  );
}

export const activateTicketlistTab = createAction<string>(ACTIVATE_TICKETLIST_TAB);

export const activateInfopagelistTab = createAction<string>(ACTIVATE_INFOPAGELIST_TAB);

export function activateContentListTab(id: string, type: ContentTypesFields) {
  return (
    {
      tickets: activateTicketlistTab(id),
      infopages: activateInfopagelistTab(id)
    }[type] ?? activateTicketlistTab(id)
  );
}

const prepareSearchPayload = (id: string, searchCriteria: SearchCriterion[]) => ({
  payload: { id, searchCriteria }
});

export const setTicketlistSearch = createAction(SET_TICKETLIST_SEARCH, prepareSearchPayload);

export const setInfopagelistSearch = createAction(SET_INFOPAGELIST_SEARCH, prepareSearchPayload);

export function setContentListSearch(id: string, searchCriteria: SearchCriterion[], type: ContentTypesFields) {
  return (
    {
      tickets: setTicketlistSearch(id, searchCriteria),
      infopages: setInfopagelistSearch(id, searchCriteria)
    }[type] ?? setTicketlistSearch(id, searchCriteria)
  );
}
