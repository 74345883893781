import { t as tr } from 'i18next';
import React from 'react';
import { Form, Input, TextArea } from 'semantic-ui-react';

import type { ISalesforceSettingsData } from '@eeedo/types';

import { replaceObjectParameters } from '../../Utilities';
import SalesforceMemoSelect from './SalesforceMemoSelect';

import type { ReplySalesforceState } from '../ReplySalesforce';
import type { IGeneralSalesforceData, SalesforceFormPayload, SalesforceObjectType } from 'src/types/Salesforce';

interface IProps {
  validationErrors: { [key: string]: any };
  salesforceSettings: ISalesforceSettingsData;
  payload: SalesforceFormPayload;
  users: IGeneralSalesforceData[];

  handleSetState: (fields: Partial<ReplySalesforceState['payload']>) => void;
  handleSelectChange: (property: string, value: string) => void;
  setSearchableSelectFields: (type: SalesforceObjectType, stringToSearch: string) => void;
}

class SalesforceLeadForm extends React.Component<IProps> {
  render() {
    return (
      <>
        {!this.props.salesforceSettings.hiddenFields.includes('lead.Name') && (
          <Form.Field
            required
            error={
              this.props.validationErrors.Name && {
                content: this.props.validationErrors.Name,
                pointing: 'above'
              }
            }
            control={Input}
            value={this.props.payload.Name}
            label={tr('GENERAL_NAME')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleSetState({
                Name: event.target.value
              })
            }
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('lead.Description') && (
          <Form.Field
            error={
              this.props.validationErrors.Description && {
                content: this.props.validationErrors.Description,
                pointing: 'above'
              }
            }
            control={TextArea}
            value={this.props.payload.Description}
            label={tr('GENERAL_DESCRIPTION')}
            onChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
              this.props.handleSetState({
                Description: data.value
              })
            }
          />
        )}
        {!this.props.salesforceSettings.hiddenFields.includes('lead.Status') && (
          <SalesforceMemoSelect
            value={this.props.payload.Status}
            fieldName="Status"
            items={this.props.salesforceSettings.leadStatuses.map((statusName: string) => ({
              text: statusName,
              value: statusName
            }))}
            errorMessage={this.props.validationErrors.Status}
            handleState={this.props.handleSelectChange}
            label={tr('GENERAL_STATUS')}
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('lead.Company') && (
          <Form.Field
            required
            error={
              this.props.validationErrors.Company && {
                content: this.props.validationErrors.Company,
                pointing: 'above'
              }
            }
            control={Input}
            value={this.props.payload.Company}
            label={tr('GENERAL_COMPANY')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleSetState({
                Company: event.target.value
              })
            }
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('lead.Owner') && (
          <SalesforceMemoSelect
            value={this.props.payload.OwnerId || this.props.salesforceSettings.leadDefaultOwnerId}
            fieldName="OwnerId"
            items={[...this.props.users]
              .sort((a, b) => (a.Name > b.Name ? 1 : -1))
              .map((singleItem) => ({
                text: this.props.salesforceSettings.userFieldView
                  ? replaceObjectParameters(this.props.salesforceSettings.userFieldView, singleItem)
                  : singleItem.Name,
                value: singleItem.Id
              }))}
            errorMessage={this.props.validationErrors.OwnerId}
            handleState={this.props.handleSelectChange}
            onSearchChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
              this.props.setSearchableSelectFields('User', data.searchQuery)
            }
            label={tr('GENERAL_OWNER')}
            search
          />
        )}
      </>
    );
  }
}

export default SalesforceLeadForm;
