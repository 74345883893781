import React from 'react';
import { Icon } from 'semantic-ui-react';
import type { VFC } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';

interface TicketListRowIconProps {
  content: string;
  name: SemanticICONS;
}

const TicketListRowIcon: VFC<TicketListRowIconProps> = ({ content, name }) => (
  <div className="ticketListRow">
    <Icon className="ticketListRow_icon" name={name} />
    {content}
  </div>
);

export default React.memo(TicketListRowIcon);
