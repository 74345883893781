import { faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Button, Comment, Popup } from 'semantic-ui-react';

import type { ConnectedProps } from 'react-redux';

import { getSelectedChannelTab } from '../CommentIconContent/ChannelType';
import CommentTextEncapsulated from './CommentTextEncapsulated';
import { updateTicketTabState } from 'src/actions/draftActions';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { parseContent } from 'src/Utilities/parseUtils';
import { onResponseTemplatePrompt } from 'src/Utilities/replyContent';
import { removeHTMLTags, sanitizeHTML } from 'src/Utilities/sanitize';
import { nonHTMLChannels } from 'src/Utilities/templates';

import type { Draft } from 'src/reducers/draftsReducer';
import type { State } from 'src/types/initialState';
import type { Comment as CommentType } from 'src/types/Ticket';

interface HelperCommentProps extends ConnectedProps<typeof connector> {
  comment: CommentType;
}

const HelperComment = ({ comment, contentId, draftContent, selectedChannelTab }: HelperCommentProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const useForReply = useCallback(async () => {
    let newContent: string = parseContent(comment.content ?? '', [], false);

    if (draftContent) {
      const update = await onResponseTemplatePrompt({
        update: { content: newContent },
        originalContent: draftContent,
        insertAtCursor: false,
        tr: t
      });

      if (update?.content) {
        newContent = update.content;
      }
    }

    if (contentId && selectedChannelTab) {
      newContent = nonHTMLChannels.includes(selectedChannelTab) ? removeHTMLTags(newContent) : sanitizeHTML(newContent);
      dispatch(updateTicketTabState(contentId, { [selectedChannelTab]: { content: newContent } }));
    }
  }, [draftContent, selectedChannelTab, contentId]);

  return (
    <Comment>
      <Comment.Avatar
        as={() => (
          <span
            className="avatar"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              width: '3em',
              height: '3em',
              backgroundColor: '#14375a',
              borderRadius: '5px'
            }}
          >
            <FontAwesomeIcon icon={faHandsHelping} color="#fff" size="xl" style={{ alignSelf: 'center' }} />
          </span>
        )}
      />

      <Comment.Content>
        <Comment.Author as="a">{t('widgets.chatGPT.author')}</Comment.Author>
        <Comment.Metadata>
          <span>{getPrettyDate(comment.created, { format: DATE_TIME_FORMAT })}</span>
        </Comment.Metadata>

        {comment.content && <CommentTextEncapsulated comment={t(comment.content)} />}
      </Comment.Content>
      <Comment.Action style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Popup trigger={<Button icon="reply" onClick={useForReply} />} content={t('USE_FOR_REPLY')} />
      </Comment.Action>
    </Comment>
  );
};

const connector = connect((state: State) => {
  const activeTicketTab = state.activeTicketTab;
  const draft = (activeTicketTab ? state.drafts[activeTicketTab] : undefined) as Draft | undefined;
  const selectedChannelTab = getSelectedChannelTab(activeTicketTab, state.detailedTickets, draft);
  const draftContent = (selectedChannelTab && draft?.[selectedChannelTab]?.content) ?? '';

  return {
    contentId: activeTicketTab,
    draftContent,
    selectedChannelTab
  };
}, {});

export default connector(HelperComment);
