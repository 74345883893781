export enum TemplateType {
  AUTOMATIC_EMAIL_REPLY = 'automaticEmailReply',
  CHAT = 'chatTemplate',
  GIOSG = 'giosgTemplate',
  WHATSAPP = 'whatsappTemplate',
  ARCHIVAL = 'archivalTemplate',
  CONTENT = 'contentTemplate'
}

export enum MatchParametersKeys {
  ticketType = 'ticketType'
}

export type TemplateJson<T> = T extends TemplateEmailReplyJson
  ? TemplateEmailReplyJson
  : T extends TemplateChatJson
  ? TemplateChatJson
  : T extends TemplateArchivalJson
  ? TemplateArchivalJson
  : T extends TemplateContentJson
  ? TemplateContentJson
  : string;

export interface TemplateFormValues<T = string> {
  id?: number;
  type?: TemplateType;
  name?: string;
  active?: boolean;
  isPrivate?: boolean;
  template?: TemplateJson<T>;
}

export interface Template<T = string> extends TemplateFormValues<T> {
  id: number;
  type: TemplateType;
  name: string;
  created: string;
  createdByName: string;
  updated: string;
  updatedByName: string;
}

export interface TemplateContentJson {
  title: string;
  content: string;
  ticketType: string;
  priority?: number;
  channel?: number;
  tags: string[];
}

export interface TemplateEmailReplyJson {
  name?: string;
  data?: {
    from: string;
    title: string;
    message: string;
  };
  metaData?: {
    lang: string;
  };
  matchParameters: [
    {
      targetValue: string[];
      targetVariable: MatchParametersKeys.ticketType;
    }
  ];
}

export interface TemplateChatJson {
  data: {
    type: string;
    title: string;
    tagIds: string[];
    channel: number;
    content: string;
    dueDate: number;
    taskType: string;
    delegatedToUserIds: string[];
  };
  metaData: {
    botOverride: string;
  };
  environmentID: number;
}

export interface TemplateArchivalJson {
  fields: string[];
  name: string;
  entries: string[];
  additionalFields?: AdditionalArchivalField[];
}

interface AdditionalArchivalField {
  label: string;
  name: string;
  defaultValue: string;
}
