import type { AxiosResponse } from 'axios';

import { apiClient } from 'src/Utilities/httpClients';

import type {
  IGeneralSalesforceData,
  SalesforceObjectDescription,
  SalesforceObjectType,
  SalesforceResponseStatus
} from 'src/types/Salesforce';

interface CreateSalesforceRecordParameters {
  ticketId: string;
  payload: { [key: string]: any };
}

export default class SalesforceApi {
  static getDataFromResponse = <T>(response: AxiosResponse<T>) => response.data;
  static getContacts = async (stringToSearch: string): Promise<IGeneralSalesforceData[]> =>
    await apiClient
      .get(`/salesforce/contacts`, {
        params: { searchTerm: stringToSearch }
      })
      .then(this.getDataFromResponse);

  static getAccounts = async (stringToSearch: string): Promise<IGeneralSalesforceData[]> =>
    await apiClient
      .get(`/salesforce/accounts`, {
        params: { searchTerm: stringToSearch }
      })
      .then(this.getDataFromResponse);

  static getUsers = async (stringToSearch: string): Promise<IGeneralSalesforceData[]> =>
    await apiClient
      .get(`/salesforce/users`, {
        params: { searchTerm: stringToSearch }
      })
      .then(this.getDataFromResponse);

  static createLead = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await apiClient.post(`/salesforce/lead`, parameters).then(this.getDataFromResponse);

  static createTask = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await apiClient.post(`/salesforce/task`, parameters).then(this.getDataFromResponse);

  static createCase = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await apiClient.post(`/salesforce/case`, parameters).then(this.getDataFromResponse);

  static createStandardObject = async (type: string, body: object): Promise<SalesforceResponseStatus> =>
    await apiClient.post(`/salesforce/sobject/${type}`, body);

  static createOpportunity = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await apiClient.post(`/salesforce/opportunity`, parameters).then(this.getDataFromResponse);

  static logAnEmail = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await apiClient.post(`/salesforce/LogAnEmail`, parameters).then(this.getDataFromResponse);

  static logACall = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await apiClient.post(`/salesforce/LogACall`, parameters).then(this.getDataFromResponse);

  static describeObject = async (type: SalesforceObjectType): Promise<SalesforceObjectDescription> => {
    return apiClient.get(`/salesforce/${type}/describe`).then(this.getDataFromResponse);
  };
}
