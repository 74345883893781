import React from 'react';
import { List, Loader } from 'semantic-ui-react';

import type { FC } from 'react';

import type { InitialRequestState } from './InitialLoad';

interface InitialLoadIconProps {
  initialRequest: InitialRequestState;
}

const InitialLoadIcon: FC<InitialLoadIconProps> = ({ initialRequest }) => {
  if (initialRequest.isCompleted) {
    return <List.Icon name="check" color="green" size="big" verticalAlign="middle" />;
  } else if (initialRequest.isLoading) {
    return (
      <List.Icon verticalAlign="middle">
        <Loader inline active indeterminate />
      </List.Icon>
    );
  } else if (initialRequest.error !== null) {
    return <List.Icon name="warning circle" size="big" color="red" verticalAlign="middle" />;
  } else {
    return <List.Icon name="question" size="big" color="yellow" verticalAlign="middle" />;
  }
};

export default React.memo(InitialLoadIcon);
