import {
  UPDATE_TICKET_TAB_STATE,
  APPEND_TO_DRAFT,
  SWITCH_DRAFT_TAB,
  FALSIFY_SCROLL,
  SCROLL_CASE_BOTTOM_INSTANTLY,
  FALSIFY_INSTANT_SCROLL
} from '../actions/index';
import type { Channels } from 'src/types/Channel';

export interface Draft
  extends Record<
    Channels,
    {
      content: string;
      isLoading?: boolean;
      selectedReplyTemplate?: string;
      endChatAfterSubmit?: boolean;
    }
  > {
  tabIndex: number;
}
interface DraftsStateBase {
  scroll: boolean;
  scrollInstantly: boolean;
}

export interface DraftsState extends DraftsStateBase {
  [ticketId: string]: Draft | DraftsStateBase[keyof DraftsStateBase];
}

const initialState: DraftsState = {
  scroll: false,
  scrollInstantly: false
};

const draftReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_TICKET_TAB_STATE: {
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] as Draft),
          ...action.payload.state
        }
      };
    }

    case FALSIFY_SCROLL: {
      return { ...state, scroll: false };
    }

    case FALSIFY_INSTANT_SCROLL: {
      return { ...state, scrollInstantly: false };
    }

    case APPEND_TO_DRAFT: {
      const draft = (state[action.payload.id] as Draft) || { tabIndex: 0 };
      const channelDraft = draft[action.payload.channel] || { content: '' };

      return {
        ...state,
        [action.payload.id]: {
          ...draft,
          [action.payload.channel]: {
            ...channelDraft,
            ...action.payload.payload,
            content: channelDraft.content + (action.payload.payload.content || '')
          }
        },
        scroll: true
      };
    }

    case SWITCH_DRAFT_TAB: {
      return {
        ...state,
        [action.payload.id]: {
          ...((state[action.payload.id] as Draft) || {}),
          tabIndex: action.payload.tabIndex
        }
      };
    }

    case SCROLL_CASE_BOTTOM_INSTANTLY: {
      return { ...state, scrollInstantly: true };
    }

    default: {
      return state;
    }
  }
};

export default draftReducer;
