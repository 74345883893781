import ChannelType from "./ChannelType";

export const getIconTranslationString = (channel: ChannelType): string => {
  const noMetadataChannels = {
    [ChannelType.Email]: 'COMMMENT_ICON_CONTENT_EMAIL_CHANNEL',
    [ChannelType.PriorityMail]: 'COMMENT_ICON_CONTENT_PRIORITY_EMAIL_CHANNEL',
    [ChannelType.Webform]: 'COMMMENT_ICON_CONTENT_WEBFORM_CHANNEL',
    [ChannelType.Chat]: 'COMMMENT_ICON_CONTENT_CHAT_CHANNEL',
    [ChannelType.Internal]: 'COMMMENT_ICON_CONTENT_INTERNAL_CHANNEL',
    [ChannelType.Sms]: 'SMS',
    [ChannelType.SecureEmail]: 'COMMMENT_ICON_CONTENT_SECURE_EMAIL_CHANNEL',
    [ChannelType.Facebook]: 'COMMMENT_ICON_CONTENT_FACEBOOK_CHANNEL',
    [ChannelType.Giosg]: 'COMMMENT_ICON_CONTENT_GIOSG_CHANNEL',
    [ChannelType.WhatsApp]: 'Whatsapp',
    [ChannelType.Phone]: 'PHONE',
    [ChannelType.CustomerPortal]: 'COMMENT_ICON_CONTENT_CP_CHANNEL',
    [ChannelType.Tampuuri]: 'COMMENT_ICON_CONTENT_TAMPUURI_CHANNEL',
    [ChannelType.Eezy]: 'COMMENT_ICON_CONTENT_EEZY_CHANNEL',
    [ChannelType.ViestiTytti]: 'COMMENT_ICON_CONTENT_VIESTI_TYTTI_CHANNEL',
    [ChannelType.Instagram]: 'COMMENT_ICON_CONTENT_VIESTI_INSTAGRAM_CHANNEL',
    [ChannelType.D365]: 'COMMENT_ICON_CONTENT_VIESTI_D365_CHANNEL',
  };

  // FIXME: COMMMENT...
  const defaultContent = 'COMMMENT_ICON_CONTENT_UNKNOWN_CHANNEL';

  return noMetadataChannels[channel] ?? defaultContent;
};

