import type { AxiosError, AxiosResponse } from 'axios';

import ApiConfig from './ApiConfig';
import { buildUrlWithQuery } from './URL';
import { apiClient } from 'src/Utilities/httpClients';
import type { EntityResponse } from 'src/types/Ticket';

interface GetEntityIdParams {
  ticketId: string;
  entityId: string;
  entityType: string;
  taskType: string;
}

export default class EntityApi {
  static getTicketEntityById = async ({
    entityType,
    taskType,
    entityId,
    ticketId
  }: GetEntityIdParams): Promise<EntityResponse> => {
    return apiClient
      .get(
        buildUrlWithQuery(`/entities/${entityId}`, {
          entityType,
          ticketType: taskType
        })
      )
      .then((response: AxiosResponse<any>) => {
        const result: EntityResponse = {
          _id: entityId,
          _type: entityType,
          hasAccess: true,
          ticketId: ticketId,
          statusCode: response.status,
          data: response.data
        };
        return result;
      })
      .catch((error: AxiosError) => {
        const errorCode = error.response?.status;
        if (errorCode === 400 || errorCode === 500 || errorCode === 502) {
          return {
            _id: entityId,
            _type: entityType,
            hasAccess: true,
            ticketId: ticketId,
            errorMessage: error.message,
            statusCode: errorCode
          };
        } else if (errorCode === 401 || errorCode === 403) {
          return {
            _id: entityId,
            _type: entityType,
            hasAccess: false,
            ticketId: ticketId,
            errorMessage: error.message,
            statusCode: errorCode
          };
        } else {
          return {
            _id: entityId,
            _type: entityType,
            hasAccess: true,
            ticketId: ticketId,
            errorMessage: error.message,
            statusCode: -1
          };
        }
      });
  };

  static verify = async (contentId: string, fields: string[]) => {
    return (await apiClient.post('/entities/verify', { contentId, fields })).data;
  };

  static verifyOpen = async (contentId: string) => {
    return await apiClient.post('/entities/verifyOpen', { contentId });
  };

  static createEntity = <B extends { entityType: string }>(body: B) => apiClient.post(`/entity`, body);

  static deleteEntity = ({ id, ...query }: { id: string; entityType: string; mongodb: boolean }) =>
    apiClient.delete(buildUrlWithQuery(`/entities/${id}`, query));

  static mergeEntities = ({
    targetEntityId,
    mergedEntityId,
    entityType
  }: {
    targetEntityId: string;
    mergedEntityId: string;
    entityType: string;
  }) =>
    apiClient.post(`/entities/merge`, {
      targetEntityId,
      mergedEntityId,
      entityType
    });

  static getEntityTags = async (entitiId: string) =>
    apiClient
      .get<number[]>(`${ApiConfig.getConfig().API_URL}/entity/${entitiId}/tags`)
      .then((response) => response.data);

  static addEntityTags = async ({
    entityId,
    entityType,
    tagIds
  }: {
    entityId: string;
    entityType: string;
    tagIds: number[];
  }) =>
    apiClient
      .post(`${ApiConfig.getConfig().API_URL}/entity/${entityId}/tags`, {
        tagIds,
        entityType
      })
      .then((response) => response.data);

  static deleteEntityTags = async ({ entityId, tagIds }: { entityId: string; tagIds: number[] }) =>
    apiClient
      .delete(`${ApiConfig.getConfig().API_URL}/entity/${entityId}/tags`, { data: { tagIds } })
      .then((response) => response.data);
}
