import type { MemoExoticComponent } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { ReactImageGalleryItem } from 'react-image-gallery';
import type { ConnectedProps } from 'react-redux';
import { connect, useDispatch } from 'react-redux';

import ApiConfig from 'src/api/ApiConfig';
import { closeAttachmentsPreview } from 'src/reducers/attachmentsReducer';
import type { State } from 'src/types/initialState';
import type { Attachment } from 'src/types/Ticket';
import { isLightboxType } from 'src/Utilities/lightbox';
import Lightbox from '../../generic/Lightbox/Lightbox';
import CSVViewer from './CSVViewer/CSVViewer';
import ImageViewer from './ImageViewer';
import ItemDescription from './ItemDescription/ItemDescription';
import PdfViewer from './PdfViewer';
import TxtViewer from './TxtViewer';
import VideoViewer from './VideoViewer';

type AttachmentsLightboxProps = ConnectedProps<typeof connector>;

const getUrl = (uri: string) => `${ApiConfig.getConfig().API_URL}/file/${uri}`;

type ViewerComponent = MemoExoticComponent<(props: { src: string }) => JSX.Element>;
const getItemRender =
  (Component: ViewerComponent) =>
  ({ original, description }: ReactImageGalleryItem) =>
    (
      <ItemDescription description={description}>
        <Component src={original} />
      </ItemDescription>
    );

const getItem = (attachment: Attachment): ReactImageGalleryItem => {
  const { uri, fileName: description } = attachment;
  const original = getUrl(uri);
  switch (true) {
    case /^.*\.pdf$/i.test(uri):
      return {
        original,
        description,
        renderItem: getItemRender(PdfViewer)
      };
    case /^.*\.csv$/i.test(uri):
      return {
        original,
        description,
        renderItem: getItemRender(CSVViewer)
      };
    case /^.*\.txt$/i.test(uri):
      return {
        original,
        description,
        renderItem: getItemRender(TxtViewer)
      };
    case /^.*\.(mov|mp4)$/i.test(uri):
      return {
        original,
        description,
        renderItem: getItemRender(VideoViewer)
      };
    default:
      return {
        original,
        description,
        renderItem: getItemRender(ImageViewer)
      };
  }
};

const AttachmentsLightbox = ({ attachments, open, firstAttachmentId }: AttachmentsLightboxProps) => {
  const dispatch = useDispatch();
  const items: ReactImageGalleryItem[] = useMemo(
    () =>
      [...attachments]
        .sort((_, a) => (a.id === firstAttachmentId ? 1 : -1))
        .filter(isLightboxType)
        .map((a) => getItem(a)),
    [attachments, firstAttachmentId]
  );

  const onClose = useCallback(() => {
    dispatch(closeAttachmentsPreview());
  }, []);

  return <Lightbox open={open} items={items} onClose={onClose} />;
};

const connector = connect((state: State) => ({
  open: state.attachments.previewAttachments,
  firstAttachmentId: state.attachments.attachmentId,
  attachments: state.attachments.attachments
}));

export default connector(AttachmentsLightbox);
