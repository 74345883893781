import React from 'react';

interface CommentIconHeader {
  index: number;
  title: string;
}

function CommentIconHeader(props: React.PropsWithChildren<CommentIconHeader>) {
  return (
    <div key={props.index} className={'comment-icon-original-ticket-content'}>
      <br />
      <b>{props.title}</b>
      <hr />
      {props.children}
    </div>
  );
}

export default CommentIconHeader;
