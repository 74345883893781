/**
 * Parse directive [name] or [name:param].
 * Directive must be at the beginning of the text
 */
export function parseDirective(text: string): { name: string; param: string | null; length: number } {
  if (!text.startsWith('[')) return { name: '', param: null, length: 0 };
  const end = text.indexOf(']');
  if (end <= 0) return { name: '', param: null, length: 0 };

  const inner = text.substr(1, end - 1);
  const splitpos = inner.indexOf(':');
  let parts: [string, string | null];
  if (splitpos > 0) {
    parts = [inner.slice(0, splitpos), inner.slice(splitpos + 1).trim()];
  } else {
    parts = [inner.trim(), null];
  }
  parts[0] = parts[0].trim().toUpperCase();
  return { name: parts[0], param: parts[1] || null, length: end + 1 };
}
