import * as React from 'react';
import { Translation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

const LoaderComponent = () => (
  <Translation ns="translations">
    {(t) => (
      <div style={{ margin: '20px' }}>
        <Loader active={true} inline={true} indeterminate={true}>
          {t('LOADING')}
        </Loader>
      </div>
    )}
  </Translation>
);
export default LoaderComponent;
