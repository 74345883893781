import { Direction, SortBy } from '@eeedo/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-select';
import { Icon } from 'semantic-ui-react';

import type { CSSProperties } from 'react';
import type { Styles } from 'react-select';

const styles: {
  direction: CSSProperties;
  wrapper: CSSProperties;
  dropdown: Partial<Styles<{ label: string; value: SortBy }, false>>;
} = {
  direction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '5px',
    cursor: 'pointer'
  },
  wrapper: { display: 'flex', marginTop: '5px', marginBottom: '5px' },
  dropdown: { singleValue: () => ({ minWidth: '60px' }) }
};

interface Props {
  sorting: SortBy;
  direction: Direction;
  onChange: (sorting: SortBy, direction: Direction) => void;
}

const Sorting = ({ sorting, direction, onChange }: Props) => {
  const { t } = useTranslation();

  const options: { label: string; value: SortBy }[] = [
    { label: t('SORTING_OPTION_DUE_DATE'), value: SortBy.dueDate },
    { label: t('SORTING_OPTION_CREATED'), value: SortBy.created },
    { label: t('SORTING_OPTION_TOUCHED'), value: SortBy.touched }
  ];
  const value = options.find((o) => o.value === sorting);

  return (
    <div style={styles.wrapper} id="sorting">
      <div
        id="sorting-direction"
        style={styles.direction}
        onClick={() => {
          onChange(sorting, direction === Direction.ASC ? Direction.DESC : Direction.ASC);
        }}
      >
        <Icon name={direction === Direction.ASC ? 'arrow up' : 'arrow down'} />
      </div>

      <Dropdown
        id="sorting-by"
        size="small"
        styles={styles.dropdown}
        options={options}
        value={value}
        onChange={(data) => {
          onChange((data as { label: string; value: SortBy })?.value as SortBy, direction);
        }}
        formatOptionLabel={({ label, value }) => <div id={`sorting-option-${value}`}>{label}</div>}
        selection
        fluid
      />
    </div>
  );
};

export default Sorting;
