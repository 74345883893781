import React from 'react';
import { useGesture } from 'react-with-gesture';
import { Grid } from 'semantic-ui-react';

import type { FC } from 'react';
import type { GestureState } from 'react-with-gesture';

import ProfileDropdownContainer from 'src/containers/ProfileDropdownContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import MenuDropdown from 'src/MenuDropdown';
import { getUrlSearchParam } from 'src/Utilities/helper';

interface Props {
  mobileMode: boolean;
  children?: React.ReactNode;

  onClickExportData: () => void;
  onDown: (state: GestureState) => void;
  onUp: (state: GestureState) => void;
}

const Layout: FC<Props> = ({ mobileMode, onClickExportData, onUp, onDown, children }) => {
  const [bindGestures] = useGesture({
    touch: true, // accept touch input
    mouse: true, // accept mouse input
    passive: { passive: true }, // event handler 3rd argument input, passive by default
    onAction: undefined, // event => eventHandler, respond to events outside React's render cycle
    onMove: undefined, // event => eventHandler, respond to mousemove/touchmove events within React's render cycle
    onUp,
    onDown
  }) as any;

  return (
    <div className={mobileMode ? 'mobileWrapper' : 'wrapper'}>
      <Grid
        verticalAlign="middle"
        className="main-topbar"
        style={{
          ...(!!getUrlSearchParam('hideNavigation') && { display: 'none' }),
          ...(mobileMode && { height: '85px', marginBottom: '0px' })
        }}
      >
        {mobileMode ? (
          <Grid.Row>
            <Grid.Column className="left floated left aligned" style={{ width: '70px' }} verticalAlign="middle">
              <ErrorBoundary>
                <MenuDropdown onClickExportData={onClickExportData} />
              </ErrorBoundary>
            </Grid.Column>
            <Grid.Column className="right floated right aligned column" style={{ width: '79px' }}>
              <ErrorBoundary>
                <ProfileDropdownContainer />
              </ErrorBoundary>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column floated="left" style={{ width: '60px' }} verticalAlign="middle">
              <ErrorBoundary>
                <MenuDropdown onClickExportData={onClickExportData} />
              </ErrorBoundary>
            </Grid.Column>
            <Grid.Column width={3} floated="left" textAlign="left">
              <div className="logo" />
            </Grid.Column>
            <Grid.Column computer={8} tablet={4} mobile={1} />
            <Grid.Column width={4} floated="right" textAlign="right">
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={12} verticalAlign="middle" style={{ color: 'white' }} />
                  <ErrorBoundary>
                    <Grid.Column width={4}>
                      <ProfileDropdownContainer />
                    </Grid.Column>
                  </ErrorBoundary>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>

      <ErrorBoundary>
        <div className={mobileMode ? 'mainMobile' : 'main'} {...(mobileMode && bindGestures())}>
          {children}
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default Layout;
