import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { addComment } from '../actions/commentsActions';
import { activateTab, addTab } from '../actions/tabActionsRTK';
import { activateTicket } from '../actions/ticketsActions';
import MitelIntegration from '../Components/PhoneServices/MitelIntegration';
import { handleIncomingPhoneCall } from '../handlers/handlePhoneCall';
import SocketInstance from '../realTimeNotifications';
import { convertCaseNaming } from 'src/Utilities/helper';

import type { IntegrationData } from '../handlers/handlePhoneCall';
import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';

const mapStateToProps = (state: State, ownProps: any) => {
  return {
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    phoneConfigurations: state.phoneConfigurations,
    workStatus: state.workStatus,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: ThunkAppDispatch) => {
  return {
    activateFoundTicket: (ticketId: number) => {
      const stringTicketId = convertCaseNaming(ticketId, 'string', 'task') as string;
      dispatch(addTab({ id: stringTicketId, type: 'tickets' }));
      dispatch(activateTicket(stringTicketId));
      dispatch(activateTab(stringTicketId));

      SocketInstance.joinRoom(stringTicketId);
    },

    createNewComment: (
      ticketId: number,
      payload: {
        content: string;
        direction: string;
        foreignId: string;
        foreignIdType: string;
      }
    ) => {
      return dispatch(addComment(`TSK${ticketId}`, payload));
    },

    handleIncomingPhoneCall: (callObject: IntegrationData) => {
      return dispatch(handleIncomingPhoneCall(callObject));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MitelIntegration));
