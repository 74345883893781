import React, { useState, useEffect } from 'react';
import type { VFC } from 'react';

import { VersionCounters, VersionViewer } from 'src/Components/Versions';
import { commentsVC } from 'src/api/VersionControl';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';
import type ChannelType from 'src/Components/CommentIconContent/ChannelType';
import type { ViewMode } from 'src/Components/Versions/VersionViewer';

interface ReplyVCDraftsProps {
  taskId: string;
  channelId: ChannelType;
  onClickLoad: (mode: ViewMode, id: number) => void;
}

export const parseJsonContent = (content: string) => {
  try {
    // We can probably remove this decodeURIComponent. This was needed for backward compatibility
    return JSON.parse(decodeURIComponent(content));
  } catch {
    try {
      return JSON.parse(content);
    } catch {
      try {
        const regex = /"content":"(.*?[^\\])"/;
        const [, matchContent] = content.match(regex) || [];
        return { content: matchContent };
      } catch {
        return {};
      }
    }
  }
};

export const parseContent = (content: string) => {
  const jsonContent = parseJsonContent(content);
  return jsonContent.content || '';
};

const ReplyVCDrafts: VFC<ReplyVCDraftsProps> = ({ taskId, channelId, onClickLoad }) => {
  const [open, setOpen] = useState<boolean>(false);
  const id = taskIdToNumericalId(taskId);

  const loadDrafts = () => {
    commentsVC.loadDraft({
      taskId: id,
      channelId: channelId,
      attachments: []
    });
  };

  useEffect(() => {
    loadDrafts();
  }, []);

  return (
    <div style={{ marginTop: '2em' }}>
      <VersionViewer
        id={id}
        channelId={channelId}
        dataSource={commentsVC}
        mode={open ? 'drafts' : null}
        onClose={() => setOpen(false)}
        onClickLoad={(mode, id) => {
          onClickLoad(mode, id);
          setOpen(false);
        }}
        parseContent={parseContent}
      />

      <VersionCounters id={id} channelId={channelId} dataSource={commentsVC} onClick={() => setOpen(true)} />
    </div>
  );
};

export default React.memo(ReplyVCDrafts);
