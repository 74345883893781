import React, { useEffect, useRef, useState } from 'react';

import ItemWrapper from './ItemWrapper/ItemWrapper';

const VideoViewer = ({ src }: { src: string }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.onloadeddata = () => {
        setHasError(false);
        setLoaded(true);
      };
      video.onerror = () => {
        setHasError(true);
        setLoaded(true);
      };
    }
  }, [src, loaded]);

  return (
    <ItemWrapper
      loaded={loaded}
      hasError={hasError}
      reload={() => {
        setHasError(false);
        setLoaded(false);
      }}
    >
      <video ref={videoRef} style={{ display: !loaded || hasError ? 'none' : 'initial' }} controls={true}>
        <source src={src} />
      </video>
    </ItemWrapper>
  );
};

export default React.memo(VideoViewer);
