export const getAttachmentSize = (size: number | null): string => {
  if (!size) {
    return '';
  }
  const sufixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const humanReadableSize = (size / Math.pow(1024, i)).toFixed(i === 0 ? 0 : 1) + ' ' + sufixes[i];
  if (['B'].includes(sufixes[i])) {
    // Dont show B
    return '';
  }
  return humanReadableSize;
};
