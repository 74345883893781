import { get } from 'lodash-es';
import moment from 'moment';

import { getCommentSender } from 'src/Components/Utilities/comments';
import { store } from 'src/store';

import type { Comment, CommentFilters } from 'src/types/Ticket';

export const botButtonClickedState = (singleComment: Comment, listOfComments: Comment[]): undefined | Comment => {
  if (singleComment.metaData?.userClickedButton) {
    return [...listOfComments]
      .reverse()
      .find((comment) =>
        comment.metaData?.buttons?.some((button) => button.buttonState === singleComment.metaData?.userClickedButton)
      );
  }
  return undefined;
};

export const filterComments = (originalComments: Comment[], filters: CommentFilters | undefined) => {
  if (!filters || Object.keys(filters).length === 0) return originalComments;

  let comments = originalComments;
  const { direction, channels, author, text, createdAfter, createdBefore, hasAttachment } = filters;

  if (direction) {
    comments = comments.filter((comment) => comment.direction === direction);
  }

  if (Array.isArray(channels) && channels.length > 0) {
    comments = comments.filter((comment) => channels.indexOf(comment.channel!) !== -1);
  }

  if (author) {
    const channelsReduxState = store.getState().channels;
    const ticketTypesReduxState = store.getState().ticketTypes;
    const usersListReduxState = store.getState().usersList.usersList;
    const taskReduxState = store
      .getState()
      .detailedTickets.find((ticket) => ticket.id === store.getState().activeTicketTab);

    comments = comments.filter((comment) =>
      author.includes(
        getCommentSender({
          originalEntityData: get(comment, ['metaData', 'originalEntity'], null),
          channelData: channelsReduxState.find((channel) => channel.id === comment.channel),
          user: usersListReduxState.find((user) => user.UID === comment.createdByUser)!,
          metaData: comment.metaData,
          direction: comment.direction,
          ticketEntities: taskReduxState?.entities ?? [],
          customerInfoFields:
            ticketTypesReduxState
              .find((ticketType) => ticketType.name === taskReduxState?.taskType)
              ?.fieldSets?.find((fieldSet) => fieldSet.id === 'customerInfo')?.customerInfo ?? []
        })
      )
    );
  }

  if (text) {
    comments = comments.filter((comment) => comment.content?.toLowerCase().indexOf(text.toLowerCase()) !== -1);
  }

  if (createdAfter || createdBefore) {
    comments = comments.filter((comment) => {
      const created = moment.unix(comment.created);
      return (
        (!createdAfter || created.isAfter(moment.unix(createdAfter))) &&
        (!createdBefore || created.isBefore(moment.unix(createdBefore)))
      );
    });
  }

  if (hasAttachment) {
    comments = comments.filter((comment) => !!comment.metaData?.attachments?.length);
  }

  return comments;
};
