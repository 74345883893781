import { t } from 'i18next';
import iziToast from 'izitoast';
import type { IziToast } from 'izitoast';
import type { TFunction } from 'i18next';

import { newlinesToLineBreaks } from './sanitize';
import { getPrettyDate } from 'src/Utilities/dates';
import type { CommentEditorProps } from 'src/Components/CommentEditorWidget/types';
import type { ResponseUpdate, ToastButtons } from 'src/types/ResponseTemplate';

export const replaceNewLineCharactersWithBreakTags = (value: string) => {
  const newValue = value.replace(/(?:\r\n|\r|\n|↵)/gm, '<br/>');
  return newValue;
};

const getPrefix = () => {
  return `<br/>----------------------------------------------------<br/>`;
};

const getPostFix = (props: CommentEditorProps) => {
  return `<br> <br> ${props.comment}`;
};

const getFormattedProperty = (translation: string, value: string) => {
  return `<b>${t(translation)}:</b> ${
    typeof value === 'string' ? replaceNewLineCharactersWithBreakTags(value) : value
  }<br/>`;
};

const getDate = (date: number) => {
  return getPrettyDate(date, {
    sinceCurrentTime: false,
    format: 'llll'
  });
};

export const getReplyContent = (props: CommentEditorProps) => {
  let replyContent = '';

  if (props.metaData.from) {
    replyContent += getFormattedProperty('COMMENT_MAIL_ORIGINAL_SENDER', props.metaData.from);
  }
  if (props.created) {
    replyContent += getFormattedProperty('COMMENT_MAIL_SENT_DATE', getDate(props.created));
  }
  if (Array.isArray(props.metaData.to) && props.metaData.to.length > 0) {
    replyContent += getFormattedProperty('COMMENT_MAIL_ORIGINAL_RECIPIENTS', props.metaData.to.join(', '));
  } else if (typeof props.metaData.to === 'string') {
    replyContent += getFormattedProperty('COMMENT_MAIL_ORIGINAL_RECIPIENTS', props.metaData.to);
  }
  if (props.metaData.cc) {
    replyContent += getFormattedProperty('COMMENT_MAIL_CC', props.metaData.cc);
  }
  if (props.title !== '') {
    replyContent += getFormattedProperty('COMMENT_MAIL_SUBJECT', props.title);
  } else {
    replyContent += getFormattedProperty('COMMENT_MAIL_SUBJECT', props.metaData.title);
  }

  return getPrefix() + replyContent + getPostFix(props);
};

export const getReplyAllContent = (props: CommentEditorProps) => {
  let replyAllContent = '';

  if (props.metaData.from) {
    replyAllContent += getFormattedProperty('COMMENT_MAIL_ORIGINAL_SENDER', props.metaData.from);
  }
  if (props.created) {
    replyAllContent += getFormattedProperty('COMMENT_MAIL_SENT_DATE', getDate(props.created));
  }
  if (Array.isArray(props.metaData.to) && props.metaData.to.length > 0) {
    replyAllContent += getFormattedProperty('COMMENT_MAIL_ORIGINAL_RECIPIENTS', props.metaData.to.join(', '));
  }
  if (props.metaData.cc) {
    replyAllContent += getFormattedProperty('COMMENT_MAIL_CC', props.metaData.cc);
  }
  if (props.title !== '') {
    replyAllContent += getFormattedProperty('COMMENT_MAIL_SUBJECT', props.title);
  } else if (props.metaData.title !== '') {
    replyAllContent += getFormattedProperty('COMMENT_MAIL_SUBJECT', props.metaData.title);
  }

  return getPrefix() + replyAllContent + getPostFix(props);
};

export const getForwardContent = (props: CommentEditorProps) => {
  let forwardContent = '';

  if (props.metaData.from) {
    forwardContent += getFormattedProperty('COMMENT_MAIL_ORIGINAL_SENDER', props.metaData.from);
  }
  if (props.created) {
    forwardContent += getFormattedProperty('COMMENT_MAIL_SENT_DATE', getDate(props.created));
  }
  if (props.metaData.to) {
    forwardContent += getFormattedProperty('COMMENT_MAIL_ORIGINAL_RECIPIENTS', props.metaData.to);
  }
  if (props.metaData.cc) {
    forwardContent += getFormattedProperty('COMMENT_MAIL_CC', props.metaData.cc);
  }
  if (props.title !== '') {
    forwardContent += getFormattedProperty('COMMENT_MAIL_SUBJECT', props.title);
  } else if (props.metaData.title !== '') {
    forwardContent += getFormattedProperty('COMMENT_MAIL_SUBJECT', props.metaData.title);
  }

  return getPrefix() + forwardContent + getPostFix(props);
};

export const getReplyContentSimple = (props: CommentEditorProps) => {
  let replyContentSimple = '';

  if (props.created) {
    replyContentSimple += getFormattedProperty('COMMENT_MAIL_SENT_DATE', getDate(props.created));
  }

  return getPrefix() + replyContentSimple + getPostFix(props);
};

export const getForwardContentSimple = (props: CommentEditorProps) => {
  let forwardContentSimple = '';

  if (props.created) {
    forwardContentSimple += getFormattedProperty('COMMENT_MAIL_SENT_DATE', getDate(props.created));
  }

  return getPrefix() + forwardContentSimple + getPostFix(props);
};

// TODO: refactor into the react component + redux state
export const onResponseTemplatePrompt = ({
  update,
  originalContent,
  insertAtCursor,
  tr
}: {
  update: ResponseUpdate;
  originalContent: string;
  insertAtCursor: boolean;
  tr: TFunction;
}): Promise<ResponseUpdate | null> => {
  return new Promise((resolve) => {
    const buttons: ToastButtons = [
      [
        `<button><b>${tr('GENERAL_REPLACE')}</b></button>`,
        (instance: IziToast, toast: HTMLDivElement) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
          resolve(update);
        },
        true
      ],
      [
        `<button>${tr('GENERAL_CONCAT_END')}</button>`,
        (instance: IziToast, toast: HTMLDivElement) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
          const content = newlinesToLineBreaks(`${originalContent}<br/><p>${update.content}</p>`);
          resolve({
            ...update,
            content: content
          });
        },
        false
      ],
      [
        `<button>${tr('GENERAL_CONCAT_START')}</button>`,
        (instance: IziToast, toast: HTMLDivElement) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
          const content = newlinesToLineBreaks(`<br/>${update.content}<br/>${originalContent}<br/>`);
          resolve({
            ...update,
            content: content
          });
        },
        false
      ]
    ];

    if (insertAtCursor) {
      buttons.push([
        `<button>${tr('GENERAL_CONCAT_CURSOR')}</button>`,
        (instance: IziToast, toast: HTMLDivElement) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
          const content = newlinesToLineBreaks(update.content);
          resolve({
            ...update,
            content: content,
            insertAtCursor: true
          });
        },
        false
      ]);
    }

    buttons.push([
      `<button>${tr('GENERAL_CANCEL')}</button>`,
      (instance: IziToast, toast: HTMLDivElement) => {
        instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
        // Perform no action.
        resolve(null);
      },
      false
    ]);

    iziToast.question({
      timeout: 0,
      close: false,
      overlay: true,
      id: 'question',
      zindex: 999,
      message: tr('TEMPLATE_TEXT_AREA_ALREADY_HAS_TEXT'),
      position: 'center',
      buttons: buttons
    });
  });
};
