import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Widget } from '@eeedo/types';
import type { DropdownProps } from 'semantic-ui-react';

import AccordionHeader from '../AccordionHeader';
import ChannelDropdown from 'src/Components/generic/ChannelDropdown';

import type { Ticket } from 'src/types/Ticket';

interface CaseChannelProps {
  task: Ticket;
  widget: Widget;
  widgetOpen: boolean;
  ind: number;

  handleChannelChange(channel: number): void;
}

const CaseChannel = ({ task, widgetOpen, widget, handleChannelChange, ind }: CaseChannelProps) => {
  const { t } = useTranslation();

  const onChange = useCallback<NonNullable<DropdownProps['onChange']>>(
    (_, data) => {
      handleChannelChange(data.value as number);
    },
    [handleChannelChange]
  );

  return (
    <AccordionHeader
      id="widget-channel"
      key={`case-channel-${ind}`}
      as="h4"
      icon="inbox"
      active={widgetOpen}
      title={t('CASE_CHANNEL')}
    >
      <ChannelDropdown id={widget.type} value={task.channel} onChange={onChange} />
    </AccordionHeader>
  );
};

export default React.memo(CaseChannel);
