import type { Channel } from '@eeedo/types';

import { FETCH_CHANNEL_TYPES_SUCCESS, PATCH_CHANNELS_SUCCESS } from '../actions/index';

const initialState: Channel[] = [];

const channelsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_CHANNEL_TYPES_SUCCESS: {
      return action.payload.channelTypes;
    }

    case PATCH_CHANNELS_SUCCESS: {
      const { channel } = action;

      const exists = state.some((item) => item.id === channel.id);
      return exists
        ? state.map((item) => (item.id === channel.id ? { ...item, ...channel } : item))
        : [...state, channel];
    }

    default:
      return state;
  }
};

export default channelsReducer;
