import type { Tag } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class TagsApi {
  static getTags = async (): Promise<Tag[]> => {
    return apiClient.get('/tags').then((response) => response.data);
  };

  static patchTag = async (data: { id: string; data: Partial<Tag> }): Promise<Tag> => {
    return apiClient.patch('/tag/' + data.id, data.data).then((response) => response.data);
  };

  static deleteTag = async (id: string) => {
    return apiClient.delete('/tag/' + id, { data: { _id: id } }).then((response) => response.data);
  };

  static createTag = async (data: any) => {
    return apiClient.post('/tags/', data).then((response) => response.data);
  };
}
