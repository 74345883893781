import iziToast from 'izitoast';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { useEffect } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { State } from 'src/types/initialState';

const DISCONNECTED_TOAST_ID = 'disconnected-notification';

type Props = DisconnectedReduxProps;

const DisconnectedNotification = ({ show }: Props) => {
  useEffect(() => {
    const toasts = document.querySelectorAll<HTMLDivElement>(`#${DISCONNECTED_TOAST_ID}`);
    toasts.forEach((toast) => {
      iziToast.hide({}, toast);
    });

    if (show) {
      iziToast.error({
        id: DISCONNECTED_TOAST_ID,
        message: t('DISCONNECTED_NOTIFICATION'),
        position: 'bottomRight',
        timeout: 0,
        icon: 'icon error warning sign',
        close: false,
        drag: false
      });
    }
  }, [show]);

  return null;
};

const connector = connect((state: State) => ({ show: state.notifications.showDisconnected && state.auth.loggedIn }));

type DisconnectedReduxProps = ConnectedProps<typeof connector>;

export default connector(DisconnectedNotification);
