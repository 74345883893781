import React, { useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import { DATE_TIME_FORMAT, getPrettyDate } from '../../../Utilities/dates';
import HeaderEmailPreview from 'src/Components/CommentIconContent/HeaderEmailPreview';
import { parseContent } from 'src/Utilities/parseUtils';

import type { State } from 'src/types/initialState';

const PrintComments = () => {
  const ticket = useSelector(
    (state: State) => state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!
  );
  const channels = useSelector((state: State) => state.channels);
  const ticketTypes = useSelector((state: State) => state.ticketTypes);

  const { t } = useTranslation();
  const { current: iframe } = useRef(document.createElement('iframe'));

  const focusListener = () => {
    iframe.remove();
  };

  useEffect(() => {
    return window.removeEventListener('focus', focusListener);
  }, []);

  const commentsToHTML = () =>
    ticket?.comments
      .filter((c) => c.type === 'normal')
      .map((comment) =>
        renderToString(
          <>
            <HeaderEmailPreview
              channels={channels}
              ticketTypes={ticketTypes}
              channel={comment.channel!}
              title={comment.title}
              metaData={comment.metaData}
              created={getPrettyDate(comment.created, { format: DATE_TIME_FORMAT })}
            />
            {typeof comment.content === 'string' && (
              <>
                <br />
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: parseContent(comment.content, comment.metaData?.attachments || ticket.attachments, true)
                  }}
                />
                <hr />
              </>
            )}
            <br />
          </>
        )
      )
      .join('') ?? '';

  const onClick = () => {
    const timestamp = new Date().getTime();
    iframe.id = `element-to-print-${timestamp}`;
    iframe.name = `element-to-print-${timestamp}`;
    iframe.title = `${ticket.id} comments`;
    iframe.style.display = 'none';
    iframe.style.top = '-1000px';
    iframe.style.left = '-1000px';
    iframe.srcdoc = `
      <html>
        <head><title>${ticket.id} comments</title></head>
        <body>
        ${commentsToHTML()}
        </body>
      </html>
    `;

    document.body.appendChild(iframe);

    window.frames[`element-to-print-${timestamp}`].document.close(); // >=IE11 fix
    window.addEventListener('focus', focusListener);
    window.frames[`element-to-print-${timestamp}`].focus();
    setTimeout(() => {
      // safari and firefox fix
      window.frames[`element-to-print-${timestamp}`].print();
    }, 500);
  };

  return ticket?.comments?.length ? (
    <Dropdown.Item onClick={onClick}>{t('top_bar.advanced_actions.print_current_discussion')}</Dropdown.Item>
  ) : null;
};

export default PrintComments;
