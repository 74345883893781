import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import type { Field } from '@eeedo/types';
import type { VFC } from 'react';

import type { InfoFieldProps } from './InfoFieldProps';

interface InfoCheckboxProps {
  field: Field;
  fieldValue: any;
  disabled?: boolean;

  onSaveData: InfoFieldProps['onSaveData'];
}

const InfoCheckbox: VFC<InfoCheckboxProps> = ({ field, fieldValue, disabled, onSaveData }) => (
  <Checkbox
    id={field.name?.split(' ').join('_')}
    disabled={disabled}
    toggle={true}
    defaultChecked={!!fieldValue}
    onChange={(_e, data) => {
      onSaveData?.(field, data.checked);
    }}
    label={field.switch!.filter((option) => option.value === field.value)}
  />
);

export default React.memo(InfoCheckbox);
