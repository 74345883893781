import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { EntitySearchResult } from 'src/types/EntityViewer';
import type { EntitySearchFields } from 'src/types/Info';
import type { TicketListTicket } from 'src/types/Ticket';

export interface EntityViewerState {
  entities: { [id: string]: EntitySearchResult };
  tickets: { [entityId: string]: TicketListTicket[] };
  selectedEntity?: EntitySearchResult;
  filledEntitySearchFields: EntitySearchFields;
  pickedSearchEntityTypes: string[];
  page: number;
}

const initialState: EntityViewerState = {
  entities: {},
  tickets: {},
  filledEntitySearchFields: {},
  pickedSearchEntityTypes: [],
  page: 1
};

const getUpdatePayload = (
  fieldName: string,
  valueToSave: UpdateEntityDetail['valueToSave'],
  object: string | false | undefined,
  previousValues?: EntityViewerState['entities'][string]
) => {
  let name = fieldName;
  let value: UpdateEntityDetail['valueToSave'] | Record<string, UpdateEntityDetail['valueToSave']> = valueToSave;
  if (typeof object === 'string') {
    name = object;
    if (typeof previousValues?.[object] === 'undefined') {
      value = {};
    }
    value = { ...previousValues?.[object], [fieldName]: valueToSave };
  }

  return { name, value };
};

const entityViewerSlice = createSlice({
  name: 'entityViewer',
  initialState,
  reducers: {
    searchEntitiesSuccess: (state, action: PayloadAction<EntitySearchResult[]>) => {
      state.entities = action.payload.reduce((entities, entity) => ({ ...entities, [entity._id]: entity }), {});
    },
    updateEntityField: (
      state,
      action: PayloadAction<Pick<UpdateEntityDetail, 'entityId' | 'fieldName' | 'valueToSave' | 'object'>>
    ) => {
      const { entityId, fieldName, valueToSave, object } = action.payload;

      const { name, value } = getUpdatePayload(fieldName, valueToSave, object, state.entities[entityId]);

      if (state.entities[entityId]) {
        state.entities[entityId][name] = value;
      }
      if (state.selectedEntity && state.selectedEntity?._id === entityId) {
        state.selectedEntity[name] = value;
      }
    },
    searchTicketsSuccess: (state, action: PayloadAction<{ tickets: TicketListTicket[]; entityId: string }>) => {
      state.tickets = { ...state.tickets, [action.payload.entityId]: action.payload.tickets };
    },
    setSelectedEntity: (state, action: PayloadAction<EntitySearchResult | undefined>) => {
      state.selectedEntity = action.payload;
    },
    setFilledEntitySearchFields: (
      state,
      action: PayloadAction<{
        fieldName: keyof EntitySearchFields | string;
        valueToSave: EntitySearchFields[keyof EntitySearchFields];
        object: string | false | undefined;
      }>
    ) => {
      const { fieldName, valueToSave, object } = action.payload;
      const { name, value } = getUpdatePayload(fieldName, valueToSave, object, state.filledEntitySearchFields as any);
      state.filledEntitySearchFields[name] = value;
    },
    clearFilledEntitySearchFields: (state) => {
      state.filledEntitySearchFields = {};
    },
    setPickedSearchEntityTypes: (state, action: PayloadAction<string[]>) => {
      state.pickedSearchEntityTypes = action.payload;
    },
    setEntityViewerPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    }
  }
});

export const {
  searchEntitiesSuccess,
  updateEntityField,
  searchTicketsSuccess,
  setSelectedEntity,
  setFilledEntitySearchFields,
  clearFilledEntitySearchFields,
  setPickedSearchEntityTypes,
  setEntityViewerPage
} = entityViewerSlice.actions;

export default entityViewerSlice.reducer;
