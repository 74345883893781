import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { ListArticle } from 'src/types/Articles';

export interface ArticlesState {
  suggested: Record<string, ListArticle[]>;
  search: Record<string, ListArticle[]>;
}

const initialState: ArticlesState = { suggested: {}, search: {} };

const articlesSlice = createSlice({
  name: 'suggestedArticles',
  initialState,
  reducers: {
    setArticles: (
      state,
      action: PayloadAction<{ ticketId: string; type: keyof ArticlesState; articles: ListArticle[] }>
    ) => {
      state[action.payload.type][action.payload.ticketId] = action.payload.articles;
    }
  }
});

export const { setArticles } = articlesSlice.actions;

export default articlesSlice.reducer;
