import { connect } from 'react-redux';

import ActivationComponent from '../ActivationComponent';
import { activate } from '../actions/authActions';
import type { State } from 'src/types/initialState';

const mapStateToProps = (state: State) => {
  return {
    activationSuccess: state.auth.activationSuccess
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    activatePassword: (token: string | null, password: string) => dispatch(activate(token, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivationComponent);
