import * as React from 'react';
import { useTranslation } from 'react-i18next';

import type { Channel, TicketType } from '@eeedo/types';

import ChannelType from './ChannelType';
import DataToContent from './DisplayTicketData';
import SmsContent from './SmsContent';

import type { Attachment } from '../../types/Ticket';

interface CommentIconContentProps {
  channel: number;
  ticketTypes: TicketType[];
  channels: Channel[];
  metaData: null | { [x: string]: any };
  title: string | null;
  content?: string;
  created: string;
}

const ChannelItem = ({ text, created }: { text: string; created: string }) => (
  <div>
    <b>{text}</b> - {created}
    <hr />
  </div>
);

const HeaderEmailPreview = (props: CommentIconContentProps) => {
  const { t } = useTranslation();
  const toHeader = props?.metaData?.headers?.['reply-to'] ?? props?.metaData?.headers?.['to'];
  const haveReplyTo = Array.isArray(toHeader);
  const channelLabels = {
    [ChannelType.Sms]: 'SMS',
    [ChannelType.Email]: t('COMMMENT_ICON_CONTENT_EMAIL_CHANNEL'),
    [ChannelType.Facebook]: t('COMMMENT_ICON_CONTENT_FACEBOOK_CHANNEL'),
    [ChannelType.SecureEmail]: t('COMMMENT_ICON_CONTENT_SECURE_EMAIL_CHANNEL'),
    [ChannelType.Internal]: t('COMMMENT_ICON_CONTENT_INTERNAL_CHANNEL'),
    [ChannelType.Chat]: t('COMMMENT_ICON_CONTENT_CHAT_CHANNEL'),
    [ChannelType.Webform]: t('COMMMENT_ICON_CONTENT_WEBFORM_CHANNEL')
  };
  const defaultLabel = t('COMMMENT_ICON_CONTENT_UNKNOWN_CHANNEL');

  if (props.metaData) {
    switch (props.channel) {
      case ChannelType.Internal: {
        const getter = (type: TicketType) => type.fieldSets.find((fSet) => fSet.id === 'customerInfo');
        const data = props.metaData.originalTicketData?.data ?? props.content;

        return (
          <div>
            <b>{t('COMMENT_METADATA_ORIGINAL_DATA')}</b>
            <hr />
            <hr />
            <br />
            <div>
              {data ? (
                <div className={'comment-icon-original-ticket-content-container'}>
                  <DataToContent
                    taskType={data?.taskData?.taskType}
                    origTicketData={data}
                    ticketTypes={props.ticketTypes}
                    channels={props.channels}
                    fieldGetter={getter}
                  />
                </div>
              ) : (
                <div>...</div>
              )}
            </div>
          </div>
        );
      }
      case ChannelType.Sms:
        return <SmsContent channel={ChannelType.Sms} metaData={props.metaData} showSender={false} />;
      case ChannelType.Email:
        return (
          <table>
            <tr>
              <td>
                <b>{t('COMMENT_METADATA_TITLE')}:</b>
              </td>
              <td>{props.title || props.metaData.title}</td>
            </tr>
            <tr>
              <td>
                <b>{t('COMMENT_METADATA_SENDER')}:</b>
              </td>
              <td>{props.metaData.from}</td>
            </tr>
            <tr>
              <td>
                <b>{t('COMMENT_METADATA_TARGETED_TO')}:</b>
              </td>
              {props.metaData.to && Array.isArray(props.metaData.to) ? (
                <td>
                  {props.metaData.to.map((to: string, index: number) => (
                    <span key={index}>{to}</span>
                  ))}
                </td>
              ) : (
                <td>...</td>
              )}
            </tr>
            {haveReplyTo !== undefined && haveReplyTo !== false && (
              <tr>
                <td>
                  <b>reply-to:</b>
                </td>
                <td>
                  {props.metaData.headers['reply-to'].value.map((replyTo: any, index: number) => (
                    <span key={index}>{replyTo.address}</span>
                  ))}
                </td>
              </tr>
            )}
            <tr>
              <td>
                <b>{t('COMMENT_MAIL_BCC')}:</b>
              </td>
              {props.metaData.bcc && Array.isArray(props.metaData.bcc) ? (
                <td>
                  {props.metaData.bcc.map((bcc: string, index: number) => {
                    return <span key={index}>{bcc}</span>;
                  })}
                </td>
              ) : (
                <td>...</td>
              )}
            </tr>
            <tr>
              <td>
                <b>{t('COMMENT_MAIL_CC')}:</b>
              </td>
              {props.metaData.cc && Array.isArray(props.metaData.cc) ? (
                <td>
                  {props.metaData.cc.map((bcc: string, index: number) => {
                    return <span key={index}>{bcc}</span>;
                  })}
                </td>
              ) : (
                <td>...</td>
              )}
            </tr>
            <tr>
              <td>
                <b>{t('COMMENT_MAIL_SENT_DATE')}:</b>
              </td>
              <td>{props.created}</td>
            </tr>
            <tr>
              <td>
                <b>{t('COMMMENT_METADATA_ATTACHMENTS')}: </b>
              </td>
              {props.metaData.attachments && Array.isArray(props.metaData.attachments) ? (
                <td>
                  <ol>
                    {props.metaData.attachments.map((attachment: Attachment, index: number) => {
                      return (
                        <li key={index}>
                          <b>{t('COMMENT_METADATA_TITLE')}:</b>
                          <div>{attachment.title}</div>
                          <b>{t('FILE_NAME')}:</b>
                          <div>{attachment.fileName}</div>
                          <b>{t('FILE_TYPE')}:</b>
                          <div>{attachment.fileType}</div>
                          <b>{t('DESCRIPTION')}:</b>
                          <div>{attachment.description}</div>
                        </li>
                      );
                    })}
                  </ol>
                </td>
              ) : (
                <td>...</td>
              )}
            </tr>
          </table>
        );
      // --
      case ChannelType.SecureEmail:
        return (
          <div>
            <div>
              <b>{t('COMMMENT_ICON_CONTENT_SECURE_EMAIL_CHANNEL')}</b>
              <hr />
              <b>{t('COMMENT_METADATA_TITLE')}</b>
              <div>{props.title || props.metaData.title}</div>
            </div>
            <div>
              <b>{t('COMMENT_METADATA_SENDER')}</b>
              <div>{props.metaData.from}</div>
            </div>
            <hr />
            <div>
              <b>{t('COMMENT_METADATA_TARGETED_TO')}</b>
              {props.metaData.to && Array.isArray(props.metaData.to) ? (
                <ol>
                  {props.metaData.to.map((to: string, index: number) => {
                    return <li key={`hear-email-preview-to-${index}`}>{to}</li>;
                  })}
                </ol>
              ) : (
                <div>...</div>
              )}
            </div>

            <div>
              <b>bcc</b>
              {props.metaData.bcc && Array.isArray(props.metaData.bcc) ? (
                <ol>
                  {props.metaData.bcc.map((bcc: string, index: number) => {
                    return <li key={index}>{bcc}</li>;
                  })}
                </ol>
              ) : (
                <div>...</div>
              )}
            </div>
            <div>
              <b>cc</b>
              {props.metaData.cc && Array.isArray(props.metaData.cc) ? (
                <ol>
                  {props.metaData.cc.map((bcc: string, index: number) => {
                    return <li key={index}>{bcc}</li>;
                  })}
                </ol>
              ) : (
                <div>...</div>
              )}
            </div>
            <div>
              <b>{t('COMMMENT_METADATA_ATTACHMENTS')}</b>
              {props.metaData.attachments && Array.isArray(props.metaData.attachments) ? (
                <ol>
                  {props.metaData.attachments.map((attachment: Attachment, index: number) => {
                    return (
                      <li key={index}>
                        <b>{t('COMMENT_METADATA_TITLE')}:</b>
                        <div>{attachment.title}</div>
                        <b>{t('FILE_NAME')}:</b>
                        <div>{attachment.fileName}</div>
                        <b>{t('FILE_TYPE')}:</b>
                        <div>{attachment.fileType}</div>
                        <b>{t('DESCRIPTION')}:</b>
                        <div>{attachment.description}</div>
                      </li>
                    );
                  })}
                </ol>
              ) : (
                <div>...</div>
              )}
            </div>
          </div>
        );
    }
  }

  return <ChannelItem text={channelLabels[props.channel] ?? defaultLabel} created={props.created} />;
};

export default HeaderEmailPreview;
