import React, { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Dropdown, Icon } from 'semantic-ui-react';

import type { ArchivalAction, ArchivalState } from './types';
import type { Template, TemplateArchivalJson } from 'src/types/Template';

interface ArchivalTemplatesProps {
  templates: Template<TemplateArchivalJson>[];
  localState: ArchivalState;

  dispatchState: Dispatch<ArchivalAction>;
}

const ArchivalTemplates = ({ templates, localState: { selectedTemplate }, dispatchState }: ArchivalTemplatesProps) => {
  const { t } = useTranslation();
  const [isConfigAccordionOpen, setConfigAccordionOpen] = useState(false);

  return (
    <>
      <h3>{t('management.templates_management.form.template.label')}</h3>

      <Dropdown
        fluid
        selection
        search={true}
        value={selectedTemplate}
        onChange={(_, data) => dispatchState({ type: 'SET_SELECTED_TEMPLATE', payload: data.value as string })}
        placeholder={t('CASE_ARCHIVAL_PICK_ARCHIVAL_TEMPLATE')}
        options={templates.map((template) => ({
          text: template.name,
          value: template.name
        }))}
      />

      {selectedTemplate && (
        <>
          <Accordion fluid styled style={{ marginTop: '10px' }}>
            <Accordion.Title
              onClick={() => setConfigAccordionOpen(!isConfigAccordionOpen)}
              active={isConfigAccordionOpen}
            >
              <Icon name="dropdown" />
              {t('top_bar.advanced_actions.archive.labels.preview_template')}
            </Accordion.Title>

            <Accordion.Content active={isConfigAccordionOpen}>
              <pre style={{ margin: 0, padding: '10px 15px' }}>
                {JSON.stringify(templates.find((template) => template.name === selectedTemplate)?.template, null, 4)}
              </pre>
            </Accordion.Content>
          </Accordion>
        </>
      )}
    </>
  );
};

export default ArchivalTemplates;
