import type { Direction, SortBy } from './sorting';
import type { TicketDirection } from './ticket';

export interface TabFilter {
  channel?: number[];
  taskType?: string[];
  taskTypeNOT?: string[];
  tags?: string[];
  categories?: number[];
  handledBy?: string;
  createdBy?: string;
  title?: string;
  handledByMe?: boolean;
  handledByMeOrNoOne?: boolean;
  delegatedToMe?: boolean;
  notDelegated?: boolean;
  delegates?: string[];
  dueDate24h?: boolean;
  dueDateToday?: boolean;
  dueDateOver?: boolean;
  priorities?: number[];
  tagsNO?: boolean;
  tagAND?: string[];
  tagNOT?: string[];
  tagCategoriesNOT?: number[];
  originalContact?: string;
  originalDirection?: TicketDirection;
  lastContactAddress?: string;
  createdAfter?: number;
  createdBefore?: number;
  touchedAfter?: number;
  touchedBefore?: number;
  dueDateAfter?: number;
  dueDateBefore?: number;
}

export enum FilterPresetType {
  global = 'global',
  personal = 'personal'
}

export enum FilterPresetView {
  main = 'main',
  infopage = 'infopage'
}

export type FilterPresetPayload = {
  name: string;
  filters: TabFilter;
  sorting: SortBy;
  direction: Direction;
  isFavourite: boolean;
  isActive: boolean;
  type: FilterPresetType;
  ticketTypes: number[];
  view: FilterPresetView;
  isDefault?: boolean;
};

export type FilterPreset = FilterPresetPayload & {
  id: number;
  createdBy: number;
  created: number;
  updated: number;
  updatedBy: number;
};
