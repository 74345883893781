import React from 'react';
import { Button, Icon, Label, Popup } from 'semantic-ui-react';

import type { Tag } from '@eeedo/types';
import type { VFC } from 'react';

import AllTags from './AllTags';
import * as styles from './TicketListItem.style';
import WorkStatusImageContainer from 'src/containers/WorkStatusImageContainer';

import type { TicketItemProps } from './TicketListItem';

const lengthsMap = { 1: 'TagMaxLength', 2: 'TagSplitLength' };
const getTagElementClassName = (tagsLength: number) => lengthsMap[tagsLength] ?? 'TagMoreThanTwoLength';

interface TicketMetaProps
  extends Pick<TicketItemProps, 'id' | 'taskType' | 'to' | 'from' | 'currentUserData' | 'type'> {
  sortedTags: Tag[];
  tagsLength: number;

  removeTicketLink(e: React.MouseEvent<HTMLButtonElement>): void;
}

const TicketMeta: VFC<TicketMetaProps> = ({
  id,
  sortedTags,
  taskType,
  type,
  to,
  from,
  tagsLength,
  currentUserData,
  removeTicketLink
}) => (
  <div className="ticket-meta">
    <div className="ticket-tags">
      <div style={styles.metaWrapper}>
        <Label className={'ticket-list-item-ticket-type-label ticketType-' + taskType}>{taskType}</Label>

        {sortedTags.slice(0, 2).map((tag, index) => {
          const style = styles.metaLabel({
            bgColor: tag.styles?.bgColor ? (tag.styles.bgColor as string) : '#e8e8e8',
            fontColor: tag.styles?.fontColor ? (tag.styles.fontColor as string) : 'rgba(0,0,0,.6)'
          });

          return (
            <Popup
              key={index}
              on={'hover'}
              hoverable={true}
              position="bottom right"
              content={<AllTags sortedTags={sortedTags} />}
              trigger={
                <Label
                  className={`ticketListTag ${getTagElementClassName(tagsLength)}`}
                  data-test={tag.name}
                  style={style}
                >
                  {tag.name}
                </Label>
              }
            />
          );
        })}
        {tagsLength > 2 && (
          <Popup
            on={'hover'}
            hoverable={true}
            position="bottom right"
            content={<AllTags sortedTags={sortedTags} />}
            trigger={
              <Label className="ellipsisLabel">
                <Icon fitted color="grey" name="ellipsis horizontal" />
              </Label>
            }
          />
        )}
      </div>
    </div>
    <div style={styles.metaButtonWrapper}>
      {to && from && (
        <Button
          negative
          size="mini"
          icon="times"
          style={styles.metaButton}
          onClick={removeTicketLink}
          className="removeTicketLinkingBtn"
        />
      )}

      {type !== 'infopage' && (
        <WorkStatusImageContainer userData={currentUserData} id={id} showStartWorkingOnButton={true} />
      )}
    </div>
  </div>
);

export default React.memo(TicketMeta);
