import type { AxiosResponse } from 'axios';

import { apiClient } from 'src/Utilities/httpClients';

export default class FacebookApi {
  static sendReply = async (parameters: {
    content: string;
    instagramCustomerId: string;
    pageId: string;
    replyToId: string;
    ticketId: string;
    imageID?: string;
  }): Promise<any> =>
    await apiClient.post(`/facebook/answerInstagram`, parameters).then((response: AxiosResponse<any>) => response.data);
}
