import { t } from 'i18next';
import queryString from 'query-string';
import { matchPath } from 'react-router';

import type { TabFilter } from '@eeedo/types';
import type { History } from 'history';
import type { Dispatch } from 'redux';

import { activateTab } from 'src/actions/tabActionsRTK';
import { clearTicketlistFilter, setTicketlistFilter } from 'src/actions/ticketListTabActionsRTK';
import { activateTicket } from 'src/actions/ticketsActions';
import ApiConfig from 'src/api/ApiConfig';
import { ServiceWorkerAction } from 'src/types/ServiceWorker';
import { getURLFilterParams } from 'src/Utilities/helper';
import { parseTicketNumber } from 'src/Utilities/parseUtils';

const routes = {
  ROUTE_CASE: '/case/:id',
  ROUTE_INFO_PAGE: '/infopage/:id',
  ROUTE_ACTION_PHONE_CONTACT: '/actions/phoneContact',
  ROUTE_WHATSAPP_SETTINGS: '/settings/whatsapp'
};

const matchRoute = (pathname: string) => {
  const [route] =
    Object.entries(routes).find(([, path]) =>
      matchPath(pathname, {
        path,
        exact: true
      })
    ) || [];
  return route;
};

const setFilters = (dispatch: Dispatch, search: string) => {
  dispatch(clearTicketlistFilter('MAIN_VIEW'));

  const urlSearchParams: TabFilter = getURLFilterParams(search);
  (Object.keys(urlSearchParams) as (keyof TabFilter)[]).forEach((parameter) =>
    dispatch(
      setTicketlistFilter({
        id: 'MAIN_VIEW',
        parameter,
        value: urlSearchParams[parameter]
      })
    )
  );
};

const isDebugMode = (urls: string[]) => {
  const { SW_DEBUG } = ApiConfig.getConfig();

  if (SW_DEBUG) {
    return true;
  }

  return urls.some((url) => {
    const { search } = new URL(url, window.location.origin);
    const { debug } = queryString.parse(search);
    return debug === 'true';
  });
};

const serviceWorkerClient = async (dispatch: Dispatch, history: History) => {
  const { pathname } = new URL(window.location.href);
  const isNewWindow = window.history.length === 1;
  let windowToClose: boolean;

  if (navigator.serviceWorker && isNewWindow && matchRoute(pathname)) {
    navigator.serviceWorker?.controller?.postMessage({
      action: ServiceWorkerAction.SW_GET_CLIENTS_COUNT_REQUEST
    });
  }

  navigator.serviceWorker.addEventListener('message', (event) => {
    const { action, payload = {} } = event.data || {};
    const debug = isDebugMode([window.location.href, payload.url]);

    if (debug) {
      console.debug('ServiceWorker received action: ', { action, payload });
    }

    if (action === ServiceWorkerAction.SW_GET_CLIENTS_COUNT && payload.clientsCount >= 2) {
      navigator.serviceWorker?.controller?.postMessage({
        action: ServiceWorkerAction.SW_OPEN_URL_REQUEST,
        payload: { url: window.location.href }
      });

      windowToClose = true;
    }

    if (action === ServiceWorkerAction.SW_OPEN_URL) {
      navigator.serviceWorker?.controller?.postMessage({
        action: ServiceWorkerAction.SW_OPEN_URL_SUCCESS
      });

      handleOpenUrl(dispatch, history, payload.url);
    }

    if (action === ServiceWorkerAction.SW_OPEN_URL_SUCCESS && windowToClose) {
      if (!debug) {
        window.onbeforeunload = null;
        window.close();
      }
    }
  });
};

const handleOpenUrl = async (dispatch: Dispatch, history: History, url: string) => {
  const { pathname, search } = new URL(url);

  switch (matchRoute(pathname)) {
    case 'ROUTE_CASE': {
      const ticketId = parseTicketNumber(url);

      dispatch(activateTicket(`TSK${ticketId}`));
      dispatch(activateTab(`TSK${ticketId}`));

      if (search) {
        setFilters(dispatch, search);
      }

      window.focus();
      break;
    }

    case 'ROUTE_INFO_PAGE': {
      const ticketId = parseTicketNumber(url);

      dispatch(activateTicket(`INF${ticketId}`));
      dispatch(activateTab(`INF${ticketId}`));

      if (search) {
        setFilters(dispatch, search);
      }

      window.focus();
      break;
    }
    case 'ROUTE_WHATSAPP_SETTINGS':
    case 'ROUTE_ACTION_PHONE_CONTACT': {
      history.push({ pathname, search });
      break;
    }
  }

  const notificationPermission = window.Notification.permission;
  if (notificationPermission === 'granted') {
    const notification = new self.Notification(t('openLinkNotification.title'), {
      body: t('openLinkNotification.body'),
      icon: '/favicons/favicon-32x32.png'
    });
    notification.onclick = () => window.focus();
  } /* else if (window['launchQueue']) {
    window.location.href = `web+eeedo://${url}`;
  }*/
};

export default serviceWorkerClient;
