import * as React from 'react';
import EnvSettings from './api/EnvSettings';

const ReportingPage = () => (
  <div style={{ margin: '10px' }}>
    <iframe
      title="Reporting"
      src={EnvSettings.getSettings().REPORTING_PAGE_URL}
      style={{ width: '100%', height: '90vh' }}
    />
  </div>
);

export default ReportingPage;
