import type { PersonalData } from '@eeedo/types';

import FeatureFlags from 'src/api/FeatureFlags';

export type SignInType = 'password' | 'SSO' | 'link';

export type UserCustomTicketOrdering = {
  fixPerTicketType: {
    id: number;
    ticketTypeId: number;
    ticketTypeName: string;
    UID: number;
    duedate_fix: number;
  }[];
};

export class Roles {
  static readonly Admin = new Roles(1, 'Admin', 'ROL1');
  static readonly Supervisor = new Roles(2, 'Supervisor', 'ROL2');
  static readonly Blocked = new Roles(3, 'Blocked', 'ROL3');
  static readonly User = new Roles(4, 'User', 'ROL4');
  static readonly Limited = new Roles(5, 'Limited', 'ROL5');
  static readonly Delegated = new Roles(6, 'Delegated', 'ROL6');
  static readonly DelegatedStrict = new Roles(7, 'DelegatedStrict', 'ROL7');
  static readonly ReadOnly = new Roles(8, 'ReadOnly', 'ROL8');
  static readonly ReadOnlySingleTicker = new Roles(9, 'ReadOnlySingleTicket', 'ROL9');
  static readonly SuperAdmin = new Roles(10, 'superAdmin', 'ROL10');

  private constructor(readonly id: number, readonly name: string, readonly rolName: string) {
    this.dbName = name.charAt(0).toLowerCase() + name.slice(1);
  }

  dbName: string;

  static isChatAnchored(user: PersonalData): boolean {
    if (user.profile.chatAnchored === null) {
      return FeatureFlags.isFlagOn('CHAT_ANCHOR_TICKET_BY_DEFAULT');
    } else {
      return user.profile.chatAnchored;
    }
  }

  static idToNumber(id: number | string): number {
    if (typeof id === 'number') {
      return id;
    }

    if (id.toUpperCase().startsWith('ROL')) {
      id = id.substring(3);
    }

    return Number(id);
  }

  static idsEqual(a: number | string, b: number | string) {
    a = Roles.idToNumber(a);
    b = Roles.idToNumber(b);

    return a === b;
  }

  static isAnyOf(original: number | string, ...others: (number | string | Roles)[]): boolean {
    for (let other of others) {
      if (other instanceof Roles) {
        other = other.id;
      }

      if (this.idsEqual(original, other)) {
        return true;
      }
    }

    return false;
  }

  static isAdminLike(role: number | string): boolean {
    return Roles.isAnyOf(role, Roles.Admin, Roles.Supervisor, Roles.SuperAdmin);
  }

  static isAdmin(role: number | string): boolean {
    return Roles.isAnyOf(role, Roles.Admin, Roles.SuperAdmin);
  }

  static isSuperAdmin(role: number | string): boolean {
    return Roles.isAnyOf(role, Roles.SuperAdmin);
  }

  static isBlocked(role: number | string): boolean {
    return Roles.isAnyOf(role, Roles.Blocked);
  }
}

export interface PasswordPolicy {
  passwordLength: [number, number];
  requirements: {
    smallLetters: boolean;
    bigLetters: boolean;
    specialChars: boolean;
    numbers: boolean;
    howManyRequirementsMustBeFulfilled: number;
  };
}
