import React, { useEffect, useState } from 'react';
import { usePapaParse } from 'react-papaparse';
import { Table } from 'semantic-ui-react';
import * as styles from './CSVViewer.style';
import ItemWrapper from '../ItemWrapper/ItemWrapper';

import './CSVViewer.css';
import { VALID_HTTP_CODES } from '../../../../Utilities/lightbox';

type ParseError = Parameters<
  Parameters<ReturnType<typeof usePapaParse>['readString']>[1]['complete']
>[0]['errors'][number];

interface CSVViewerProps {
  src: string;
}

const CSVViewer = ({ src }: CSVViewerProps) => {
  const { readString } = usePapaParse();
  const [data, setData] = useState<string[][] | undefined>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<ParseError | Error | undefined>();
  const parseCsv = (csv: string) =>
    new Promise<string[][]>((resolve, reject) => {
      readString<string[]>(csv, {
        worker: true,
        complete: (results) => {
          if (results.errors.length && !data?.length) {
            reject(new Error(results.errors[0].message));
          }

          resolve(results.data);
        }
      });
    });

  useEffect(() => {
    const controller = new AbortController();

    if (src) {
      (async () => {
        try {
          const response = await fetch(src, { credentials: 'include', signal: controller.signal });
          if (!VALID_HTTP_CODES.includes(response.status)) {
            throw new Error(response.statusText);
          }
          const csv = await response.text();
          const data = await parseCsv(csv);

          setData(data.slice(0, 100));
        } catch (err) {
          if ((err as any).name !== 'AbortError') {
            setError(err as any);
          }
        }

        setLoaded(true);
      })();
    }

    return () => {
      controller.abort();
    };
  }, [src, loaded]);

  return (
    <ItemWrapper
      loaded={loaded}
      hasError={!!error && !data?.length}
      reload={() => {
        setError(undefined);
        setLoaded(false);
      }}
    >
      <div className="csv-viewer-wrapper" style={styles.wrapper}>
        {!!data?.length && (
          <Table celled>
            <Table.Body>
              {data.map((row, rowIndex) => (
                <Table.Row key={`csv-viewer-row-${rowIndex}`}>
                  {row.map((cell, cellIndex) => (
                    <Table.Cell key={`csv-viewer-cell-${cellIndex}`}>{cell}</Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </ItemWrapper>
  );
};

export default React.memo(CSVViewer);
