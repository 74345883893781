import React, { useState, useEffect } from 'react';
import iziToast from 'izitoast';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ApiConfig from './api/ApiConfig';

let interval: NodeJS.Timer;

const OutdatedVersionModal = () => {
  const { t } = useTranslation();
  const updateAppHandler = () => window.location.reload();
  const postponeHandler = () => {
    ApiConfig.setMetaDataVersionPostpone(true);
    setOpen(false);
    iziToast.info({
      message: t('OUTDATED_VERSION_MODAL_TOAST'),
      timeout: 2500
    });
    interval = setInterval(checkVersion, 300000);
  };

  const checkVersion = async () => {
    await ApiConfig.fetchMetadata();
    if (!ApiConfig.isRunningCurrentVersion() && !ApiConfig.isVersionCheckPostponed) {
      setOpen(true);
      clearInterval(interval);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkVersion();
      interval = setInterval(checkVersion, 300000);
    }, 10000);

    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  const [isOpen, setOpen] = useState(false);

  return (
    <Modal open={isOpen} closeIcon onClose={postponeHandler}>
      <Header icon="warning sign" content={t('OUTDATED_VERSION_MODAL_TITLE')} />
      <Modal.Content>
        {t('OUTDATED_VERSION_MODAL_DESCRIPTION')}
        <ul>
          {/*<li>{t("OUTDATED_VERSION_MODAL_NEWEST")}: {ApiConfig.getVersion()}</li>*/}
          <li>
            {t('OUTDATED_VERSION_MODAL_YOUR')}: <strong>{import.meta.env.VITE_CONTAINER_IMAGE}</strong>
          </li>
        </ul>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={postponeHandler}>
          <Icon name="history" /> {t('OUTDATED_VERSION_MODAL_POSTPONE')}
        </Button>
        <Button color="blue" onClick={updateAppHandler}>
          <Icon name="refresh" /> {t('OUTDATED_VERSION_MODAL_UPDATE_NOW')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OutdatedVersionModal;
