import * as React from 'react';
import { Icon } from 'semantic-ui-react';

import type { Channel, TicketDirection } from '@eeedo/types';
import type { CSSProperties } from 'react';

import { iconName } from 'src/Utilities/iconName';

interface CommentProps {
  channel: Channel | undefined;
  style: CSSProperties;
  originalDirection: TicketDirection;
}

const CommentIcon = (props: CommentProps) => {
  const name = iconName(props.channel, props.originalDirection);

  return <Icon name={name} className={'icon-medium'} style={props.style} />;
};

export default CommentIcon;
