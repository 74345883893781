import React from 'react';
import TicketList from 'src/Components/ticketList/TicketList';
import type { TicketListTicket } from 'src/types/Ticket';

export const mockTicketList: TicketListTicket[] = [];

export default function EntityTickets(props: any) {
  return (
    <div style={{ flex: '1 1 auto', height: '100%' }}>
      <TicketList tickets={props.entityTickets} type="task" active />
    </div>
  );
}
