import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Icon } from 'semantic-ui-react';

import type { UserWithProfile } from '@eeedo/types';
import type { WithTranslation } from 'react-i18next';

import CopyToClipboardIcon from '../generic/CopyToClipboardIcon';
import UserAvatar from './UserAvatar';

interface UserProfileProps extends WithTranslation {
  user?: UserWithProfile;
  botName?: string;
}

class UserProfile extends React.Component<UserProfileProps> {
  render() {
    const { user, botName } = this.props;

    if (!user) {
      return <div>Failed to load userprofile!</div>;
    }

    const { profile, visibility } = user;

    return (
      <Card style={{ boxShadow: 'none' }}>
        <Card.Content>
          <div style={{ float: 'right' }}>
            <UserAvatar user={user} round size="70" />
          </div>
          <Card.Header as="h4">
            <Icon size="small" name="circle" color={visibility === 'offline' ? 'red' : 'green'} />
            {botName ?? ` ${profile.firstName} ${profile.lastName}`}
          </Card.Header>
          <Card.Meta>
            <Icon name="mail" />
            <span style={{ width: '130px', wordBreak: 'break-all' }}> {profile.email} </span>
            <CopyToClipboardIcon text={profile.email} />
            <br />
            {(profile.phoneWork || profile.phoneHome) && (
              <>
                <Icon name="phone" />
                <span> {profile.phoneWork || profile.phoneHome}</span>
                <span> {profile?.unit}</span>
                <CopyToClipboardIcon text={profile.phoneWork || profile.phoneHome} />
              </>
            )}
          </Card.Meta>
          <Card.Description>
            {profile.description !== '' ? profile.description : <i>{this.props.t('NO_DESCRIPTION')}</i>}
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

export default withTranslation()(UserProfile);
