import * as React from 'react';
import type ChannelType from './ChannelType';

export interface WhatsappContentProps {
  channel: ChannelType;
  metaData: Record<string, any>;
}

export function WhatsappContent(props: WhatsappContentProps) {
  // TODO: we got more
  return (
    <div>
      <b>Whatsapp</b>
      <hr />
      <div>Phone: {props.metaData.phone}</div>
    </div>
  );
}
