import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Translation } from 'react-i18next';

import type { ComponentProps, FC } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import './DateTimePicker.css';

type OnDateChange = ComponentProps<typeof DatePicker>['onChange'];

interface DateTimePickerProps {
  value?: number | null;
  trigger?: React.ReactElement;
  isClearable?: boolean;
  inline?: boolean;
  minDate?: moment.Moment;
  dateFormat?: string;

  onChange: (date: number | null) => void;
}

const DateTimePicker: FC<DateTimePickerProps> = ({
  value,
  inline,
  trigger,
  minDate,
  dateFormat,
  isClearable,
  onChange
}) => {
  const [currentValue, setCurrentValue] = useState<Date | null>(value ? moment.unix(value).toDate() : null);

  useEffect(() => {
    setCurrentValue(value ? moment.unix(value).toDate() : null);
  }, [value]);

  const onDateChange = useCallback<OnDateChange>(
    (value) => {
      setCurrentValue(value as Date);

      if (value === null) {
        onChange(null);
      } else {
        onChange(moment(value as Date).unix());
      }
    },
    [onChange]
  );

  return (
    <Translation ns="translations">
      {(t) => (
        <DatePicker
          showTimeSelect
          showWeekNumbers
          timeFormat="HH:mm"
          timeIntervals={15}
          calendarStartDay={1}
          todayButton={t('TODAY')}
          inline={!!inline}
          customInput={trigger}
          selected={currentValue}
          isClearable={isClearable}
          minDate={minDate?.toDate()}
          dateFormat={dateFormat ?? 'LLLL'}
          onChange={onDateChange}
        />
      )}
    </Translation>
  );
};

export default DateTimePicker;
