import { isEqual, uniqWith } from 'lodash-es';

import type { RpaRuleNodeDelaySimple, RpaRuleTrigger, Suggestion, TicketType } from '@eeedo/types';
import type { DropdownItemProps } from 'semantic-ui-react';

const rulesToAIFunctionConflictMapping: { [key: string]: string[] } = {
  delegation_added: ['comment.'],
  delegation_removed: ['comment.'],
  entity_attached: ['comment.'],
  comment: [],
  status_changed: ['comment.'],
  taskType_changed: ['comment.'],
  tag_added: ['comment.'],
  call_rescheduled: ['comment.']
};

export const validateAIFunctionPerRPAEvent = (aiFunction: Suggestion, trigger?: RpaRuleTrigger) => {
  return trigger && rulesToAIFunctionConflictMapping[trigger].some((conflict) => aiFunction.value.includes(conflict));
};

export const getEntityTypes = (ticketTypes: TicketType[]): DropdownItemProps[] => {
  const entityTypes: { text: string; value: any }[] = [];

  ticketTypes
    .filter((ticketType) => ticketType.entityRouting?.length)
    .forEach((ticketType) => {
      ticketType.entityRouting!.forEach((entityRouting) => {
        entityTypes.push({
          text: entityRouting.displayName,
          value: entityRouting.entityType
        });
      });
    });
  return uniqWith(entityTypes, isEqual);
};

export const getEntitiesFieldsOptions = (ticketTypes: TicketType[]): DropdownItemProps[] => {
  const entityFields: { text: string; value: any; key: number }[] = [];

  let key = 0;
  ticketTypes
    .filter((ticketType) => ticketType.entityRouting?.length)
    .forEach((ticketType) =>
      ticketType.entityRouting?.forEach((entityRouting) =>
        ticketType.fieldSets.forEach((fieldSet) => {
          const entityType = fieldSet.id !== entityRouting.entityType ? 'customerInfo' : entityRouting.entityType;
          if (fieldSet.group === 'entity' && entityType) {
            fieldSet[entityType].forEach((field: { name: string; value: string }) =>
              entityFields.push({
                key: key++,
                text: `${entityType} - ${field.name}`,
                value: {
                  _type: entityType === 'customerInfo' ? 'eeedoCustomer' : entityType,
                  field: field.value
                }
              })
            );
          }
        })
      )
    );

  return uniqWith(entityFields, isEqual);
};

export const getCaseDetailsFields = (ticketTypes: TicketType[]): DropdownItemProps[] => {
  const dropdownOptions: { text: string; value: any; key: number }[] = [];

  let key = 0;
  ticketTypes.forEach((ticketType) => {
    ticketType.fieldSets
      .filter(({ group }) => group === 'CaseDetails')
      .forEach((fieldSet) => {
        if (!fieldSet[fieldSet.id]) return;

        fieldSet[fieldSet.id].forEach((field: { name: string; value: string }) => {
          dropdownOptions.push({
            key: key++,
            text: `${fieldSet.displayName} - ${field.name}`,
            value: {
              _type: 'CaseDetails',
              field: field.value
            }
          });
        });
      });
  }, []);

  return dropdownOptions;
};

export const getNodeDelayMsValue = (nodeDelay: Partial<RpaRuleNodeDelaySimple>) => {
  return nodeDelay.delayMs ? nodeDelay.delayMs / 1000 : 0;
};
