import { Input } from 'semantic-ui-react';
import * as React from 'react';
import type { TFunction } from 'i18next';
import type { CSSProperties } from 'react';

interface OriginalContactProps {
  tr: TFunction;
  originalContactFilter?: string;
  onChangeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
}

function OriginalContactFilter(props: OriginalContactProps) {
  return (
    <Input
      id="FILTER_BY_ORIGINAL_CONTACT"
      placeholder={props.tr('FILTER_BY_ORIGINAL_CONTACT')}
      icon="search"
      value={props.originalContactFilter || ''}
      onChange={props.onChangeFilter}
      fluid
      className="filterInputHover"
      style={props.style}
    />
  );
}

export default OriginalContactFilter;
