import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface SpinnerProps {
  taskId: string;
  className: string;
}

const Spinner = ({ taskId, className }: SpinnerProps) => {
  const { t } = useTranslation();

  return (
    <span className={className}>
      <Icon loading name="spinner" />
      {taskId}: {t('CASE_SURVEY_LOADING')}
    </span>
  );
};

export default Spinner;
