import { t } from 'i18next';
import iziToast from 'izitoast';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { FETCH_EIDENTIFICATION_RELATON_SUCCESS } from '.';
import { startAjaxCall } from './ajaxStatusActions';
import EIdentificationApi from 'src/api/EIdentificationApi';

export const fetchEIDRelationSuccess = createAction<{ contentId: number; eIdentificationRelationHash: string }>(
  FETCH_EIDENTIFICATION_RELATON_SUCCESS
);

export const fetchEIDRelation = createAsyncThunk<
  { status: 'success' | 'failed' },
  { contentId: number; eIdentificationRelationHash: string },
  { rejectValue: { status: 'failed' } }
>('fetchEIDRelation', async ({ contentId, eIdentificationRelationHash }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'FETCH_EIDENTIFICATION_RELATION', id: contentId }));
  try {
    const result = await EIdentificationApi.verifyEIdentificationRelation(contentId, eIdentificationRelationHash);
    if (result.status === 'success') {
      dispatch(fetchEIDRelationSuccess({ contentId, eIdentificationRelationHash }));
      return { status: 'success' };
    }

    throw new Error('Failed to verify E-Idenfitication relation');
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('widgets.eIdentification.notifications.verification_failed'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'failed' };
  }
});
