import { createReducer } from '@reduxjs/toolkit';
import { activateIFrameTab, removeCaseIFrame, setCaseIFrame } from '../actions/caseIFrameActions';
import { closeTab } from 'src/actions/tabActionsRTK';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';

interface IFrameItem {
  url: string;
  activeTab: 'case' | 'iframe';
  tabTitle: string;
}

const initialState: CaseIFrameUrlState = {
  frames: new Map<number, IFrameItem>()
};

export interface CaseIFrameUrlState {
  frames: Map<number, IFrameItem>;
}

const caseIFrameUrlReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCaseIFrame, (state, action) => {
      const frames = new Map<number, IFrameItem>(state.frames);
      frames.set(action.payload.id, {
        url: action.payload.url,
        activeTab: 'iframe',
        tabTitle: action.payload.tabTitle
      });
      state.frames = frames;
    })
    .addCase(removeCaseIFrame, (state, action) => {
      const frames = new Map<number, IFrameItem>(state.frames);
      frames.delete(action.payload);
      state.frames = frames;
    })
    .addCase(closeTab, (state, action) => {
      const frames = new Map<number, IFrameItem>(state.frames);
      frames.delete(taskIdToNumericalId(action.payload));
      state.frames = frames;
    })
    .addCase(activateIFrameTab, (state, action) => {
      const frames = new Map<number, IFrameItem>(state.frames);
      const frame = frames.get(action.payload.id);
      if (!frame) {
        return;
      }

      frame.activeTab = action.payload.activeTab;
      state.frames = frames;
    });
});

export default caseIFrameUrlReducer;
