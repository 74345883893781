import {
  UPDATE_INITIAL_REQUEST_STATUS_FAILURE,
  UPDATE_INITIAL_REQUEST_STATUS_LOADING,
  UPDATE_INITIAL_REQUEST_STATUS_SUCCESS
} from './index';

import type { initialRequestsTypes } from 'src/Components/InitialLoad/InitialLoad';

export const initialRequestSuccess = (type: initialRequestsTypes) => {
  return { type: UPDATE_INITIAL_REQUEST_STATUS_SUCCESS, payload: { type: type, isCompleted: true } };
};

export const initialRequestFailure = (type: initialRequestsTypes, error: Error | unknown) => {
  return { type: UPDATE_INITIAL_REQUEST_STATUS_FAILURE, payload: { type: type, error: error } };
};

export const setInitialRequestToLoading = (type: initialRequestsTypes) => {
  return { type: UPDATE_INITIAL_REQUEST_STATUS_LOADING, payload: { type: type } };
};
