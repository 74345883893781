import * as React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import type { SearchCriterion } from 'src/types/Search';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import DateTimePicker from '../DateTimePicker';

interface DateSelectorProps {
  value: any;
  textRight?: string;

  // value is the unix timestamp prefixed with TIMESTAMP, for Search.tsx
  onChange: (timestamp: string | undefined) => void;
}

export const timePrefix = 'TIMESTAMP';

const DateSelector = ({ value, textRight, onChange }: DateSelectorProps) => {
  const date = timestampPrefixToDate(value);
  const displayedDate = date ? getPrettyDate(date, { format: DATE_TIME_FORMAT }) : '';

  return (
    <>
      <div className={'ui buttons'}>
        <DateTimePicker
          trigger={
            <div>
              <Button
                style={{ fontSize: '12px' }}
                className={'ui button basic surveyDateBtn'}
                onClick={(e) => e.preventDefault()}
              >
                {displayedDate}
              </Button>
            </div>
          }
          onChange={(data) => {
            onChange(dateToTimestampPrefix(data));
          }}
          value={timestampPrefixToDate(value) || undefined}
        />
        <Button className={'ui icon button compact'} onClick={() => onChange(undefined)}>
          <Icon name={'delete'} />
        </Button>
      </div>
      {textRight && <span style={{ marginLeft: '0.5rem' }}>{textRight}</span>}
    </>
  );
};

export const criteriaToTimestamp = (criteria: SearchCriterion[], param: string) => {
  const criterion = criteria.find((criterion) => criterion.param === param)?.value;
  return dateToTimestampPrefix(criterion);
};

export const dateToTimestampPrefix = (date: string | number | null | undefined): string => {
  if (typeof date === 'number' || date == null || !date.startsWith(timePrefix)) {
    return `${timePrefix}${date}`;
  }

  return date;
};

export const timestampPrefixToDate = (timestamp: string | number | undefined): number => {
  if (timestamp == null || typeof timestamp === 'number') {
    timestamp = `TIMESTAMP${timestamp}`;
  }

  if (timestamp.startsWith(timePrefix)) {
    timestamp = timestamp.substring(timePrefix.length);
  }

  return Number(timestamp);
};

export default DateSelector;
