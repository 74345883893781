import React from 'react';
import IFrameView from './IFrameView';

interface TxtViewerProps {
  src: string;
}

const TxtViewer = ({ src }: TxtViewerProps) => <IFrameView src={src} type="text/plain" />;

export default React.memo(TxtViewer);
