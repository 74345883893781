import { cloneDeep } from 'lodash-es';
import moment from 'moment';

import type { DateShiftConfig } from '@eeedo/types';
import type { TFunction } from 'i18next';

import { timestampPrefixToDate } from '../Components/Case/DateSelector';
import { camelToSnakeCase } from './casing';
import EnvSettings from 'src/api/EnvSettings';

import type { FormattedSearch, SearchCriterion } from 'src/types/Search';

const timestampParams: (keyof FormattedSearch['basic'])[] = [
  'createdAfter',
  'createdBefore',
  'touchedAfter',
  'touchedBefore',
  'duedateAfter',
  'duedateBefore'
];

const formatMultiSearch = (searchValue: (string | number)[] | null | undefined, obj: SearchCriterion): void => {
  if (searchValue == null) {
    if (!Array.isArray(obj.value)) {
      obj.value = [obj.value];
    }
  } else if (typeof obj.value === 'string') {
    obj.value = searchValue.concat([obj.value]);
  } else {
    obj.value = searchValue.concat(obj.value);
  }
};

const formatTimestampPrefix = (acc: FormattedSearch, obj: SearchCriterion): void => {
  acc.basic[obj.param] = timestampPrefixToDate(obj.value);
};

export const formatSearch = (searchCriteria: SearchCriterion[], userId: string): FormattedSearch => {
  const baseObject: FormattedSearch = {
    basic: {
      limit: EnvSettings.getSettings().MAX_SEARCH_RESULTS
    },
    details: {},
    entity: {}
  };

  return searchCriteria.reduce((acc: FormattedSearch, objOrig) => {
    const obj = cloneDeep(objOrig);
    if (obj.param === 'workedBy') {
      obj.value = [parseInt(userId.slice(3), 10)];
    }

    if (obj.param === 'delegatedToMe') {
      obj.value = parseInt(userId.slice(3), 10).toString();
      obj.param = 'delegatedTo';
    }

    const param = obj.param as keyof FormattedSearch['basic'];
    switch (param) {
      case 'ticketTypesOr':
      case 'contactChannel':
      case 'statuses':
      case 'selectedTicketTagsAnd':
      case 'selectedTicketTagsOr':
      case 'selectedTicketTagsNot':
      case 'selectedTicketTagCategoriesOr':
      case 'workedByUsers':
      case 'delegatedTo':
        formatMultiSearch(acc.basic?.[param], obj);
        break;
    }

    if (timestampParams.includes(obj.param as keyof FormattedSearch['basic'])) {
      formatTimestampPrefix(acc, obj);
      return acc;
    }

    switch (obj.datagroup) {
      case 'basic':
        acc.basic[obj.param] = obj.value;
        break;
      case 'CaseDetails':
      case 'case':
      case 'detail':
        acc.details[obj.param] = obj.value;
        break;
      case 'entity':
        if (obj.object) {
          acc.entity[obj.object + '.' + obj.param] = obj.value;
        } else {
          acc.entity[obj.param] = obj.value;
        }
        break;
      default:
        break;
    }

    return acc;
  }, baseObject);
};

export const getSearchFieldTranslation = (fieldName: string, t: TFunction) => {
  const fieldNameToTranslationMapping = {
    titleSearch: t('search.ticket.title'),
    selectedTicketWords: t('search.ticket.content'),
    entityId: t('search.entity.id'),
    ticketTypesOr: t('search.ticket.type_or'),
    contactChannel: t('search.ticket.channel'),
    selectedTicketTagsAnd: t('search.ticket.tags_and'),
    selectedTicketTagsOr: t('search.ticket.tags_or'),
    selectedTicketTagsNot: t('search.ticket.tags_not'),
    statuses: t('GENERAL_STATUS'),
    selectedTicketTagCategoriesOr: t('search.ticket.tag_categories_or'),
    searchToggleAndOr: t('search.exclusive'),
    originalContactSearch: t('search.original_contact'),
    lastContactAddress: t('search.email.last_contact_address'),
    emailTo: t('search.email.recipient'),
    emailFrom: t('search.email.sender'),
    emailCc: t('search.email.cc'),
    ticketAuthorId: t('search.ticket.author'),
    ticketEditedById: t('search.last_edited_by'),
    showOnlyNotReady: t('search.ticket.dont_show_done'),
    workedBy: t('search.worked_by_me'),
    delegatedToMe: t('search.delegated_to_me'),
    workedByUsers: t('search.worked_by_users'),
    delegatedTo: t('search.delegated_to_users'),
    originalDirection: t('search.original_direction')
  };

  timestampParams.forEach(
    (element) => (fieldNameToTranslationMapping[element] = t(`search.date.${camelToSnakeCase(element)}`))
  );

  return fieldNameToTranslationMapping[fieldName];
};

export const prepareDateDefaultFieldvalue = ({
  value,
  dateShiftConfig
}: {
  value: string | number;
  dateShiftConfig: DateShiftConfig | undefined;
}) => {
  if (!dateShiftConfig) {
    const timestamp = typeof value === 'string' ? parseInt(value) : value;
    return timestamp;
  }

  switch (dateShiftConfig.operator) {
    case 'add': {
      return moment().add(dateShiftConfig.amount, dateShiftConfig.unit).unix();
    }

    case 'subtract': {
      return moment().subtract(dateShiftConfig.amount, dateShiftConfig.unit).unix();
    }

    default: {
      console.error('[DefaultSearchConfig]: date type config has wrong operator');
      return moment().unix();
    }
  }
};
