import type { VFC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Popup } from 'semantic-ui-react';

import FeatureFlags from 'src/api/FeatureFlags';
import { setShowImages } from 'src/reducers/commentsMenuReducer';
import type { State } from 'src/types/initialState';

const ImagesToggle: VFC = () => {
  const { t } = useTranslation();
  const contentId = useSelector((state: State) => state.activeTicketTab);
  const showImages = useSelector((state: State) =>
    state.commentsMenu.showImages === undefined
      ? FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES')
      : state.commentsMenu.showImages
  );
  const dispatch = useDispatch();
  if (!contentId) {
    return null;
  }
  const onClick = () => {
    dispatch(setShowImages(!showImages));
  };

  return (
    <Popup
      trigger={
        <Button icon size="tiny" onClick={onClick}>
          <Icon color={showImages ? 'green' : 'grey'} name="images" />
        </Button>
      }
    >
      <Popup.Content>{showImages ? t('comments.popups.hide_images') : t('comments.popups.show_images')}</Popup.Content>
    </Popup>
  );
};

export default React.memo(ImagesToggle);
