import { postMessageToIFrame } from './postMessageToIFrame';

export const callMitel = (phoneNumber: string) => {
  postMessageToIFrame('MitelIntegration', {
    apiType: 'CrmConnector',
    apiVersion: '1.2.0',
    methodName: 'MakeCall',
    arguments: {
      // Mitel API accepts only number converted to string
      destination: phoneNumber.toString()
    }
  });
};
