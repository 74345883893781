import type { WhatsappConfigurationData } from './types';

export default class WhatsappConfigurationApi {
  public static getWhatsappConfigurations = (): Promise<WhatsappConfigurationData[]> =>
    fetch(this.url(), { credentials: 'include' }).then((res) => res.json());

  public static upsertWhatsappConfiguration = ({
    channel,
    phone,
    name,
    signal
  }: {
    channel: string;
    phone: string;
    name: string;
    signal: AbortSignal;
  }) =>
    fetch(this.url(), {
      credentials: 'include',
      signal,
      method: 'POST',
      body: JSON.stringify({ channel, phone, name }),
      headers: { 'Content-Type': 'application/json' }
    }).then((res) => {
      if (!res.ok) {
        throw new Error('Failed to upsert whatsapp configuration');
      }
      return res;
    });

  private static url = () => `/whatsapp/configurations`;
}
