import queryString from 'query-string';

import type { LoginStrategy, PersonalData, Role, UserWithProfile } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

import type { ChangePasswordData } from '../types/ApiRequests';
import type { PasswordPolicy } from '../types/User';

type CustomTicketOrdering = {
  fixPerTicketType: {
    id: number;
    UID: number;
    ticketTypeId: number;
    ticketTypeName: string;
    duedate_fix: number;
  }[];
};

export type CustomTicketOrderingInput = {
  fixPerTicketType: {
    ticketTypeId: number;
    duedate_fix: number;
  }[];
};

export default class UsersApi {
  static getUsers = async (): Promise<UserWithProfile[]> => {
    return apiClient.get('/usersWithData').then((response) => response.data);
  };

  static getUser = async (id: number): Promise<UserWithProfile> => {
    return apiClient.get(`/user/${id}`).then((response) => response.data.user);
  };

  static getRoles = async (): Promise<Role[]> => {
    return apiClient.get('/roles').then((response) => response.data);
  };

  static getPersonalData = async (): Promise<PersonalData> => {
    return apiClient.get('/personalData').then((response) => response.data);
  };

  static createUser = async (data: any): Promise<UserWithProfile> => {
    return apiClient.post('/users', data).then((response) => response.data);
  };

  static changePassword = async (data: ChangePasswordData) => {
    return apiClient.put('/password', data).then((response) => response.data);
  };

  static setTicketAnchor = async (chatAnchored: boolean) => {
    return apiClient.patch('/setChatAnchor', { chatAnchored }).then((response) => response.data);
  };

  static patchUserProfile = async (data: any) => {
    return apiClient.patch(`/users/${data.UID}/profile`, data.data).then((response) => response.data);
  };

  static addTicketType = async (user: number, ticketType: number) => {
    return apiClient.put(`/users/${user}/ticketType/${ticketType}`);
  };

  static removeTicketType = async (user: number, ticketType: number) => {
    return apiClient.delete(`/users/${user}/ticketType/${ticketType}`);
  };

  static addChannel = async (user: number, channel: number) => {
    return apiClient.put(`/users/${user}/channel/${channel}`);
  };

  static removeChannel = async (user: number, channel: number) => {
    return apiClient.delete(`/users/${user}/channel/${channel}`);
  };

  static addForbiddenLoginStrategy = async (user: number, strategy: LoginStrategy) => {
    return apiClient.put(`/users/${user}/forbiddenLoginStrategy/${strategy}`);
  };

  static removeForbiddenLoginStrategy = async (user: number, strategy: LoginStrategy) => {
    return apiClient.delete(`/users/${user}/forbiddenLoginStrategy/${strategy}`);
  };

  static patchUserNotifications = async (data: any) => {
    return apiClient.put('/user/notifications', data.data).then((response) => response.data);
  };

  static getPasswordPolicy = async (): Promise<PasswordPolicy> => {
    return apiClient.get<PasswordPolicy>('/passwordPolicy').then((response) => {
      switch (typeof response.data) {
        case 'string': {
          return JSON.parse(response.data);
        }

        default: {
          return response.data;
        }
      }
    });
  };

  static getPasswordPolicyNoAuth = async (token: string, uid: number): Promise<PasswordPolicy> => {
    const queryParams = queryString.stringify({ token, UID: uid });
    return apiClient.get<PasswordPolicy>(`/passwordPolicyNoAuth?${queryParams}`).then((response) => response.data);
  };

  static getCustomTicketOrdering = async (uid: number): Promise<CustomTicketOrdering> => {
    const url = `/users/${uid}/customTicketOrdering`;
    const res = await apiClient.get(url);
    return res.data;
  };

  static setCustomTicketOrdering = async (uid: number, data: CustomTicketOrderingInput) => {
    const url = `/users/${uid}/customTicketOrdering`;
    await apiClient.post(url, data);
  };
}
