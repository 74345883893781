import { FETCH_TICKET_PRIORITIES_SUCCESS } from '../actions';

const initialState = {
  ticketPriorities: []
};

const ticketPrioritiesReducer = (state: any = initialState.ticketPriorities, action: any) => {
  switch (action.type) {
    case FETCH_TICKET_PRIORITIES_SUCCESS: {
      return [...state, ...action.payload.priorities];
    }
    default:
      return state;
  }
};

export default ticketPrioritiesReducer;
