import { Icon, Menu, Message } from 'semantic-ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface RelationsErrorMessageProps {
  setShowError: (show: boolean) => void;
}

export default function (props: RelationsErrorMessageProps) {
  const { t } = useTranslation();

  return (
    <Menu vertical={true} className="sideMenu">
      <div style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '1em' }}>
        <Message icon={true} negative={true} onDismiss={() => props.setShowError(false)}>
          <Icon name="warning sign" />
          <Message.Content>
            <Message.Header>{t('ERROR_HAPPENED')}</Message.Header>
            <p>{t('TICKETLIST_FETCH_FAILED')}</p>
          </Message.Content>
        </Message>
      </div>
    </Menu>
  );
}
