import type { Webhook } from '@eeedo/types';
import type { AxiosResponse } from 'axios';

import { apiClient } from 'src/Utilities/httpClients';

export default class WebhooksApi {
  static getWebhooks = async (): Promise<Webhook[]> =>
    apiClient.get('/webhooks').then((response: AxiosResponse) => response.data);

  static createWebhook = async (webhook: Omit<Webhook, 'id'>) =>
    apiClient.post('/webhooks', webhook).then((response: AxiosResponse<Webhook>) => response.data);

  static updateWebhook = async (id: number, webhook: Omit<Webhook, 'id'>) =>
    apiClient.patch(`/webhooks/${id}`, webhook).then((response: AxiosResponse<Webhook>) => response.data);

  static deleteWebhook = async (id: number) =>
    apiClient.delete(`/webhooks/${id}`).then((response: AxiosResponse<void>) => response.data);
}
