import { t } from 'i18next';
import iziToast from 'izitoast';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import EntityApi from 'src/api/EntityApi';
import { FETCH_ENTITY_TAGS_SUCCESS, UPDATE_ENTITY_TAGS_SUCCESS, DELETE_ENTITY_TAGS_SUCCESS } from '.';

export const fetchEntityTagsSuccess = createAction<{ entityId: string; tagIds: number[] }>(FETCH_ENTITY_TAGS_SUCCESS);
export const updateEntityTagsSuccess = createAction<{ entityId: string; tagIds: number[] }>(UPDATE_ENTITY_TAGS_SUCCESS);
export const deleteEntityTagsSuccess = createAction<{ entityId: string; tagIds: number[] }>(DELETE_ENTITY_TAGS_SUCCESS);

export const fetchEntityTags = createAsyncThunk<{ status: string }, { entityId: string }, { rejectValue: Error }>(
  'fetchEntityTags',
  async ({ entityId }, { dispatch }) => {
    try {
      const result = await EntityApi.getEntityTags(entityId);

      dispatch(fetchEntityTagsSuccess({ entityId, tagIds: result }));
      return { status: 'success' };
    } catch (error) {
      iziToast.error({
        title: t('ERROR_HAPPENED'),
        message: t('entity_tags.toasts.fetch_failed'),
        position: 'bottomRight',
        timeout: 2500,
        icon: 'delete'
      });
      return { status: 'failed' };
    }
  }
);

export const addEntityTags = createAsyncThunk<
  { status: string },
  { entityId: string; entityType: string; tagIds: number[] },
  { rejectValue: Error }
>('addEntityTags', async ({ entityId, entityType, tagIds }) => {
  try {
    await EntityApi.addEntityTags({ entityId, entityType, tagIds });

    iziToast.success({
      title: t('OK'),
      icon: 'icon check',
      message: t('entity_tags.toasts.tag_add'),
      timeout: 5000
    });
    return { status: 'success' };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('entity_tags.toasts.tag_add_failed'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'failed' };
  }
});

export const deleteEntityTags = createAsyncThunk<
  { status: string },
  { entityId: string; tagIds: number[] },
  { rejectValue: Error }
>('deleteEntityTags', async ({ entityId, tagIds }) => {
  try {
    await EntityApi.deleteEntityTags({
      entityId,
      tagIds
    });

    iziToast.success({
      title: t('OK'),
      icon: 'icon check',
      message: t('entity_tags.toasts.tag_remove'),
      timeout: 5000
    });
    return { status: 'success' };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('entity_tags.toasts.tag_remove_failed'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'failed' };
  }
});
