import type { AxiosResponse } from 'axios';

import type { EIdentification, EIdentificationTemplateTypes, UpdatableConfigPayload } from 'src/types/EIdentification';
import { apiClient } from 'src/Utilities/httpClients';

export default class EIdentificationApi {
  static getEIdentificationsByContentId = async (contentId: number): Promise<EIdentification[]> =>
    apiClient.get(`/eidentifications/eid/${contentId}`).then((response: AxiosResponse) => response.data);

  static updateEIdentification = async ({
    contentId,
    hash,
    type
  }: {
    contentId: number;
    hash: string;
    type: 'accept' | 'reject';
  }) =>
    apiClient
      .patch(`/eidentifications/eid/${contentId}`, { hash, type })
      .then((response: AxiosResponse) => response.data);

  static generateEIdentificationLink = async (contentId: number) =>
    apiClient
      .post(`/eidentifications/eid/${contentId}/link`, {})
      .then((response: AxiosResponse<{ link: string }>) => response.data);

  static getEIdentificationLink = async (hash: string) =>
    apiClient
      .get(`/eidentifications/eid/${hash}/link`, {})
      .then((response: AxiosResponse<{ link: string }>) => response.data);

  static getEIdentificationConfiguration = async () =>
    apiClient.get(`/eidentifications/eid/config`, {}).then((response: AxiosResponse) => response.data);

  static patchEIdentificationConfiguration = async (payload: UpdatableConfigPayload) =>
    apiClient.patch(`/eidentifications/eid/config`, payload).then((response: AxiosResponse) => response.data);

  static getEIdentificationTemplate = async (templateType: EIdentificationTemplateTypes, styles: string) =>
    apiClient
      .post(`/eidentifications/eid/template/${templateType}`, { styles })
      .then((response: AxiosResponse) => response.data);

  static verifyEIdentificationRelation = async (contentId: number, hash: string) =>
    apiClient.get(`/eidentifications/eid/verify/${contentId}/${hash}`).then((response: AxiosResponse) => response.data);
}
