import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { Icon, Label, Popup } from 'semantic-ui-react';
import type { VFC } from 'react';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import DueDatePickerModal from './DueDatePickerModal';

interface Props {
  dueDate: number | null;
  disabled?: boolean;

  changeDueDate: (newDueDate: number) => void;
}

const getFormattedDate = (date: number | string) => getPrettyDate(date, { format: DATE_TIME_FORMAT });

const DueDatePicker: VFC<Props> = ({ changeDueDate, dueDate, disabled }) => {
  const formattedDueDate = getFormattedDate(dueDate || 0);

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Translation ns="translations">
      {(t) => (
        <>
          <Popup
            content={t('TOOLTIP_DUEDATE')}
            position="top center"
            header={formattedDueDate}
            trigger={
              <Label
                onClick={() => {
                  if (disabled !== true) {
                    setModalOpen(true);
                  }
                }}
                className="dueDatePicker__label"
              >
                <Icon name="time" />
                <span>{formattedDueDate}</span>
              </Label>
            }
            on={['hover', 'focus']}
            flowing
            hoverable
            wide
          />
          <DueDatePickerModal
            dueDate={dueDate}
            changeDueDate={changeDueDate}
            isOpen={isModalOpen}
            closeModal={() => {
              setModalOpen(false);
            }}
          />
        </>
      )}
    </Translation>
  );
};

export default DueDatePicker;
