import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Channel } from '@eeedo/types';

import CommentIconHeader from './CommentIconHeader';

interface TaskDataContentProps {
  index: number;
  data: {
    title: any;
    content: any;
    taskType: any;
    dueDate: any;
  };
  channels: Channel[];
  channel: number;
}

const TaskDataContent = (props: TaskDataContentProps) => {
  const { t } = useTranslation();

  const origTicketChannel = props.channels.find((channel: Channel) => {
    return channel.id === props.channel;
  });

  return (
    <CommentIconHeader index={props.index} title={t('COMMENT_METADATA_TASK')}>
      <p>{t('COMMENT_METADATA_TASK_TITLE') + ': ' + props.data.title}</p>
      <p>{t('COMMENT_METADATA_TASK_CONTENT') + ': ' + props.data.content}</p>
      <p>{t('COMMENT_METADATA_TASK_CHANNEL') + ': ' + (origTicketChannel?.channel ?? '')}</p>
      <p>{t('COMMENT_METADATA_TASK_TASKTYPE') + ': ' + props.data.taskType}</p>
      <p>{t('COMMENT_METADATA_TASK_DUEDATE') + ': ' + props.data.dueDate}</p>
    </CommentIconHeader>
  );
};

export default TaskDataContent;
