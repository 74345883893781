import React from 'react';
import IFrameView from './IFrameView';

interface PdfViewerProps {
  src: string;
}

const PdfViewer = ({ src }: PdfViewerProps) => <IFrameView src={src} type="application/pdf" />;

export default React.memo(PdfViewer);
