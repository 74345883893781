import React from 'react';
import { useTranslation } from 'react-i18next';

import type ChannelType from './ChannelType';

export interface InstagramContentProps {
  channel: ChannelType;
  metaData: Record<string, any>;
}

function InstagramContent() {
  const { t } = useTranslation();

  // TODO: populate
  return (
    <div>
      <b>{t('COMMENT_ICON_CONTENT_INSTAGRAM_CHANNEL')}</b>
      <hr />
    </div>
  );
}

export default InstagramContent;
