import React from 'react';
import { Modal } from 'semantic-ui-react';

import { useModal } from './ModalContext';

const GlobalModal: React.FC = () => {
  const { modalContent, modalSize, closeModal } = useModal();

  return (
    <Modal open={!!modalContent} onClose={closeModal} size={modalSize}>
      {modalContent}
    </Modal>
  );
};

export default GlobalModal;
