import React, { useEffect, useState } from 'react';

import BlobFetcher from '../../generic/BlobFetcher';
import ItemWrapper from './ItemWrapper/ItemWrapper';

import type { IFrameViewContentType } from '../../../types/IFrameView';

interface IFrameViewWrapperProps {
  src: string;
  type: IFrameViewContentType;
}

const SCREEN_PERCENT = 80;

const IFrameView = ({ src: url, type }: IFrameViewWrapperProps) => {
  const [dimentions, setDimentions] = useState([0, 0]);
  useEffect(() => {
    setDimentions([(window.innerWidth * SCREEN_PERCENT) / 100, (window.innerHeight * SCREEN_PERCENT) / 100]);
  }, [url]);

  const [width, height] = dimentions;

  return (
    <BlobFetcher url={url} type={type}>
      {({ blobUrl, loaded, error, reload }) => (
        <ItemWrapper loaded={loaded} hasError={!!error} reload={reload}>
          {loaded && !error && <iframe src={blobUrl} width={width} height={height} frameBorder={0} />}
        </ItemWrapper>
      )}
    </BlobFetcher>
  );
};

export default IFrameView;
