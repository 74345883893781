import classNames from 'classnames';
import React, { useState } from 'react';
import { Checkbox, Table } from 'semantic-ui-react';

import type { Field, TicketType } from '@eeedo/types';
import type { CSSProperties, VFC } from 'react';
import type { SemanticWIDTHS } from 'semantic-ui-react';

import InfoInput from './InfoInput';
import EntityApi from 'src/api/EntityApi';

import type { InfoFieldProps } from 'src/Components/Case/Info/InfoField/InfoFieldProps';

interface DefaultInfoFieldProps extends Pick<InfoFieldProps, 'onSaveData' | 'setValue'> {
  fields: Field[];
  field: Field;
  fieldValue: string;
  values?: { [k: string]: any };
  disabled?: boolean;
  mongoSaveDisabled?: boolean;
  integrationSaveDisabled?: boolean;
  inputType: 'text' | 'number';
  ticketType: TicketType;
  taskId: string | number;
  width?: SemanticWIDTHS;
  disableWarning?: boolean;
}

const DefaultInfoField: VFC<DefaultInfoFieldProps> = ({
  fields,
  field,
  fieldValue,
  values,
  disabled,
  inputType,
  mongoSaveDisabled,
  integrationSaveDisabled,
  onSaveData,
  setValue,
  ticketType,
  taskId,
  disableWarning,
  width = 10
}) => {
  const [openInfoCheckboxActive, setOpenInfoCheckboxActive] = useState(new Set<string>());
  const className = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });
  let openField: Field | null | undefined = null;
  if (ticketType?.openInfoCheckbox) {
    openField = fields.find((field) => field.value === ticketType.openInfoCheckbox?.fieldValue);
  }

  const shouldRenderCheckbox = field.name && field.params?.openInfoCheckbox && openField;
  const style: CSSProperties = {
    width: shouldRenderCheckbox ? '75%' : undefined,
    display: shouldRenderCheckbox ? 'inline-flex' : undefined
  };

  return (
    <Table.Cell width={width}>
      <div>
        <InfoInput
          field={field}
          values={values}
          id={field.name}
          name={field.name}
          disabled={!!disabled}
          className={className}
          inputType={inputType}
          fieldValue={fieldValue}
          style={style}
          mongoSaveDisabled={!!mongoSaveDisabled}
          integrationSaveDisabled={!!integrationSaveDisabled}
          setValue={setValue}
          onSaveData={onSaveData}
          disableWarning={disableWarning}
        />

        {shouldRenderCheckbox && (
          <Checkbox
            style={{ display: 'inline-flex', marginLeft: '10px', paddingTop: '4px' }}
            checked={openInfoCheckboxActive.has(field.name!)}
            onChange={(e, data) => {
              if (!field.name) {
                return;
              }

              const checked = new Set<string>([...openInfoCheckboxActive]);
              if (data.checked) {
                checked.add(field.name);
              } else {
                checked.delete(field.name);
              }

              if (ticketType?.openInfoCheckbox && checked.size >= ticketType.openInfoCheckbox?.threshold) {
                EntityApi.verify(taskId.toString(), [...checked]);
                setOpenInfoCheckboxActive(new Set<string>());
              } else {
                setOpenInfoCheckboxActive(checked);
              }
            }}
          />
        )}
      </div>
    </Table.Cell>
  );
};

export default DefaultInfoField;
