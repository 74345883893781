import { createAction } from '@reduxjs/toolkit';

import { ACTIVATE_TAB, ADD_TAB, CLOSE_TAB, HIGHLIGHT_TAB_OFF, HIGHLIGHT_TAB_ON } from './index';
import type { ContentTypesFields } from 'src/types/Ticket';

export const addTab = createAction<{
  id: string;
  type: ContentTypesFields;
  activeTab?: boolean;
}>(ADD_TAB);

export const closeTab = createAction<string>(CLOSE_TAB);

export const activateTab = createAction<string>(ACTIVATE_TAB);

export const addHighlightToTab = createAction<string>(HIGHLIGHT_TAB_ON);

export const removeHighlightFromTab = createAction<string>(HIGHLIGHT_TAB_OFF);
