/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FieldTypes } from '@eeedo/types';
import { prop, uniqBy } from 'ramda';

import type { Field, TicketType } from '@eeedo/types';

import { normalizePhoneNumber } from './normalizeNumber';
import { buildUrlWithQuery } from 'src/api/URL';

import type { CommonQuery } from 'src/api/URL';

export const getEntityFieldSet = (ticketTypes: TicketType[], ticketTypeName: string, entityTypeName: string) =>
  ticketTypes
    .find((tt) => tt.name === ticketTypeName)
    ?.fieldSets.filter((fieldSet) => fieldSet.group === 'entity')
    ?.find((fieldSet) => (!fieldSet.entityTypes ? true : fieldSet.entityTypes.includes(entityTypeName)));

export const getEntityTypes = (ticketTypes: TicketType[]) =>
  uniqBy(prop('entityType'), ticketTypes.flatMap((ticketType) => ticketType?.entityRouting!).filter(Boolean));

const nonSearchableFields: Field['customType'][] = [
  FieldTypes.link,
  FieldTypes.loadingChevron,
  FieldTypes.separator,
  FieldTypes.empty,
  FieldTypes.googleMaps,
  FieldTypes.verifyFields
];
export const filterOutNonSearchableFields = (field: Field) => !nonSearchableFields.includes(field.customType);

export const getEntityFields = (chosenTicketTypes: TicketType[]): Field[] =>
  uniqBy(
    prop('value'),
    chosenTicketTypes
      .flatMap((ticketType) =>
        ticketType.fieldSets.filter((t) => t.group === 'entity').flatMap((fieldSet) => fieldSet[fieldSet.id])
      )
      .filter(filterOutNonSearchableFields)
  );

export const getEntityFieldsByEntityType = (ticketTypes: TicketType[], entityType: string): Field[] =>
  uniqBy(
    prop('value'),
    ticketTypes
      .flatMap((ticketType) => {
        const fieldSet = ticketType?.fieldSets?.find(
          (fieldSet) =>
            (fieldSet.entityTypes?.length ? fieldSet.entityTypes?.includes(entityType) : true) &&
            fieldSet.group === 'entity'
        );

        return fieldSet?.[fieldSet.id];
      })
      .filter(Boolean)
      .filter(filterOutNonSearchableFields)
  );

export const getFittingSearchTicketTypes = <P extends { [p: Field['value']]: any }>({
  ticketTypes,
  params
}: {
  ticketTypes: TicketType[];
  params: P;
}) =>
  ticketTypes.filter(
    (tType) =>
      !!tType.fieldSets
        .filter((fSet) => fSet.group === 'entity')
        .find((fSet) => fSet[fSet.id].find((field: Field) => Object.keys(params).includes(field.value)))
  );

export const buildSearchUrl = <P extends { [p: Field['value'] | 'entityTypes']: any } & CommonQuery>(params?: P) =>
  buildUrlWithQuery('/entities/search', {
    ...params,
    ['phoneNumber.number']:
      typeof params?.['phoneNumber.number'] === 'string'
        ? normalizePhoneNumber(params['phoneNumber.number'])
        : undefined,
    mode: 'approximate'
  });

export const getMutableEntityTypes = (ticketTypes: TicketType[]) =>
  uniqBy(
    prop('entityType'),
    ticketTypes.flatMap((ticketType) => ticketType?.entityRouting?.filter(({ readonly }) => !readonly)!).filter(Boolean)
  );

export const getRemovableEntityTypes = (ticketTypes: TicketType[]) =>
  uniqBy(
    prop('entityType'),
    ticketTypes
      .flatMap((ticketType) => ticketType?.entityRouting?.filter(({ addingSupported }) => !addingSupported)!)
      .filter(Boolean)
  );

export const getEntityOptions = (ticketTypes: TicketType[]) =>
  getMutableEntityTypes(ticketTypes).map(({ entityType }) => ({
    text: entityType,
    value: entityType
  }));

export const isEntityTypeReadonly = (ticketTypes: TicketType[], entityType: string) =>
  ticketTypes.some(
    (ticketType) => ticketType.entityRouting?.find((entityRoute) => entityRoute.entityType === entityType)?.readonly
  );
