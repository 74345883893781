import type { TicketType } from '@eeedo/types';

interface SecureEmailTransformOptions {
  secureMailAddressMailPostfix?: TicketType['secureMailAddressMailPostfix'];
  secureMailTitlePrefix?: TicketType['secureMailTitlePrefix'];
  secureMailTitlePostfix?: TicketType['secureMailTitlePostfix'];
  apiurl: string;
}

export const transformSecureEmailField = (
  field: 'to' | 'cc' | 'bcc' | 'title',
  value: string | string[] | undefined,
  opts: SecureEmailTransformOptions
) => {
  if (field === 'title') {
    // Shorten
    let mailsubject = value;

    if (opts.secureMailTitlePostfix) {
      mailsubject = mailsubject + opts.secureMailTitlePostfix;
    }

    if (opts.secureMailTitlePrefix) {
      mailsubject = opts.secureMailTitlePrefix + mailsubject;
    } else if (opts.apiurl.endsWith('tys')) {
      mailsubject = 'TURVAPOSTI ' + mailsubject;
    }
    return mailsubject;
  } else if (['to', 'cc', 'bcc'].includes(field)) {
    if (!Array.isArray(value)) return value;

    // just add +.s at recipient like previously according to end of API URL and if secureMailTitlePrefix exists
    if (value !== undefined && opts.apiurl.endsWith('yhkodit')) {
      value = value.map((recipient: string) => {
        return recipient + '.s';
      });
    }
    // add ending at end of recipients if ticketType.secureMailAddressMailPostfix exist (Note : this.props.ticketType.secureMailTitlePrefix || this.props.ticketType.secureMailTitlePostfix don't add anything at the end of recipient mail)
    if (value !== undefined && opts.secureMailAddressMailPostfix) {
      value = value.map((recipient: string) => recipient + opts.secureMailAddressMailPostfix);
    }
    return value;
  } else {
    console.error('This field is not supported by code for transformation in secure emails.');
    return value;
  }
};
