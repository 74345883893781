import { kebabCase } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import type { ConnectedProps } from 'react-redux';

import AccordionHeader from '../AccordionHeader';
import Info from '../Info/Info';
import { CollectionFieldSets } from './CollectionFieldSets/CollectionFieldSets';
import { updateSingleTicketCaseDetail } from 'src/actions/ticketsActions';

import type { State } from 'src/types/initialState';
import type { SearchCriterion } from 'src/types/Search';
import type { Ticket } from 'src/types/Ticket';

interface FieldSetsProps extends FieldSetsReduxProps {
  task: Ticket;
  fieldSetNames: string[];
  widgetOpen: boolean;
  ind: number;
  entitySearchButton: string;
  fieldMap: Map<string, any>;
  detailGroupMap: Map<string, any>;
  fireCaseInfoSearch(fields: any[], values: any[]): void;
  fireTicketSearch(searchCriteria: SearchCriterion[], uid: string): void;
}

export const FieldSets = ({
  task,
  fieldSetNames,
  widgetOpen,
  ind,
  entitySearchButton,
  fieldMap,
  detailGroupMap,
  fireCaseInfoSearch,
  fireTicketSearch,
  // redux
  userData,
  updateSingleTicketCaseDetail
}: FieldSetsProps) => (
  <>
    {fieldSetNames.map((fieldSetName, index) => {
      const fielsetNameClassName = kebabCase(`${fieldSetName}-color`);

      if (task.case[fieldSetName]?.type === 'collection') {
        return (
          <CollectionFieldSets
            taskCase={task.case}
            taskId={task.id}
            widgetOpen={widgetOpen}
            i={ind}
            fieldMap={fieldMap}
            className={fielsetNameClassName}
            index={index}
            fieldSetName={fieldSetName}
          />
        );
      }

      return (
        <div className={fielsetNameClassName} key={`case-fieldset-${index}`}>
          <AccordionHeader
            key={`case-fieldset-accordion-header-${index}`}
            as="h4"
            active={widgetOpen}
            title={fieldSetName}
            icon="info circle"
          >
            {entitySearchButton && (
              <Button
                icon={true}
                color="blue"
                labelPosition="left"
                onClick={() => fireCaseInfoSearch(fieldMap.get(fieldSetName), task.case)}
              >
                <Icon name="search" />
                {entitySearchButton}
              </Button>
            )}

            <Info
              onSave={(editedField: any, editedValue: any) => {
                updateSingleTicketCaseDetail(task.id, editedField, editedValue, detailGroupMap.get(fieldSetName));
              }}
              generalDisable={!userData.permissions.includes('modifyCaseDetails')}
              fields={fieldMap.get(fieldSetName) || []}
              values={task.case || {}}
              taskId={task.id}
              fireSearch={(value: any, type: string) => {
                const searchFormat = [
                  {
                    value: value,
                    datagroup: 'detail',
                    name: type,
                    object: false,
                    param: type,
                    text: ''
                  }
                ];

                fireTicketSearch(searchFormat, userData.UID);
              }}
            />
          </AccordionHeader>
        </div>
      );
    })}
  </>
);

const connector = connect(
  (state: State) => ({
    userData: state.userData
  }),
  { updateSingleTicketCaseDetail }
);

type FieldSetsReduxProps = ConnectedProps<typeof connector>;

export default connector(FieldSets);
