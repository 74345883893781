import { t as tr } from 'i18next';
import iziToast from 'izitoast';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import type { IconProps } from 'semantic-ui-react';

interface Props extends IconProps {
  text: string;
}

const CopyToClipboardIcon = ({ text, size, link = true, ...props }: Props) => {
  const onClickCopy = () => {
    navigator.clipboard.writeText(text);
    iziToast.info({
      message: tr('COPIED')
    });
  };

  return <Icon name="copy" link={link} onClick={onClickCopy} size={size} {...props} />;
};

export default CopyToClipboardIcon;
