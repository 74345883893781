export interface Suggestion {
  id: number;
  name: string;
  value: string;
  type: string;
  ticketTypes: number[];
  actions?: SuggestionAction[];
}

export const suggestionActionTypeValues = {
  'Update details with result': 'CONTENT_DETAIL_UPSERT'
} as const;
export type SuggestionActionTypes = (typeof suggestionActionTypeValues)[keyof typeof suggestionActionTypeValues];

export interface SuggestionAction<T = unknown> {
  type: SuggestionActionTypes;
  data: T;
}

export interface ContentDetailsUpsertAction {
  destination: string;
}

export interface Suggestion {
  id: number;
  name: string;
  value: string;
  type: string;
  ticketTypes: number[];
  actions?: SuggestionAction[];
}

export enum SuggestionType {
  email = 'email',
  phone = 'phone',
  delegationGroup = 'DelegationGroup',
  ticketTitle = 'ticketTitle',
  aiPrompt = 'aiPrompt'
}

export interface NewAutoSuggestion {
  name: string;
  value: string;
  type: string;
  ticketTypes: number[];
  DelegationGroup: number[];
}
