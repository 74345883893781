import type { EnvSettingsData, PhoneConfiguration } from '@eeedo/types';
import type { AxiosResponse } from 'axios';

import { ACTIVE_FRONTEND_FEATURE_FLAGS } from './FeatureFlags';
import { apiClient } from 'src/Utilities/httpClients';

import type { TFeatureFlag } from './FeatureFlags';
import type { SMSConfiguration } from 'src/types/Configuration';

export default class ConfigurationsApi {
  static getEnvSettings = async (): Promise<EnvSettingsData> => {
    return apiClient.get('/settings').then((response) => response.data);
  };

  static getFeatureFlags = async (): Promise<TFeatureFlag[]> => {
    return apiClient
      .post('/featureFlagsSelected', { featureFlags: ACTIVE_FRONTEND_FEATURE_FLAGS })
      .then((response) => response.data);
  };

  static getPhoneConfigurations = async (): Promise<PhoneConfiguration[]> => {
    return apiClient.get('/phoneconfigurations').then((response) => response.data);
  };

  static createPhoneConfigurations = async (phoneConfiguration: Omit<PhoneConfiguration, 'id'>) =>
    apiClient
      .post('/phoneconfigurations', phoneConfiguration)
      .then((response: AxiosResponse<PhoneConfiguration>) => response.data);

  static updatePhoneConfigurations = async (id: number, phoneConfiguration: Omit<PhoneConfiguration, 'id'>) =>
    apiClient
      .patch(`/phoneconfigurations/${id}`, phoneConfiguration)
      .then((response: AxiosResponse<PhoneConfiguration>) => response.data);

  static deletePhoneConfigurations = async (id: number) =>
    apiClient.delete(`/phoneconfigurations/${id}`).then((response: AxiosResponse<void>) => response.data);

  static getSMSConfigurations = async (): Promise<SMSConfiguration[]> => {
    return apiClient.get('/SMS/serviceNumbers/all').then((response) => response.data);
  };
}
