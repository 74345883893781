import type { CSSProperties } from 'react';

export const modalTrigger: CSSProperties = { display: 'flex', flexDirection: 'column', paddingTop: '15px' };

export const rescheduledCallsInfo: CSSProperties = { display: 'flex', flexDirection: 'column', paddingTop: '20px' };

export const modalContentWrapper: CSSProperties = { display: 'flex' };

export const optionsWrapper: CSSProperties = { flex: '1', display: 'flex', flexDirection: 'column', padding: '15px' };

export const optionButton: CSSProperties = { marginBottom: '10px' };

export const datepickerWrapper: CSSProperties = { flex: '1', padding: '15px' };
