import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UrlActions from '../Components/Utilities/UrlActions';
import { handleIncomingPhoneCall } from '../handlers/handlePhoneCall';

import type { IntegrationData } from '../handlers/handlePhoneCall';
import type { State } from 'src/types/initialState';

const mapStateToProps = (state: State) => ({
  ticketTypes: state.ticketTypes,
  userData: state.userData,
  user: state.usersList.usersList.find((user) => user.UID === state.userData.UID),
  phoneConfigurations: state.phoneConfigurations
});

const mapDispatchToProps = (dispatch: any) => ({
  handleIncomingPhoneCall: (callObject: IntegrationData) => {
    return dispatch(handleIncomingPhoneCall(callObject));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    // TODO: refactor to infer connector types
    UrlActions as any
  )
);
