import classNames from 'classnames';
import type { VFC } from 'react';
import React, { createRef } from 'react';
import type { DropEvent, DropzoneRef } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import type { DropdownItemProps } from 'semantic-ui-react';
import { Dropdown, Form } from 'semantic-ui-react';

import { MAX_ATTACHMENT_SIZE } from 'src/config';
import { openAttachmentsPreview } from 'src/reducers/attachmentsReducer';
import type { Attachment } from 'src/types/Ticket';
import { onDropRejected } from '../UploadErrorHandler';

interface Props {
  attachments: Array<Attachment>;
  options: DropdownItemProps[];
  value: string[] | string;
  isMultiple?: boolean;
  acceptExtensions?: {
    [key: string]: string[];
  };
  maxFileSize?: number;

  onDropAccepted: (files: File[], event: DropEvent) => void;
  onChangeAttachments: (attachments: string[]) => void;
}

const FormDropzoneDropdown: VFC<Props> = ({
  value,
  attachments,
  options,
  acceptExtensions,
  maxFileSize,
  isMultiple = true,
  onChangeAttachments,
  onDropAccepted
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropzoneRef = createRef<DropzoneRef>();

  return (
    <Form.Input label={t('ADD_COMMENT_ATTACHMENT_LABEL')}>
      <Dropzone
        maxSize={maxFileSize || MAX_ATTACHMENT_SIZE}
        multiple={isMultiple}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        ref={dropzoneRef}
        {...(!!acceptExtensions && { accept: acceptExtensions })}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className={classNames('AttachmentDropzone-hidden', isDragActive && 'AttachmentDropzone-Active')}
            {...getRootProps()}
          >
            <input name="attachments" {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      <Dropdown
        description="ctrl + o"
        fluid={true}
        multiple={isMultiple}
        noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
        options={options}
        placeholder={t('ADD_COMMENT_ATTACHMENT_PLACEHOLDER')}
        search={true}
        selectOnBlur={false}
        selection={true}
        value={value}
        onChange={(_event, data) => {
          const val = data.value as string[] | string;
          if (val.toString().includes('ADD_ATTACHMENT')) {
            dropzoneRef.current?.open();
            if (Array.isArray(val)) val.pop();
          } else {
            const addedAttachments = attachments
              .filter((attachment) => val.includes(attachment.id))
              .map((attachment) => attachment.id);
            onChangeAttachments(addedAttachments);
          }
        }}
        renderLabel={(label, _index, option) => ({
          onClick: () => {
            dispatch(openAttachmentsPreview({ firstAttachmentId: option.value, attachments }));
          },
          content: label.text
        })}
      />
    </Form.Input>
  );
};

export default FormDropzoneDropdown;
