import iziToast from 'izitoast';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import type { ConnectedProps } from 'react-redux';

import AccordionHeader from '../AccordionHeader';
import { addEntityToCase } from 'src/actions/ticketsActions';
import AddCustomer from 'src/Components/Customer/AddCustomer';

import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';

interface AttachEntityByIdProps extends AttachEntityByIdReduxProps {
  task: Ticket;
  widgetOpen: boolean;
  ind: number;
  entityInfoHeader: string;
  entityInfo: any[];
  id?: string;

  changeEntity(entityName: string, id: string): void;
}

const AttachEntityById = ({
  id: elementId,
  task,
  widgetOpen,
  addEntityToCase,
  ticketTypes,
  ind,
  entityInfoHeader,
  entityInfo,
  changeEntity
}: AttachEntityByIdProps) => {
  const { t } = useTranslation();
  const ticketType = [...ticketTypes].find((type) => type.name === task.taskType)!;

  const actualEntities = (ticketType.fieldSets ?? []).filter((ent) => ent.group === 'entity');

  const { id, taskType, entities: taskEntities } = task;

  return (
    <AccordionHeader
      as="h4"
      active={widgetOpen && task && task.entities && task.entities.length === 0}
      title={actualEntities.length > 1 ? t('CASE_TITLE_ATTACH_TO_TICKET') : t('CASE_TITLE_ADD_ENTITY')}
      icon="edit"
      id={elementId}
      key={`case-title-attach-to-ticket-${ind}`}
      render={(toggleAccordion: (...args: any[]) => any) => (
        <AddCustomer
          fieldSets={ticketType.fieldSets}
          dropDownValue={entityInfoHeader || ''}
          task={{
            id,
            taskType,
            entities: taskEntities
          }}
          ticketTypes={ticketTypes}
          fields={entityInfo || []}
          changeEntity={(entity: string, id: string) => changeEntity(entity, id)}
          onSelect={async (body: any) => {
            if (body.personalIdentityCode) {
              iziToast.warning({
                title: t('ADD_CUSTOMER_CREATE_TITLE'),
                message: t('ADD_CUSTOMER_CREATE_VALUE_NOT_ALLOWED', {
                  value: t('ADD_CUSTOMER_FORBIDDEN_FIELDS_SSN')
                }),
                timeout: 20000,
                close: true,
                overlay: true,
                closeOnEscape: true,
                zindex: 999,
                position: 'center'
              });
            } else {
              body.taskType = task.taskType;
              await addEntityToCase(task.id, body);
              toggleAccordion();
            }
          }}
        />
      )}
    />
  );
};

const connector = connect(
  (state: State) => ({
    ticketTypes: state.ticketTypes
  }),
  { addEntityToCase }
);

type AttachEntityByIdReduxProps = ConnectedProps<typeof connector>;

export default connector(AttachEntityById);
