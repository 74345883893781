import React from 'react';

import type { FC, PropsWithChildren } from 'react';

import ErrorBoundary from '../../ErrorBoundary';
import LoaderComponent from '../Loader/Loader';

const SettingsPanesWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <React.Suspense fallback={<LoaderComponent />}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </React.Suspense>
  );
};

export default SettingsPanesWrapper;
