import classNames from 'classnames';
import React, { forwardRef } from 'react';
import type { DropEvent, DropzoneRef } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { Translation } from 'react-i18next';
import { Header, Icon } from 'semantic-ui-react';
import { onDropRejected } from 'src/Components/ReplyMethods/UploadErrorHandler';
import { MAX_ATTACHMENT_SIZE } from 'src/config';

import './AttachmentDropzone.css';

type UploadFunction = (acceptedOrRejected: File[], event: DropEvent) => void;

interface AttachmentDropzoneProps {
  onUpload: UploadFunction;
  dropzoneRef?: DropzoneRef | null;
  enabled: boolean;
}

const AttachmentDropzone = forwardRef<DropzoneRef, AttachmentDropzoneProps>(function AttachmentDropzone(
  { enabled, onUpload },
  dropzoneRef
) {
  return (
    <Translation ns="translations">
      {(t) =>
        enabled ? (
          <Dropzone
            onDropAccepted={onUpload}
            ref={dropzoneRef}
            onDropRejected={onDropRejected}
            multiple={true}
            maxSize={MAX_ATTACHMENT_SIZE}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                className={classNames('AttachmentDropzone-Basic', isDragActive && 'AttachmentDropzone-Active')}
                {...getRootProps()}
              >
                <input name="attachments" {...getInputProps()} />
                <Header as="h4" icon={true}>
                  <Icon name="cloud upload" />
                  {t('ATTACHMENT_DROPZONE_TITLE')}
                  <Header.Subheader>{t('ATTACHMENT_DROPZONE_DESCRIPTION')}</Header.Subheader>
                </Header>
              </div>
            )}
          </Dropzone>
        ) : null
      }
    </Translation>
  );
});

export default React.memo(AttachmentDropzone);
