import type { ActionCreator } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import { UPDATE_WORK_RECORDING_INFO, UPDATE_WORK_STATUS } from './index';
import TicketsApi from 'src/api/TicketsApi';
import type { WorkStatusItem } from 'src/types/RealTimeService';
import type { State } from 'src/types/initialState';

export interface UpdateWorkStatusData {
  message: string;
  status: Array<WorkStatusItem>;
  timestamp: number;
}

interface UpdateRecordInfo {
  ticketId: number;
  UID: number;
}

export const updateWorkStatus = (data: UpdateWorkStatusData) => {
  return { type: UPDATE_WORK_STATUS, payload: data };
};

export const updateWorkRecordingInfo = (data: UpdateRecordInfo) => {
  return { type: UPDATE_WORK_RECORDING_INFO, payload: data };
};

export const ticketStopWorkingOn: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: number,
  UID: number
) => {
  return (dispatch, getState) => {
    const { workStatus } = getState();
    const ticketStatus = workStatus.status.find(
      (status) => Number(status.ticketId) === ticketId && Number(status.UID) === UID
    );

    if (!ticketStatus) {
      return;
    }

    return TicketsApi.stopWorkingOn(ticketId, UID);
  };
};
