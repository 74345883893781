import iziToast from 'izitoast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Button } from 'semantic-ui-react';

import { generateTicketRelevantInfo } from 'src/actions/openAIActions';
import { convertCaseNaming, getLastIncommingComment } from 'src/Utilities/helper';
import { useAppThunkDispatch } from 'src/store';
import type { Ticket } from 'src/types/Ticket';

interface RelevantInformationProps {
  task: Ticket;
}

export default ({ task }: RelevantInformationProps) => {
  const { t } = useTranslation();
  const dispatchThunk = useAppThunkDispatch();
  const [isGenerating, setGenerating] = useState(false);

  const handleTicketRelevantInfo = async () => {
    setGenerating(true);
    const commentPayload = getLastIncommingComment(task);
    if (commentPayload.content) {
      await dispatchThunk(
        generateTicketRelevantInfo({
          contentId: convertCaseNaming(task.id, 'number', 'task') as number,
          content: commentPayload.content,
          isHtml: commentPayload.isHtml,
          commentId: commentPayload.commentId
        })
      );
    } else {
      iziToast.info({
        message: t('widgets.chatGPT.toasts.no_incoming')
      });
    }
    setGenerating(false);
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <Button disabled={isGenerating} onClick={() => handleTicketRelevantInfo()} primary icon labelPosition="left">
        {isGenerating ? <Icon name="circle notch" loading /> : <Icon name="info" />}
        {t('widgets.chatGPT.buttons.rel_info')}
      </Button>
    </div>
  );
};
