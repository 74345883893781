import React from 'react';
import { Label } from 'semantic-ui-react';

import type { Tag } from '@eeedo/types';
import type { FC } from 'react';

import * as styles from './TicketListItem.style';
import ErrorBoundary from 'src/ErrorBoundary';

const AllTags: FC<{ sortedTags: Tag[] }> = ({ sortedTags }) => (
  <ErrorBoundary>
    <div>
      {sortedTags.map((tag: Tag, index: number) => {
        const style = styles.allTagsLabel({
          bgColor: (tag?.styles?.bgColor as string) ?? '#e8e8e8',
          fontColor: (tag?.styles?.fontColor as string) ?? 'rgba(0,0,0,.6)'
        });

        return (
          <Label style={style} data-test={tag.name} key={index}>
            {tag.name}
          </Label>
        );
      })}
    </div>
  </ErrorBoundary>
);

export default AllTags;
