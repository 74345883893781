import { UPDATE_TICKET_TAB_STATE, APPEND_TO_DRAFT, SWITCH_DRAFT_TAB } from './index';

export const updateTicketTabState = <S>(ticketId: string, state: S) => {
  return {
    type: UPDATE_TICKET_TAB_STATE,
    payload: {
      id: ticketId,
      state
    }
  };
};

export const appendToDraft = (payload: object, id: string, channel: string) => {
  return {
    type: APPEND_TO_DRAFT,
    payload: {
      payload,
      id,
      channel
    }
  };
};

export const switchDraftTabIndex = (id: string, tabIndex: number) => {
  return { type: SWITCH_DRAFT_TAB, payload: { id, tabIndex } };
};
