import { assocPath, dissocPath, insert, pathOr } from 'ramda';

import type { CampaignManagementState } from 'src/reducers/campaignReducer';
import type {
  ImportTemplate,
  ImportType,
  ParameterArrayOptionType,
  ParameterOption,
  ParameterType,
  TypedParameter
} from 'src/types/Campaign';

const pathsMap: { [K in ParameterArrayOptionType]: (string | number)[] } = {
  enum: ['payload', 'parameters', 'data', 'options'],
  date: ['payload', 'parameters', 'data'],
  matchArray: ['payload', 'parameters', 'data', 'matchList'],
  pushToArray: ['payload', 'parameters', 'data', 'targetVariables']
};
export const defaultOptionsMap: { [K in ParameterArrayOptionType]: ParameterOption<K> } = {
  date: {
    date: { targetVariables: '', inputFormat: '' },
    offset: { numberOfTimeUnits: 0, timeUnit: 'days', direction: 'forwards' },
    fix: { direction: 'startOf', timeUnit: 'days' },
    formats: { outputFormat: '' }
  },
  enum: { inputValue: '', outputValue: '' },
  matchArray: { outputValue: '', targetValue: '', targetVariables: '' },
  pushToArray: ''
};
export const validatorsMap: { [K in ParameterType]: (data: TypedParameter<K>['data']) => boolean } = {
  date: (data) => {
    return (
      !data.length ||
      data.some(
        (option) =>
          !option.date?.targetVariables ||
          !option.formats?.outputFormat ||
          (!!option.fix && (!option.fix.direction || !option.fix.timeUnit)) ||
          (!!option.offset && (!option.offset.direction || !option.offset.numberOfTimeUnits || !option.offset.timeUnit))
      )
    );
  },
  enum: (data) => !data.targetVariables || data.options.some((option) => !option.inputValue || !option.outputValue),
  matchArray: (data) =>
    !data.matchList?.length ||
    data.matchList.some((option) => !option.outputValue || !option.targetValue || !option.targetVariables),
  pushToArray: (data) => !data.targetVariables?.length,
  string: (data) => !data.targetVariables
};

export type UPOArgs<T extends ParameterArrayOptionType> = {
  parameterIndex: number;
  optionIndex: number;
  option: ParameterOption<T>;
  type: ParameterArrayOptionType;
};
export const upsertParameterOption = <T extends ParameterArrayOptionType>({
  parameterIndex,
  optionIndex,
  option,
  type
}: UPOArgs<T>) =>
  assocPath<ParameterOption<typeof type>, CampaignManagementState>(
    [...insert(2, parameterIndex, pathsMap[type]), optionIndex],
    option
  );

export const getParameterOptionPath = (index: number, type: ParameterArrayOptionType) =>
  insert(2, index, pathsMap[type]);

export const getParameterOptionsLength = (index: number, type: ParameterArrayOptionType) =>
  pathOr<number>(0, [...insert(2, index, pathsMap[type]), 'length']);

export type RPOArgs = {
  parameterIndex: number;
  optionIndex: number;
  type: ParameterArrayOptionType;
};
export const dropParameterOption = ({ parameterIndex, optionIndex, type }: RPOArgs) =>
  dissocPath<CampaignManagementState>([...insert(2, parameterIndex, pathsMap[type]), optionIndex]);

export const parametersHasError = (parameters: CampaignManagementState['payload']['parameters']) =>
  parameters.some(
    (parameter) => !parameter.type || !parameter.path || (validatorsMap[parameter.type](parameter.data) ?? false)
  );

export function composeTemplateData(
  id: string,
  importType: ImportType,
  payload: CampaignManagementState['payload'],
  selectedTemplateData?: ImportTemplate['templateData']
): ImportTemplate['templateData'] {
  const { tags, title, content, channel, responseTemplates, parameters, ticketType, ticketStatus, ticketDueDate } =
    payload;

  const templateData: ImportTemplate['templateData'] = {
    id,
    formData: {
      task: {
        type: 'task',
        title,
        tagIds: tags,
        channel,
        content,
        dueDate: ticketDueDate,
        taskType: ticketType!,
        ticketStatus,
        delegatedToUserIds: selectedTemplateData?.formData?.task?.delegatedToUserIds?.length
          ? [...selectedTemplateData.formData.task.delegatedToUserIds]
          : []
      },
      customer: { ...selectedTemplateData?.formData?.customer }
    },
    parameters,
    importType,
    responseTemplates: responseTemplates || []
  };

  return templateData;
}
