import React from 'react';
import { useTranslation } from 'react-i18next';

import type ChannelType from './ChannelType';
import type { Attachment } from 'src/types/Ticket';

export interface TampuuriIconMetaData {
  attachments: Attachment[];
  from: string[];
  to: string[];
  title: string;
  headers: any;
}

export interface TampuuriContentProps {
  channel: ChannelType;
  metaData: Record<string, any>;
  title: string | null;
}

const Attachments = (props: { attachments: Attachment[] }) => {
  const { t } = useTranslation();

  if (!Array.isArray(props.attachments) || !props.attachments.length) {
    return <></>;
  }

  return (
    <div>
      <b>{t('COMMMENT_METADATA_ATTACHMENTS')}</b>
      <ol>
        {props.attachments.map((attachment: Attachment, index: number) => (
          <li key={index}>
            <b>{t('COMMENT_METADATA_TITLE')}:</b>
            <div>{attachment.title}</div>
            <b>{t('FILE_NAME')}:</b>
            <div>{attachment.fileName}</div>
            <b>{t('FILE_TYPE')}:</b>
            <div>{attachment.fileType}</div>
            <b>{t('DESCRIPTION')}:</b>
            <div>{attachment.description}</div>
          </li>
        ))}
      </ol>
    </div>
  );
}

const Body = (props: { metaData: { [x: string]: any } }) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <b>{t('COMMENT_METADATA_SENDER')}</b>
        <div>{props.metaData.from}</div>
      </div>
      <hr />
      <div>
        <b>{t('COMMENT_METADATA_TARGETED_TO')}</b>
        {Array.isArray(props.metaData.to) ? (
          <ol>
            {props.metaData.to.map((to: string, index: number) => (
              <li key={`tampuuri-content-to-${index}`}>{to}</li>
            ))}
          </ol>
        ) : (
          <div>...</div>
        )}
      </div>
    </>
  );
}

export const TampuuriContent = (props: TampuuriContentProps) => {
  const { t } = useTranslation();

  const haveReplyTo = Array.isArray(props.metaData.headers?.['reply-to']);

  return (
    <div>
      <div>
        <b>{t('COMMENT_METADATA_TITLE')}</b>
        <div>{props.title || props.metaData.title}</div>
      </div>
      <Body metaData={props.metaData} />
      {haveReplyTo && (
        <div>
          <b>reply-to</b>
          <ol>
            {props.metaData.headers['reply-to'].value.map((replyTo: any, index: number) => (
              <li key={index}>{replyTo.address}</li>
            ))}
          </ol>
        </div>
      )}
      <Attachments attachments={props.metaData.attachments} />
    </div>
  );
}
