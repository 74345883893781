import type { Priority } from '@eeedo/types';

import { ADD_PRIORITIES_SUCCESS, FETCH_PRIORITIES_SUCCESS, PATCH_PRIORITIES_SUCCESS } from '../actions';

const initialState: Priority[] = [];

const prioritiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PRIORITIES_SUCCESS: {
      return action.payload.priorities;
    }

    case ADD_PRIORITIES_SUCCESS: {
      if (state.some((priority) => priority.id === action.priority.id)) {
        return state;
      } else {
        return [...state, action.priority];
      }
    }

    case PATCH_PRIORITIES_SUCCESS: {
      const { priority } = action;

      const exists = state.some((item) => item.id === priority.id);
      return exists
        ? state.map((item) => (item.id === priority.id ? { ...item, ...priority } : item))
        : [...state, priority];
    }

    default:
      return state;
  }
};

export default prioritiesReducer;
