import React from 'react';
import { Modal } from 'semantic-ui-react';

import type { FC } from 'react';

import LoaderComponent from './Loader';

const ModalLoaderComponent: FC = () => (
  <Modal isOpen={true} size="fullscreen">
    <Modal.Content>
      <LoaderComponent />
      Hello world!
    </Modal.Content>
  </Modal>
);

export default ModalLoaderComponent;
