import { useState, useLayoutEffect } from 'react';

export const useIsOverflow = (ref: React.RefObject<HTMLElement>) => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = (current?.scrollWidth || 0) > (current?.clientWidth || 0);

      setIsOverflow(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [ref]);

  return isOverflow;
};
