import React, { useContext } from 'react';

import type { CSSProperties, VFC } from 'react';
import type { ButtonProps, SemanticICONS } from 'semantic-ui-react';

import { CommentsContainerContext } from '../Case/CommentsContainerContext';
import ShrinkableButton from '../generic/ShrinkableButton';

import type { CommentWidgetButtonsProps } from './CommentWidgetButtons';

interface CommentWidgetButtonProps extends Pick<CommentWidgetButtonsProps, 'buttonsType'> {
  text: string;
  iconName: SemanticICONS;
  style?: CSSProperties;

  onClick: ButtonProps['onClick'];
}

const CommentWidgetButton: VFC<CommentWidgetButtonProps> = ({ text, iconName, buttonsType, style, onClick }) => {
  const { shouldShrink } = useContext(CommentsContainerContext);

  return (
    <ShrinkableButton
      icon
      size="mini"
      style={style}
      iconName={iconName}
      shrink={shouldShrink}
      labelPosition={!shouldShrink ? 'left' : undefined}
      onClick={onClick}
      {...(buttonsType && {
        [buttonsType]: true
      })}
    >
      {text}
    </ShrinkableButton>
  );
};

export default React.memo(CommentWidgetButton);
