import { createReducer } from '@reduxjs/toolkit';

import { addCallRequest, removeCallRequest } from '../actions/phoneActions';
import type { CallRequestPayload } from '../actions/phoneActions';

const initialState: CallRequestState = [];

export type CallRequestState = CallRequestPayload[];

const callRequestsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addCallRequest, (state, { payload }) => {
      return [
        ...state.filter(
          ({ phoneNumber, ticketId }) => payload.phoneNumber !== phoneNumber || payload.ticketId !== ticketId
        ),
        payload
      ];
    })
    .addCase(removeCallRequest, (state, { payload }) => {
      return state.filter(
        ({ phoneNumber, ticketId }) => payload.phoneNumber !== phoneNumber || payload.ticketId !== ticketId
      );
    });
});

export default callRequestsReducer;
