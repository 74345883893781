import { createReducer } from '@reduxjs/toolkit';

import type { Suggestion } from '@eeedo/types';

import {
  createAutoSuggestionSuccess,
  deleteAutoSuggestionSuccess,
  fetchAutoSuggestionsSuccess,
  updateAutoSuggestionSuccess
} from 'src/actions/suggestionActions';

type SuggestionState = Suggestion[];
const initialState: SuggestionState = [];

const suggestionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAutoSuggestionsSuccess, (state, action) => {
      return action.payload.suggestions;
    })
    .addCase(updateAutoSuggestionSuccess, (state, action) => {
      const index = state.findIndex((suggestion) => suggestion.id === action.payload.suggestion.id);
      state[index] = action.payload.suggestion;
    })
    .addCase(createAutoSuggestionSuccess, (state, action) => {
      const { suggestion } = action.payload;
      const index = state.findIndex((item) => item.id === suggestion.id);

      if (index !== -1) {
        state[index] = suggestion;
      } else {
        state.push(suggestion);
      }
    })
    .addCase(deleteAutoSuggestionSuccess, (state, action) => {
      return state.filter((suggestion) => suggestion.id !== action.payload.id);
    });
});

export default suggestionsReducer;
