import { createReducer } from '@reduxjs/toolkit';

import type { ChatStatus } from '@eeedo/types';

import { loadChatsSuccess, loadChatSuccess } from 'src/actions/chatActions';

const initialState: ChatStatus[] = [];

const chatConfigsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadChatsSuccess, (_, action) => {
      return action.payload;
    })
    .addCase(loadChatSuccess, (state, action) => {
      const chatIndex = state.findIndex((chat) => chat.id === action.payload.id);

      if (chatIndex !== -1) {
        state[chatIndex] = action.payload;
      } else {
        state.push(action.payload);
      }
    });
});

export default chatConfigsReducer;
