import React, { useState } from 'react';
import { Icon, Input, Label, List, Modal, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface WeightModalProps {
  weight: number;
  onChange: (weight: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const roundNumber = (n: string | number): number => {
  if (typeof n === 'string') {
    n = parseFloat(n);
  }

  return Math.round((n + Number.EPSILON) * 1000) / 1000;
};

const WeightModal = (props: WeightModalProps) => {
  const { t } = useTranslation();
  const [weight, setWeight] = useState<number>(roundNumber(props.weight));

  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={() => props.setOpen(false)}
      onOpen={() => props.setOpen(true)}
      open={props.open}
      size={'mini'}
      trigger={
        <Popup
          position={'bottom center'}
          disabled={true}
          trigger={
            <List.Item style={{ cursor: 'pointer' }}>
              <Label onClick={() => props.setOpen(true)}>
                <Icon name={'sort'} />
                {roundNumber(props.weight)}
              </Label>
            </List.Item>
          }
        />
      }
    >
      <Modal.Header>{t('weight')}</Modal.Header>
      <Modal.Content>
        <Input
          type="number"
          onChange={(e) => {
            setWeight(Number(e.target.value));
          }}
          onBlur={(e: any) => {
            setWeight(Number(e.target.value));
            props.onChange(roundNumber(e.target.value));
          }}
          value={weight}
          autoFocus
          onFocus={(e: any) => e.currentTarget.select()}
        />
      </Modal.Content>
    </Modal>
  );
};

export default WeightModal;
