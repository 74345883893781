import React from 'react';
import { useTranslation } from 'react-i18next';

import type { SurveyQuestionAnswerQuestionConfiguration } from 'src/types/Survey';

/**
 * Resolve survey answers to predefined values, e.g. dropdown selection
 */
interface SelectProps {
  type: string;
  data: SurveyQuestionAnswerQuestionConfiguration;
  value: number;
}

const Select = ({ data, value }: SelectProps) => {
  const { t } = useTranslation();
  let selectedText = '';
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'test') {
    selectedText = `(${value})`;
  } else {
    selectedText = `${t('CASE_SURVEY_ANSWER_TYPE_NOT_FOUND')} (${value})`;
  }
  // let selectedText = "";
  if (data?.options) {
    const selected = data.options;

    for (const item of selected) {
      if (item.value === value) {
        selectedText = item.text; // Will display contents of the object inside the array
      }
    }
  }

  return <div className="selected-answer">{selectedText}</div>;
};

export default Select;
