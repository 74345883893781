import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface ErrorProps {
  taskId: string;
  className: string;
  text: string;
}

const Error = ({ text }: ErrorProps) => {
  const { t } = useTranslation();

  return (
    <Message icon negative>
      <Icon name="warning sign" />
      <Message.Content>
        <Message.Header>{t('ERROR')}</Message.Header>
        {text}
      </Message.Content>
    </Message>
  );
};

export default Error;
