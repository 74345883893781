import { orderBy } from 'lodash-es';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import type { ComponentProps } from 'react';

import type { State } from 'src/types/initialState';

interface ChannelDropdownProps {
  id?: string;
  value?: number;
  placeholder?: string;
  error?: ComponentProps<typeof Dropdown>['error'];

  onChange?: ComponentProps<typeof Dropdown>['onChange'];
}

const ChannelDropdown = ({ value, error, placeholder, onChange }: ChannelDropdownProps) => {
  const channels = useSelector((state: State) => state.channels);
  const userChannels = useSelector((state: State) => state.userData.channels);

  const channelTypes = useMemo(
    () =>
      orderBy(
        channels
          .filter((channel) => channel.active && userChannels.includes(channel.id))
          .map((channelType) => ({
            text: channelType.channel,
            value: channelType.id,
            icon: channelType.icon
          })),
        ['text', 'value']
      ),
    [channels, userChannels]
  );

  return (
    <Dropdown
      fluid
      selection
      error={error}
      search
      placeholder={placeholder}
      value={value}
      options={channelTypes}
      onChange={onChange}
    />
  );
};

export default ChannelDropdown;
