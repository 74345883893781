import * as React from 'react';

import type { PropsWithChildren } from 'react';

import ErrorMessage from './ErrorMessage';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
  state = {
    hasError: false
  };

  componentDidCatch(error: Error) {
    console.error('ErrorBoundary caught an error:', error);
    this.setState({ hasError: true });
  }

  componentDidUpdate(props: PropsWithChildren) {
    if (props.children !== this.props.children) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage negative onClick={() => this.setState({ hasError: false })} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
