import type { ChangeEvent, VFC } from 'react';
import React from 'react';
import { Message, TextArea } from 'semantic-ui-react';
import type { InfoFieldProps } from './InfoFieldProps';

interface TextAreaFieldProps extends Pick<InfoFieldProps, 'field' | 'setValue' | 'onSaveData'> {
  fieldValue: any;
  disabled?: boolean;
  mongoSaveDisabled?: boolean;
  integrationSaveDisabled?: boolean;
}

const TextAreaField: VFC<TextAreaFieldProps> = ({
  field,
  fieldValue,
  disabled,
  mongoSaveDisabled,
  integrationSaveDisabled,
  setValue,
  onSaveData
}) => {
  const { regex, flags, errorText } = field.validation || {};
  const error = regex ? !new RegExp(regex, flags).test(fieldValue) : false;
  return (
    <>
      <TextArea
        error={error}
        disabled={disabled}
        style={error ? { borderColor: '#e0b4b4', backgroundColor: '#fff6f6', color: '#9f3a38' } : undefined}
        onChange={(_e, { value }) => {
          if (!integrationSaveDisabled && !mongoSaveDisabled) {
            setValue?.(field, value);
          }
        }}
        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
          if (!integrationSaveDisabled && !mongoSaveDisabled) {
            onSaveData?.(field, e.target.value);
          }
        }}
        rows={3}
        value={fieldValue || ''}
        fluid={true}
      />
      {error && <Message error content={errorText} />}
    </>
  );
};

export default React.memo(TextAreaField);
