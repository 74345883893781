import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import EnreachVoiceIntegration from 'src/Utilities/intergrations/callEnreachVoice';
import type { State } from 'src/types/initialState';

const EnreachVoiceButtons = () => {
  const { t } = useTranslation();
  const { enreachVoiceLoggedIn, enreachVoiceStatus } = useSelector((state: State) => state.integrations);
  const [answerEnabled, setAnswerEnabled] = useState(false);
  const [rejectEnabled, setRejectEnabled] = useState(false);

  useEffect(() => {
    switch (enreachVoiceStatus) {
      case 'call-incoming':
        setAnswerEnabled(true);
        setRejectEnabled(true);
        break;
      case 'call-answered':
        setAnswerEnabled(false);
        setRejectEnabled(true);
        break;
      case 'call-ended':
        setAnswerEnabled(false);
        setRejectEnabled(false);
        break;
    }
  }, [enreachVoiceStatus]);

  if (!enreachVoiceLoggedIn) {
    return null;
  }

  return (
    <Grid.Row columns={2}>
      <Grid.Column className="topBarButtonColumn">
        <Button
          disabled={!answerEnabled}
          fluid
          icon
          labelPosition="left"
          positive
          onClick={EnreachVoiceIntegration.answerEnreachVoice}
        >
          <Icon name="phone" />
          {t('ANSWER_CALL')}
        </Button>
      </Grid.Column>
      <Grid.Column className="topBarButtonColumn">
        <Grid.Column className="topBarButtonColumn">
          <Button
            disabled={!rejectEnabled}
            fluid
            icon
            labelPosition="left"
            negative
            onClick={EnreachVoiceIntegration.rejectEnreachVoice}
          >
            <Icon name="phone" />
            {t('REJECT_CALL')}
          </Button>
        </Grid.Column>
      </Grid.Column>
    </Grid.Row>
  );
};

export default EnreachVoiceButtons;
