import type { CSSProperties } from 'react';

export const searchWrapper: CSSProperties = { marginBottom: '0px' };

export const bottomSectionWrapper: CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
};
