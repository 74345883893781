import { path } from 'ramda';

import ApiConfig from './ApiConfig';
import { apiClient } from 'src/Utilities/httpClients';

import type { RescheduledCallback } from 'src/types/RescheduledCallback';

export default class RescheduledCallbacksApi {
  static async getRescheduledCallbacks(contentId: number): Promise<RescheduledCallback[] | undefined> {
    return apiClient.get(`${ApiConfig.getConfig().API_URL}/rescheduled-callbacks/${contentId}`).then(path(['data']));
  }

  static async createRescheduledCallback(
    rescheduledCallback: Omit<RescheduledCallback, 'id' | 'created'>
  ): Promise<RescheduledCallback | undefined> {
    return apiClient
      .post<RescheduledCallback>(`${ApiConfig.getConfig().API_URL}/rescheduled-callbacks`, rescheduledCallback)
      .then(path(['data']));
  }
}
