import * as yup from 'yup';

import type { SalesforceObjectType } from 'src/types/Salesforce';

export const taskValidationSchema = yup.object().shape({
  Subject: yup.string().required(),
  Description: yup.string(),
  Status: yup.string().required(),
  WhoId: yup.string()
});

export const caseValidationSchema = yup.object().shape({
  Subject: yup.string().required(),
  Description: yup.string(),
  Status: yup.string().required(),
  AccountId: yup.string()
});

export const leadValidationSchema = yup.object().shape({
  Name: yup.string().required(),
  Description: yup.string(),
  Status: yup.string().required(),
  Company: yup.string().required()
});

export const opportunityValidationSchema = yup.object().shape({
  Name: yup.string().required(),
  Description: yup.string(),
  StageName: yup.string().required(),
  Amount: yup.string(),
  AccountId: yup.string().required()
});

export const emailValidationSchema = yup.object().shape({
  Emails: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().email('All values should be email addresses')
      })
    )
    .min(1, 'Should contain at least one email address')
    .required(),
  Description: yup.string().required()
});

export const callValidationSchema = yup.object().shape({
  Phones: yup
    .array()
    .of(
      yup.object().shape({
        value: yup
          .string()
          .matches(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/, 'All values should be phone numbers')
      })
    )
    .min(1, 'Should contain at least one phone number')
    .required(),
  Description: yup.string().required()
});

export const salesforceValidationSchemasMap: Partial<Record<SalesforceObjectType, yup.AnyObjectSchema>> = {
  Task: taskValidationSchema,
  Lead: leadValidationSchema,
  Opportunity: opportunityValidationSchema,
  Email: emailValidationSchema,
  Call: callValidationSchema,
  Case: caseValidationSchema
};
