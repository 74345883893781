import { FETCH_LINKLISTS_SUCCESS } from '../actions/index';

const initialState: any = [];

const linkListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_LINKLISTS_SUCCESS: {
      return action.payload.linkLists;
    }
    default:
      return state;
  }
};

export default linkListReducer;
