import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash-es';

import type { ResponseTemplate } from '@eeedo/types';

import ResponseTemplateApi from '../api/ResponseTemplatesApi';
import { startAjaxCall } from './ajaxStatusActions';
import { initialRequestFailure, initialRequestSuccess } from './initActions';
import { loadResponseTemplatesFailure } from './ticketsActions';
import { loadResponseTemplatesSuccess } from 'src/reducers/templatesReducer';

export const fetchResponseTemplates = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchResponseTemplates',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_RESPONSE_TEMPLATES' }));
    try {
      const templates = await ResponseTemplateApi.getResponseTemplates();
      dispatch(loadResponseTemplatesSuccess(templates));
      dispatch(initialRequestSuccess('responseTemplates'));
    } catch (error) {
      console.error('Failed to load response templates', (error as Error).message);
      dispatch(loadResponseTemplatesFailure(error as Error));
      dispatch(initialRequestFailure('responseTemplates', error));
    }
  }
);

export const createResponseTemplate = createAsyncThunk<
  ResponseTemplate,
  { template: ResponseTemplate },
  { rejectValue: Error }
>('createResponseTemplate', async ({ template }) => {
  return ResponseTemplateApi.createTemplate(omit(template, '_id'));
});

export const updateResponseTemplate = createAsyncThunk<
  ResponseTemplate,
  { template: ResponseTemplate },
  { rejectValue: Error }
>('updateResponseTemplate', async ({ template }) => {
  return ResponseTemplateApi.updateTemplate(template);
});

export const deleteResponseTemplate = createAsyncThunk<ResponseTemplate, { id: string }, { rejectValue: Error }>(
  'deleteResponseTemplate',
  async ({ id }) => {
    return ResponseTemplateApi.deleteTemplate(id);
  }
);
