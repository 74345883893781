import type { ForeignIdRecord } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class ForeignIdApi {
  static searchForeignId = async (params: {
    foreignId?: string | number;
    foreignIdType?: string;
    localIdType?: string;
    localId?: string;
  }): Promise<ForeignIdRecord[]> =>
    apiClient
      .post('/foreignids/search', {
        foreignId: null,
        foreignIdType: null,
        localIdType: null,
        localId: null,
        ...params
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(`Could not search for comments ${error.message}`);
        throw error;
      });
}
