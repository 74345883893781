import type { FC } from 'react';
import { useEffect } from 'react';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResizableContainerProps {
  children: React.ReactNode;
  onSizeChange(width: number, height: number): void;
}

const ResizeDetectorContainer: FC<ResizableContainerProps> = ({ children, onSizeChange }) => {
  const { width, height, ref } = useResizeDetector();

  useEffect(() => {
    onSizeChange(width ?? 0, height ?? 0);
  }, [width, height]);

  return <div ref={ref}>{children}</div>;
};

export default React.memo(ResizeDetectorContainer);
