import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';

import type { FilterPreset, FilterPresetPayload, FilterPresetView } from '@eeedo/types';

import FilterPresetsApi from 'src/Components/Management/FilterPresets/FilterPresetsApi';

export const fetchFilterPresetsSuccess = createAction<{ presets: FilterPreset[] }>('fetchFilterPresetsSuccess');

export const updateFilterPresetSuccess = createAction<{ preset: FilterPreset }>('updateFilterPresetSuccess');

export const createFilterPresetSuccess = createAction<{ preset: FilterPreset }>('createFilterPresetSuccess');

export const deleteFilterPresetSuccess = createAction<{ filterPresetId: number }>('deleteFilterPresetSuccess');

export const setActiveFilterPreset = createAction<{ filterPresetId: number | null }>('setActiveFilterPreset');

export const resetFilterPresets = createAction('resetFilterPresets');

export const fetchFilterPresets = createAsyncThunk<
  { status: string },
  { view?: FilterPresetView },
  { rejectValue: Error }
>('fetchFilterPresets', async ({ view }, { dispatch }) => {
  try {
    const presets = view
      ? await FilterPresetsApi.getWidgetFilterPresets(view)
      : await FilterPresetsApi.getFilterPresets();
    dispatch(fetchFilterPresetsSuccess({ presets }));
    return { status: 'success' };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('filter_presets.failed_to_fetch'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'failed' };
  }
});

export const updateFilterPreset = createAsyncThunk<
  { status: string },
  { filterPresetId: number; payload: FilterPresetPayload },
  { rejectValue: Error }
>('updateFilterPreset', async ({ filterPresetId, payload }, { dispatch }) => {
  try {
    const preset = await FilterPresetsApi.updateFilterPreset(filterPresetId, payload);
    dispatch(updateFilterPresetSuccess({ preset }));
    iziToast.success({
      title: t('GENERAL_SAVED'),
      icon: 'icon check',
      timeout: 5000
    });
    return { status: 'success' };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('filter_presets.failed_to_update'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'failed' };
  }
});

export const createFilterPreset = createAsyncThunk<
  { status: string; filterPresetId?: number },
  { payload: FilterPresetPayload },
  { rejectValue: Error }
>('createFilterPreset', async ({ payload }, { dispatch }) => {
  try {
    const preset = await FilterPresetsApi.createFilterPreset(payload);
    dispatch(createFilterPresetSuccess({ preset }));
    iziToast.success({
      title: t('GENERAL_CREATED'),
      icon: 'icon check',
      timeout: 5000
    });
    return { status: 'success', filterPresetId: preset.id };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('filter_presets.failed_to_create'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'failed' };
  }
});

export const deleteFilterPreset = createAsyncThunk<
  { status: string },
  { filterPresetId: number },
  { rejectValue: Error }
>('deleteFilterPreset', async ({ filterPresetId }, { dispatch }) => {
  try {
    await FilterPresetsApi.deleteFilterPreset(filterPresetId);
    dispatch(deleteFilterPresetSuccess({ filterPresetId }));
    iziToast.success({
      title: t('GENERAL_DELETED'),
      icon: 'icon check',
      timeout: 5000
    });
    return { status: 'success' };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('filter_presets.failed_to_delete'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'failed' };
  }
});
