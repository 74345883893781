import EnvSettings from 'src/api/EnvSettings';

const isDefined = (value?: string) => value !== '' && typeof value !== 'undefined';

export const hasPhoneIntegrations = () => {
  const { OC_URL, ELISARING_URL, MITEL_URL, ENREACH_VOICE_URL } = EnvSettings.getSettings();

  return {
    hasElisaOCIntegration: isDefined(OC_URL),
    hasElisaRingIntegration: isDefined(ELISARING_URL),
    hasMitelIntegration: isDefined(MITEL_URL),
    hasEnreachVoiceIntegration: isDefined(ENREACH_VOICE_URL)
  };
};
