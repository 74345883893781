import createAuthRefreshInterceptor from 'axios-auth-refresh';

import type { AxiosInstance } from 'axios';

import { NOT_ACCEPTABLE } from './httpClients';
import { dispatchExpiredLogoutOnce, refreshToken } from 'src/actions/authActions';
import { store } from 'src/store';

import type { ThunkAppDispatch } from 'src/types/store';

const dispatch: ThunkAppDispatch = store.dispatch;

export const applyTokenInterceptor = (httpClient: AxiosInstance) => {
  httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === NOT_ACCEPTABLE) {
        dispatchExpiredLogoutOnce(dispatch);
      }

      return Promise.reject(error);
    }
  );

  createAuthRefreshInterceptor(httpClient, () => {
    return refreshToken(true)(dispatch);
  });
};
