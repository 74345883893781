import { postMessageToIFrame } from './postMessageToIFrame';

export const callElisaOC = (phoneNumber: string, serviceName?: string) => {
  postMessageToIFrame('ElisaOC', {
    type: 'outboundCallData',
    payload: {
      serviceName: serviceName || 'Asiakaspalvelu',
      phonenumber: phoneNumber
    }
  });
};
