import React from 'react';
import { useTranslation } from 'react-i18next';

import CommentIconHeader from './CommentIconHeader';

interface CaseDetailsDataContentProps {
  index: number;
  ticketDetailsFields: any;
  data: any;
}

const Content = (props: { index: number; details: JSX.Element }) => {
  const { t } = useTranslation();

  return (
    <CommentIconHeader index={props.index} title={t('COMMENT_METADATA_DETAILS')}>
      {props.details}
    </CommentIconHeader>
  );
}

function InfoToDetails(field: any, data: any) {
  if (field.object && data[field.object]) {
    return <p>{field.name + ': ' + (data[field.object][field.value] || '')}</p>;
  } else {
    return <p>{field.name + ': ' + (data[field.value] || '')}</p>;
  }
}

function CaseDetailsDataContent(props: CaseDetailsDataContentProps) {
  const { index, ticketDetailsFields, data } = props;

  let details: JSX.Element;

  if (ticketDetailsFields && ticketDetailsFields.caseInfo) {
    details =
      ticketDetailsFields?.caseInfo && ticketDetailsFields.caseInfo.map((cField: any) => InfoToDetails(cField, data));
  } else if (ticketDetailsFields?.case) {
    details = ticketDetailsFields?.case && ticketDetailsFields.case.map((cField: any) => InfoToDetails(cField, data));
  } else {
    details = <p>INVALID case details variable name in ticket</p>;
  }

  return <Content index={index} details={details} />;
}

export default CaseDetailsDataContent;
